import { useState } from "react";

import { toast } from "react-toastify";

import { getErrorMessage } from "../../common/utils/formatter.util";
import { useServiceContainer } from "../useServiceContainer";

export const useDeliveryServiceExcelUploadModal = (onUploaded: () => void) => {
  const [loader, setLoader] = useState(false);
  const { backofficeService } = useServiceContainer();

  const onSubmitHandler = async (data: any) => {
    if (!data.file) return;
    setLoader(true);
    try {
      await backofficeService.uploadDeliveryServiceExcel(data.file);
      onUploaded();
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setLoader(false);
    }
  };

  return {
    onSubmitHandler,
    loader
  };
};
