import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { ProductAssetsState } from "../../common/interfaces/product-assets-slice.interface";
import { IProductAssetItem } from "../../common/interfaces/products.interface";
import { ASSETS_ACTION_UPDATE_LIST } from "./ThunkActions/assetsSliceThunkActions";

export const productAssetsInitialState: ProductAssetsState = {
  selectedAsset: null,
  assetList: {
    loader: false,
    data: []
  }
};

export const productAssetsSlice = createSlice({
  name: "productAssets",
  initialState: productAssetsInitialState,
  reducers: {
    ASSETS_SET_SELECTED: (state: ProductAssetsState, action: PayloadAction<IProductAssetItem | null>) => {
      state.selectedAsset = action.payload;
    },
    ASSETS_RESET_SELECTED: (state: ProductAssetsState) => {
      state.selectedAsset = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(ASSETS_ACTION_UPDATE_LIST.pending, (state) => {
        state.assetList.loader = true;
      })
      .addCase(ASSETS_ACTION_UPDATE_LIST.fulfilled, (state, action) => {
        state.assetList.data = action.payload;
        // eslint-disable-next-line prefer-destructuring
        state.selectedAsset = action.payload[0];
        state.assetList.loader = false;
      });
  }
});

export const { ASSETS_SET_SELECTED, ASSETS_RESET_SELECTED } = productAssetsSlice.actions;

export default productAssetsSlice.reducer;

export * from "./ThunkActions/assetsSliceThunkActions";
