import React, { useCallback, useEffect, useMemo, useState } from "react";

// @ts-ignore
import { yupResolver } from "@hookform/resolvers/yup";
import debounce from "lodash.debounce";
import moment from "moment/moment";
import Flatpickr from "react-flatpickr";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Card, CardBody, Col, FormGroup, InputGroup, Label, Row } from "reactstrap";
import * as Yup from "yup";

import { IIncomingPayment, IInvoiceClient } from "../../../common/interfaces/invoices.interface";
import { getErrorMessage } from "../../../common/utils/formatter.util";
import { InputField } from "../../../components/common/InputField";
import { Loader } from "../../../components/common/Loader";
import { SelectField } from "../../../components/common/SelectField";
import { useServiceContainer } from "../../../hooks/useServiceContainer";

import "flatpickr/dist/themes/material_blue.css";
import { DatePickerField } from "../../../components/common/DatePickerField";
import { getObjectDelta } from "../../../common/utils/object.util";

const validationSchema = Yup.object().shape({
  client_id: Yup.string().required("This field is required"),
  amount: Yup.number().typeError("This field is required").min(0).required("This field is required"),
  date: Yup.string()
});

const validationSchemaEdit = Yup.object().shape({
  client_id: Yup.string(),
  amount: Yup.number().typeError("This field is required").min(0).required("This field is required"),
  date: Yup.string()
});

interface Props {
  isEdit?: boolean;
}

export const RegisterPayment: React.FC<Props> = ({ isEdit }) => {
  const [loader, setLoader] = useState(false);
  const [clientsLoader, setClientsLoader] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [existingPayment, setExistingPayment] = useState<IIncomingPayment | null>(null);
  const [clients, setClientList] = useState<IInvoiceClient[]>([]);
  const [selectedClient, setSelectedClient] = useState<IInvoiceClient | null>(null);
  const { backofficeService } = useServiceContainer();
  const { id } = useParams();

  const navigate = useNavigate();

  const {
    register,
    formState: { errors },
    handleSubmit,
    setError,
    setValue,
    watch
  } = useForm({
    defaultValues: {} as any,
    resolver: yupResolver(isEdit ? validationSchemaEdit : validationSchema)
  });

  const dateValue = watch("date");

  const selectOptions = useMemo(() => {
    return clients.map((e) => {
      return {
        label: `[${e.id}] ${e.name} ${e.surname} | Company: ${e.company_title} | VAT: ${e.vat_nr} | Reg. nr: ${
          e.reg_nr
        } | Email: ${e.users_email ?? ""}`,
        value: e.id.toString()
      };
    });
  }, [clients]);

  const selectedOption = useMemo(() => {
    if (!selectedClient) return null;

    return {
      label: `[${selectedClient.id}] ${selectedClient.name} ${selectedClient.surname} | Company: ${
        selectedClient.company_title
      } | VAT: ${selectedClient.vat_nr} | Reg. nr: ${selectedClient.reg_nr} | Email: ${
        selectedClient.users_email ?? ""
      }`,
      value: selectedClient.id.toString()
    };
  }, [selectedClient]);

  const loadExistingPayment = async (paymentId: string) => {
    setLoader(true);
    try {
      const data = await backofficeService.getIncomingPaymentById(paymentId);
      setExistingPayment(data);
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setLoader(false);
    }
  };

  const loadClients = async (search?: string) => {
    try {
      setClientsLoader(true);
      const items = await backofficeService.getInvoiceClients(search);
      setClientList(items);
    } catch (e) {
      toast.error(await getErrorMessage(e));
    } finally {
      setClientsLoader(false);
    }
  };

  const debouncedLoadClients = useCallback(debounce(loadClients, 500), []);

  const handleClientChange = (e: any) => {
    const target = clients.find((client) => client.id === +e.value);

    if (!target) return;

    setSelectedClient(target);

    setValue("client_id", target.id.toString());
    setError("client_id", {
      type: "manual",
      message: ""
    });
  };

  const handleDateChange = (date: string) => {
    setValue("date", date);
    setError("date", {
      type: "manual",
      message: ""
    });
  };

  const onSubmitHandler = async (data: any) => {
    try {
      setLoader(true);

      if (isEdit) {
        if (!data.amount || !existingPayment) {
          return;
        }
        const origin = {
          amount: +existingPayment.payment_amount,
          date: existingPayment.payment_date,
          client_id: +existingPayment.customer_id
        };

        const newData = {
          amount: +data.amount,
          date: moment(data.date, "DD/MM/YYYY").format("YYYY-MM-DD"),
          client_id: +data.client_id || +existingPayment.customer_id
        };

        const delta = getObjectDelta(origin, newData);
        await backofficeService.updatePayment(existingPayment.id, delta);
      } else {
        if (!data.client_id || !data.amount) {
          return;
        }
        await backofficeService.registerPayment(
          +data.client_id,
          +data.amount,
          moment(data.date, "DD/MM/YYYY").format("YYYY-MM-DD")
        );
      }

      navigate("/backoffice/invoices/incoming-payments");

      toast.success(!isEdit ? "Payment registered successfully" : "Payment updated successfully");
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (!searchValue) {
      setClientList([]);
      return;
    }
    debouncedLoadClients(searchValue);
  }, [searchValue]);

  useEffect(() => {
    if (isEdit) {
      if (!id) {
        navigate("/backoffice/invoices/incoming-payments");
      } else {
        loadExistingPayment(id).then(() => null);
      }
    }
  }, []);

  useEffect(() => {
    if (existingPayment) {
      setValue("amount", existingPayment.payment_amount);
      setValue("date", moment(existingPayment.payment_date).format("DD/MM/YYYY"));
    }
  }, [existingPayment]);

  return (
    <div className="data-table">
      <Card className="border">
        {loader && <Loader />}
        <CardBody>
          <form onSubmit={handleSubmit(onSubmitHandler)}>
            <Row>
              <Col lg="6" className="mx-auto">
                <h3 className="mb-4">{isEdit ? "Edit payment" : "Register payment"}</h3>

                {isEdit && existingPayment && (
                  <InputField
                    label="Current client"
                    readOnly
                    type="text"
                    name="current_client"
                    value={`[${existingPayment.customer_id}] ${existingPayment.customer.name} ${existingPayment.customer.surname}`}
                  />
                )}

                <SelectField
                  label={isEdit ? "New client" : "Client"}
                  options={selectOptions}
                  placeholder="Search client by id, name, surname, company title, VAT, Email or reg. nr."
                  inputValue={searchValue}
                  error={errors.client_id?.message}
                  required={!isEdit}
                  value={selectedOption}
                  isLoading={clientsLoader}
                  onInputChange={(e: any) => {
                    setSearchValue(e);
                  }}
                  onChange={handleClientChange}
                  filterOption={() => {
                    return true;
                  }}
                />

                <InputField
                  {...register("amount")}
                  error={errors.amount?.message}
                  label="Amount"
                  required
                  type="number"
                  step="0.01"
                  name="amount"
                />

                <DatePickerField
                  label="Date"
                  value={dateValue}
                  error={errors.date?.message}
                  onChange={(dates: Date[], dateStr: string) => {
                    handleDateChange(dateStr);
                  }}
                />

                <Button type="submit" color="success">
                  Save
                </Button>

                <Button
                  type="button"
                  color="primary"
                  className="ms-2"
                  onClick={() => {
                    navigate("/backoffice/invoices/incoming-payments");
                  }}
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          </form>
        </CardBody>
      </Card>
    </div>
  );
};
