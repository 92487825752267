import React, { useMemo, useState } from "react";

import moment from "moment/moment";
import BootstrapTable from "react-bootstrap-table-next";
// @ts-ignore
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { Button, Col, Row } from "reactstrap";

import { IHistoryEntity } from "../../common/interfaces/history.interface";
import { IIncomingPayment } from "../../common/interfaces/invoices.interface";
import { transformHistoryData } from "../../common/utils/history-transformer.util";
import { getObjectDelta, getObjectEntriesDelta } from "../../common/utils/object.util";

const transformDict = {
  amount: "Payment amount",
  payment_amount: "Payment amount",
  payment_date: "Payment date",
  customer_id: "Client id",
  created_at: "Creation date",
  date: "Payment date",
  client_id: "Client id"
};

export const useIncomingPaymentTableExpandControl = (options: { items: IIncomingPayment[] }) => {
  const [expandedRows, setExpandedRows] = useState<number[]>([]);

  const nonExpandableItemIds = useMemo(() => {
    return options.items.filter((item) => item.history.length === 0).map((item) => item.id);
  }, [options.items]);

  const onRowExpand = (row: IIncomingPayment, isExpand: boolean) => {
    if (isExpand) {
      setExpandedRows([row.id]);
    } else {
      setExpandedRows([]);
    }
  };

  const expandableRowColumns = useMemo(() => {
    return [
      {
        dataField: "created_at",
        text: "Creation date",
        formatter: (cellContent: string) => {
          return moment(cellContent).format("DD.MM.YYYY HH:mm");
        }
      },
      {
        dataField: "original_data",
        text: "Original data",
        formatter: (cellContent: string, row: IHistoryEntity) => {
          if (!cellContent || cellContent === "null") return "";
          const transformedData = transformHistoryData(cellContent, transformDict);
          const transformerInputData = transformHistoryData(row.input_data, transformDict);
          const data = transformedData.filter((e) => {
            const target = transformerInputData.find((i) => i[0] === e[0]);
            return !!target;
          });

          return (
            <>
              <ul>
                {data.map((e, idx) => (
                  <li key={idx}>
                    {e[0]}: {e[1]}
                  </li>
                ))}
              </ul>
            </>
          );
        }
      },
      {
        dataField: "input_data",
        text: "Input data",
        formatter: (cellContent: string) => {
          const data = transformHistoryData(cellContent, transformDict);
          return (
            <>
              <ul>
                {data.map((e, idx) => (
                  <li key={idx}>
                    {e[0]}: {e[1]}
                  </li>
                ))}
              </ul>
            </>
          );
        }
      }
    ];
  }, []);

  const expandRowData = {
    renderer: (row: IIncomingPayment) => {
      return (
        <ToolkitProvider keyField="id" columns={expandableRowColumns} data={row.history}>
          {(toolkitProps: any) => (
            <>
              <Row>
                <Col xl="12">
                  <div className="table-responsive">
                    <BootstrapTable
                      bootstrap4
                      // onTableChange={onTableChange}
                      bordered={false}
                      striped={false}
                      classes="table align-middle table-nowrap table-sm"
                      headerWrapperClasses="thead-light"
                      {...toolkitProps.baseProps}
                      keyField="id"
                    />
                  </div>
                </Col>
              </Row>
            </>
          )}
        </ToolkitProvider>
      );
    },
    showExpandColumn: true,
    expanded: expandedRows,
    nonExpandable: nonExpandableItemIds,
    className: "order-management-table-expanded-row",
    onExpand: onRowExpand,
    expandHeaderColumnRenderer: () => null,
    expandColumnRenderer: (data: { rowKey: string; expanded: boolean; expandable: boolean }) => {
      if (!data.expandable) return "";

      const item = options.items.find((e) => e.id === +data.rowKey);

      if (!item) return "";

      return (
        <span className="react-boostrap-table-expandable-row-control">
          {data.expanded ? "-" : "+"}({item.history.length})
        </span>
      );
    }
  };

  return {
    expandedRows,
    nonExpandableItemIds,
    expandRowData,
    onRowExpand
  };
};
