import { OrderDeliveryData } from "../interfaces/order-wizard.interface";

export const isDeliveryDataCompleted = (deliveryData: OrderDeliveryData): boolean => {
  return !!(
    deliveryData.fullName &&
    deliveryData.addressLine1 &&
    deliveryData.city &&
    deliveryData.zipCode &&
    deliveryData.country &&
    deliveryData.deliveryOption &&
    deliveryData.paymentMethod
  );
};

export const isOrderDataCompleted = (data: {
  productId: number;
  productAssetId: number;
  designId: number;
}): boolean => {
  return !!(data.productId && data.productAssetId && data.designId);
};
