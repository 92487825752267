import React, { useEffect, useMemo, useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Button, CardBody, Modal } from "reactstrap";
import * as Yup from "yup";

import { PRODUCT_TYPES } from "../../../common/constants/product-types.constant";
import { ICategoryItem, ProductType } from "../../../common/interfaces/products.interface";
import { getErrorMessage } from "../../../common/utils/formatter.util";
import { useServiceContainer } from "../../../hooks/useServiceContainer";
import { RichTextEditor } from "../../common/Input/RichTextEditor";
import { InputField } from "../../common/InputField";
import { Loader } from "../../common/Loader";
import { SelectField } from "../../common/SelectField";
import { FileUploadStep } from "../../modals/create-design/FileUploadStep";

const validationSchema = Yup.object().shape({
  title: Yup.string().required("This field is required"),
  default_price: Yup.string().required("This field is required"),
  printfile_width: Yup.string().required("This field is required"),
  max_designs: Yup.string().required("This field is required"),
  printfile_height: Yup.string().required("This field is required"),
  image: Yup.mixed().required("This field is required"),
  image_hover: Yup.mixed().required("This field is required"),
  description: Yup.string().required("This field is required"),
  type: Yup.string().required("This field is required"),
  category_id: Yup.string().required("This field is required"),
  gallery_images: Yup.array().of(Yup.mixed()).min(1, "Please upload at least one file")
});

interface Props {
  show: boolean;
  toggle: () => void;
}

export const CreateProductModal: React.FC<Props> = ({ show, toggle }) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    watch,
    setError
  } = useForm({
    defaultValues: {
      title: "",
      default_price: "",
      image: null,
      image_hover: null,
      description: "",
      type: "",
      category_id: "",
      printfile_width: "",
      max_designs: "",
      printfile_height: "",
      gallery_images: []
    } as any,
    resolver: yupResolver(validationSchema)
  });

  const [loader, setLoader] = useState(false);

  const [categories, setCategories] = useState<ICategoryItem[]>([]);

  const { backofficeService } = useServiceContainer();

  const formTypeValue = watch("type");
  const formCategoryValue = watch("category_id");
  const description = watch("description");

  const productTypes = useMemo(() => {
    return PRODUCT_TYPES;
  }, []);

  const categoriesOptions = useMemo(() => {
    return categories.map((category) => ({
      value: category.id.toString(),
      label: category.title
    }));
  }, [categories]);

  const selectedType = useMemo(() => {
    return productTypes.find((type) => type.value === formTypeValue) ?? null;
  }, [formTypeValue, productTypes]);

  const selectedCategory = useMemo(() => {
    return categoriesOptions.find((type) => +type.value === formCategoryValue) ?? null;
  }, [formCategoryValue, categoriesOptions]);

  const onSubmitHandler = async (data: any) => {
    try {
      setLoader(true);
      await backofficeService.createProduct(data);
      toggle();
      reset();
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setLoader(false);
    }
  };

  const fetchCategories = async () => {
    try {
      const res = await backofficeService.getProductsCategories();
      setCategories(res);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (show) {
      reset();
      fetchCategories().then(() => null);
    }
  }, [show]);

  return (
    <Modal isOpen={show} toggle={toggle} centered size="lg">
      {loader && <Loader />}
      <div className="modal-header">
        <h5 className="modal-title mt-0">Create new product</h5>
        <button
          type="button"
          onClick={() => {
            toggle();
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form onSubmit={handleSubmit(onSubmitHandler)}>
        <div className="modal-body">
          <div className="row">
            <div className="col-12">
              <div className="card border">
                <CardBody>
                  <InputField
                    {...register("title")}
                    error={errors.title?.message}
                    label="Product title"
                    name="title"
                    placeholder="Enter product title"
                  />

                  <InputField
                    {...register("default_price")}
                    error={errors.default_price?.message}
                    label="Product default price"
                    name="default_price"
                    type="number"
                    step="0.01"
                    placeholder="Enter product default price"
                  />

                  <RichTextEditor
                    value={description}
                    error={errors.description?.message}
                    name="description"
                    onChange={(val) => {
                      setValue("description", val);
                      setError("description", {
                        type: "manual",
                        message: ""
                      });
                    }}
                    label="Description"
                  />

                  <SelectField
                    options={productTypes}
                    label="Product type"
                    error={errors.type?.message}
                    value={selectedType}
                    placeholder="Search.."
                    onChange={(data) => {
                      setValue("type", data.value);
                      setError("type", {
                        type: "manual",
                        message: ""
                      });
                    }}
                  />

                  <InputField
                    {...register("printfile_width")}
                    error={errors.printfile_width?.message}
                    label="Print file width"
                    name="printfile_width"
                    type="number"
                    step="1"
                    placeholder="Enter print file width (in pixels)"
                  />

                  <InputField
                    {...register("printfile_height")}
                    error={errors.printfile_height?.message}
                    label="Print file height"
                    name="printfile_height"
                    type="number"
                    step="1"
                    placeholder="Enter print file height (in pixels)"
                  />

                  <InputField
                    {...register("max_designs")}
                    error={errors.max_designs?.message}
                    label="Max designs count"
                    name="max_designs"
                    type="number"
                    step="1"
                    placeholder="Enter max designs count (for mockups)"
                  />

                  <SelectField
                    options={categoriesOptions}
                    label="Product category"
                    error={errors.category_id?.message}
                    value={selectedCategory}
                    placeholder="Search.."
                    onChange={(data) => {
                      setValue("category_id", +data.value);
                      setError("category_id", {
                        type: "manual",
                        message: ""
                      });
                    }}
                  />

                  <FileUploadStep
                    hideSubmitButton
                    alwaysShowTitle
                    error={errors.image?.message}
                    title="Image"
                    small
                    containerSize={12}
                    onUpload={(file) => {
                      setValue("image", file);
                      setError("image", {
                        type: "manual",
                        message: ""
                      });
                    }}
                  />

                  <FileUploadStep
                    error={errors.image_hover?.message}
                    hideSubmitButton
                    alwaysShowTitle
                    title="Hover image"
                    small
                    containerSize={12}
                    onUpload={(file) => {
                      setValue("image_hover", file);
                      setError("image_hover", {
                        type: "manual",
                        message: ""
                      });
                    }}
                  />

                  <FileUploadStep
                    error={errors.gallery_images?.message}
                    hideSubmitButton
                    alwaysShowTitle
                    title="Gallery images"
                    small
                    multiple
                    containerSize={12}
                    onUpload={(file) => {
                      setValue("gallery_images", file);
                      setError("gallery_images", {
                        type: "manual",
                        message: ""
                      });
                    }}
                  />
                </CardBody>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <Button
            color="danger"
            onClick={() => {
              toggle();
            }}
          >
            Cancel
          </Button>
          <Button color="primary" type="submit">
            Save
          </Button>
        </div>
      </form>
    </Modal>
  );
};
