import { ICountryItem } from "./common.interface";
import { IOrderComment } from "./order-comments.interface";
import { IOrderDeliveryDataSubmitted, OrderDeliveryData, OrderDeliveryDataWithAmount } from "./order-wizard.interface";
import { IDesignItem, IProductItem, ProductAssetType } from "./products.interface";

export interface IOrderTableItem {
  id: string;
  creation_date: string;
  tracking_number: string;
  awb_number: string;
  tracking_url: string | null;
  country: ICountryItem;
  delivery_data: IOrderDeliveryDataSubmitted;
  clientName: string;
  is_paid: number;
  amount: number;
  amount_with_vat: number;
  children: IOrderTableItemChildren[];
  unread_comment_count: number;
}

export interface IOrderTableItemChildren {
  id: string;
  product: IProductItem;
  product_asset: OrderProductAsset;
  status: OrderStatus;
  count: number;
  gift_message: string | null;
  premium_bag: boolean;
  delivery_data: IOrderDeliveryDataSubmitted;
  comment: IOrderComment[];
  design: Pick<IDesignItem, "id" | "title"> & {
    print_file: string;
  };
}

export interface IOrderItem {
  id: string;
  creation_date: string;
  status: string;
  tracking_number: string;
  tracking_url: string | null;
  product: IProductItem;
  product_asset: OrderProductAsset;
  country: ICountryItem;
  count: number;
  delivery_data: IOrderDeliveryDataSubmitted;
  gift_message: string | null;
  premium_bag: boolean;
  clientName: string;
  is_paid: number;
  amount: number;
  amount_with_vat: number;
  design: Pick<IDesignItem, "id" | "title"> & {
    print_file: string;
  };
  user_id: number;
}

export interface OrderProductAsset {
  label: string;
  type: ProductAssetType;
  value: string;
}

export interface ICartOrder {
  amount: number;
  amountWithVat: number;
  totalAmountWithDelivery: number;
  totalAmountWithDeliveryWithVat: number;
  salesOrderId: number;
  deliveryData: OrderDeliveryDataWithAmount;
  products: ICardOrderProductItem[];
  created_at: string;
}

export interface ICardOrderProductItem {
  cart_order_id: number;
  product_id: number;
  amount: string;
  count: number;
  productAssetId: number;
  designId: number;
  giftMessage: string | null;
  includePremiumBag: boolean;
  created_at: string;
  sales_order_product_id: string;
  product: ICartOrderProduct;
  productAsset: ICartOrderProductAsset;
  design: ICartOrderDesign;
}

export interface ICartOrderProduct {
  name: string;
  id: number;
}

export interface ICartOrderDesign {
  id: number;
  title: string;
  print_file: string;
}

export interface ICartOrderProductAsset {
  description: string;
  id: number;
  image: string;
  price: number;
  title: string;
  type_id: number;
}

export enum OrderStatus {
  Created = "created",
  Open = "open",
  Closed = "closed",
  Deleted = "deleted",
  Restored = "restored",
  MissingInformation = "missing_information",
  ReadyToPrint = "ready_to_print",
  Processing = "processing",
  ReadyToSend = "ready_to_send"
}

export const ORDER_STATUSES = {
  [OrderStatus.Created]: "Created",
  [OrderStatus.Open]: "Open",
  [OrderStatus.Closed]: "Closed",
  [OrderStatus.Deleted]: "Deleted",
  [OrderStatus.Restored]: "Restored",
  [OrderStatus.MissingInformation]: "Missing information",
  [OrderStatus.ReadyToPrint]: "Ready to Print",
  [OrderStatus.Processing]: "Processing",
  [OrderStatus.ReadyToSend]: "Ready to Send"
};

export interface OrderSummaryData {
  productId: number;
  productAssetId: number;
  designId: number;
  giftMessage?: string;
  includePremiumBag: boolean;
  count: number;
  deliveryData: OrderDeliveryData;
}

export interface IDeliveryOption {
  id: number;
  name: string;
  image: string | null;
  price: number | null;
  price_with_vat: number | null;
  has_tracking: boolean;
}

export type OrderStatusMap = Record<string, string[]>;
