import React, { useEffect, useMemo, useState } from "react";

import _ from "lodash";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Card, CardBody, Col, Row } from "reactstrap";

import { OrderTrackingNumberUpdatedMessage } from "../../../common/interfaces/event-bus.interface";
import { IOrderDeliveryDataSubmitted } from "../../../common/interfaces/order-wizard.interface";
import { IOrderItem, OrderStatus } from "../../../common/interfaces/orders.interface";
import { UserRole } from "../../../common/interfaces/user.interface";
import { formatOrderStatus, getErrorMessage, getImageUrl } from "../../../common/utils/formatter.util";
import { OrderTrackingNumberSetter } from "../../../components/backoffice/common/OrderTrackingNumberSetter";
import { UpdateOrderDeliveryServiceModal } from "../../../components/backoffice/modals/UpdateOrderDeliveryServiceModal";
import { TextFieldInput } from "../../../components/common/Input/TextFieldInput";
import { Loader } from "../../../components/common/Loader";
import { OrderComment } from "../../../components/common/OrderComment/OrderComment";
import useEventBus from "../../../hooks/useEventBus";
import { useRole } from "../../../hooks/useRole";
import { useServiceContainer } from "../../../hooks/useServiceContainer";

interface Props {
  editable?: boolean;
}

export const OrderPage: React.FC<Props> = ({ editable }) => {
  const [data, setData] = useState<IOrderItem | null>(null);
  const [deliveryData, setDeliveryData] = useState<IOrderDeliveryDataSubmitted | null>(null);
  const [deliveryDataLoader, setDeliveryDataLoader] = useState(false);
  const [showUpdateDeliveryServiceModal, setShowUpdateDeliveryServiceModal] = useState(false);

  const { backofficeService } = useServiceContainer();
  const navigate = useNavigate();
  const eventBus = useEventBus();
  const { id } = useParams();
  const { hasRole } = useRole();

  const trackingNumber = useMemo(() => {
    if (!data) return "";
    if (data.tracking_number) {
      return data.tracking_url ? (
        <a href={data.tracking_url} target="_blank" rel="noreferrer">
          {data.tracking_number}
        </a>
      ) : (
        data.tracking_number
      );
    }

    if (hasRole(UserRole.Admin) && !data.delivery_data.deliveryServiceIsApi) {
      return <OrderTrackingNumberSetter orderId={data.id} />;
    }

    return "-";
  }, [data]);

  const handleDeliveryDataChange = (key: keyof IOrderDeliveryDataSubmitted, value: any) => {
    setDeliveryData((pv) => ({
      ...pv!,
      [key]: value
    }));
  };

  const fetchData = async () => {
    try {
      if (!id) return;
      const res = await backofficeService.getOrderById(id);

      setData(res);
      setDeliveryData(_.cloneDeep(res.delivery_data));
    } catch (e) {
      console.error(e);
    }
  };

  const handleUpdateDeliveryInformationClick = async () => {
    if (!editable) return;
    if (!deliveryData) return;

    const newDeliveryData = {
      fullName: deliveryData.fullName,
      phone: deliveryData.phone,
      email: deliveryData.email,
      addressLine1: deliveryData.addressLine1,
      addressLine2: deliveryData.addressLine2,
      state: deliveryData.state,
      zipCode: deliveryData.zipCode,
      country: deliveryData.country,
      city: deliveryData.city
    };

    try {
      setDeliveryDataLoader(true);
      await backofficeService.updateOrderDeliveryData(data!.id, newDeliveryData);
      toast.success("Delivery information updated successfully");
    } catch (e) {
      console.error(e);
      toast.error(getErrorMessage(e));
    } finally {
      setDeliveryDataLoader(false);
    }
  };

  const orderTrackingNumberUpdateHandler = (message: OrderTrackingNumberUpdatedMessage) => {
    setData((pv) => ({
      ...pv!,
      tracking_number: message.trackingNumber
    }));
  };

  useEffect(() => {
    const trackingNumberUpdateListener = eventBus.subscribe(
      "OrderTrackingNumberUpdated",
      orderTrackingNumberUpdateHandler
    );
    return () => {
      trackingNumberUpdateListener.unsubscribe();
    };
  }, []);

  useEffect(() => {
    fetchData().then(() => null);
  }, []);

  return (
    <Row className="order-details">
      {data && (
        <>
          <Col xs={12}>
            <Button
              onClick={() => {
                navigate(-1);
              }}
              className="btn btn-primary btn-sm mb-3"
            >
              <i className="bx bx-arrow-back" /> Back to orders
            </Button>
          </Col>
          <Col xs={8}>
            <Row>
              <Col xs={6}>
                <Card>
                  <CardBody>
                    <h3>Order data</h3>
                    <div className="table-responsive">
                      <table className="table table-nowrap table-sm">
                        <tbody>
                          <tr>
                            <td className="">Order id</td>
                            <td className="text-end">{data.id}</td>
                          </tr>
                          <tr>
                            <td className="">Status</td>
                            <td className="text-end">{formatOrderStatus(data.status as OrderStatus)}</td>
                          </tr>
                          <tr>
                            <td className="">Client name</td>
                            <td className="text-end">{data.clientName}</td>
                          </tr>
                          <tr>
                            <td className="">Product</td>
                            <td className="text-end">
                              {/* [{formatProductType(data.product.type)}] */}
                              {data.product.title}
                            </td>
                          </tr>
                          <tr>
                            <td className="">Asset</td>
                            <td className="text-end">{data.product_asset.label}</td>
                          </tr>
                          {data.count > 1 && (
                            <tr className="bg-warning">
                              <td className="">Asset count</td>
                              <td className="text-end">{data.count}</td>
                            </tr>
                          )}
                          <tr>
                            <td className="">Country</td>
                            <td className="text-end">{data.country.label}</td>
                          </tr>
                          <tr>
                            <td className="">Gift message</td>
                            <td className="text-end">{data.gift_message || "-"}</td>
                          </tr>
                          <tr>
                            <td className="">Premium bag</td>
                            <td className="text-end">{data.premium_bag ? "Yes" : "No"}</td>
                          </tr>
                          <tr className="table-light">
                            <td className="">Amount</td>
                            <td className="text-end">€{data.amount}</td>
                          </tr>
                          <tr className="table-light">
                            <td className="">Amount with VAT</td>
                            <td className="text-end">€{data.amount_with_vat}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col xs={6}>
                <Card>
                  <CardBody>
                    <h3>Delivery information</h3>
                    {deliveryData && (
                      <div className="table-responsive  delivery-data">
                        {deliveryDataLoader && <Loader />}
                        <table className="table table-nowrap table-sm">
                          <tbody>
                            <tr>
                              <td className="">Full name</td>
                              <td className="text-end">
                                {editable ? (
                                  <TextFieldInput
                                    value={deliveryData.fullName}
                                    onChange={(e) => {
                                      handleDeliveryDataChange("fullName", e.target.value);
                                    }}
                                  />
                                ) : (
                                  deliveryData.fullName
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td className="">Phone</td>
                              <td className="text-end">
                                {editable ? (
                                  <TextFieldInput
                                    value={deliveryData.phone}
                                    onChange={(e) => {
                                      handleDeliveryDataChange("phone", e.target.value);
                                    }}
                                  />
                                ) : (
                                  deliveryData.phone
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td className="">Address Line 1</td>
                              <td className="text-end">
                                {editable ? (
                                  <TextFieldInput
                                    value={deliveryData.addressLine1}
                                    onChange={(e) => {
                                      handleDeliveryDataChange("addressLine1", e.target.value);
                                    }}
                                  />
                                ) : (
                                  deliveryData.addressLine1
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td className="">Address Line 2</td>
                              <td className="text-end">
                                {editable ? (
                                  <TextFieldInput
                                    value={deliveryData.addressLine2}
                                    onChange={(e) => {
                                      handleDeliveryDataChange("addressLine2", e.target.value);
                                    }}
                                  />
                                ) : (
                                  deliveryData.addressLine2
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td className="">City</td>
                              <td className="text-end">
                                {editable ? (
                                  <TextFieldInput
                                    value={deliveryData.city}
                                    onChange={(e) => {
                                      handleDeliveryDataChange("city", e.target.value);
                                    }}
                                  />
                                ) : (
                                  deliveryData.city
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td className="">State</td>
                              <td className="text-end">
                                {editable ? (
                                  <TextFieldInput
                                    value={deliveryData.state}
                                    onChange={(e) => {
                                      handleDeliveryDataChange("state", e.target.value);
                                    }}
                                  />
                                ) : (
                                  deliveryData.state
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td className="">Country</td>
                              <td className="text-end">{data.country.label}</td>
                            </tr>
                            <tr>
                              <td className="">Zip code</td>
                              <td className="text-end">
                                {editable ? (
                                  <TextFieldInput
                                    value={deliveryData.zipCode}
                                    onChange={(e) => {
                                      handleDeliveryDataChange("zipCode", e.target.value);
                                    }}
                                  />
                                ) : (
                                  deliveryData.zipCode
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td className="">Delivery service</td>
                              <td className="text-end">
                                <div className="delivery-data__service">
                                  {data.delivery_data.deliveryService}
                                  {editable && (
                                    <Button
                                      color="link"
                                      size="sm"
                                      className="px-1 py-0"
                                      onClick={() => setShowUpdateDeliveryServiceModal(true)}
                                      type="button"
                                    >
                                      <i
                                        className="bx bxs-edit"
                                        style={{
                                          color: "red",
                                          fontSize: "17px"
                                        }}
                                      />
                                    </Button>
                                  )}
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="">Tracking number</td>
                              <td className="text-end">{trackingNumber}</td>
                            </tr>
                          </tbody>
                        </table>
                        {editable && (
                          <div className="d-flex justify-content-end">
                            <Button
                              color="primary"
                              size="sm"
                              onClick={handleUpdateDeliveryInformationClick}
                              type="button"
                            >
                              Update delivery information
                            </Button>
                          </div>
                        )}
                      </div>
                    )}
                  </CardBody>
                </Card>
              </Col>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <h3>Design</h3>

                    <div className="order-details__design">
                      <img src={getImageUrl(data.design.print_file)} alt="Design" />
                      <div className="order-details__design__title">{data.design.title}</div>

                      <a
                        className="btn btn-primary"
                        href={getImageUrl(data.design.print_file)}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="bx bx-download" /> Download
                      </a>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Col>
          <Col xs={4}>
            <OrderComment orderId={data.id} userId={data.user_id} />
          </Col>

          {editable && (
            <UpdateOrderDeliveryServiceModal
              order={data}
              toggle={() => {
                setShowUpdateDeliveryServiceModal(false);
              }}
              show={showUpdateDeliveryServiceModal}
              onUpdated={() => {
                fetchData().then(() => null);
              }}
            />
          )}
        </>
      )}
    </Row>
  );
};
