import React from "react";

import { Button } from "reactstrap";

import { MockupType } from "../../../common/interfaces/mockup-wizard.interface";

interface Props {
  onTypeSelect: (type: MockupType) => void;
}

export const MockupTypeSelection: React.FC<Props> = ({ onTypeSelect }) => {
  return (
    <div className="row justify-content-center align-items-center h-100">
      <div className="mockup-type-selection">
        <div className="mockup-type-selection__card green">
          <div className="mockup-type-selection__card__image">
            <img src="/assets/mockup-generator/auto-mockup.png" alt="AUTO Mockups" />
          </div>
          <div className="mockup-type-selection__card__body">
            <h5>AUTO Mockups</h5>
            <p>We will generate mockups for you automatically based on your print files</p>
            <Button
              onClick={() => {
                onTypeSelect(MockupType.AUTO);
              }}
              className="btn btn-info waves-effect waves-light"
            >
              Upload files
            </Button>
          </div>
        </div>
        <div className="mockup-type-selection__card purple">
          <div className="mockup-type-selection__card__image">
            <img src="/assets/mockup-generator/custom-mockup.png" alt="Custom Mockups" />
          </div>
          <div className="mockup-type-selection__card__body">
            <h5>Custom</h5>
            <p>Upload your own mockups</p>
            <Button
              onClick={() => {
                onTypeSelect(MockupType.CUSTOM);
              }}
              className="btn btn-info waves-effect waves-light"
            >
              Upload files
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
