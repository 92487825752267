import React, { useEffect, useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Button, Modal } from "reactstrap";
import * as Yup from "yup";

import { IInvoice } from "../../../common/interfaces/invoices.interface";
import { getErrorMessage } from "../../../common/utils/formatter.util";
import { useServiceContainer } from "../../../hooks/useServiceContainer";
import { DatePickerField } from "../../common/DatePickerField";
import { InputField } from "../../common/InputField";
import { Loader } from "../../common/Loader";

interface Props {
  show: boolean;
  selectedData: IInvoice | null;
  onSubmit: () => void;
  toggle: () => void;
}

const validationSchema = Yup.object().shape({
  client_id: Yup.string().required("This field is required"),
  amount: Yup.number().typeError("This field is required").min(0).required("This field is required"),
  date: Yup.string()
});

export const RegisterPaymentModal: React.FC<Props> = ({ selectedData, show, toggle, onSubmit }) => {
  const [loader, setLoader] = useState(false);

  const { backofficeService } = useServiceContainer();

  const {
    register,
    formState: { errors },
    handleSubmit,
    setError,
    setValue,
    watch
  } = useForm({
    defaultValues: {} as any,
    resolver: yupResolver(validationSchema)
  });

  const dateValue = watch("date");

  const handleDateChange = (date: string) => {
    setValue("date", date);
    setError("date", {
      type: "manual",
      message: ""
    });
  };

  const onSubmitHandler = async (data: any) => {
    try {
      console.log("data", data);
      setLoader(true);
      if (!data.client_id || !data.amount) {
        return;
      }
      await backofficeService.registerPayment(
        +data.client_id,
        +data.amount,
        moment(data.date, "DD/MM/YYYY").format("YYYY-MM-DD")
      );

      onSubmit();
      toggle();
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (selectedData) {
      setValue("amount", selectedData.balance);
      setValue("client_id", selectedData.customer_id);
    }
  }, [selectedData]);

  return (
    <Modal isOpen={show} toggle={toggle} centered size="md">
      {loader && <Loader />}
      <div className="modal-header">
        <h5 className="modal-title mt-0">Register invoice</h5>
        <button
          type="button"
          onClick={() => {
            toggle();
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form onSubmit={handleSubmit(onSubmitHandler)}>
        <div className="modal-body">
          <div className="row">
            {selectedData && (
              <div className="col-12">
                <InputField
                  label="Client"
                  readOnly
                  type="text"
                  name="current_client"
                  value={`[${selectedData.customer_id}] ${selectedData.customer.name} ${selectedData.customer.surname}`}
                />

                <InputField
                  {...register("amount")}
                  error={errors.amount?.message}
                  label="Amount"
                  required
                  type="number"
                  step="0.01"
                  name="amount"
                />

                <DatePickerField
                  label="Date"
                  value={dateValue}
                  error={errors.date?.message}
                  onChange={(dates: Date[], dateStr: string) => {
                    handleDateChange(dateStr);
                  }}
                />
              </div>
            )}
          </div>
        </div>
        <div className="modal-footer">
          <Button
            color="danger"
            onClick={() => {
              toggle();
            }}
          >
            Cancel
          </Button>
          <Button color="primary" type="submit">
            Save
          </Button>
        </div>
      </form>
    </Modal>
  );
};
