import React, { useMemo, useState } from "react";

import { toast } from "react-toastify";
import { Button, Modal } from "reactstrap";

import { IProductAssetGroup, IProductListingPresetItem } from "../../../common/interfaces/products.interface";
import { getErrorMessage } from "../../../common/utils/formatter.util";
import { useAppSelector } from "../../../store/store";
import { Loader } from "../../common/Loader";
import { ListingGroupEditForm } from "../../common/ProductWizard/listings/ListingGroupEditForm";
import { ItemLimitReachedModal } from "./ItemLimitReachedModal";
import { ItemZeroModal } from "./ItemZeroModal";

interface Props {
  show: boolean;
  listingItem: IProductListingPresetItem | null;
  onSubmit: (newGroups: IProductAssetGroup[]) => Promise<void>;
  allGroups: IProductAssetGroup[];
  toggle: () => void;
}

const ITEM_LIMIT = 70;

export const UpdateListingGroupsModal: React.FC<Props> = ({ allGroups, show, toggle, listingItem, onSubmit }) => {
  const [updatedGroups, setUpdatedGroups] = useState<IProductAssetGroup[]>([]);
  const [loader, setLoader] = useState(false);
  const [showItemLimitReachedModal, setShowItemLimitReachedModal] = useState(false);
  const [showItemZeroModal, setShowItemZeroModal] = useState(false);

  const totalItemsSelected = useMemo(() => {
    return updatedGroups.reduce((acc, curr) => {
      return acc + curr.items.length;
    }, 0);
  }, [updatedGroups]);
  const handleCancel = async () => {
    if (totalItemsSelected === 0) {
      setShowItemZeroModal(true);
      return;
    }
    toggle();
  };

  const handleSubmit = async () => {
    if (totalItemsSelected > ITEM_LIMIT) {
      setShowItemLimitReachedModal(true);
      return;
    }
    if (totalItemsSelected === 0) {
      setShowItemZeroModal(true);
      return;
    }

    setLoader(true);
    try {
      await onSubmit(updatedGroups);
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setLoader(false);
    }
  };

  return (
    <Modal isOpen={show} toggle={toggle} centered size="xl">
      {loader && <Loader />}
      <div className="modal-header">
        <h5 className="modal-title mt-0">Select models for &quot;{listingItem?.title}&quot; listing</h5>
        <button
          type="button"
          onClick={() => {
            toggle();
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-content border-0 align-items-center">
        <p className="mt-0">
          Selected {totalItemsSelected} of {ITEM_LIMIT}
        </p>
      </div>
      <div className="modal-body pb-5">
        {listingItem && (
          <div className="listing-group-modal-body">
            <ListingGroupEditForm
              allGroups={allGroups}
              currentGroups={listingItem.groups}
              onChange={(newGroups) => {
                setUpdatedGroups(newGroups);
              }}
            />
          </div>
        )}
      </div>
      <div className="modal-footer">
        <Button
          color="danger"
          onClick={handleCancel}
        >
          Cancel
        </Button>
        <Button color="primary" type="button" onClick={handleSubmit}>
          Save
        </Button>
      </div>

      <ItemLimitReachedModal
        show={showItemLimitReachedModal}
        selectedItems={totalItemsSelected}
        limit={ITEM_LIMIT}
        toggle={() => {
          setShowItemLimitReachedModal(!showItemLimitReachedModal);
        }}
      />
      <ItemZeroModal
        show={showItemZeroModal}
        selectedItems={totalItemsSelected}
        toggle={() => {
          setShowItemZeroModal(!showItemZeroModal);
        }}
      />
    </Modal>
  );
};
