import React from "react";

import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";

export const IntegrationSuccessPage = () => {
  return (
    <div className="integration-status-page">
      <Row>
        <Col md={12}>
          <div className="text-center">
            <div>
              <Row className="justify-content-center">
                <Col sm={5}>
                  <div className="integration-status-page__icon">
                    <i className="bx bx-badge-check" />
                  </div>
                </Col>
              </Row>
            </div>
            <h4 className="text-uppercase mt-4">Success</h4>
            <p className="text-muted">Shop successfully connected to your account!</p>
            <div className="mt-5">
              <Link className="btn btn-primary waves-effect waves-light" to="/integrations">
                Back to Integrations
              </Link>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};
