import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import { Card, CardBody, Col, Row, Table } from "reactstrap";

import { ICategoryItem } from "../../../common/interfaces/products.interface";
import { CreateCategoryModal } from "../../../components/backoffice/modals/CreateCategoryModal";
import { Loader } from "../../../components/common/Loader";
import { useServiceContainer } from "../../../hooks/useServiceContainer";

export const CategoryListPage = () => {
  const [loader, setLoader] = useState(true);
  const [data, setData] = useState<ICategoryItem[]>([]);
  const [showCreateCategoryModal, setShowCreateCategoryModal] = useState<boolean>(false);

  const { backofficeService } = useServiceContainer();

  const fetchList = async () => {
    setLoader(true);
    const res = await backofficeService.getProductsCategories();

    setData(res);
    setLoader(false);
  };

  useEffect(() => {
    fetchList().then(() => null);
  }, []);

  return (
    <Row>
      {loader && <Loader />}
      <Col md={12}>
        <Card>
          <CardBody>
            <button
              type="button"
              className="btn btn-success waves-effect w-100 ms-3"
              onClick={() => {
                setShowCreateCategoryModal(true);
              }}
            >
              Create new category
            </button>
          </CardBody>
        </Card>
      </Col>
      <Col md={12}>
        <Card>
          <CardBody>
            <div className="table-responsive">
              <Table className="table mb-0">
                <thead>
                  <tr>
                    <th>Id</th>
                    <th>Code</th>
                    <th>Title</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item) => (
                    <tr key={item.id}>
                      <td>{item.id}</td>
                      <td>{item.code}</td>
                      <td>{item.title}</td>
                      <td>
                        <Link
                          className="btn btn-primary btn-sm ms-2"
                          color="primary"
                          to={`/backoffice/categories/${item.id}/edit`}
                        >
                          Edit
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </CardBody>
        </Card>
      </Col>

      <CreateCategoryModal
        show={showCreateCategoryModal}
        toggle={() => {
          setShowCreateCategoryModal(false);
          fetchList().then(() => null);
        }}
      />
    </Row>
  );
};
