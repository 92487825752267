import React from "react";

import { Button, CardBody } from "reactstrap";

import { IMockupEntity, IMockupFile } from "../../../../common/interfaces/mockup.inteface";
import { getImageUrl } from "../../../../common/utils/formatter.util";
import { useServiceContainer } from "../../../../hooks/useServiceContainer";

interface Props {
  data: IMockupEntity;
  onGenerateNewMockup: () => void;
}

interface GeneratedMockupProps {
  item: IMockupFile;
  index: number;
}

export const GeneratedMockup: React.FC<GeneratedMockupProps> = ({ item, index }) => {
  return (
    <div className="col-3 mockup-preview">
      <div className="card border h-100">
        <div className="mockup-preview__image">
          <img className="card-img-top img-fluid rounded-top" src={getImageUrl(item.url)} alt="mockup preview" />
        </div>
        <CardBody className="d-flex align-items-center flex-column">
          <h6 className="text-center">Generated Mockup #{index + 1}</h6>
        </CardBody>
      </div>
    </div>
  );
};

export const GeneratedMockups: React.FC<Props> = ({ data, onGenerateNewMockup }) => {
  const { commonService } = useServiceContainer();

  return (
    <div className="row d-flex flex-column mockup-preview mt-3">
      <div className="col-12">
        <div className="card border">
          <h4 className="text-center mt-4">
            Generated Mockups <br /> <span className="badge bg-success">SKU = {data.id}</span>
          </h4>
          <CardBody>
            <div className="row">
              {data?.files.map((file, idx) => (
                <GeneratedMockup item={file} key={idx} index={idx + 1} />
              ))}

              <div className="col-12">
                <div className="d-flex justify-content-center">
                  <Button
                    color="info"
                    type="button"
                    onClick={() => {
                      commonService
                        .downloadZipArchiveFromStorageUrls(
                          data.files.map((file) => [file.url!]),
                          data.id
                        )
                        .then(() => null);
                    }}
                  >
                    <i className="bx bx-download me-2" />
                    Download all
                  </Button>
                  <Button className="ms-2" color="primary" type="button" onClick={onGenerateNewMockup}>
                    Generate new mockups
                  </Button>
                </div>
              </div>
            </div>
          </CardBody>
        </div>
      </div>
    </div>
  );
};
