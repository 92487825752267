import React, { useEffect, useState } from "react";

import { Col, Row } from "react-bootstrap";
import { ColorResult, SketchPicker } from "react-color"
import { useClickAway } from "@uidotdev/usehooks";

import { SettingBarProps } from "..";
import useI18n from "../../hook/usei18n";
import useItem from "../../hook/useItem";
import positionStyles from "../../style/position.module.css";
import { WidgetKind } from "../Widget";
import { Group } from "konva/lib/Group";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import Konva from "konva";
import Rect = Konva.Rect;
import { Label } from "reactstrap";

type BackgroundWidgetProps = {
  data: WidgetKind & SettingBarProps;
};

const BackgroundWidget: React.FC<BackgroundWidgetProps> = ({ data }) => {
  const { updateItem, stageData } = useItem();
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [backgroundInitialized, setBackgroundInitialized] = useState(false);
  const [transparentBackground, setTransparentBackground] = useState(true);
  const [newColor, setNewColor] = useState<string>("#fff");
  const colorPickerWrapperRef: any = useClickAway(() => {
    setShowColorPicker(false);
  });
  const { getTranslation } = useI18n();
  const changeNewColor = (color: ColorResult) => {
    setNewColor(color.hex);
  };

  const applyTransparentBackground = () => {
    const children = data.stageRef.current.getChildren()[0].getChildren();
    const contentWrapperFrame = children.find(item => item.attrs.name === "content-wrapper");
    const frameRect = (((contentWrapperFrame as Group).getChildren()[0] as Group).getChildren()[0]) as Rect;
    const imageObj = new Image();
    imageObj.onload = function() {
      frameRect.attrs.fillPatternImage = imageObj;
      frameRect.fillPatternImage(imageObj);
      delete frameRect.attrs.fill;
      updateItem(frameRect.id(), (attrs) => frameRect.attrs);
      frameRect.draw();
    };
    imageObj.src = '/assets/editor/grid.png';
  }

  const applyFrameColor = () => {
    setTransparentBackground(false);
    const children = data.stageRef.current.getChildren()[0].getChildren();
    const contentWrapperFrame = children.find(item => item.attrs.name === "content-wrapper");
    const frameRect = (((contentWrapperFrame as Group).getChildren()[0] as Group).getChildren()[0]) as Rect;
    frameRect.attrs.fill = newColor;
    updateItem(frameRect.id(), (attrs) => frameRect.attrs);
    frameRect.draw();
  }

  useDidMountEffect(()=>{
    // if (!transparentBackground) {
      applyFrameColor();
    // }
  },[newColor]);

  useDidMountEffect(()=>{
    if (transparentBackground) {
      applyTransparentBackground();
    } else {
      applyFrameColor();
    }
  },[transparentBackground]);

  useDidMountEffect(() => {
    if (transparentBackground && stageData.length && !backgroundInitialized) {
      setBackgroundInitialized(true)
      applyTransparentBackground();
    }
  }, [stageData])

  return (
    <Col>
      <h6>
        {getTranslation("widget", "background", "name")}
      </h6>
      <Row xs={4}>
        <div className="col-12">
          <div className="form-check mb-3">
            <input
              className="form-check-input"
              type="checkbox"
              id="premiumBagNo"
              checked={transparentBackground}
              onChange={() => {
                setTransparentBackground(!transparentBackground);
              }}
            />
            <Label className="form-check-label" for="premiumBagNo">
              Transparent background
            </Label>
          </div>
        </div>
       <div className="col-12">
         <div className="canvas-color-picker">
           <div
             onClick={()=>{
               setShowColorPicker(true);
             }}
             role={"button"}
             className={"canvas-color-picker__color"}
             style={{
               backgroundColor: newColor,
             }}
           />
           <div className="canvas-color-picker__value">
             {newColor}
           </div>
         </div>
       </div>
      </Row>
      <div ref={colorPickerWrapperRef} style={{
        position: "absolute",
        marginTop: "-21px"
      }}>
        {showColorPicker && (
          <SketchPicker
            color={newColor}
            onChange={changeNewColor}
          />
        )}
      </div>
    </Col>
  );
};

export default BackgroundWidget;

