import React, { useEffect, useMemo, useState } from "react";

import { useNavigate } from "react-router-dom";
import { Button } from "reactstrap";

import { IMockupEntity } from "../../common/interfaces/mockup.inteface";
import { formatProductType } from "../../common/utils/formatter.util";
import { Loader } from "../../components/common/Loader";
import { SelectField } from "../../components/common/SelectField";
import { MockupListItems } from "../../components/MockupList/MockupListItems";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { PRODUCT_TYPES_SET_SELECTED } from "../../store/wizards/productTypesSlice";
import { PRODUCT_TYPES_ACTION_UPDATE_LIST } from "../../store/wizards/ThunkActions/productTypesSliceThunkActions";

export const MockupListPage = () => {
  const dispatch = useAppDispatch();
  const {
    productList: { data: productTypes, loader: productTypesLoader },
    selectedProduct
  } = useAppSelector((state) => state.productTypes);

  const [selectedMockup, setSelectedMockup] = useState<IMockupEntity | null>(null);

  const navigate = useNavigate();

  const productTypesOptions = useMemo(() => {
    return productTypes.all.map((item) => ({
      label: `[${formatProductType(item.type)}] ${item.title}`,
      value: item.id.toString()
    }));
  }, [productTypes]);

  const handleProductTypeChange = (data: any) => {
    const product = productTypes.all.find((e) => e.id.toString() === data.value);
    if (product) {
      dispatch(PRODUCT_TYPES_SET_SELECTED(product));
    }
  };

  const handleCreateNewMockupClick = () => {
    navigate("/mockup");
  };

  useEffect(() => {
    dispatch(PRODUCT_TYPES_ACTION_UPDATE_LIST());
  }, []);

  return (
    <div className="position-relative d-flex flex-column flex-grow-1">
      {productTypesLoader ? <Loader /> : null}
      <div className="row">
        <div className="col-3">
          <SelectField
            options={productTypesOptions}
            placeholder="Select product type"
            required
            value={productTypesOptions.find((e) => e.value === selectedProduct?.id.toString()) || null}
            onChange={handleProductTypeChange}
          />
        </div>
        <div className="col-2">
          <Button color="primary" onClick={handleCreateNewMockupClick} className="w-100">
            Create new mockup
          </Button>
        </div>
      </div>
      <MockupListItems
        selectedProduct={selectedProduct}
        onEntitySelect={(mockup) => {
          setSelectedMockup(mockup);
        }}
        selectedEntity={selectedMockup}
      />
    </div>
  );
};
