import React, { forwardRef } from "react";

import classNames from "classnames";
import { Label } from "reactstrap";

interface Props {
  value?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  name: string;
  label?: string;
  required?: boolean;
  placeholder?: string;
  error?: any;
  [key: string]: any;
}

export const InputField: React.FC<Props> = forwardRef<HTMLInputElement, Props>(
  ({ error, value, onChange, label, name, placeholder, required, ...rest }, ref: any) => {
    return (
      <div className="mb-3">
        {label ? (
          <Label
            for={name}
            className={classNames("form-label", {
              required
            })}
          >
            {label}
          </Label>
        ) : (
          ""
        )}
        <input
          className={classNames("form-control", {
            "is-invalid": !!error
          })}
          value={value}
          onChange={(e) => {
            if (onChange) {
              onChange(e);
            }
          }}
          type="text"
          required={required}
          name={name}
          placeholder={placeholder}
          id={name}
          {...rest}
          ref={ref}
        />
        {error && <div className="invalid-feedback">{error}</div>}
      </div>
    );
  }
);
