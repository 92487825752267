import { configureStore, ThunkAction, Action, ThunkDispatch, AnyAction } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

import appStateSlice from "./app/appSlice";
import backofficeStateSlice from "./backoffice/backofficeSlice";
import stageDataReducer from "./canvasImageEditor/currentStageData";
import fileMetaReducer from "./canvasImageEditor/fileMeta";
import imageAssetListReducer from "./canvasImageEditor/imageAssetList";
import stageDataListReducer from "./canvasImageEditor/StageDataList";
import userStateSlice from "./user/userSlice";
import checkoutStateSlice from "./wizards/checkoutSlice";
import orderWizardStateSlice from "./wizards/orderSlice";
import productAssetsStateSlice from "./wizards/productAssetsSlice";
import productDesignsStateSlice from "./wizards/productDesignsSlice";
import productWizardStateSlice from "./wizards/productSlice";
import productTypesStateSlice from "./wizards/productTypesSlice";

export const store = configureStore({
  reducer: {
    app: appStateSlice,
    backoffice: backofficeStateSlice,
    user: userStateSlice,
    productWizard: productWizardStateSlice,
    orderWizard: orderWizardStateSlice,
    productAssets: productAssetsStateSlice,
    productDesigns: productDesignsStateSlice,
    productTypes: productTypesStateSlice,
    checkout: checkoutStateSlice,

    //
    fileMeta: fileMetaReducer,
    currentStageData: stageDataReducer,
    stageDataList: stageDataListReducer,
    imageAssetList: imageAssetListReducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false })
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
type TypedDispatch<T> = ThunkDispatch<T, any, AnyAction>;

export const useAppDispatch = () => useDispatch<TypedDispatch<RootState>>();
