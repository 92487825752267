import React, { useCallback, useEffect, useMemo, useState } from "react";

import Konva from "konva";
import { KonvaEventObject, Node, NodeConfig } from "konva/lib/Node";
import { IRect, Vector2d } from "konva/lib/types";
import { useHotkeys } from "react-hotkeys-hook";
import { Group, Layer, Rect, Stage } from "react-konva";
import { Provider, ReactReduxContext } from "react-redux";

import { AssetPreview } from "../assetPreview/AssetPreview";
import useDragAndDrop from "../hook/useDragAndDrop";
import useItem, { ITEMS_CONTEXT } from "../hook/useItem";
import useLocalStorage from "../hook/useLocalStorage";
import useStage, { STAGE_POSITION, STAGE_SCALE } from "../hook/useStage";
import positionStyles from "../style/position.module.css";
import { decimalUpToSeven } from "../util/decimalUpToSeven";
import Drop from "../util/Drop";
import { IDesignItem, ProductType } from "../../common/interfaces/products.interface";
import { nanoid } from "nanoid";
import { getImageUrl } from "../../common/utils/formatter.util";
import { getFramePos } from "./frame";
import { getSizeWithAspectRatio } from "../util/framesize.util";

type ViewProps = {
  onSelect: ITEMS_CONTEXT["onSelect"];
  stage: ReturnType<typeof useStage>;
  children: React.ReactNode;
  firstLayer?: React.ReactNode;
  secondLayer?: React.ReactNode;
  assetSrc?: string;
  printfileSize: [number, number]; // [width, height]
  copyItemTarget?: IDesignItem | null;
};


const View: React.FC<ViewProps> = ({
  assetSrc,
  children,
  onSelect,
  firstLayer,
  secondLayer,
  printfileSize,
  copyItemTarget,
  stage: { stageRef, dragBackgroundOrigin }
}) => {
  const { onDropOnStage, insertFrame } = useDragAndDrop(stageRef, dragBackgroundOrigin);
  const {createItem} = useItem()
  const [container, setContainer] = useState<HTMLDivElement>();

  const onSelectEmptyBackground = useCallback(
    (e: KonvaEventObject<MouseEvent>) => {
      e.target.getType() === "Stage" && onSelect(e);
    },
    [onSelect]
  );

  const onMouseDownOnStage = useCallback(
    (e: KonvaEventObject<MouseEvent>) => {
      onSelectEmptyBackground(e);
      const stage = e.target.getStage();
      if (!stage) {
        return;
      }
      const selectBox = stage.findOne(".select-box");
      const scaledCurrentMousePos = getScaledMousePosition(stage, e.evt);
      const currentMousePos = stage.getPointerPosition();
      selectBox.position(scaledCurrentMousePos);
      if (stage.getAllIntersections(currentMousePos).length || stageRef.current?.draggable()) {
        selectBox.visible(false);
        return;
      }
      selectBox.visible(true);
    },
    [onSelectEmptyBackground]
  );

  const getFrameSize = () => {
      return {
        width: printfileSize[0],
        height: printfileSize[1]
      }
  }

  const calculateScale = () => {
    const { height, width } = getFrameSize();

    const mockupHeight = getSizeWithAspectRatio(width, height).height;
    return mockupHeight / height;
  }

  const onMouseMoveOnStage = (e: KonvaEventObject<MouseEvent>) => {
    if (e.evt.which === 1) {
      const stage = e.target.getStage();
      if (!stage) {
        return;
      }
      const selectBox = stage.findOne(".select-box");
      if (!selectBox.visible()) {
        return;
      }
      const currentMousePos = getScaledMousePosition(stage, e.evt);
      const origin = selectBox.position();
      const size = selectBox.size();
      const adjustedRectInfo = getOriginFromTwoPoint(origin, currentMousePos, size);
      selectBox.position({
        x: adjustedRectInfo.x,
        y: adjustedRectInfo.y
      });
      selectBox.size({
        width: adjustedRectInfo.width,
        height: adjustedRectInfo.height
      });
      selectBox.getStage()?.batchDraw();
    }
  };

  const onMouseUpOnStage = useCallback(
    (e: KonvaEventObject<MouseEvent>) => {
      const stage = e.target.getStage();
      if (!stage) {
        return;
      }
      const selectBox = stage.findOne(".select-box");
      const overlapItems: Node<NodeConfig>[] = getItemsInBoundary(stage, selectBox)
        ? getItemsInBoundary(stage, selectBox)!
            .map((_item) => (_item.attrs["data-item-type"] === "frame" ? _item.getParent().getChildren() ?? [] : _item))
            .flat()
            .filter((_item) => _item.className !== "Label")
        : [];

      selectBox.visible(false);
      selectBox.position({
        x: 0,
        y: 0
      });
      selectBox.size({
        width: 0,
        height: 0
      });
      selectBox.getLayer()?.batchDraw();
      overlapItems?.length && onSelect(undefined, overlapItems);
    },
    [onSelect]
  );
  //
  // useEffect(() => {
  //   window.addEventListener("load", setStateSizeToFitIn);
  //   window.addEventListener("resize", setStateSizeToFitIn);
  //   return () => window.removeEventListener("resize", setStateSizeToFitIn);
  // }, [setStateSizeToFitIn]);

  useEffect(() => {
    if (stageRef.current) {
      setContainer(stageRef.current!.container());

      setTimeout(() => {
        const { width, height } = getFrameSize()
        insertFrame(null, {
          width,
          height,
          scaleX: 0.5,
          scaleY: 0.5
        });

        if (copyItemTarget) {
          const position = getFramePos(stageRef!.current!, undefined, width, height);
          createItem({
            id: nanoid(),
            attrs: {
              name: "label-target",
              "data-item-type": "image",
              x: position.x,
              y: position.y,
              width: printfileSize[0],
              height: printfileSize[1],
              src: getImageUrl(copyItemTarget.image),
              zIndex: 0,
              brightness: 0,
              // _filters: ["Brighten"],
              updatedAt: Date.now()
            },
            className: "sample-image",
            children: []
          })
        }
      }, 100);
    }
  }, []);

  // console.log(getFrameSize().width * calculateScale(), getFrameSize().height * calculateScale())

  return (
    <ReactReduxContext.Consumer>
      {({ store }) => (
        <>
          {assetSrc && <AssetPreview src={assetSrc} size={getFrameSize()} />}
          <Stage
            ref={stageRef}
            width={1000}
            height={800}
            scaleX={calculateScale()}
            scaleY={calculateScale()}
            draggable={false}
            onMouseDown={onMouseDownOnStage}
            onMouseMove={onMouseMoveOnStage}
            onMouseUp={onMouseUpOnStage}
            className={[positionStyles.absolute, positionStyles.top0, positionStyles.horizontalCenter].join(" ")}
          >
            <Provider store={store}>
              <Layer>
                <Group name="content-wrapper">{firstLayer}</Group>
              </Layer>
              <Layer>
                {secondLayer}
                {children}
                <Rect name="select-box" x={0} y={0} width={0} height={0} fill="skyblue" opacity={0.4} visible={false} />
              </Layer>
              {container ? <Drop callback={onDropOnStage} targetDOMElement={container} /> : null}
            </Provider>
          </Stage>
        </>
      )}
    </ReactReduxContext.Consumer>
  );
};

export default View;

export const getScaledMousePosition = (stage: Konva.Stage, e: DragEvent | MouseEvent) => {
  stage.setPointersPositions(e);
  const stageOrigin = stage.getAbsolutePosition();
  const mousePosition = stage.getPointerPosition();
  if (mousePosition) {
    return {
      x: decimalUpToSeven((mousePosition.x - stageOrigin.x) / stage.scaleX()),
      y: decimalUpToSeven((mousePosition.y - stageOrigin.y) / stage.scaleY())
    };
  }
  return {
    x: 0,
    y: 0
  };
};

export const getItemsInBoundary = (stage: Konva.Stage, targetItem: Konva.Node) => {
  const boundary = targetItem.getClientRect({ relativeTo: stage.getLayer() });
  const result = targetItem
    .getLayer()
    ?.getChildren((item: Konva.Node) => {
      if (item.name() === "select-box") {
        return false;
      }
      const itemBoundary = item.getClientRect({ relativeTo: stage.getLayer() });
      return (
        boundary.x <= itemBoundary.x &&
        boundary.y <= itemBoundary.y &&
        boundary.x + boundary.width >= itemBoundary.x + itemBoundary.width &&
        boundary.y + boundary.height >= itemBoundary.y + itemBoundary.height
      );
    })
    .map((item) => {
      if (item.name() === "label-group") {
        return (item as Konva.Group).findOne(".label-target") ?? null;
      }
      return item;
    })
    .filter(Boolean);
  return result;
};

export const getOriginFromTwoPoint = (p1: Vector2d, p2: Vector2d, size: { width: number; height: number }): IRect => {
  const result: IRect = {
    x: p1.x,
    y: p1.y,
    width: size.width,
    height: size.height
  };
  result.x = p1.x;
  result.y = p1.y;
  result.width = p2.x - p1.x;
  result.height = p2.y - p1.y;
  return result;
};
