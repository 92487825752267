import React, { useCallback, useEffect, useState } from "react";

import { toast } from "react-toastify";

import { IShopItem } from "../../common/interfaces/integrations.interface";
import { getErrorMessage } from "../../common/utils/formatter.util";
import { useServiceContainer } from "../../hooks/useServiceContainer";
import { Loader } from "../common/Loader";
import { ConnectedShopItem } from "./ConnectedShopItem";

interface Props {
  selectedItems: IShopItem[];
  onSelect: (id: IShopItem) => void;
}

export const SelectableShopList: React.FC<Props> = ({ selectedItems, onSelect }) => {
  const { integrationService } = useServiceContainer();

  const [items, setItems] = useState<IShopItem[]>([]);
  const [loader, setLoader] = useState(false);

  const fetchList = useCallback(async () => {
    try {
      setLoader(true);
      const res = await integrationService.getConnectedShopList();
      setItems(res);
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setLoader(false);
    }
  }, []);

  useEffect(() => {
    fetchList().then(() => null);
  }, []);

  return (
    <div className="shop-list">
      {loader && <Loader />}

      <h4 className="mb-3">Connect</h4>

      <h6>Select store:</h6>

      <div className="row shop-list__items">
        {items.map((item, idx) => (
          <div className="col-3">
            <ConnectedShopItem
              key={idx}
              item={item}
              selected={selectedItems.findIndex((e) => e.id === item.id) !== -1}
              onSelect={(e) => {
                onSelect(e);
              }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};
