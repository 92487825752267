import React, { useEffect } from "react";

import { useFormContext } from "react-hook-form";
import { toast } from "react-toastify";

import { IProductItem } from "../../../../common/interfaces/products.interface";
import { getErrorMessage } from "../../../../common/utils/formatter.util";
import { useAppDispatch, useAppSelector } from "../../../../store/store";
import { PRODUCT_SET_SELECTED_PRODUCT_TYPE } from "../../../../store/wizards/productSlice";
import { PRODUCT_TYPES_ACTION_UPDATE_LIST } from "../../../../store/wizards/ThunkActions/productTypesSliceThunkActions";
import { ProductTypesList } from "../../../common/ProductTypes/ProductTypesList";

export const ProductSelectionStep = () => {
  const dispatch = useAppDispatch();
  const selectedProduct = useAppSelector((state) => state.productWizard.selectedProductType);

  const {
    register,
    setValue,
    setError,
    formState: { errors }
  } = useFormContext();

  useEffect(() => {
    register("productType");
  }, [register]);

  useEffect(() => {
    if (!selectedProduct) return;
    setValue("productType", selectedProduct.id);
  }, [selectedProduct]);

  useEffect(() => {
    dispatch(PRODUCT_TYPES_ACTION_UPDATE_LIST());
  }, []);

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      if (errors.productType?.message) {
        // @ts-ignore
        toast.error(errors.productType?.message);
      }
    }
  }, [errors]);

  // useEffect(() => {
  //   if (selectedProduct) {
  //     dispatch(
  //       ASSETS_ACTION_UPDATE_LIST({
  //         selectedProduct
  //       })
  //     );
  //   }
  // }, [selectedProduct]);

  return (
    <>
      <ProductTypesList
        hidePrice={false}
        onSelect={(item) => {
          setError("productType", {
            type: "manual",
            message: ""
          });
          dispatch(PRODUCT_SET_SELECTED_PRODUCT_TYPE(item as IProductItem));
        }}
        selectedProduct={selectedProduct}
        error={errors.productType?.message}
      />
    </>
  );
};
