import React, { useEffect, useMemo, useState } from "react";

import TreeView, { flattenTree } from "react-accessible-treeview";
import { ITreeViewOnSelectProps } from "react-accessible-treeview/dist/TreeView";
// import { DndProvider } from "react-dnd";
// import { HTML5Backend } from "react-dnd-html5-backend";
import { toast } from "react-toastify";
import { Card, CardBody } from "reactstrap";

import { IMockupEntity, IMockupFile } from "../../common/interfaces/mockup.inteface";
import { IProductItem } from "../../common/interfaces/products.interface";
import { addPostfixToFileName, getErrorMessage, getImageUrl } from "../../common/utils/formatter.util";
import useDidMountEffect from "../../hooks/useDidMountEffect";
import { useServiceContainer } from "../../hooks/useServiceContainer";
import { TextFieldInput } from "../common/Input/TextFieldInput";
import { Loader } from "../common/Loader";
import { MockupListPreview } from "./MockupListPreview";

interface Props {
  selectedProduct: IProductItem | null;
  onEntitySelect?: (item: IMockupEntity | null) => void;
  selectedEntity?: IMockupEntity | null;
  mockupPreviewBlock?: any;
  itemsDraggable?: boolean;
  itemsOrderable?: boolean;
  hideDownloadButton?: boolean;
  onItemDrop?: (item: { file: IMockupFile }, target: any) => void;
  onItemMove?: (file: IMockupFile, cursor: 1 | -1) => void;
  // onItemDragMove?: (current: IMockupFile, target: IMockupFile) => void;
  previewTitleText?: any;
  hideDefaultPreviewItems?: boolean;
}

export const MockupListItems: React.FC<Props> = ({
  mockupPreviewBlock,
  selectedEntity,
  onEntitySelect,
  selectedProduct,
  itemsDraggable,
  itemsOrderable,
  onItemDrop,
  onItemMove,
  // onItemDragMove,
  hideDownloadButton,
  previewTitleText,
  hideDefaultPreviewItems
}) => {
  const [loader, setLoader] = useState(false);
  const { mockupService } = useServiceContainer();
  const [mockupList, setMockupList] = useState<IMockupEntity[]>([]);
  const [treeViewKey, setTreeViewKey] = useState(0);

  const treeViewRef = React.useRef<any>(null);

  const [selectedNode, setSelectedNode] = useState<IMockupEntity | IMockupFile | null>(null);
  const [searchInputValue, setSearchInputValue] = useState("");

  const listErrorMessage = useMemo(() => {
    if (!selectedProduct) {
      return "Please select a product type to see the mockup list.";
    }

    if (!mockupList.length && !loader) {
      return "No mockups found for the selected product type.";
    }

    return "";
  }, [selectedProduct, mockupList, loader]);

  const mockupListForTreeView = useMemo(() => {
    return flattenTree({
      name: "",
      children: mockupList
        .filter((e) => e.files && e.files.length)
        .map((item) => ({
          id: item.id,
          name: item.id,
          children: item.files.map((mockup) => ({
            id: mockup.id,
            name: mockup.url ? (mockup.url.split("/").pop() as string) : (mockup.name as string)
          }))
        }))
    });
  }, [mockupList]);

  const handleFolderDelete = async (id: string) => {
    setLoader(true);
    try {
      await mockupService.deleteMockupFolderById(id);

      setMockupList(mockupList.filter((e) => e.id !== id));
      setTreeViewKey((prev) => prev + 1);

      toast.success("Mockup folder deleted successfully.");
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setLoader(false);
    }
  };

  const handleTreeViewSelect = (data: ITreeViewOnSelectProps) => {
    if (data.isSelected) {
      // it's a file
      if (data.element.parent) {
        const mockup = mockupList.find((e) => e.id === data.element.parent);
        if (mockup) {
          const file = mockup.files.find((e) => e.id === data.element.id);
          if (file) {
            setSelectedNode(file);
          }
        }
      } else {
        const mockup = mockupList.find((e) => e.id === data.element.id);
        if (mockup) {
          setSelectedNode(mockup);
        }
      }
    }
  };

  const fetchMockupList = async () => {
    if (!selectedProduct) return;

    setLoader(true);

    try {
      const list = await mockupService.getMockupList(selectedProduct.id);

      setMockupList(list);
    } catch (e) {
      console.error(e);
    } finally {
      setLoader(false);
    }
  };

  const handleNodeSelect = (node: IMockupEntity | IMockupFile | null) => {
    let entity: IMockupEntity | null = null;
    if (node && !("files" in node)) {
      entity = mockupList.find((e) => e.files.findIndex((f) => f.id === node?.id) !== -1) || null;
    } else {
      entity = node;
    }

    if (onEntitySelect) {
      onEntitySelect(entity);
    }
  };

  useEffect(() => {
    if (selectedProduct) {
      onEntitySelect?.(null);
      setSelectedNode(null);
      fetchMockupList().then(() => null);
    }
  }, [selectedProduct]);

  useEffect(() => {
    handleNodeSelect(selectedNode);
  }, [selectedNode]);

  useEffect(() => {
    if (selectedEntity) {
      if (mockupList.find((e) => e.id === selectedEntity.id)) {
        setSelectedNode(selectedEntity);
      } else {
        fetchMockupList().then(() => {
          setSelectedNode(selectedEntity);
        });
      }
    }
  }, []);

  return (
    // <DndProvider backend={HTML5Backend}>
    <div className="row flex-grow-1 mockup-list">
      {loader ? <Loader /> : null}
      <div className="col-5">
        <h3>Mockup folders:</h3>
        <Card className="h-100">
          <CardBody>
            {listErrorMessage ? (
              <div className="d-flex h-100 align-items-center justify-content-center">
                <p>{listErrorMessage}</p>
              </div>
            ) : (
              <div className="tree-view">
                <div className="mb-3 search">
                  <TextFieldInput
                    value={searchInputValue}
                    onChange={(e) => {
                      setSearchInputValue(e.target.value);
                    }}
                    placeholder="Search.."
                  />
                </div>
                <TreeView
                  ref={treeViewRef}
                  key={treeViewKey}
                  selectedIds={
                    selectedNode && mockupList.find((e) => e.id === selectedNode.id) ? [selectedNode.id as string] : []
                  }
                  onSelect={handleTreeViewSelect}
                  data={mockupListForTreeView}
                  aria-label="directory tree"
                  nodeRenderer={({ element, isBranch, getNodeProps, level }) => {
                    if (!isBranch) return null;
                    if (isBranch && element) {
                      const matchFilter = element.name.toLowerCase().includes(searchInputValue.toLowerCase());
                      if (!matchFilter) {
                        return null;
                      }
                    }
                    const previewImage = isBranch
                      ? mockupList?.find((e) => e.id === element.id)?.files.find((e) => e.id === element.children[0])
                          ?.url
                      : element.name;
                    return (
                      <div {...getNodeProps()} style={{ paddingLeft: 20 * (level - 1) }}>
                        <div className="tree-node__image">
                          <span className="tree-view__folder-preview">
                            {previewImage ? (
                              <img
                                src={getImageUrl(addPostfixToFileName(previewImage!, "_thumbnail"))}
                                id={`preview-mockup-${element.id}`}
                                alt="preview"
                              />
                            ) : (
                              <i className="bx bx-folder" />
                            )}
                          </span>
                          {isBranch && (
                            <button
                              className="tree-view__delete"
                              type="button"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleFolderDelete(element.id as string).then(() => null);
                              }}
                            >
                              <i className="bx bx-trash-alt" />
                            </button>
                          )}
                        </div>
                        <div className="tree-view__name">{element.name}</div>
                      </div>
                    );
                  }}
                />
              </div>
            )}
          </CardBody>
        </Card>
      </div>
      <div className="col-7">
        {selectedEntity && (
          <MockupListPreview
            titleText={previewTitleText}
            hideDownloadButton={hideDownloadButton}
            itemsDraggable={itemsDraggable}
            itemsOrderable={itemsOrderable}
            additionalMockupPreviewBlock={mockupPreviewBlock}
            selectedNode={selectedEntity as IMockupEntity}
            onItemDrop={onItemDrop}
            onItemMove={onItemMove}
            hideDefaultPreviewItems={hideDefaultPreviewItems}
            // onItemDragMove={onItemDragMove}
          />
        )}
      </div>
    </div>
    // </DndProvider>
  );
};
