import React, { useMemo, useState } from "react";

import BootstrapTable from "react-bootstrap-table-next";
// @ts-ignore
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { Link } from "react-router-dom";
import { Button, Col, Row } from "reactstrap";

import { ORDER_HISTORY_COLUMNS_CHILDREN } from "../../common/constants/order-history.constant";
import { IOrderItem, IOrderTableItem, IOrderTableItemChildren } from "../../common/interfaces/orders.interface";

export const useOrderManagementTableExpandControl = (options: {
  items: IOrderTableItem[];
  tableSelectedRows?: string[];
  onItemSelect?: (item: IOrderTableItemChildren, isSelected: boolean) => void;
  isPublic?: boolean;
}) => {
  const [expandedRows, setExpandedRows] = useState<string[]>([]);

  const nonExpandableItemIds = useMemo(() => {
    return options.items.filter((item) => item.children.length === 1).map((item) => item.id);
  }, [options.items]);

  const onRowExpand = (row: IOrderItem, isExpand: boolean) => {
    if (isExpand) {
      setExpandedRows([row.id]);
    } else {
      setExpandedRows([]);
    }
  };

  const expandableRowColumns = useMemo(() => {
    return [
      ...ORDER_HISTORY_COLUMNS_CHILDREN(),
      {
        dataField: "actions",
        isDummyField: true,
        text: "Actions",
        formatter: (cellContent: any, row: IOrderTableItemChildren) => {
          return (
            <Link
              className="btn btn-primary btn-sm ms-2"
              color="primary"
              to={
                options.isPublic
                  ? `/orders/${encodeURIComponent(row.id)}`
                  : `/backoffice/orders/${encodeURIComponent(row.id)}`
              }
            >
              View
            </Link>
          );
        },
        sort: false
      }
    ];
  }, []);

  const selectRowData = {
    mode: "checkbox",
    bgColor: "#a3ffa1",
    hideSelectAll: true,
    onSelect: (selectData: IOrderTableItemChildren, isSelected: boolean) => {
      if (options?.onItemSelect) {
        options.onItemSelect(selectData, isSelected);
      }
    },
    selectionRenderer: ({ mode, ...rest }: any) => {
      return (
        <>
          <div className="form-check">
            <input className="form-check-input" type={mode} onChange={() => null} {...rest} />
          </div>
        </>
      );
    },
    selected: options.tableSelectedRows ?? []
  } as any;

  const expandRowData = {
    renderer: (row: IOrderTableItem) => {
      return (
        <ToolkitProvider keyField="id" columns={expandableRowColumns} data={row.children}>
          {(toolkitProps: any) => (
            <>
              <Row>
                <Col xl="12">
                  <div className="table-responsive">
                    <BootstrapTable
                      bootstrap4
                      // onTableChange={onTableChange}
                      bordered={false}
                      striped={false}
                      selectRow={options.onItemSelect ? selectRowData : undefined}
                      classes="table align-middle table-nowrap table-sm"
                      headerWrapperClasses="thead-light"
                      {...toolkitProps.baseProps}
                      keyField="id"
                    />
                  </div>
                </Col>
              </Row>
            </>
          )}
        </ToolkitProvider>
      );
    },
    showExpandColumn: true,
    expanded: expandedRows,
    nonExpandable: nonExpandableItemIds,
    className: "order-management-table-expanded-row",
    onExpand: onRowExpand,
    expandHeaderColumnRenderer: () => null,
    expandColumnRenderer: (data: { rowKey: string; expanded: boolean; expandable: boolean }) => {
      if (!data.expandable) return "";

      const item = options.items.find((e) => e.id === data.rowKey);

      if (!item) return "";

      return (
        <span className="react-boostrap-table-expandable-row-control">
          {data.expanded ? "-" : "+"}({item.children.length})
        </span>
      );
    }
  };

  return {
    expandedRows,
    nonExpandableItemIds,
    expandRowData,
    onRowExpand
  };
};
