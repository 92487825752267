import React, { useRef } from "react";

import classNames from "classnames";
import { Button, Col, Row } from "reactstrap";

import { getImageUrl } from "../../../common/utils/formatter.util";

interface Props {
  images: string[];
  title?: string;
  error?: any;
  onUpload: (file: File[]) => void;
  onDelete: (file: string) => void;
}

export const UploadedImageList: React.FC<Props> = ({ error, title, images, onUpload, onDelete }) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  return (
    <div
      className={classNames("uploaded-image-list", {
        "uploaded-image-list--error": !!error
      })}
    >
      {title && (
        <div className="uploaded-image-list__title">
          {title} {error && <span className="error-message">{error}</span>}
        </div>
      )}
      <div className="uploaded-image-list__inner">
        <div className="uploaded-image-list__images">
          <Row>
            {images.map((image, idx) => (
              <Col xs={3} className="uploaded-image-list__image-wrapper">
                <div key={idx} className="uploaded-image-list__image">
                  <img src={getImageUrl(image)} alt="Preview" />
                  <Button
                    type="button"
                    color="danger"
                    size="sm"
                    onClick={() => {
                      onDelete(image);
                    }}
                  >
                    <i className="bx bx-trash-alt" />
                  </Button>
                </div>
              </Col>
            ))}
          </Row>
        </div>

        <div className="image-preview-with-reupload__upload">
          <input
            style={{ display: "none" }}
            ref={fileInputRef}
            type="file"
            multiple
            accept="image/*"
            onChange={(e) => {
              if (!e.target.files?.length) return;
              onUpload(Array.from(e.target.files));
            }}
          />
          <Button
            color="primary"
            type="button"
            onClick={() => {
              fileInputRef.current?.click();
            }}
          >
            Add new
          </Button>
        </div>
      </div>
    </div>
  );
};
