import React from "react";

import ReactPaginate from "react-paginate";

interface Props {
  onChange: (selectedItem: { selected: number }) => void;
  value: number;
  pageCount: number;
}

export const Pagination: React.FC<Props> = ({ value, onChange, pageCount }) => {
  return (
    <div className="pagination-wrapper">
      <ReactPaginate
        forcePage={value}
        breakLabel="..."
        nextLabel="&raquo;"
        containerClassName="pagination pagination-sm"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        activeClassName="active"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        onPageChange={onChange}
        pageRangeDisplayed={3}
        pageCount={pageCount}
        previousLabel="&laquo;"
        renderOnZeroPageCount={null}
      />
    </div>
  );
};
