import React from "react";

import { ButtonGroup, ButtonToolbar, Nav } from "react-bootstrap";

import alignStyles from "../style/align.module.css";
import colorStyles from "../style/color.module.css";
import coreStyles from "../style/core.module.css";

type NavBarProps = {
  children: React.ReactNode;
};

const NavBar: React.FC<NavBarProps> = ({ children }) => (
  <Nav className={["canvas-btn-toolbar",coreStyles.navBarButtons, colorStyles.darkTheme, alignStyles.fromBottomCenter].join(" ")}>
    <ButtonToolbar>
      {children}
    </ButtonToolbar>
  </Nav>
);

export default NavBar;
