import React, { useMemo } from "react";

import { getExternalImageUrl } from "../../../common/utils/formatter.util";

interface Props {
  // model: string;
  assetSrc: string;
  // width: number;
}

// const AVAILABLE_PHONES = ["iphone14", "iphone6s", "iphone6s+"];
// const FALLBACK_PHONE = AVAILABLE_PHONES[0];

export const AssetPreview: React.FC<Props> = ({ assetSrc }) => {
  const baseUrl = useMemo(() => {
    return process.env.REACT_APP_API_BASE_ENDPOINT;
  }, []);
  // const [preview, setPreview] = useState<any>();

  // const phoneModel = useMemo(() => {
  //   // if (!AVAILABLE_PHONES.includes(model)) {
  //   //   return FALLBACK_PHONE;
  //   // }
  //
  //   return model;
  // }, [model]);

  return (
    <div className="asset-preview">
      <div className="asset-preview__container">
        <img src={getExternalImageUrl(assetSrc, baseUrl)} alt="" height={600} />
      </div>
    </div>
  );
};
