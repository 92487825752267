import React, { RefObject, useCallback, useEffect, useRef, useState } from "react";

import Konva from "konva";
import { Context } from "konva/lib/Context";
import { Group, Label, Rect, Tag, Text, Image } from "react-konva";

import useItem, { OverrideItemProps } from "../../hook/useItem";
import useStage from "../../hook/useStage";
import { StageData } from "../../../store/canvasImageEditor/currentStageData";

export type FrameKind = {
  "data-frame-type": string;
  id: string;
  width: number;
  height: number;
};

export type FrameProps = OverrideItemProps<{
  data: StageData;
  e?: DragEvent;
}>;

const Frame: React.FC<FrameProps> = ({ data, e, onSelect }) => {
  const { id: stageId, attrs } = data;
  const { removeItem, createItem, updateItem } = useItem();
  const stage = useStage();
  const frameRef = useRef() as RefObject<Konva.Rect>;
  const imageRef = useRef() as RefObject<Konva.Image>;

  // const [backgroundImage, setBackgroundImage] = useState<any>(null);

  const clipFunc = (ctx: Context) => {
    const position = attrs;
    ctx.beginPath();
    // ctx.fillText(attrs["data-frame-type"], position.x, position.y - 15);
    ctx.moveTo(position.x, position.y);
    ctx.lineTo(position.x + position.width, position.y);
    ctx.lineTo(position.x + position.width, position.y + position.height);
    ctx.lineTo(position.x, position.y + position.height);
    ctx.closePath();
  };

  useEffect(() => {
    if (frameRef.current) {
      stage.setStageRef(frameRef.current.getStage()!);
      frameRef.current.brightness(data.attrs.brightness);
      frameRef.current.findAncestor("Group").clipFunc(frameRef.current.getLayer()!.getContext());
      frameRef.current.cache();
    }
  }, [data]);

  // Group should always be the on background
  // useEffect(() => {
  //   if (frameRef.current) {
  //     frameRef.current.moveDown();
  //   }
  // },[frameRef]);

  const fillAttributes = {
    ...(attrs.fillPatternImage ? { fillPatternImage: attrs.fillPatternImage } : {
      fill: attrs.fill ?? "transparent"
    }),
  };

  return (
    <Group name="label-group" onClick={onSelect} clipFunc={clipFunc}>
      <Rect
        ref={frameRef}
        name="label-target"
        data-item-type="frame"
        data-frame-type={attrs["data-frame-type"]}
        id={data.id}
        x={attrs.x}
        y={attrs.y}
        width={attrs.width}
        height={attrs.height}
        scaleX={attrs.scaleX}
        scaleY={attrs.scaleY}
        {...fillAttributes}
        opacity={attrs.opacity ?? 1}
        draggable={false}
      />
      {/* {backgroundImage && ( */}
      {/*  <Image */}
      {/*    ref={imageRef} */}
      {/*    zIndex={12312} */}
      {/*    x={attrs.x} */}
      {/*    y={attrs.y} */}
      {/*    width={attrs.width} */}
      {/*    height={attrs.height} */}
      {/*    image={backgroundImage} */}
      {/*  /> */}
      {/* )} */}
    </Group>
  );
};

export default Frame;

export const getFramePos = (stage: Konva.Stage, e: any, width?: number, height?: number) => {
  const stageWidth = stage.width();
  const stageHeight = stage.height();
  const stageScaleX = stage.scaleX();
  const stageScaleY = stage.scaleY();

  const centerX = (stageWidth / 2 - (width! * stageScaleX) / 2) / stageScaleX;
  const centerY = (stageHeight / 2 - (height! * stageScaleY) / 2) / stageScaleY;

  return {
    x: centerX,
    y: centerY
  };
};
