import React, { useMemo } from "react";

import classNames from "classnames";
import { Button } from "reactstrap";

import icon from "../../canvasImageEditor/view/object/icon";
import { IShopItem, ShopPlatform } from "../../common/interfaces/integrations.interface";

interface Props {
  item: IShopItem;
  onDeleteClick?: (item: IShopItem) => void;
  selected?: boolean;
  onSelect?: (item: IShopItem) => void;
  actionElement?: React.ReactNode;
}

export const ConnectedShopItem: React.FC<Props> = ({ actionElement, selected, onSelect, item, onDeleteClick }) => {
  const logoUrl = useMemo(() => {
    switch (item.platform) {
      case ShopPlatform.Etsy:
        return "/assets/integration/platforms/etsy.png";
      case ShopPlatform.Shopify:
        return "/assets/integration/platforms/shopify.png";
      default:
        return `/assets/integration/platforms/${item.platform}.png`;
    }
  }, [item.platform]);

  const selectableProps = {
    ...(onSelect ? { role: "button", onClick: () => onSelect(item) } : {})
  };

  return (
    <div
      className={classNames("shop-item", {
        "shop-item--selected": selected,
        "shop-item--selectable": onSelect
      })}
      {...selectableProps}
    >
      {onDeleteClick && (
        <div className="shop-item__controls">
          <button
            className="shop-item__controls__btn shop-item__controls__btn--delete"
            onClick={() => onDeleteClick(item)}
            type="button"
            aria-label="Delete shop"
          >
            <i className="bx bx-trash-alt" />
          </button>
        </div>
      )}
      {selected && (
        <div className="shop-item__selected-icon">
          <i className="bx bx-check" />
        </div>
      )}
      <div className="shop-item__name">{item.shop_name}</div>
      <div className="shop-item__logo">
        <img src={logoUrl} alt={item.platform} />
      </div>
      {/* <div className="shop-item__currency"> */}
      {/*  Billing currency: <span>{item.currency_code ?? "USD"}</span> */}
      {/* </div> */}
      {item.platform === ShopPlatform.Etsy && (
        <div className="shop-item__copyright">
          ’Etsy’ is a trademark of Etsy, Inc. This application uses the Etsy API but is not endorsed or certified by
          Etsy, Inc.
        </div>
      )}
      {actionElement ? <div className="shop-item__actions">{actionElement}</div> : ""}
    </div>
  );
};
