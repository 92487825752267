import React, { useMemo, useState } from "react";

import classNames from "classnames";
import { CardBody, Tooltip } from "reactstrap";

import { getImageUrl } from "../../../common/utils/formatter.util";
import { PreviewModal } from "../../modals/preview-modal/PreviewModal";
import { ProductRowItem } from "./ProductRowItem";

export interface ProductRowItemBase {
  title: string;
  image: string | [string, string] | null;
  id: any;
  [key: string]: any;
}

interface Props<T, K extends boolean> {
  items: T[];
  firstItem?: T;
  onFirstItemClick?: (item: T) => void;
  selectable?: boolean;
  onSelect?: (item: T) => void;
  onItemClick?: (item: T) => void;
  selectedValue?: K extends true ? T[] : T | null;
  colSize?: number;
  innerPadding?: boolean;
  emptyPreviewText?: string;
  listTitle?: string;
  previewTitle?: string;
  lgImage?: boolean;
  coverImage?: boolean;
  searchEnabled?: boolean;
  required?: boolean;
  error?: any;
  titleEditable?: boolean;
  onTitleChange?: (item: T, title: string) => Promise<void>;
  imageHoverable?: boolean;
  itemsWithControls?: boolean;
  onCopyItemClick?: (item: T) => void;
  onDeleteItemClick?: (item: T) => void;
  previewAssetSrc?: string;
  renderTooltip?: (item: T) => React.ReactNode;
  hidePrice?: boolean;
  multiSelect?: K;
  maxSelectedItems?: number;
}

export const ProductRowItems = <T extends ProductRowItemBase, K extends boolean>({
  firstItem,
  onItemClick,
  innerPadding,
  lgImage,
  items,
  coverImage,
  onSelect,
  selectable,
  selectedValue,
  listTitle,
  colSize,
  searchEnabled,
  required,
  error,
  imageHoverable,
  titleEditable,
  onFirstItemClick,
  itemsWithControls,
  onCopyItemClick,
  onDeleteItemClick,
  onTitleChange,
  previewAssetSrc,
  renderTooltip,
  hidePrice,
  multiSelect,
  maxSelectedItems
}: Props<T, K>) => {
  const [search, setSearch] = useState("");
  const [errorTooltipOpen, setErrorTooltipOpen] = useState(false);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [previewImage, setPreviewImage] = useState("");

  const handleSelect = (item: T) => {
    if (onSelect) {
      onSelect(item);
    }
  };

  const filteredItems = useMemo(() => {
    if (!search) {
      if (firstItem) {
        return [firstItem, ...items];
      }

      return items;
    }

    const filtered = items.filter((item) => {
      const title = item.title.toLowerCase();
      const searchValue = search.toLowerCase();
      return title.includes(searchValue);
    });

    if (firstItem) {
      return [firstItem, ...filtered];
    }

    return filtered;
  }, [search, items, firstItem]);

  return (
    <div
      className={classNames("row", "product-row-items", "product-row-items--without-preview", {
        "product-row-items--inner-padding": innerPadding,
        "product-row-items--lg-image": lgImage,
        "product-row-items--cover-image": coverImage,
        "product-row-items--is-invalid": !!error
      })}
    >
      <div className="col-12">
        <div className="row">
          <div className={classNames("product-row-items__selector col-12")}>
            {listTitle && (
              <div className="product-row-items__list__title-container">
                <h6
                  className={classNames("product-row-items__title", {
                    required
                  })}
                >
                  {listTitle}
                </h6>
                {error && (
                  <>
                    <Tooltip
                      placement="top"
                      isOpen={errorTooltipOpen}
                      target="error-tt"
                      toggle={() => {
                        setErrorTooltipOpen(!errorTooltipOpen);
                      }}
                    >
                      {error}
                    </Tooltip>{" "}
                    <i className="bx bx-error-circle" id="error-tt" />
                  </>
                )}
              </div>
            )}
            {/* {error && <p className="product-row-items__error-text text-danger">{error}</p>} */}
            {searchEnabled && (
              <div className="product-row-items__search">
                <input
                  className="form-control"
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                  type="text"
                  placeholder="Search..."
                />
              </div>
            )}
            <div className="product-row-items__list">
              {filteredItems.map((item, idx) => (
                <ProductRowItem
                  item={item}
                  key={item.id}
                  onItemClick={onItemClick}
                  selectable={selectable}
                  isSelected={
                    selectable
                      ? multiSelect
                        ? (selectedValue as T[]).some((selectedItem) => selectedItem.id === item.id)
                        : (selectedValue as T)?.id === item.id
                      : false
                  }
                  colSize={colSize}
                  imageHoverable={imageHoverable}
                  titleEditable={titleEditable}
                  onFirstItemClick={onFirstItemClick}
                  itemsWithControls={itemsWithControls}
                  onCopyItemClick={onCopyItemClick}
                  renderTooltip={renderTooltip}
                  onDeleteItemClick={onDeleteItemClick}
                  onTitleChange={onTitleChange}
                  onSelect={handleSelect}
                  hidePrice={hidePrice}
                  isLastItem={idx === filteredItems.length - 1}
                  onShowPreview={(target) => {
                    setShowPreviewModal(true);
                    setPreviewImage(getImageUrl(target.image));
                  }}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
      <PreviewModal
        show={showPreviewModal}
        title="Preview"
        image={previewImage}
        previewAssetSrc={previewAssetSrc}
        toggle={() => {
          setShowPreviewModal(false);
        }}
      />
    </div>
  );
};
