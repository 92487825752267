import React, {useEffect, useMemo} from "react";

import { useFormContext } from "react-hook-form";

import { PricingTableEntityItem } from "../../../../common/interfaces/product-wizard.interface";
import { useAppSelector } from "../../../../store/store";
import { TextFieldInput } from "../../Input/TextFieldInput";
import {toast} from "react-toastify";

interface Props {
  listingId: number;
  item: PricingTableEntityItem;
  onPriceChange: (value: string | null) => void;
  showDeliveryPrice?: boolean;
  deliveryPrice?: number;
}

export const PricingTableItem: React.FC<Props> = ({
  showDeliveryPrice,
  deliveryPrice,
  listingId,
  item,
  onPriceChange
}) => {
  const {
    formState: { errors }
  } = useFormContext();

  // const productDefaultRetailPrice = useAppSelector((state)=>state.productWizard.selectedProductType?.default_retail_price)

  const estimatedRevenue = useMemo(() => {
    const withoutDelivery = +item.price - item.asset.price_with_vat;

    if (!showDeliveryPrice || typeof deliveryPrice === "undefined") {
      return withoutDelivery.toFixed(2);
    }

    // console.log("deliveryPrice", deliveryPrice);

    return (withoutDelivery - deliveryPrice!).toFixed(2);
  }, [item.price, showDeliveryPrice, deliveryPrice]);

  const handleAction = (action: "increase" | "decrease") => {
    const newValue = action === "increase" ? Number(item.price) + 1 : Number(item.price) - 1;

    onPriceChange(newValue.toFixed(2));
  };
  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      // @ts-ignore
      if (errors?.priceMap?.[listingId]?.[item.asset.id]?.message) {
        // @ts-ignore
        toast.error(errors?.priceMap?.[listingId]?.[item.asset.id]?.message);
      }
    }
  }, [errors]);

  // useDidMountEffect(() => {
  //   onPriceChange(+itemRetailPrice);
  // }, [itemRetailPrice]);

  return (

    <tr className="pricing__table__item">

      <td>{item.asset.title}</td>
      <td className="pricing__table__item__retail-price">
        <div className="pricing__table__item__retail-price__input">
          <button type="button" onClick={() => handleAction("decrease")}>
            -
          </button>
          <TextFieldInput
            value={item.price?.toString() || ""}
            // @ts-ignore
            error={errors?.priceMap?.[listingId]?.[item.asset.id]?.message}
            onKeyPress={(event) => {
              // numbers and minus and dot
              if (!/[0-9-.]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            onChange={(e) => {
              // setItemRetailPrice(e.target.value);
              if (e.target.value === "") {
                onPriceChange(null);
                return;
              }
              onPriceChange(e.target.value || "");
            }}
            placeholder="Value"
          />
          <button type="button" onClick={() => handleAction("increase")}>
            +
          </button>
        </div>
      </td>
      <td>€{item.asset.price_with_vat}</td>
      {showDeliveryPrice && (
        <td className="pricing__table__item__delivery-price">
          {deliveryPrice ? `€${deliveryPrice?.toFixed(2)}` : "-"}
        </td>
      )}
      <td className="pricing__table__item__estimated-revenue">€{estimatedRevenue}</td>
    </tr>
  );
};
