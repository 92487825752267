import React, { useEffect, useMemo, useState } from "react";

import { useFormContext } from "react-hook-form";
import { Label, Tooltip } from "reactstrap";
import sanitize from "sanitize-html";

import { OrderInstance } from "../../../../common/interfaces/order-wizard.interface";
import { IProductDetails, IProductItem, ProductType } from "../../../../common/interfaces/products.interface";
import { getExternalImageUrl } from "../../../../common/utils/formatter.util";
import { useAppDispatch, useAppSelector } from "../../../../store/store";
import {
  ORDER_GET_INSTANCE,
  ORDER_SET_GIFT_MESSAGE,
  ORDER_SET_PREMIUM_BAG,
  ORDER_SET_SELECTED_PRODUCT_ASSET
} from "../../../../store/wizards/orderSlice";
import { ASSETS_ACTION_UPDATE_LIST } from "../../../../store/wizards/ThunkActions/assetsSliceThunkActions";
import { ImageGallery } from "../../../common/Gallery/ImageGallery";
import { Loader } from "../../../common/Loader";
import { SelectField } from "../../../common/SelectField";

export const ProductSettingsStep = () => {
  const dispatch = useAppDispatch();
  const { data: assetList, loader: assetLoader } = useAppSelector((state) => state.productAssets.assetList);
  const [premiumBagTooltipOpen, setPremiumBagTooltipOpen] = useState(false);
  const [giftMessageTooltipOpen, setGiftMessageTooltipOpen] = useState(false);
  const [productDetails, setProductDetails] = useState<IProductDetails | null>(null);
  const {
    register,
    setValue,
    setError,
    formState: { errors }
  } = useFormContext();

  const selectedProduct = useAppSelector((state) => {
    const order = ORDER_GET_INSTANCE(state.orderWizard) as OrderInstance;
    return order.selectedProductType;
  });

  const title = useMemo(() => {
    if (!selectedProduct) return "";

    switch (selectedProduct.type) {
      case ProductType.PHONE_CASE: {
        return (
          <>
            Please select the phone model and material for your phone case.
            <br /> You can also add a premium bag and a gift message.
          </>
        );
      }
      default: {
        return "Please select asset model. You can also add premium bag and gift message.";
      }
    }
  }, [selectedProduct]);

  const assetTitle = useMemo(() => {
    if (!selectedProduct) return "";

    switch (selectedProduct.type) {
      case ProductType.PHONE_CASE: {
        return "Phone model:";
      }
      default: {
        return "Asset model:";
      }
    }
  }, [selectedProduct]);

  const values = useAppSelector((state) => {
    const order = ORDER_GET_INSTANCE(state.orderWizard) as OrderInstance;
    return {
      selectedAsset: order.selectedProductAsset
        ? {
            value: order.selectedProductAsset.id.toString(),
            label: order.selectedProductAsset.title
          }
        : null,
      // selectedMaterial: order.selectedProductMaterial,
      premiumBag: order.premiumBag,
      giftMessage: order.giftMessage
    };
  });

  const handleModelChange = (data: any) => {
    const asset = assetList.find((item) => item.id === +data.value);
    if (!asset) return;
    dispatch(ORDER_SET_SELECTED_PRODUCT_ASSET(asset));
    setError("productAssetType", {
      type: "manual",
      message: ""
    });
  };

  const handlePremiumBagChange = (data: boolean) => {
    dispatch(ORDER_SET_PREMIUM_BAG(data));
  };

  const populateProductDetails = async (product: IProductItem) => {
    const data: IProductDetails = {
      description: product.description,
      gallery_images: product.gallery_images
    };

    setProductDetails(data);
  };

  useEffect(() => {
    register("productAssetType");
    register("productMaterial");
  }, [register]);

  useEffect(() => {
    if (!values.selectedAsset) return;
    setValue("productAssetType", values.selectedAsset.value);
  }, [values.selectedAsset]);

  useEffect(() => {
    if (selectedProduct) {
      populateProductDetails(selectedProduct).then(() => null);
      dispatch(
        ASSETS_ACTION_UPDATE_LIST({
          selectedProduct
        })
      );
    }
  }, [selectedProduct]);

  return (
    <>
      {assetLoader && <Loader />}
      {/* <div className="row"> */}
      {/*  <div className="col-8"> */}
      <p className="wizard-step__description">{title}</p>
      {/*  </div> */}
      {/* </div> */}

      <div className="row">
        <div className="col-5">
          <ImageGallery images={productDetails?.gallery_images.map((e) => getExternalImageUrl(e)) ?? []} />
        </div>
        <div className="col-7">
          <div className="row">
            <div className="col-11">
              <div className="wizard__step__product-description">
                <h5>About the product:</h5>
                <div
                  dangerouslySetInnerHTML={{
                    __html: sanitize(productDetails?.description || "")
                  }}
                />
              </div>
              <SelectField
                options={assetList.map((item) => ({
                  value: item.id.toString(),
                  label: item.title
                }))}
                label={assetTitle}
                required
                error={errors.productAssetType?.message}
                value={values.selectedAsset}
                placeholder="Search.."
                onChange={(data) => {
                  handleModelChange(data);
                }}
              />
              <Label>
                Premium bag:
                <Tooltip
                  placement="top"
                  isOpen={premiumBagTooltipOpen}
                  target="premium-bag-tt"
                  toggle={() => {
                    setPremiumBagTooltipOpen(!premiumBagTooltipOpen);
                  }}
                >
                  {/* <img src="/assets/image-placeholder.png" alt="" /> */}
                  Premium plastic bag that is made from white plastic. Will make your shipment feel more premium.
                  Additional cost 0.17 EUR (with VAT).
                </Tooltip>
                <i className="ms-2 bx bxs-info-circle" id="premium-bag-tt" />
              </Label>
              <div className="mb-2">
                <div className="form-check form-check-inline form-check-success mb-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="premiumBagYes"
                    checked={values.premiumBag}
                    onChange={() => {
                      if (!values.premiumBag) {
                        handlePremiumBagChange(true);
                      }
                    }}
                  />
                  <Label className="form-check-label" for="premiumBagYes">
                    Yes
                  </Label>
                </div>

                <div className="form-check form-check-inline form-check-success mb-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="premiumBagNo"
                    checked={!values.premiumBag}
                    onChange={() => {
                      if (values.premiumBag) {
                        handlePremiumBagChange(false);
                      }
                    }}
                  />
                  <Label className="form-check-label" for="premiumBagNo">
                    No
                  </Label>
                </div>
              </div>
              <div className="mb-2">
                <Label htmlFor="gift-message">
                  Gift message:
                  <Tooltip
                    placement="top"
                    isOpen={giftMessageTooltipOpen}
                    target="gift-message-tt"
                    toggle={() => {
                      setGiftMessageTooltipOpen(!giftMessageTooltipOpen);
                    }}
                  >
                    If you wish to have a gift message, please add your message here . Maximum length 250 symbols. This
                    service will cost 0.25 EUR (Including VAT). Leave this field empty if you do not need a gift
                    message.
                  </Tooltip>
                  <i className="ms-2 bx bxs-info-circle" id="gift-message-tt" />
                </Label>
                <textarea
                  id="gift-message"
                  value={values.giftMessage}
                  onChange={(e) => {
                    dispatch(ORDER_SET_GIFT_MESSAGE(e.target.value));
                  }}
                  className="form-control"
                  rows={2}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
