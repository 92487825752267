import React, { useEffect, useMemo, useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Button, CardBody, Modal } from "reactstrap";
import * as Yup from "yup";

import { getErrorMessage } from "../../../common/utils/formatter.util";
import { useServiceContainer } from "../../../hooks/useServiceContainer";
import { InputField } from "../../common/InputField";
import { Loader } from "../../common/Loader";
import { FileUploadStep } from "../../modals/create-design/FileUploadStep";

const validationSchema = Yup.object().shape({
  title: Yup.string().required("This field is required"),
  balance: Yup.number().integer().required("This field is required"),
  image: Yup.mixed().required("This field is required"),
  weight: Yup.number().required("This field is required"),
  price: Yup.number()
});

interface Props {
  show: boolean;
  productId?: number;
  toggle: () => void;
}

export const CreateProductAssetModal: React.FC<Props> = ({ productId, show, toggle }) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    setError
  } = useForm({
    defaultValues: {
      title: "",
      image: null,
      balance: "",
      price: "",
      weight: ""
    } as any,
    resolver: yupResolver(validationSchema)
  });

  const [loader, setLoader] = useState(false);

  const { backofficeService } = useServiceContainer();

  const onSubmitHandler = async (data: any) => {
    try {
      if (!productId) return;
      setLoader(true);
      await backofficeService.createProductAsset(productId, data);
      toggle();
      reset();
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (show) {
      reset();
    }
  }, [show]);

  return (
    <Modal isOpen={show} toggle={toggle} centered size="lg">
      {loader && <Loader />}
      <div className="modal-header">
        <h5 className="modal-title mt-0">Create new product asset</h5>
        <button
          type="button"
          onClick={() => {
            toggle();
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form onSubmit={handleSubmit(onSubmitHandler)}>
        <div className="modal-body">
          <div className="row">
            <div className="col-12">
              <div className="card border">
                <CardBody>
                  <InputField
                    {...register("title")}
                    error={errors.title?.message}
                    label="Asset title"
                    name="title"
                    placeholder="Enter asset title"
                  />

                  <InputField
                    {...register("balance")}
                    error={errors.balance?.message}
                    label="Balance"
                    name="balance"
                    type="number"
                    step="1"
                  />

                  <InputField
                    {...register("weight")}
                    error={errors.weight?.message}
                    label="Weight"
                    name="weight"
                    type="number"
                    step="0.001"
                  />

                  <InputField
                    {...register("price")}
                    error={errors.price?.message}
                    label="Price"
                    name="price"
                    type="number"
                    step="0.01"
                  />

                  <FileUploadStep
                    hideSubmitButton
                    alwaysShowTitle
                    error={errors.image?.message}
                    title="Image"
                    small
                    containerSize={12}
                    onUpload={(file) => {
                      setValue("image", file);
                      setError("image", {
                        type: "manual",
                        message: ""
                      });
                    }}
                  />
                </CardBody>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <Button
            color="danger"
            onClick={() => {
              toggle();
            }}
          >
            Cancel
          </Button>
          <Button color="primary" type="submit">
            Save
          </Button>
        </div>
      </form>
    </Modal>
  );
};
