import React, { useCallback, useEffect, useMemo, useState } from "react";

import debounce from "lodash.debounce";
import { toast } from "react-toastify";
import { Col, Row } from "reactstrap";

import { COURSES_CATEGORIES } from "../../common/constants/courses.constant";
import { IPaginationMeta } from "../../common/interfaces/api.interface";
import { ICoursesItem } from "../../common/interfaces/courses.interface";
import { getErrorMessage, getImageUrl } from "../../common/utils/formatter.util";
import { LinkInfoCard } from "../../components/common/LinkInfoCard/LinkInfoCard";
import { Loader } from "../../components/common/Loader";
import { Pagination } from "../../components/common/Pagination";
import {
  SearchBarButtonItem,
  SearchBarWithButtons
} from "../../components/common/SearchBarWithButtons/SearchBarWithButtons";
import useDidMountEffect from "../../hooks/useDidMountEffect";
import { useServiceContainer } from "../../hooks/useServiceContainer";

export const CoursesListPage = () => {
  const [loader, setLoader] = useState(false);
  const [items, setItems] = useState<ICoursesItem[]>([]);
  const [paginationMeta, setPaginationMeta] = useState<null | IPaginationMeta>(null);
  const searchBarButtons = useMemo(() => {
    return COURSES_CATEGORIES;
  }, []);
  const [searchInput, setSearchInput] = useState("");
  const [selectedButton, setSelectedButton] = useState<SearchBarButtonItem | null>(null);

  const [page, setPage] = useState(0);

  const { commonService } = useServiceContainer();

  const fetchList = useCallback(
    async (_page: number, _selectedButton: SearchBarButtonItem | null, _search?: string) => {
      setLoader(true);
      try {
        const res = await commonService.getCourses(_page + 1, 10, _search, _selectedButton?.value);
        setItems(res.items);
        setPaginationMeta(res.meta);
      } catch (e) {
        toast.error(getErrorMessage(e));
      } finally {
        setLoader(false);
      }
    },
    []
  );

  const debouncedFetchList = useCallback(debounce(fetchList, 500), []);

  useDidMountEffect(() => {
    debouncedFetchList(page, selectedButton, searchInput);
  }, [searchInput, selectedButton]);

  useEffect(() => {
    fetchList(page, selectedButton, searchInput);
  }, [page]);

  const handleSearch = (val: string) => {
    setSearchInput(val);
  };

  const handleButtonClick = (val: SearchBarButtonItem) => {
    setSelectedButton(val);
  };

  return (
    <div className="p-3">
      <div className="d-flex flex-column">
        {loader && <Loader />}
        <Row>
          <Col xs={6}>
            <h3 className="mb-0">Courses</h3>
            <p className="mt-3">
              Dive into our Courses section crafted for aspiring sellers, featuring insightful Etsy courses, Midjourney
              prompts, and a range of other valuable content. Elevate your skills, navigate entrepreneurial journeys,
              and unlock your potential with our diverse and enriching educational offerings.
            </p>
          </Col>
        </Row>

        <Row>
          <Col xs={12}>
            <SearchBarWithButtons
              selectedButton={selectedButton}
              searchInput={searchInput}
              buttons={searchBarButtons}
              onSearch={handleSearch}
              onButtonClick={handleButtonClick}
            />
          </Col>
          <Row className="mt-4">
            {items.map((e, idx) => (
              <Col xs={3} key={idx}>
                <LinkInfoCard
                  title={e.title}
                  category={e.category}
                  image={getImageUrl(e.image)}
                  description={e.description}
                  link={e.url}
                  isNew={false}
                  buttonTitle="View"
                />
              </Col>
            ))}
          </Row>
          <Row>
            {paginationMeta && paginationMeta.lastPage !== 1 && (
              <Col xs={12}>
                <Pagination
                  value={page}
                  onChange={(e) => {
                    setPage(e.selected);
                  }}
                  pageCount={paginationMeta.lastPage}
                />
              </Col>
            )}
          </Row>
        </Row>
      </div>
    </div>
  );
};
