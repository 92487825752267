export interface IDownloadItem {
  id: number;
  category: DownloadCategory;
  url: string;
  title: string;
  image: string;
  description: string;
}

export enum DownloadCategory {
  Mockups = "mockups",
  Pictures = "pictures",
  AI = "ai"
}
