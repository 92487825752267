import React, { useCallback, useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Alert, Button, Col, Row } from "reactstrap";

import { IMessage } from "../../../common/interfaces/messages.interface";
import { getErrorMessage } from "../../../common/utils/formatter.util";
import { useServiceContainer } from "../../../hooks/useServiceContainer";
import { Loader } from "../../common/Loader";
import { MessageItem } from "../../common/messages/MessageItem";

export const MessagesWidget = () => {
  const navigate = useNavigate();
  const [items, setItems] = useState<IMessage[]>([]);
  const [loader, setLoader] = useState(false);
  const { commonService } = useServiceContainer();

  const fetchList = useCallback(async () => {
    setLoader(true);
    try {
      const res = await commonService.getNews(1, 4);
      setItems(res.items);
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setLoader(false);
    }
  }, []);

  useEffect(() => {
    fetchList();
  }, []);

  return (
    <div className="message-widget">
      {loader && <Loader />}
      <div className="d-flex align-items-center">
        <h3 className="mb-0">New Messages</h3>
        <Button
          color="link"
          className="p-0 ms-4"
          onClick={() => {
            navigate("/messages");
          }}
        >
          See all messages
        </Button>
      </div>
      <Row className="mt-3">
        {items.length ? (
          items.map((e, idx) => (
            <Col key={idx} md={3}>
              <MessageItem
                item={e}
                onMarkAsReadClick={() => {
                  fetchList();
                }}
              />
            </Col>
          ))
        ) : (
          <Alert color="info">There are no messages yet.</Alert>
        )}
      </Row>
    </div>
  );
};
