import React, { useEffect, useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Button, Modal } from "reactstrap";
import * as Yup from "yup";

import { SELECTED_LISTING_VALIDATION_SCHEMA } from "../../../common/constants/product-wizard.constant";
import { IProductListingPresetItem } from "../../../common/interfaces/products.interface";
import { getErrorMessage } from "../../../common/utils/formatter.util";
import { useServiceContainer } from "../../../hooks/useServiceContainer";
import { TextAreaInput } from "../../common/Input/TextAreaInput";
import { TextFieldInput } from "../../common/Input/TextFieldInput";
import { Loader } from "../../common/Loader";
import { ListingFormTags } from "../../common/ProductWizard/listings/ListingFormTags";
import useDidMountEffect from "../../../hooks/useDidMountEffect";

interface Props {
  show: boolean;
  selectedPreset: IProductListingPresetItem | null;
  onSubmit: () => void;
  toggle: () => void;
}

export const UpdateListingTemplateValuesModal: React.FC<Props> = ({ selectedPreset, show, toggle, onSubmit }) => {
  const [loader, setLoader] = useState(false);
  const [tagValue, setTagValue] = useState<string>("");
  const [materialValue, setMaterialValue] = useState<string>("");

  const { productsApiService } = useServiceContainer();


  const {
    register,
    formState: { errors },
    setValue,
    setError,
    watch,
    trigger,
    handleSubmit,
    reset
  } = useForm({
    defaultValues: {} as any,
    resolver: yupResolver(
      Yup.object().shape(
        SELECTED_LISTING_VALIDATION_SCHEMA({
          includeKeywords: false
        })
      )
    )
  });

  const currentTags = watch(`tags`);
  const currentMaterials = watch(`materials`);

  const handleAddTag = () => {
    if (!tagValue) return;

    const trimmedValue = tagValue.trim();
    if (!trimmedValue) return;
    const splittedValue = trimmedValue.split(",").map((e) => e.trim());
    if (!splittedValue.length) return;

    for (const tag of splittedValue) {
      if (tag.length > 20) {
        toast.error(`Tag (${tag}) length must be less than 20 characters`);

        return;
      }
    }

    setValue(`tags`, [...(currentTags ?? []), ...splittedValue]);
    trigger(`tags`);
    // setError(`tags`, {
    //   type: "manual",
    //   message: ""
    // });
    setTagValue("");
  };

  const handleRemoveTag = (tag: string) => {
    setValue(
      `tags`,
      currentTags.filter((e: string) => e !== tag)
    );
    trigger(`tags`);
  };

  const handleAddMaterial = () => {
    if (!materialValue) return;

    const trimmedValue = materialValue.trim();
    if (!trimmedValue) return;
    const splittedValue = trimmedValue.split(",").map((e) => e.trim());
    if (!splittedValue.length) return;

    for (const material of splittedValue) {
      if (material.length > 45) {
        toast.error(`Meterial (${material}) length must be less than 45 characters`);

        return;
      }
    }

    setValue(`materials`, [...(currentMaterials ?? []), ...splittedValue]);
    trigger(`materials`);
    // setError(`materials`, {
    //   type: "manual",
    //   message: ""
    // });
    setMaterialValue("");
  };

  const handleRemoveMaterial = (tag: string) => {
    setValue(
      `materials`,
      currentMaterials.filter((e: string) => e !== tag)
    );
    trigger(`materials`);
  };

  const onSubmitHandler = async (data: any) => {
    try {
      if (!selectedPreset || !data) return;

      setLoader(true);

      const updatedTemplate = {
        fields: {
          keywords: {
            keyword1: data.keywords.keyword1,
            keyword2: data.keywords.keyword2
          },
          title: data.title,
          description: data.description,
          tags: data.tags,
          materials: data.materials
        }
      };

      await productsApiService.updateUserListingTemplate(selectedPreset.template!.id, updatedTemplate);

      toast.success("Template values successfully updated");
      onSubmit();
      toggle();
    } catch (e) {
      console.error(e);
      toast.error(getErrorMessage(e));
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (selectedPreset && selectedPreset.template) {
      reset({
        keywords: {
          keyword1: selectedPreset.template.fields.keywords?.keyword1,
          keyword2: selectedPreset.template.fields.keywords?.keyword2
        },
        title: selectedPreset.template.fields.title,
        description: selectedPreset.template.fields.description,
        tags: selectedPreset.template.fields.tags,
        materials: selectedPreset.template.fields.materials
      });
    }
  }, [selectedPreset]);

  // const title = watch(`title`);
  //
  // useEffect(() => {
  //   console.log('title1=>',title)
  //   setValue("title", title);
  //   console.log('title2=>',title)
  //   trigger(title);
  //   console.log('title3=>',title)
  // }, [title]);

  return (
    <Modal isOpen={show} toggle={toggle} centered size="md">
      {loader && <Loader />}
      <div className="modal-header">
        <h5 className="modal-title mt-0">Update {`"${selectedPreset?.title}"`} default template values</h5>
        <button
          type="button"
          onClick={() => {
            toggle();
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <div className="row">
          <div className="col-12">
            <form>
              <TextFieldInput
                {...register(`keywords.keyword1`)}
                label="Keyword 1:"
                hint="Your keyword will be marked as {keyword1}. Use {keyword1} to insert bellow into the title, description, and tags."
                // @ts-ignore
                error={errors?.keywords?.keyword1?.message}
              />

              <TextFieldInput
                className="mt-3"
                {...register(`keywords.keyword2`)}
                label="Keyword 2:"
                hint="Your keyword will be marked as {keyword2}. Use {keyword2} to insert bellow into the title, description, and tags."
                // @ts-ignore
                error={errors?.keywords?.keyword2?.message}
              />

              <TextFieldInput
                className="mt-3"
                {...register(`title`)}
                label="Product title:"
                // @ts-ignore
                error={errors?.title?.message}
              />

              <TextAreaInput
                className="mt-3"
                {...register(`description`)}
                label="Product description:"
                style={{ height: "180px" }}
                // @ts-ignore
                error={errors?.description?.message}
              />

              <ListingFormTags
                value={tagValue}
                onChange={(e) => {
                  setTagValue(e.target.value);
                }}
                label="Tags (max 13)"
                currentTags={currentTags}
                onAddTag={handleAddTag}
                onRemoveTag={handleRemoveTag}
                // @ts-ignore
                error={errors?.tags?.message}
              />

              <ListingFormTags
                value={materialValue}
                onChange={(e) => {
                  setMaterialValue(e.target.value);
                }}
                label="Materials (max 13)"
                currentTags={currentMaterials}
                onAddTag={handleAddMaterial}
                onRemoveTag={handleRemoveMaterial}
                // @ts-ignore
                error={errors?.materials?.message}
              />
            </form>
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <Button
          color="danger"
          onClick={() => {
            toggle();
          }}
        >
          Cancel
        </Button>
        <Button color="primary" onClick={handleSubmit((d) => onSubmitHandler(d))}>
          Save
        </Button>
      </div>
    </Modal>
  );
};
