import React from "react";

import { Navigate } from "react-router-dom";

import { IUserRole, UserRole } from "../../common/interfaces/user.interface";
import useAuth, { AuthUser } from "../../hooks/useAuth";
import { useRole } from "../../hooks/useRole";

type Props = {
  children: any;
  role?: UserRole;
};

function PrivateRoute({ children, role }: Props) {
  const auth = useAuth();
  const { hasRole } = useRole();

  if (!auth.isAuthenticated) {
    return <Navigate to="/auth/login" replace />;
  }

  if (role && !hasRole(role)) {
    return <Navigate to="/" replace />;
  }

  return children;
}

export default PrivateRoute;
