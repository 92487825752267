import React from "react";

import { Navigate } from "react-router-dom";

import { UserRole } from "../../common/interfaces/user.interface";
import {
  CartPage,
  CoursesListPage,
  CreateOrderPage,
  CreateProductPage,
  DashboardPage,
  DownloadsListPage,
  MessagesListPage,
  MockupListPage,
  NotificationsPage,
  OrderHistoryPage,
  PrintFilesPage,
  ProductDetailPage
} from "../../pages";
import { LoginPage, PasswordResetPage, RegisterPage } from "../../pages/auth";
import { PasswordResetConfirmationPage } from "../../pages/auth/PasswordResetConfirmationPage";
import {
  CategoryEditPage,
  CategoryListPage,
  DownloadsCreatePage,
  DownloadsEditPage,
  DownloadsManagementPage,
  ProductEditPage,
  ProductListPage,
  UserEditPage,
  UsersListPage,
  CoursesCreatePage,
  CoursesEditPage,
  CoursesManagementPage
} from "../../pages/backoffice";
import { CustomerEditPage, CustomerListPage } from "../../pages/backoffice/customers";
import { DeliveryServiceDetailPage } from "../../pages/backoffice/delivery/DeliveryServiceDetailPage";
import { DeliveryServicesPage } from "../../pages/backoffice/delivery/DeliveryServicesPage";
import { IncomingPaymentsPage } from "../../pages/backoffice/invoices/IncomingPaymentsPage";
import { InvoicesManagementPage } from "../../pages/backoffice/invoices/InvoicesManagementPage";
import { RegisterPayment } from "../../pages/backoffice/invoices/RegisterPayment";
import { ListingsManagementPage } from "../../pages/backoffice/listings/ListingsManagementPage";
import { ListingsTemplateManagementPage } from "../../pages/backoffice/listings/ListingsTemplateManagementPage";
import { MessagesCreatePage } from "../../pages/backoffice/messages/MessagesCreatePage";
import { MessagesEditPage } from "../../pages/backoffice/messages/MessagesEditPage";
import { MessagesManagementPage } from "../../pages/backoffice/messages/MessagesManagementPage";
import { OrdersManagementPage } from "../../pages/backoffice/orders/OrderManagementPage";
import { OrderPage } from "../../pages/backoffice/orders/OrderPage";
import { ProductAssetEdit } from "../../pages/backoffice/products/ProductAssetEdit";
import { ProductAssetsManagement } from "../../pages/backoffice/products/ProductAssetsManagement";
import { ProductDeliveryServiceManagement } from "../../pages/backoffice/products/ProductDeliveryServiceManagement";
import { WarehouseListPage } from "../../pages/backoffice/warehouse/WarehouseListPage";
import { EditMePage } from "../../pages/edit-me/EditMePage";
import { IntegrationsPage, IntegrationSuccessPage } from "../../pages/integrations";
import { InvoicesList } from "../../pages/invoices/InvoicesList";
import { MockupGeneratorPage } from "../../pages/MockupGenerator";
import { ProductListingsPage } from "../../pages/products/ProductListingsPage";
// import { SettingsPage } from "../../pages/SettingsPage";
// import { WithdrawPage } from "../../pages/WithdrawPage";

export interface AppRoute {
  path: string;
  private?: boolean;
  role?: UserRole;
  exact?: boolean;
  component: any;
}

export const routes: {
  authRoutes: AppRoute[];
  dashRoutes: AppRoute[];
  systemRoutes: AppRoute[];
} = {
  authRoutes: [
    {
      path: "/auth/login",
      component: <LoginPage />
    },
    {
      path: "/auth/register",
      component: <RegisterPage />
    },
    {
      path: "/auth/recover",
      component: <PasswordResetPage />
    },
    {
      path: "/auth/recover/process",
      component: <PasswordResetConfirmationPage />
    }
  ],
  dashRoutes: [
    {
      path: "/",
      private: true,
      component: <DashboardPage />
    },

    {
      path: "/messages",
      private: true,
      component: <MessagesListPage />
    },

    {
      path: "/downloads",
      private: true,
      component: <DownloadsListPage />
    },

    {
      path: "/courses",
      private: true,
      component: <CoursesListPage />
    },

    {
      path: "/integrations",
      private: true,
      component: <IntegrationsPage />
    },

    {
      path: "/integrations/success",
      private: true,
      component: <IntegrationSuccessPage />
    },

    {
      path: "/notifications",
      private: true,
      component: <NotificationsPage />
    },

    {
      path: "/mockup",
      private: true,
      component: <MockupGeneratorPage />
    },
    // ~~~~~~ Orders ~~~~~~~~
    {
      path: "/orders/create",
      private: true,
      component: <CreateOrderPage />
    },
    {
      path: "/orders/list",
      private: true,
      component: <OrderHistoryPage />
    },
    {
      path: "/orders/:id",
      private: true,
      component: <OrderPage />
    },
    // ~~~~~~ Orders ~~~~~~~~

    // ~~~~~~ Print files ~~~~~~~~
    {
      path: "/print-files",
      private: true,
      component: <PrintFilesPage />
    },
    // ~~~~~~ Print files ~~~~~~~~

    // ~~~~~~ Common ~~~~~~~~
    {
      path: "/user/edit",
      private: true,
      component: <EditMePage />
    },
    // ~~~~~~ Print files ~~~~~~~~

    // ~~~~~~ Mockups ~~~~~~~~
    {
      path: "/mockups/list",
      private: true,
      component: <MockupListPage />
    },
    // ~~~~~~ Mockups ~~~~~~~~

    // ~~~~~~ Products ~~~~~~
    // {
    //   path: "/categories",
    //   private: true,
    //   component: <CategoryListPage />
    // },
    // {
    //   path: "/categories/:id",
    //   private: true,
    //   component: <CategoryProductListPage />
    // },
    {
      path: "/products/create",
      private: true,
      component: <CreateProductPage />
    },
    {
      path: "/invoices",
      private: true,
      component: <InvoicesList />
    },
    {
      path: "/products/:id",
      private: true,
      component: <ProductDetailPage />
    },

    {
      path: "/products",
      private: true,
      component: <ProductListingsPage />
    },

    {
      path: "/cart",
      private: true,
      component: <CartPage />
    },

    // ~~~~~~ Backoffice ~~~~~~

    {
      path: "/backoffice/categories",
      private: true,
      role: UserRole.Admin,
      component: <CategoryListPage />
    },
    {
      path: "/backoffice/categories/:id/edit",
      private: true,
      role: UserRole.Admin,
      component: <CategoryEditPage />
    },
    {
      path: "/backoffice/products",
      private: true,
      role: UserRole.Admin,
      component: <ProductListPage />
    },
    {
      path: "/backoffice/products/:id/edit",
      private: true,
      role: UserRole.Admin,
      component: <ProductEditPage />
    },
    {
      path: "/backoffice/products/:id/assets",
      private: true,
      role: UserRole.Admin,
      component: <ProductAssetsManagement />
    },
    {
      path: "/backoffice/products/:id/delivery-services",
      private: true,
      role: UserRole.Admin,
      component: <ProductDeliveryServiceManagement />
    },
    {
      path: "/backoffice/products/:productId/assets/:assetId/edit",
      private: true,
      role: UserRole.Admin,
      component: <ProductAssetEdit />
    },
    {
      path: "/backoffice/users",
      private: true,
      role: UserRole.Admin,
      component: <UsersListPage />
    },
    {
      path: "/backoffice/users/:id/edit",
      private: true,
      role: UserRole.Admin,
      component: <UserEditPage />
    },
    {
      path: "/backoffice/customers",
      private: true,
      role: UserRole.Admin,
      component: <CustomerListPage />
    },
    {
      path: "/backoffice/customers/:id/edit",
      private: true,
      role: UserRole.Admin,
      component: <CustomerEditPage />
    },

    {
      path: "/backoffice/orders",
      private: true,
      role: UserRole.Admin,
      component: <OrdersManagementPage />
    },

    {
      path: "/backoffice/orders/:id",
      private: true,
      role: UserRole.Admin,
      component: <OrderPage editable />
    },

    {
      path: "/backoffice/warehouse",
      private: true,
      role: UserRole.Admin,
      component: <WarehouseListPage />
    },

    {
      path: "/backoffice/invoices",
      private: true,
      role: UserRole.Admin,
      component: <InvoicesManagementPage />
    },

    {
      path: "/backoffice/invoices/register-payment",
      private: true,
      role: UserRole.Admin,
      component: <RegisterPayment />
    },

    {
      path: "/backoffice/invoices/incoming-payments",
      private: true,
      role: UserRole.Admin,
      component: <IncomingPaymentsPage />
    },

    {
      path: "/backoffice/invoices/incoming-payments/:id/edit",
      private: true,
      role: UserRole.Admin,
      component: <RegisterPayment isEdit />
    },

    {
      path: "/backoffice/delivery",
      private: true,
      role: UserRole.Admin,
      component: <DeliveryServicesPage />
    },

    {
      path: "/backoffice/delivery/:deliveryServiceId/edit",
      private: true,
      role: UserRole.Admin,
      component: <DeliveryServiceDetailPage />
    },

    {
      path: "/backoffice/messages",
      private: true,
      role: UserRole.Admin,
      component: <MessagesManagementPage />
    },

    {
      path: "/backoffice/messages/create",
      private: true,
      role: UserRole.Admin,
      component: <MessagesCreatePage />
    },

    {
      path: "/backoffice/messages/:id/edit",
      private: true,
      role: UserRole.Admin,
      component: <MessagesEditPage />
    },

    {
      path: "/backoffice/downloads",
      private: true,
      role: UserRole.Admin,
      component: <DownloadsManagementPage />
    },

    {
      path: "/backoffice/downloads/create",
      private: true,
      role: UserRole.Admin,
      component: <DownloadsCreatePage />
    },

    {
      path: "/backoffice/downloads/:id/edit",
      private: true,
      role: UserRole.Admin,
      component: <DownloadsEditPage />
    },

    {
      path: "/backoffice/courses",
      private: true,
      role: UserRole.Admin,
      component: <CoursesManagementPage />
    },

    {
      path: "/backoffice/courses/create",
      private: true,
      role: UserRole.Admin,
      component: <CoursesCreatePage />
    },

    {
      path: "/backoffice/courses/:id/edit",
      private: true,
      role: UserRole.Admin,
      component: <CoursesEditPage />
    },

    {
      path: "/backoffice/listings",
      private: true,
      role: UserRole.Admin,
      component: <ListingsManagementPage />
    },
    {
      path: "/backoffice/listings/templates",
      private: true,
      role: UserRole.Admin,
      component: <ListingsTemplateManagementPage />
    }
  ],
  systemRoutes: [
    {
      path: "*",
      private: true,
      component: <Navigate replace to="/dash" />
    }
  ]
};
