import React, { useRef } from "react";

import TawkMessengerReact from "@tawk.to/tawk-messenger-react";
import { sha256 } from "js-sha256";

import useAuth from "../../hooks/useAuth";

export const TawkMessenger = () => {
  const tawkMessengerRef = useRef<any>();
  const { user } = useAuth();

  const onLoad = () => {
    if (user) {
      tawkMessengerRef.current.setAttributes(
        {
          name: user.name,
          email: user.email,
          hash: sha256(`${user.email}:${user.id}`)
        },
        function (error: any) {
          console.log("Tawk Error: setAttributes", error);
        }
      );
    }
  };

  return (
    <TawkMessengerReact
      ref={tawkMessengerRef}
      onLoad={onLoad}
      propertyId="65f2a35b9131ed19d979ba51"
      widgetId="1hotss80n"
    />
  );
};
