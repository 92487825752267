import React, { useMemo, useRef, useState } from "react";

import { useDrag, useDrop } from "react-dnd";
import { toast } from "react-toastify";
import { Button, Card, CardBody } from "reactstrap";

import { IMockupEntity, IMockupFile } from "../../common/interfaces/mockup.inteface";
import { downloadMockupFile } from "../../common/utils/file.util";
import { getErrorMessage, getImageUrl } from "../../common/utils/formatter.util";
import { useDragIfAvailable } from "../../hooks/useDragIfAvailable";
import { useServiceContainer } from "../../hooks/useServiceContainer";
import { Loader } from "../common/Loader";

interface Props {
  selectedNode: IMockupEntity | IMockupFile;
  additionalMockupPreviewBlock: any;
  itemsDraggable?: boolean;
  itemsOrderable?: boolean;
  onItemDrop?: (item: { file: IMockupFile }, target: any) => void;
  onItemMove?: (file: IMockupFile, cursor: 1 | -1) => void;
  // onItemDragMove?: (current: IMockupFile, target: IMockupFile) => void;
  hideDownloadButton?: boolean;
  titleText?: string;
  hideDefaultPreviewItems?: boolean;
}

interface MockupEntityPreviewProps {
  file: IMockupFile;
  canDownload?: boolean;
  itemsDraggable?: boolean;
  itemsOrderable?: boolean;
  onDrop?: (item: { file: IMockupFile }, target: any) => void;
  onMove?: (cursor: 1 | -1) => void;
  onDragMove?: (current: IMockupFile, target: IMockupFile) => void;
}

export const MockupEntityPreview: React.FC<MockupEntityPreviewProps> = ({
  file,
  canDownload,
  itemsDraggable,
  itemsOrderable,
  onDrop,
  onMove
  // onDragMove
}) => {
  const ref = useRef<any>(null);
  const [{ isDragging }, drag] = useDragIfAvailable(
    () => ({
      type: "MOCKUP_FILE",
      item: { file },
      canDrag: false,
      end: (item, monitor) => {
        const dropResult = monitor.getDropResult<any>();
        if (item && dropResult && onDrop) {
          onDrop(item, dropResult);
        }
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
        handlerId: monitor.getHandlerId(),
        isOver: monitor
      })
    }),
    [file]
  );

  // const [, drop] = useDrop({
  //   accept: "MOCKUP_FILE",
  //   hover(item: { file: IMockupFile }, monitor) {
  //     console.log("IS_OVER", monitor.isOver());
  //     const isOver = monitor.isOver();
  //     if (isOver) {
  //       const target = item;
  //       const current = file;
  //
  //       if (file.id === item.file.id || !onDragMove) return;
  //
  //       onDragMove(current, target.file);
  //       // console.log("item", file);
  //       // console.log("target", item);
  //     }
  //
  //     // const currentIndex = renderedItems.indexOf(draggedItem);
  //     // const targetIndex = renderedItems.indexOf(item);
  //     //
  //     // if (currentIndex !== -1 && targetIndex !== -1) {
  //     //   const newItems = [...renderedItems];
  //     //   newItems.splice(currentIndex, 1);
  //     //   newItems.splice(targetIndex, 0, draggedItem);
  //     //   setTemporaryItems(newItems);
  //     // }
  //   },
  //   drop: (item, monitor) => {
  //     console.log("DROP", item, monitor);
  //   },
  //   collect: (monitor) => ({
  //     isOver: monitor.isOver()
  //   })
  // });

  if (itemsDraggable) {
    drag(ref);
  }

  const opacity = itemsDraggable && isDragging ? 0.4 : 1;

  return (
    <div
      className="mockup-preview h-100"
      {...(itemsDraggable
        ? {
            ref,
            style: { opacity }
          }
        : {})}
    >
      <div className="card border h-100">
        {canDownload && (
          <div className="mockup-preview__download">
            <Button
              color="info"
              type="button"
              onClick={() => {
                downloadMockupFile(file);
              }}
            >
              <i className="bx bx-download" />
            </Button>
          </div>
        )}
        {itemsOrderable && onMove ? (
          <div className="mockup-preview__order-cursor">
            <button
              type="button"
              onClick={() => {
                onMove(-1);
              }}
            >
              <i className="bx bx-chevron-left" />
            </button>
            <button
              type="button"
              onClick={() => {
                onMove(1);
              }}
            >
              <i className="bx bx-chevron-right" />
            </button>
          </div>
        ) : (
          ""
        )}
        <div className="mockup-preview__image">
          <img
            className="card-img-top img-fluid rounded-top"
            src={file.url ? getImageUrl(file.url) : file.image}
            alt="mockup preview"
          />
        </div>
        <div className="mockup-preview__text">{file.url ? file.url.split("/").pop() : (file.name as string)}</div>
      </div>
    </div>
  );
};

export const MockupListPreview: React.FC<Props> = ({
  selectedNode,
  additionalMockupPreviewBlock,
  itemsDraggable,
  itemsOrderable,
  onItemDrop,
  onItemMove,
  // onItemDragMove,
  hideDownloadButton,
  titleText,
  hideDefaultPreviewItems
}) => {
  const { commonService } = useServiceContainer();
  const [loader, setLoader] = useState(false);
  const isFile = useMemo(() => {
    return selectedNode && "url" in selectedNode;
  }, [selectedNode]);

  const isGroup = useMemo(() => {
    return selectedNode && "files" in selectedNode;
  }, [selectedNode]);

  const handleDownloadClick = async () => {
    setLoader(true);
    try {
      if (isGroup) {
        await commonService.downloadZipArchiveFromStorageUrls(
          (selectedNode as IMockupEntity).files.map((file) => [file.url!]),
          (selectedNode as IMockupEntity).id
        );
      } else if (isFile) {
        const file = selectedNode as IMockupFile;
        downloadMockupFile(file);
      }
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setLoader(false);
    }
  };

  return (
    <>
      <h3>Folder: {selectedNode.id}</h3>
      <Card className="mockup-list-preview h-100 mb-0">
        {loader && <Loader />}
        <CardBody className="d-flex flex-column">
          {additionalMockupPreviewBlock}
          {!hideDefaultPreviewItems ? (
            <>
              <h6>{titleText ?? "Mockup files"}</h6>
              {isFile && (
                <div className="row flex-grow-1">
                  <div className="col-12">
                    <MockupEntityPreview
                      file={selectedNode as IMockupFile}
                      itemsDraggable={itemsDraggable}
                      onDrop={onItemDrop}
                    />
                  </div>
                </div>
              )}

              {isGroup && (
                <div className="row">
                  {(selectedNode as IMockupEntity).files.map((file, idx) => (
                    <div className="col-3 mb-3" key={idx}>
                      <MockupEntityPreview
                        file={file}
                        canDownload
                        itemsOrderable={itemsOrderable}
                        itemsDraggable={itemsDraggable}
                        onDrop={onItemDrop}
                        onMove={onItemMove ? (cursor) => onItemMove(file, cursor) : undefined}
                        // onDragMove={onItemDragMove}
                      />
                    </div>
                  ))}
                </div>
              )}
            </>
          ) : (
            ""
          )}

          {!hideDownloadButton && (
            <Button color="info" className="mt-auto" type="button" onClick={handleDownloadClick}>
              <i className="bx bx-download me-2" />
              Download
            </Button>
          )}
        </CardBody>
      </Card>
    </>
  );
};
