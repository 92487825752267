import React, { useEffect, useMemo, useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import * as Yup from "yup";

import { IUserItemWithCustomer } from "../../../common/interfaces/backoffice.interface";
import { showConfirmationModal } from "../../../common/utils/confirm.util";
import { getErrorMessage } from "../../../common/utils/formatter.util";
import { getObjectDelta } from "../../../common/utils/object.util";
import { InputField } from "../../../components/common/InputField";
import { Loader } from "../../../components/common/Loader";
import { useServiceContainer } from "../../../hooks/useServiceContainer";

const validationSchema = Yup.object().shape({
  called: Yup.string(),
  email: Yup.string(),
  phone: Yup.string(),
  name: Yup.string()
});

export const UserEditPage = () => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset
  } = useForm({
    defaultValues: {} as any,
    resolver: yupResolver(validationSchema)
  });

  const [userData, setUserData] = useState<null | IUserItemWithCustomer>(null);

  const [loader, setLoader] = useState(true);
  const { id } = useParams();
  const { backofficeService } = useServiceContainer();
  const navigate = useNavigate();

  const customerValue = useMemo(() => {
    if (!userData) return "-";

    const customer = userData.customer[0] ?? undefined;

    if (!customer) return "-";

    return `[${customer.id}] ${customer.name} ${customer.surname}`;
  }, [userData]);

  const fetch = async () => {
    try {
      if (!id) return;
      setLoader(true);
      const data = await backofficeService.getUserById(+id);
      setUserData(data);
    } catch (e) {
      toast.error(getErrorMessage(e));
      navigate("/");
    }
  };

  const processResetPassword = async () => {
    try {
      if (!id) return;
      setLoader(true);
      await backofficeService.resetUserPasswordById(+id);
      toast.success("Password reset successfully");
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setLoader(false);
    }
  };

  const onSubmitHandler = async (data: any) => {
    try {
      if (!userData || !id) return;
      setLoader(true);
      const delta = getObjectDelta(userData, {
        called: data.called,
        email: data.email,
        phone: data.phone,
        name: data.name
      });

      const updatedUser = await backofficeService.updateUserById(+id, delta);

      setUserData(updatedUser);

      toast.success("User updated successfully");
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setLoader(false);
    }
  };

  const handleResetPasswordButtonClick = async () => {
    showConfirmationModal({
      title: "Are you sure?",
      message: "Do you really want to reset password for this user?",
      onConfirm: async () => {
        await processResetPassword();
      },
      onCancel: () => {}
    });
  };

  const updateFormState = async (data: IUserItemWithCustomer) => {
    setLoader(true);
    reset({
      called: data.called,
      email: data.email,
      phone: data.phone,
      name: data.name
    });

    setLoader(false);
  };

  useEffect(() => {
    if (userData) {
      updateFormState(userData).then(() => null);
    }
  }, [userData]);

  useEffect(() => {
    fetch().then(() => null);
  }, [id]);

  return (
    <Row>
      {loader && <Loader />}
      <Col xs={12}>
        <Card>
          <CardBody>
            {userData && (
              <>
                <h3>User #{userData.id}</h3>
                <form onSubmit={handleSubmit(onSubmitHandler)}>
                  <InputField label="Customer" name="customer" disabled readonly value={customerValue} />
                  <InputField {...register("name")} error={errors.name?.message} label="Name" name="name" />
                  <InputField {...register("called")} error={errors.called?.message} label="Called" name="called" />
                  <InputField {...register("email")} error={errors.email?.message} label="Email" name="email" />
                  <InputField {...register("phone")} error={errors.phone?.message} label="Phone" name="phone" />

                  <Button type="submit" color="success">
                    Save
                  </Button>
                  <Button
                    type="button"
                    color="primary"
                    className="ms-2"
                    onClick={() => {
                      updateFormState(userData).then(() => null);
                    }}
                  >
                    Reset
                  </Button>
                  <Button type="button" color="danger" className="ms-2" onClick={handleResetPasswordButtonClick}>
                    Reset password
                  </Button>
                </form>
              </>
            )}
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};
