import React from "react";

import classNames from "classnames";
import moment from "moment";
import { Comparator, textFilter } from "react-bootstrap-table2-filter";

import { IInvoice } from "../interfaces/invoices.interface";

export const INVOICES_LIST_TABLE_FILTER_INSTANCES: Record<string, any> = {
  invoice_number: null,
  due: null,
  clientName: null,
  clientRegNr: null,
  clientVatNr: null,
  balance: null
};

export const CLEAR_INVOICES_LIST_TABLE_FILTER_VALUES = () => {
  for (const key in INVOICES_LIST_TABLE_FILTER_INSTANCES) {
    if (INVOICES_LIST_TABLE_FILTER_INSTANCES[key] && typeof INVOICES_LIST_TABLE_FILTER_INSTANCES[key] === "function") {
      if (key === "created_at") {
        INVOICES_LIST_TABLE_FILTER_INSTANCES[key]({
          comparator: Comparator.EQ,
          date: ""
        });
      } else {
        INVOICES_LIST_TABLE_FILTER_INSTANCES[key]("");
      }
    }
  }
};

export const INVOICES_LIST_TABLE_COLUMNS = [
  {
    dataField: "invoice_number",
    text: "Invoice ID",
    filter: textFilter({
      getFilter: (filter: any) => {
        INVOICES_LIST_TABLE_FILTER_INSTANCES.invoice_number = filter;
      },
      caseSensitive: true,
      placeholder: " ",
      className: "form-control-sm mt-1"
    }),
    sort: true,
    style: {
      minWidth: "90px"
    }
  },
  {
    dataField: "created_at",
    text: "Creation date",
    formatter: (cellContent: string) => {
      return moment(cellContent).format("DD.MM.YYYY");
    }
  },
  {
    dataField: "due",
    text: "Due date",
    filter: textFilter({
      getFilter: (filter: any) => {
        INVOICES_LIST_TABLE_FILTER_INSTANCES.due = filter;
      },
      caseSensitive: true,
      placeholder: " ",
      className: "form-control-sm mt-1"
    }),
    sort: true,
    style: {
      minWidth: "90px"
    }
  },
  {
    dataField: "clientName",
    isDummyField: true,
    text: "Client name",
    filter: textFilter({
      getFilter: (filter: any) => {
        INVOICES_LIST_TABLE_FILTER_INSTANCES.clientName = filter;
      },
      caseSensitive: true,
      placeholder: " ",
      className: "form-control-sm mt-1"
    }),
    sort: true,
    style: {
      minWidth: "90px"
    },
    formatter: (cellContent: any, row: IInvoice) => {
      if (row.customer.company_title) {
        return row.customer.company_title;
      }

      return `${row.customer.name} ${row.customer.surname}`;
    }
  },
  {
    dataField: "clientRegNr",
    isDummyField: true,
    text: "Client reg nr",
    filter: textFilter({
      getFilter: (filter: any) => {
        INVOICES_LIST_TABLE_FILTER_INSTANCES.clientRegNr = filter;
      },
      caseSensitive: true,
      placeholder: " ",
      className: "form-control-sm mt-1"
    }),
    sort: true,
    style: {
      minWidth: "90px"
    },
    formatter: (cellContent: any, row: IInvoice) => {
      return `${row.customer.reg_nr}`;
    }
  },
  {
    dataField: "clientVatNr",
    isDummyField: true,
    text: "Client vat nr",
    filter: textFilter({
      getFilter: (filter: any) => {
        INVOICES_LIST_TABLE_FILTER_INSTANCES.clientVatNr = filter;
      },
      caseSensitive: true,
      placeholder: " ",
      className: "form-control-sm mt-1"
    }),
    sort: true,
    style: {
      minWidth: "90px"
    },
    formatter: (cellContent: any, row: IInvoice) => {
      return `${row.customer.vat_nr}`;
    }
  },
  {
    dataField: "balance",
    text: "Balance",
    filter: textFilter({
      getFilter: (filter: any) => {
        INVOICES_LIST_TABLE_FILTER_INSTANCES.Balance = filter;
      },
      caseSensitive: true,
      placeholder: " ",
      className: "form-control-sm mt-1"
    }),
    sort: true,
    style: {
      minWidth: "90px"
    },
    formatter: (cellContent: string) => {
      return `€${cellContent}`;
    }
  },
  {
    dataField: "fully_paid",
    text: "Fully paid",
    formatter: (cellContent: boolean) => {
      return (
        <span
          className={classNames("badge", {
            "bg-success": cellContent,
            "bg-danger": !cellContent
          })}
        >
          {cellContent ? "Yes" : "No"}
        </span>
      );
    }
  }
];
