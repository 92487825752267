import React, { useCallback, useEffect, useMemo, useState } from "react";

import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Modal } from "reactstrap";

import { MockupType } from "../../../common/interfaces/mockup-wizard.interface";
import { IMockupEntity } from "../../../common/interfaces/mockup.inteface";
import { ExtendedFile } from "../../../common/interfaces/vendor.interface";
import { useAppDispatch } from "../../../store/store";
import { ASSETS_RESET_SELECTED } from "../../../store/wizards/productAssetsSlice";
import { DESIGNS_RESET_SELECTED } from "../../../store/wizards/productDesignsSlice";
import { PRODUCT_SET_SELECTED_MOCKUP_ENTITY } from "../../../store/wizards/productSlice";
import { PRODUCT_TYPES_RESET_SELECTED } from "../../../store/wizards/productTypesSlice";
import { AutoMockupWizard } from "./AutoMockup/AutoMockupWizard";
import { CustomMockupWizard } from "./CustomMockup/CustomMockupWizard";
import { MockupTypeSelection } from "./TypeSelection";

interface Props {
  show: boolean;
  toggle: () => void;
  onSubmit: (data: ExtendedFile[]) => void;
}

export const GenerateMockupModal: React.FC<Props> = ({ show, toggle, onSubmit }) => {
  const [selectedType, setSelectedType] = useState<MockupType | null>(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const hasPredefinedType = useMemo(() => {
    return !!searchParams.get("type");
  }, [searchParams]);

  const hasReturnUrl = useMemo(() => {
    return !!searchParams.get("return_to");
  }, [searchParams]);

  const moveBack = useCallback(() => {
    if (hasReturnUrl) {
      navigate(searchParams.get("return_to")!);
    } else {
      navigate("/mockups/list");
    }
  }, [hasReturnUrl]);

  const handleCreatedEntity = useCallback(
    (item: IMockupEntity) => {
      if (searchParams.get("store") && searchParams.get("store") === "product") {
        dispatch(PRODUCT_SET_SELECTED_MOCKUP_ENTITY(item));
      }
    },
    [searchParams]
  );

  const stepContent = useMemo(() => {
    if (selectedType) {
      switch (selectedType) {
        case MockupType.AUTO:
          return (
            <AutoMockupWizard
              predefinedData={{
                productId: searchParams.get("productId") ?? undefined,
                designIds: searchParams.get("designIds") ? searchParams.get("designIds")!.split(",") : undefined,
                store: searchParams.get("store") ?? undefined
              }}
              onReset={() => {
                resetModalState();
              }}
              onSubmit={(mockup) => {
                // toggle();
                handleCreatedEntity(mockup);
                moveBack();
              }}
            />
          );
        case MockupType.CUSTOM:
          return (
            <CustomMockupWizard
              onSubmit={(mockup) => {
                // toggle();
                handleCreatedEntity(mockup);
                moveBack();
              }}
            />
          );
        default:
          return "not implemented";
      }
    }

    return (
      <MockupTypeSelection
        onTypeSelect={(type) => {
          setSelectedType(type);
        }}
      />
    );
  }, [selectedType, onSubmit, searchParams, moveBack]);

  const resetModalState = () => {
    dispatch(ASSETS_RESET_SELECTED());
    dispatch(PRODUCT_TYPES_RESET_SELECTED());
    dispatch(DESIGNS_RESET_SELECTED());
  };

  useEffect(() => {
    if (!show) {
      resetModalState();
    }
  }, [show]);

  useEffect(() => {
    if (hasPredefinedType) {
      setSelectedType(searchParams.get("type") as MockupType);
    }
  }, [hasPredefinedType]);

  return (
    <Modal fade={false} isOpen={show} toggle={toggle} centered size="xl" className="modal-fullscreen">
      <div className="modal-header">
        <h5 className="modal-title mt-0">Mockup generator</h5>
        <button
          type="button"
          onClick={() => {
            toggle();
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body modal-body--gray">{stepContent}</div>
    </Modal>
  );
};
