import { IBaseApiResponse, IPaginationMeta } from "../../common/interfaces/api.interface";
import {
  IProductAssetGroup,
  IProductCreateSubmitPayload,
  IProductListingItem,
  IProductListingPresetItem,
  IProductListingTemplate,
  IProductMockupDesignConnectorStorageItem,
  IProductSectionOption,
  IProductShippingProfileItem
} from "../../common/interfaces/products.interface";
import { assembleFiltersAndSort } from "../../common/utils/filter.util";
import BaseAPIService from "./BaseApiService";

class ProductsApiService extends BaseAPIService {
  constructor() {
    super(`${process.env.REACT_APP_API_BASE_ENDPOINT}/api`);
  }

  public async setListingStatuses(listingId: number, statuses: Record<string, boolean>) {
    await this.api.post(`/ecommerce/shops/${listingId}/set-status`, statuses);
  }

  public async getProductListings(page = 1, filters?: Record<string, string>, isAdmin = false) {
    const query = new URLSearchParams();

    if (isAdmin) {
      query.append("isAdmin", "1");
    }

    query.append("page", page.toString());
    // console.log("API", filters);

    const additionalParams = filters ? assembleFiltersAndSort(filters) : {};

    for (const [key, value] of Object.entries(additionalParams)) {
      if (value) {
        query.append(key, value);
      }
    }

    const res = await this.api.get<
      IBaseApiResponse<{
        meta: IPaginationMeta;
        items: IProductListingItem[];
      }>
    >(`/ecommerce/products?${query.toString()}`);

    return res.data.data;
  }

  public async getProductListingById(id: number) {
    const res = await this.api.get<IBaseApiResponse<IProductListingItem>>(`/ecommerce/products/${id}`);

    return res.data.data;
  }

  public async deleteProductListingById(id: number) {
    await this.api.delete(`/ecommerce/products/${id}`);
  }

  public async getDefaultListingGroupPresets(
    productId: number,
    isManagement?: boolean
  ): Promise<{
    presets: IProductListingPresetItem[];
    groups: IProductAssetGroup[];
  }> {
    const query = new URLSearchParams({ product_id: productId.toString() });
    const defaultPresetsRes = await this.api.get<IBaseApiResponse<IProductListingPresetItem[]>>(
      `/ecommerce/presets/default?${query.toString()}`
    );

    const modelGroupsRes = await this.api.get<IBaseApiResponse<IProductAssetGroup[]>>(
      `/ecommerce/models/default?${query.toString()}`
    );

    let customListingRes: any[] = [];

    if (!isManagement) {
      customListingRes = await this.getCustomListingGroupPresets(productId);
    }

    return {
      presets: [...defaultPresetsRes.data.data, ...customListingRes],
      groups: modelGroupsRes.data.data
    };
  }

  public async getCustomListingGroupPresets(productId: number): Promise<IProductListingPresetItem[]> {
    const query = new URLSearchParams({ product_id: productId.toString() });
    const res = await this.api.get<IBaseApiResponse<IProductListingPresetItem[]>>(
      `/ecommerce/presets?${query.toString()}`
    );

    return res.data.data;
  }

  public async addCustomListingGroupPreset(
    productId: number,
    name: string,
    assetIds?: number[]
  ): Promise<IProductListingPresetItem> {
    const res = await this.api.post<IBaseApiResponse<IProductListingPresetItem>>(`/ecommerce/presets/create`, {
      product_id: productId,
      name,
      asset_ids: assetIds ?? []
    });

    return res.data.data;
  }

  public async addDefaultListingGroupPreset(
    productId: number,
    name: string,
    templateData: {
      name: string;
    }
  ): Promise<IProductListingPresetItem> {
    const res = await this.api.post<IBaseApiResponse<IProductListingPresetItem>>(`/ecommerce/presets/create`, {
      product_id: productId,
      name,
      is_default: true
    });

    const templateRes = await this.api.post<IBaseApiResponse<IProductListingTemplate>>(
      `/ecommerce/listings/templates/create`,
      {
        name: templateData.name,
        fields: {
          keywords: {},
          materials: [],
          tags: [],
          description: "",
          title: ""
        },
        preset_id: res.data.data.id,
        is_default: true,
        product_id: productId
      }
    );

    return {
      ...res.data.data,
      template: templateRes.data.data
    };
  }

  public async deleteListingGroupPresetById(id: number): Promise<void> {
    await this.api.delete(`/ecommerce/presets/${id}`);
  }

  public async updateCustomListingGroupPreset(id: number, assetIds?: number[]): Promise<IProductListingPresetItem> {
    const res = await this.api.post<IBaseApiResponse<IProductListingPresetItem>>(`/ecommerce/presets/update/${id}`, {
      asset_ids: assetIds ?? []
    });

    return res.data.data;
  }

  public async deleteCustomListingGroupPreset(id: number): Promise<void> {
    const res = await this.api.delete(`/ecommerce/presets/${id}`);

    return res.data.data;
  }

  public async getUserListingTemplates(presetId: number, productId: number) {
    const query = new URLSearchParams({
      product_id: productId.toString()
      // preset_id: presetId.toString()
    });
    const res = await this.api.get<IBaseApiResponse<IProductListingTemplate[]>>(
      `/ecommerce/listings/templates?${query.toString()}`
    );

    return res.data.data;
  }

  public async createUserListingTemplate(data: Omit<IProductListingTemplate, "id" | "preset_id">) {
    const res = await this.api.post<IBaseApiResponse<IProductListingTemplate>>(
      `/ecommerce/listings/templates/create`,
      data
    );

    return res.data.data;
  }

  public async deleteUserListingTemplate(id: number) {
    await this.api.delete<IBaseApiResponse<IProductListingTemplate>>(`/ecommerce/listings/templates/${id}`);
  }

  public async updateUserListingTemplate(id: number, data: Partial<Omit<IProductListingTemplate, "id">>) {
    await this.api.post<IBaseApiResponse<IProductListingTemplate>>(`/ecommerce/listings/templates/${id}/update`, {
      ...data
    });
  }

  public async updateDefaultListingPreset(id: number, data: Partial<Omit<IProductListingPresetItem, "id">>) {
    await this.api.post<IBaseApiResponse<IProductListingPresetItem>>(`/ecommerce/presets/update/${id}`, {
      ...data
    });

    // return res.data.data;
    // await this.api.post<IBaseApiResponse<IProductListingTemplate>>(`/ecommerce/presets/${id}/update`, {
    //   ...data
    // });
  }

  public async getEtsySectionOptions(shopId: number) {
    const res = await this.api.get<IBaseApiResponse<IProductSectionOption[]>>(
      `/ecommerce/etsy/shop/${shopId}/sections`
    );
    return res.data.data;
    // const res = {
    //   "success": true,
    //   "data": [
    //     {
    //       "id": 47532839,
    //       "title": "Abstract"
    //     },
    //     {
    //       "id": 49404592,
    //       "title": "Animals"
    //     },
    //     {
    //       "id": 46674031,
    //       "title": "Flowers"
    //     },
    //     {
    //       "id": 47525426,
    //       "title": "Family"
    //     },
    //     {
    //       "id": 49447271,
    //       "title": "Cars"
    //     },
    //     {
    //       "id": 49497065,
    //       "title": "Art"
    //     },
    //     {
    //       "id": 50573759,
    //       "title": "Halloween"
    //     },
    //     {
    //       "id": 50705078,
    //       "title": "Gifts for Him"
    //     }
    //   ],
    //   "message": "Success"
    // };
    // return res.data
  }

  public async getEtsyShippingProfiles(shopId: number) {
    const res = await this.api.get<IBaseApiResponse<IProductShippingProfileItem[]>>(
      `/ecommerce/etsy/shop/${shopId}/shipping-profiles`
    );

    return res.data.data;

    // const res = {
    //   "success": true,
    //   "data": [
    //     {
    //       "shipping_profile_id": 234043766520,
    //       "title": "FREE SHIPPING",
    //       "user_id": 876230145,
    //       "min_processing_days": 1,
    //       "max_processing_days": 3,
    //       "processing_days_display_label": "1-3 days",
    //       "origin_country_iso": "LV",
    //       "origin_postal_code": "LV1006",
    //       "profile_type": "manual",
    //       "is_deleted": false,
    //       "domestic_handling_fee": 0,
    //       "international_handling_fee": 0,
    //       "shipping_profile_destinations": [
    //         {
    //           "shipping_profile_destination_id": 48522557188,
    //           "shipping_profile_id": 234043766520,
    //           "origin_country_iso": "LV",
    //           "destination_country_iso": "",
    //           "destination_region": "none",
    //           "primary_cost": {
    //             "amount": 0,
    //             "divisor": 100,
    //             "currency_code": "EUR"
    //           },
    //           "secondary_cost": {
    //             "amount": 0,
    //             "divisor": 100,
    //             "currency_code": "EUR"
    //           },
    //           "shipping_carrier_id": 0,
    //           "mail_class": null,
    //           "min_delivery_days": 5,
    //           "max_delivery_days": 21
    //         },
    //         {
    //           "shipping_profile_destination_id": 48522557184,
    //           "shipping_profile_id": 234043766520,
    //           "origin_country_iso": "LV",
    //           "destination_country_iso": "LV",
    //           "destination_region": "none",
    //           "primary_cost": {
    //             "amount": 0,
    //             "divisor": 100,
    //             "currency_code": "EUR"
    //           },
    //           "secondary_cost": {
    //             "amount": 0,
    //             "divisor": 100,
    //             "currency_code": "EUR"
    //           },
    //           "shipping_carrier_id": 0,
    //           "mail_class": null,
    //           "min_delivery_days": 1,
    //           "max_delivery_days": 2
    //         }
    //       ],
    //       "shipping_profile_upgrades": []
    //     },
    //     {
    //       "shipping_profile_id": 219163188450,
    //       "title": "Shipping FROM EU",
    //       "user_id": 876230145,
    //       "min_processing_days": 1,
    //       "max_processing_days": 3,
    //       "processing_days_display_label": "1-3 days",
    //       "origin_country_iso": "LV",
    //       "origin_postal_code": "LV1006",
    //       "profile_type": "manual",
    //       "is_deleted": false,
    //       "domestic_handling_fee": 0,
    //       "international_handling_fee": 0,
    //       "shipping_profile_destinations": [
    //         {
    //           "shipping_profile_destination_id": 45432135539,
    //           "shipping_profile_id": 219163188450,
    //           "origin_country_iso": "LV",
    //           "destination_country_iso": "",
    //           "destination_region": "none",
    //           "primary_cost": {
    //             "amount": 399,
    //             "divisor": 100,
    //             "currency_code": "EUR"
    //           },
    //           "secondary_cost": {
    //             "amount": 0,
    //             "divisor": 100,
    //             "currency_code": "EUR"
    //           },
    //           "shipping_carrier_id": 0,
    //           "mail_class": null,
    //           "min_delivery_days": 8,
    //           "max_delivery_days": 21
    //         },
    //         {
    //           "shipping_profile_destination_id": 45457047736,
    //           "shipping_profile_id": 219163188450,
    //           "origin_country_iso": "LV",
    //           "destination_country_iso": "",
    //           "destination_region": "eu",
    //           "primary_cost": {
    //             "amount": 0,
    //             "divisor": 100,
    //             "currency_code": "EUR"
    //           },
    //           "secondary_cost": {
    //             "amount": 0,
    //             "divisor": 100,
    //             "currency_code": "EUR"
    //           },
    //           "shipping_carrier_id": 0,
    //           "mail_class": null,
    //           "min_delivery_days": 4,
    //           "max_delivery_days": 10
    //         },
    //         {
    //           "shipping_profile_destination_id": 45457047726,
    //           "shipping_profile_id": 219163188450,
    //           "origin_country_iso": "LV",
    //           "destination_country_iso": "GB",
    //           "destination_region": "none",
    //           "primary_cost": {
    //             "amount": 299,
    //             "divisor": 100,
    //             "currency_code": "EUR"
    //           },
    //           "secondary_cost": {
    //             "amount": 100,
    //             "divisor": 100,
    //             "currency_code": "EUR"
    //           },
    //           "shipping_carrier_id": 0,
    //           "mail_class": null,
    //           "min_delivery_days": 8,
    //           "max_delivery_days": 14
    //         },
    //         {
    //           "shipping_profile_destination_id": 45432135409,
    //           "shipping_profile_id": 219163188450,
    //           "origin_country_iso": "LV",
    //           "destination_country_iso": "LV",
    //           "destination_region": "none",
    //           "primary_cost": {
    //             "amount": 0,
    //             "divisor": 100,
    //             "currency_code": "EUR"
    //           },
    //           "secondary_cost": {
    //             "amount": 0,
    //             "divisor": 100,
    //             "currency_code": "EUR"
    //           },
    //           "shipping_carrier_id": 0,
    //           "mail_class": null,
    //           "min_delivery_days": 1,
    //           "max_delivery_days": 3
    //         },
    //         {
    //           "shipping_profile_destination_id": 45432135429,
    //           "shipping_profile_id": 219163188450,
    //           "origin_country_iso": "LV",
    //           "destination_country_iso": "US",
    //           "destination_region": "none",
    //           "primary_cost": {
    //             "amount": 399,
    //             "divisor": 100,
    //             "currency_code": "EUR"
    //           },
    //           "secondary_cost": {
    //             "amount": 0,
    //             "divisor": 100,
    //             "currency_code": "EUR"
    //           },
    //           "shipping_carrier_id": 0,
    //           "mail_class": null,
    //           "min_delivery_days": 5,
    //           "max_delivery_days": 14
    //         }
    //       ],
    //       "shipping_profile_upgrades": []
    //     }
    //   ],
    //   "message": "Success"
    // };
    // return res.data
  }

  public async createProductListing(payload: IProductCreateSubmitPayload) {
    const res = await this.api.post<IBaseApiResponse<IProductListingItem>>(`/ecommerce/products`, payload);

    return res.data.data;
  }

  public async updateProductListing(
    productId: number,
    listingId: number,
    payload: {
      items?: number[];
      price_map?: Record<string, number>;
      fields?: Record<string, any>;
    }
  ) {
    const res = await this.api.post<IBaseApiResponse<IProductListingItem>>(
      `/ecommerce/products/${productId}/listings/${listingId}`,
      payload
    );

    return res.data.data;
  }

  public async createMockupDesignConnectorStorageItem(
    mockupFolderId: string,
    designIds: number[],
    connections: Record<string, string>
  ) {
    await this.api.post<IBaseApiResponse<IProductMockupDesignConnectorStorageItem>>(
      `/ecommerce/products/mockup-design-connector-storage/create`,
      {
        mockup_folder_id: mockupFolderId,
        selected_design_ids: designIds,
        connections
      }
    );
  }

  public async getMockupDesignConnectorStorageItem(mockupFolderId: string) {
    const res = await this.api.get<IBaseApiResponse<IProductMockupDesignConnectorStorageItem>>(
      `/ecommerce/products/mockup-design-connector-storage/${mockupFolderId}`
    );

    return res.data.data;
  }
}

const instance = new ProductsApiService();

export default instance;
