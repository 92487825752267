import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  IOrderSummary,
  OrderDeliveryData,
  OrderWizardInstanceStatus,
  OrderWizardState,
  OrderWizardStep
} from "../../common/interfaces/order-wizard.interface";
import {
  IDesignItem,
  IProductAssetItem,
  IProductItem,
  IProductMaterial
} from "../../common/interfaces/products.interface";
import { ORDER_ACTION_UPDATE_COUNTRY_LIST } from "./ThunkActions/orderSliceThunkActions";

const ORDER_STARTER = {
  index: 1,
  status: OrderWizardInstanceStatus.DRAFT,
  activeWizardStep: OrderWizardStep.ORDER_PRODUCT_SELECTION,
  selectedProductType: null,
  selectedProductAsset: null,
  selectedDesign: null,
  selectedProductMaterial: null,
  customPrintFile: null,
  premiumBag: false,
  count: 1,
  giftMessage: ""
};

export const orderWizardInitialState: OrderWizardState = {
  selectedIndex: 1,
  orders: [ORDER_STARTER],
  deliveryDataInvalidKeys: [],
  deliveryData: {
    fullName: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    zipCode: "",
    country: "",
    phone: "",
    email: "",
    deliveryOption: "",
    paymentMethod: ""
  },
  // assetList: {
  //   loader: false,
  //   data: []
  // },
  // designList: {
  //   loader: false,
  //   meta: null,
  //   data: []
  // },
  countryList: {
    loader: false,
    data: []
  },
  summaryData: null
};

export const orderWizardSlice = createSlice({
  name: "order",
  initialState: orderWizardInitialState,
  reducers: {
    ORDER_SET_SUMMARY_DATA: (state: OrderWizardState, action: PayloadAction<IOrderSummary | null>) => {
      state.summaryData = action.payload;
    },
    ORDER_INIT_NEW_ORDER: (state: OrderWizardState) => {
      const newIndex = state.orders[state.orders.length - 1].index + 1;
      state.orders.push({
        ...ORDER_STARTER,
        selectedProductType: state.orders[0].selectedProductType,
        index: newIndex
      });
      state.selectedIndex = newIndex;
    },
    ORDER_RESET_WIZARD: (state: OrderWizardState) => {
      state.orders = [ORDER_STARTER];
      state.selectedIndex = 1;
      state.summaryData = orderWizardInitialState.summaryData;
      state.deliveryData = orderWizardInitialState.deliveryData;
      // state.productList = orderWizardInitialState.productList;
      // state.assetList = orderWizardInitialState.assetList;
      // state.designList = orderWizardInitialState.designList;
      state.countryList = orderWizardInitialState.countryList;
    },
    ORDER_SET_WIZARD_ACTIVE_STEP: (state: OrderWizardState, action: PayloadAction<OrderWizardStep>) => {
      const target = state.orders.find((order) => order.index === state.selectedIndex);
      if (!target) return;

      target.activeWizardStep = action.payload;
    },
    ORDER_SET_DELIVERY_VALUE: (
      state: OrderWizardState,
      action: PayloadAction<{ key: keyof OrderDeliveryData; value: string }>
    ) => {
      state.deliveryData[action.payload.key] = action.payload.value;
    },
    ORDER_SET_DELIVERY_DATA: (state: OrderWizardState, action: PayloadAction<OrderDeliveryData>) => {
      state.deliveryData = action.payload;
    },
    ORDER_ADD_INVALID_DELIVERY_KEY: (state: OrderWizardState, action: PayloadAction<keyof OrderDeliveryData>) => {
      if (state.deliveryDataInvalidKeys.includes(action.payload)) return;
      state.deliveryDataInvalidKeys.push(action.payload);
    },
    ORDER_REMOVE_INVALID_DELIVERY_KEY: (state: OrderWizardState, action: PayloadAction<keyof OrderDeliveryData>) => {
      const targetIndex = state.deliveryDataInvalidKeys.findIndex((key) => key === action.payload);
      if (targetIndex === -1) return;

      state.deliveryDataInvalidKeys.splice(targetIndex, 1);
    },
    ORDER_SET_DELIVERY_DATA_VALUE: (
      state: OrderWizardState,
      action: PayloadAction<{
        key: keyof OrderDeliveryData;
        value: unknown;
      }>
    ) => {
      state.deliveryData[action.payload.key] = action.payload.value as any;
    },
    ORDER_SET_SELECTED_INDEX: (state: OrderWizardState, payload: PayloadAction<number>) => {
      state.selectedIndex = payload.payload;
    },
    ORDER_REMOVE_ORDER: (state: OrderWizardState, payload: PayloadAction<number>) => {
      const targetIndex = state.orders.findIndex((order) => order.index === payload.payload);
      if (targetIndex === -1) return;
      state.orders.splice(targetIndex, 1);

      if (state.selectedIndex === payload.payload) {
        state.selectedIndex = state.orders[0].index;
      }

      if (!state.orders.length) {
        state.orders.push(ORDER_STARTER);
        state.selectedIndex = 1;
      }
    },
    ORDER_EDIT_ORDER: (state: OrderWizardState, payload: PayloadAction<number>) => {
      const targetIndex = state.orders.findIndex((order) => order.index === payload.payload);
      if (targetIndex === -1) return;

      const target = state.orders[targetIndex];
      target.activeWizardStep = OrderWizardStep.ORDER_PRODUCT_SELECTION;
      state.selectedIndex = target.index;
    },
    ORDER_SET_SELECTED_PRODUCT_TYPE: (state: OrderWizardState, action: PayloadAction<IProductItem | null>) => {
      const target = state.orders.find((order) => order.index === state.selectedIndex);
      if (!target) return;

      target.selectedProductType = action.payload;
      target.selectedProductAsset = null;
      target.selectedDesign = null;
      // state.designList.data = [];
      // state.assetList.data = [];
    },
    ORDER_SET_COUNT: (
      state: OrderWizardState,
      action: PayloadAction<{
        orderIndex: number;
        count: number;
      }>
    ) => {
      const target = state.orders.find((order) => order.index === action.payload.orderIndex);
      if (!target) return;

      target.count = action.payload.count;
    },
    ORDER_SET_SELECTED_PRODUCT_ASSET: (state: OrderWizardState, action: PayloadAction<IProductAssetItem | null>) => {
      const target = state.orders.find((order) => order.index === state.selectedIndex);
      if (!target) return;

      target.selectedProductAsset = action.payload;
    },
    ORDER_SET_SELECTED_PRODUCT_MATERIAL: (state: OrderWizardState, action: PayloadAction<IProductMaterial | null>) => {
      const target = state.orders.find((order) => order.index === state.selectedIndex);
      if (!target) return;

      target.selectedProductMaterial = action.payload;
    },
    ORDER_SET_STATUS: (state: OrderWizardState, action: PayloadAction<OrderWizardInstanceStatus>) => {
      const target = state.orders.find((order) => order.index === state.selectedIndex);
      if (!target) return;

      target.status = action.payload;

      if (action.payload === OrderWizardInstanceStatus.DRAFT) {
        target.activeWizardStep = OrderWizardStep.ORDER_PRODUCT_SETTINGS;
      }
    },
    ORDER_SET_CUSTOM_PRINT_FILE: (
      state: OrderWizardState,
      action: PayloadAction<{
        image: [string, string];
        title: string;
        description: string;
      } | null>
    ) => {
      const target = state.orders.find((order) => order.index === state.selectedIndex);
      if (!target) return;

      target.customPrintFile = action.payload;
    },
    ORDER_SET_SELECTED_DESIGN: (state: OrderWizardState, action: PayloadAction<IDesignItem | null>) => {
      const target = state.orders.find((order) => order.index === state.selectedIndex);
      if (!target) return;

      target.selectedDesign = action.payload;
    },
    ORDER_SET_PREMIUM_BAG: (state: OrderWizardState, action: PayloadAction<boolean>) => {
      const target = state.orders.find((order) => order.index === state.selectedIndex);
      if (!target) return;

      target.premiumBag = action.payload;
    },
    ORDER_SET_GIFT_MESSAGE: (state: OrderWizardState, action: PayloadAction<string>) => {
      const target = state.orders.find((order) => order.index === state.selectedIndex);
      if (!target) return;

      target.giftMessage = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(ORDER_ACTION_UPDATE_COUNTRY_LIST.pending, (state) => {
        state.countryList.loader = true;
      })
      .addCase(ORDER_ACTION_UPDATE_COUNTRY_LIST.fulfilled, (state, action) => {
        state.countryList.data = action.payload;
        state.countryList.loader = false;
      });
  }
});

export const {
  ORDER_SET_SELECTED_PRODUCT_TYPE,
  ORDER_SET_CUSTOM_PRINT_FILE,
  ORDER_SET_DELIVERY_DATA_VALUE,
  ORDER_EDIT_ORDER,
  ORDER_SET_SELECTED_PRODUCT_ASSET,
  ORDER_SET_SELECTED_DESIGN,
  ORDER_SET_SELECTED_PRODUCT_MATERIAL,
  ORDER_SET_GIFT_MESSAGE,
  ORDER_SET_PREMIUM_BAG,
  ORDER_INIT_NEW_ORDER,
  ORDER_SET_WIZARD_ACTIVE_STEP,
  ORDER_SET_SELECTED_INDEX,
  ORDER_REMOVE_ORDER,
  ORDER_SET_DELIVERY_VALUE,
  ORDER_SET_DELIVERY_DATA,
  ORDER_SET_STATUS,
  ORDER_RESET_WIZARD,
  ORDER_SET_COUNT,
  ORDER_SET_SUMMARY_DATA,
  ORDER_ADD_INVALID_DELIVERY_KEY,
  ORDER_REMOVE_INVALID_DELIVERY_KEY
} = orderWizardSlice.actions;

export const ORDER_GET_INSTANCE = (state: OrderWizardState) =>
  state.orders.find((order) => order.index === state.selectedIndex);

export default orderWizardSlice.reducer;

export * from "./ThunkActions/orderSliceThunkActions";
