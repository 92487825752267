import React, { useState } from "react";

import { toast } from "react-toastify";
import { Button, Modal } from "reactstrap";

import { IUnPaidSalesOrderInvoice } from "../../../common/interfaces/invoices.interface";
import { getErrorMessage } from "../../../common/utils/formatter.util";
import { useServiceContainer } from "../../../hooks/useServiceContainer";
import { CheckboxFieldInput } from "../../common/Input/CheckboxFieldInput";
import { Loader } from "../../common/Loader";

interface Props {
  show: boolean;
  selectedData: IUnPaidSalesOrderInvoice[];
  onSubmit: () => void;
  toggle: () => void;
}

export const MakeInvoicesModal: React.FC<Props> = ({ selectedData, show, toggle, onSubmit }) => {
  const [loader, setLoader] = useState(false);
  const [groupByClient, setGroupByClient] = useState(true);

  const { backofficeService } = useServiceContainer();

  const onSubmitHandler = async () => {
    try {
      if (!selectedData.length) return;
      setLoader(true);
      await backofficeService.makeInvoices(
        selectedData.map((e) => e.id),
        groupByClient
      );

      toggle();

      onSubmit();

      setGroupByClient(false);

      toast.success("Invoices created successfully");
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setLoader(false);
    }
  };

  return (
    <Modal isOpen={show} toggle={toggle} centered size="md">
      {loader && <Loader />}
      <div className="modal-header">
        <h5 className="modal-title mt-0">Make invoices</h5>
        <button
          type="button"
          onClick={() => {
            toggle();
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <div className="row">
          <div className="col-12">
            <h5>Make invoice for:</h5>
            <ul>
              {selectedData.map((e, idx) => (
                <li key={idx}>
                  #{e.id} (
                  {e.customer.company_title ? e.customer.company_title : `${e.customer.name} ${e.customer.surname}`})
                </li>
              ))}
            </ul>
            <CheckboxFieldInput
              checked={groupByClient}
              onChange={(e) => {
                setGroupByClient(e.target.checked);
              }}
              label="Group by client"
              name="group_by_client"
            />
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <Button
          color="danger"
          onClick={() => {
            toggle();
          }}
        >
          Cancel
        </Button>
        <Button color="primary" onClick={onSubmitHandler}>
          Save
        </Button>
      </div>
    </Modal>
  );
};
