import React, { useMemo, useState } from "react";

interface BaseItem {
  id: any;
  [key: string]: any;
}

export const useGenericTableSelectionControl = <T extends BaseItem>(items: T[]) => {
  const [selectedData, setSelectedData] = useState<T[]>([]);

  const tableSelectedRows = useMemo(() => {
    return selectedData.map((item) => item.id);
  }, [selectedData, items]);

  const handleSelect = (selectData: T, isSelected: boolean) => {
    if (isSelected) {
      setSelectedData([...selectedData, selectData]);
    } else {
      setSelectedData(selectedData.filter((item) => item.id !== selectData.id));
    }
  };

  const handleSelectAll = (selected: boolean) => {
    if (selected) {
      setSelectedData([...selectedData, ...items]);
    } else {
      setSelectedData(selectedData.filter((item) => !items.some((e) => e.id === item.id)));
    }
  };

  const resetSelection = () => {
    setSelectedData([]);
  };

  const selectRowData = {
    mode: "checkbox",
    bgColor: "#a3ffa1",
    hideSelectAll: false,
    onSelect: (selectData: T, isSelected: boolean) => {
      handleSelect(selectData, isSelected);
    },
    onSelectAll: (selected: boolean) => {
      handleSelectAll(selected);
    },
    selectionRenderer: ({ mode, ...rest }: any) => {
      return (
        <>
          <div className="form-check">
            <input className="form-check-input" type={mode} onChange={() => null} {...rest} />
          </div>
        </>
      );
    },
    selected: tableSelectedRows
  } as any;

  return {
    selectedData,
    selectRowData,
    resetSelection,
    tableSelectedRows
  };
};
