import React, { forwardRef } from "react";

import classNames from "classnames";
import { Label } from "reactstrap";

interface Props {
  value?: string;
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  name: string;
  label?: string;
  required?: boolean;
  placeholder?: string;
  error?: any;
  [key: string]: any;
  clearAll?: boolean;
  onClearAll?: () => void;
}

export const TextAreaInput: React.FC<Props> = forwardRef<HTMLTextAreaElement, Props>(
  (
    { clearAll, onClearAll, error, value, onChange, label, name, placeholder, required, className, ...rest },
    ref: any
  ) => {
    return (
      <div className={classNames("mb-3", className)}>
        {label ? (
          <Label
            for={name}
            className={classNames("form-label", {
              required
            })}
          >
            {label}
          </Label>
        ) : (
          ""
        )}
        {!!clearAll && (
          <button
            className="listing-group-edit-form__header__clear-all"
            onClick={(e) => {
              e.preventDefault();
              // @ts-ignore
              onClearAll(name);
            }}
          >
            Clear all
          </button>
        )}
        <textarea
          className={classNames("form-control", {
            "is-invalid": !!error
          })}
          value={value}
          onChange={(e) => {
            if (onChange) {
              onChange(e);
            }
          }}
          required={required}
          name={name}
          placeholder={placeholder}
          id={name}
          {...rest}
          ref={ref}
        />
        {error && <div className="invalid-feedback">{error}</div>}
      </div>
    );
  }
);
