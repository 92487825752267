import FileDownload from "js-file-download";
import moment from "moment";

import { IBaseApiResponse, IPaginationMeta } from "../../common/interfaces/api.interface";
import {
  ICustomerItemWithUser,
  IDeliveryService,
  IDeliveryServiceItem,
  IProductBackofficeAssetItem,
  IUserItemWithCustomer
} from "../../common/interfaces/backoffice.interface";
import { ICoursesItem } from "../../common/interfaces/courses.interface";
import { IDownloadItem } from "../../common/interfaces/downloads.interface";
import {
  IIncomingPayment,
  IInvoice,
  IInvoiceClient,
  IUnPaidSalesOrderInvoice
} from "../../common/interfaces/invoices.interface";
import { IMessage } from "../../common/interfaces/messages.interface";
import { IOrderDeliveryDataSubmitted, IPossibleDeliveryService } from "../../common/interfaces/order-wizard.interface";
import { IOrderItem, OrderStatusMap } from "../../common/interfaces/orders.interface";
import {
  ICategoryItem,
  IProductDeliveryService,
  IProductItem,
  IProductListingItem,
  ProductType
} from "../../common/interfaces/products.interface";
import { IWarehouseItem } from "../../common/interfaces/warehouse.interface";
import { assembleFiltersAndSort } from "../../common/utils/filter.util";
import { PRODUCT_LISTINGS_MOCK } from "../../mocks/product-listings.mock";
import BaseAPIService from "./BaseApiService";

class BackofficeApiService extends BaseAPIService {
  constructor() {
    super(`${process.env.REACT_APP_API_BASE_ENDPOINT}/api`);
  }

  public async getProductsCategories(): Promise<ICategoryItem[]> {
    const res = await this.api.get<IBaseApiResponse<[ICategoryItem[]]>>(`/products/categories`);

    return res.data.data[0];
  }

  public async getProductList(): Promise<IProductItem[]> {
    const res = await this.api.get<
      IBaseApiResponse<{
        all: IProductItem[];
        top: IProductItem[] | null;
        categories: ICategoryItem[];
      }>
    >("/products/list?show_all=1");

    return res.data.data.all;
  }

  // @ts-ignore
  public async saveProductListOrder(productList) {
    // @ts-ignore
    const newOrder = productList.map((e,index) => ([e.id,index]));
    console.log(newOrder);
    await this.api.post<IBaseApiResponse<void>>(`/products/list/updateOrder`, {
      productOrder: newOrder
    });
  }

  //@ts-ignore
  public async saveProductAssetListOrder(productList) {
    // @ts-ignore
    const newOrder = productList.map((e,index) => ([e.id,index]));
    console.log(newOrder);
    await this.api.post<IBaseApiResponse<void>>(`/products/assets/updateOrder`, {
      assetOrder: newOrder
    });
  }

  public async getUsersList(
    page: number,
    filters?: Record<string, string>,
    sort?: Record<string, string>
  ): Promise<{
    meta: IPaginationMeta;
    items: IUserItemWithCustomer[];
  }> {
    const queryParams = new URLSearchParams();
    queryParams.append("page", page.toString());
    queryParams.append("perPage", "20");

    const additionalParams = filters || sort ? assembleFiltersAndSort(filters, sort) : {};

    for (const [key, value] of Object.entries(additionalParams)) {
      queryParams.append(key, value);
    }

    const res = await this.api.get<
      IBaseApiResponse<{
        meta: IPaginationMeta;
        items: IUserItemWithCustomer[];
      }>
    >(`/users/all?${queryParams.toString()}`);

    return res.data.data;
  }

  public async getCustomersList<
    T extends boolean,
    K = T extends true ? null : { meta: IPaginationMeta; items: ICustomerItemWithUser[] }
  >(page: number, isExport: T, filters?: Record<string, string>, sort?: Record<string, string>): Promise<K> {
    const queryParams = new URLSearchParams();
    if (!isExport) {
      queryParams.append("page", page.toString());
      queryParams.append("perPage", "20");
    }

    const additionalParams = filters || sort ? assembleFiltersAndSort(filters, sort) : {};

    for (const [key, value] of Object.entries(additionalParams)) {
      queryParams.append(key, value);
    }

    const baseEndpoint = isExport ? `/customers/all/xlsx` : `/customers/all`;

    const res = await this.api.get<
      IBaseApiResponse<{
        meta: IPaginationMeta;
        items: ICustomerItemWithUser[];
      }>
    >(`${baseEndpoint}?${queryParams.toString()}`, {
      ...(isExport && {
        responseType: "blob"
      })
    });

    if (isExport) {
      FileDownload(res.data as any, `customers.xlsx`);
      return null as unknown as K;
    }

    return res.data.data as unknown as K;
  }

  public async getUserById(id: number): Promise<IUserItemWithCustomer> {
    const res = await this.api.get<IBaseApiResponse<IUserItemWithCustomer>>(`/users/${id}/show`);

    return res.data.data;
  }

  public async getCustomerById(id: number): Promise<ICustomerItemWithUser> {
    const res = await this.api.get<IBaseApiResponse<ICustomerItemWithUser>>(`/customers/${id}/show`);

    return res.data.data;
  }

  public async updateCustomerById(id: number, delta: any): Promise<ICustomerItemWithUser> {
    const res = await this.api.post<IBaseApiResponse<ICustomerItemWithUser>>(`/customers/${id}/update`, {
      ...delta
    });

    return res.data.data;
  }

  public async updateUserById(id: number, delta: any): Promise<IUserItemWithCustomer> {
    const res = await this.api.post<IBaseApiResponse<IUserItemWithCustomer>>(`/users/${id}/update`, {
      ...delta
    });

    return res.data.data;
  }

  public async resetUserPasswordById(id: number): Promise<void> {
    const res = await this.api.post(`/users/${id}/reset-password`);

    return res.data.data;
  }

  public async getCategoryById(id: number): Promise<ICategoryItem> {
    const res = await this.api.get<IBaseApiResponse<ICategoryItem>>(`/products/categories/${id}/show`);

    return res.data.data;
  }

  public async updateCategoryById(id: number, delta: any): Promise<ICategoryItem> {
    const res = await this.api.post<IBaseApiResponse<ICategoryItem>>(`/products/categories/${id}/update`, {
      ...delta
    });

    return res.data.data;
  }

  public async getProductById(id: number): Promise<IProductItem> {
    const res = await this.api.get<IBaseApiResponse<IProductItem>>(`/products/${id}/show`);

    return {
      ...res.data.data
    };
  }

  public async duplicateProductById(id: number): Promise<void> {
    await this.api.get<IBaseApiResponse<IProductItem>>(`/products/${id}/copy`);
  }

  public async updateProductById(id: number, delta: any): Promise<IProductItem> {
    const formData = new FormData();

    Object.keys(delta).forEach((key) => {
      formData.append(key, delta[key]);
    });

    const res = await this.api.postForm<IBaseApiResponse<IProductItem>>(`/products/${id}/update`, formData);

    return res.data.data;
  }

  public async deleteProductGalleryImageById(productId: number, imageId: string): Promise<void> {
    await this.api.post<IBaseApiResponse<IProductItem>>(`/products/${productId}/gallery/delete`, {
      gallery_images: [imageId]
    });
  }

  public async addGalleryImagesToProduct(
    productId: number,
    images: File[]
  ): Promise<{
    galleryImages: string[];
  }> {
    const formData = new FormData();
    images.forEach((image) => {
      formData.append("gallery_images[]", image);
    });
    const res = await this.api.postForm<
      IBaseApiResponse<{
        galleryImages: string[];
      }>
    >(`/products/${productId}/gallery/add`, formData);

    return res.data.data;
  }

  public async createCategory(code: string, title: string): Promise<void> {
    await this.api.post(`/products/category/create`, {
      code,
      title
    });
  }

  public async getOrderChangeStatusMap(): Promise<OrderStatusMap> {
    const res = await this.api.get<IBaseApiResponse<OrderStatusMap>>(`/orders/status-map`);

    return res.data.data;
  }

  public async getOrderPossibleDeliveryServices(id: string): Promise<IPossibleDeliveryService[]> {
    const res = await this.api.get<IBaseApiResponse<any>>(`/orders/${id}/possibleDeliveryOptions`);

    return res.data.data;
  }

  public async updateOrdersStatus(newStatus: string, orderIds: string[]): Promise<void> {
    await this.api.post<IBaseApiResponse<OrderStatusMap>>(`/orders/change-status`, {
      status: newStatus,
      order_ids: orderIds
    });
  }

  public async updateOrderDeliveryService(orderId: string, newDeliveryServiceId: number): Promise<void> {
    await this.api.post<IBaseApiResponse<OrderStatusMap>>(`/orders/${orderId}/updateDeliveryOption`, {
      deliveryOption: newDeliveryServiceId
    });
  }

  public async generateDhlLabel(orderIds: string[]): Promise<void> {
    const res = await this.api.post(
      `/orders/print-dhl-label`,
      {
        order_ids: orderIds
      },
      {
        responseType: "blob"
      }
    );

    FileDownload(res.data, `dhl-labels.pdf`);
  }

  public async generateAwbLabel(orderIds: string[]): Promise<void> {
    const res = await this.api.post(
      `/orders/print-awb`,
      {
        order_ids: orderIds
      },
      {
        responseType: "blob"
      }
    );

    FileDownload(res.data, `awb-labels.pdf`);
  }

  public async requestNewTrackingNumbers(orderIds: string[]): Promise<void> {
    await this.api.post(`/orders/request-new-tracking-number`, {
      order_ids: orderIds
    });
  }

  public async getWarehouse(): Promise<IWarehouseItem[]> {
    const res = await this.api.get<IBaseApiResponse<IWarehouseItem[]>>(`/warehouse`);

    return res.data.data;
  }

  public async updateWarehouseItemBalance(id: number, action: "add" | "remove", count: number): Promise<void> {
    await this.api.post<IBaseApiResponse<void>>(`/warehouse/products/${id}`, {
      reason: action,
      balance: count
    });
  }

  public async createProduct(data: {
    title: string;
    default_price: string;
    image_hover: File[];
    image: File[];
    description: string;
    type: ProductType;
    gallery_images: File[];
    category_id: string;
    printfile_width: string;
    printfile_height: string;
    max_designs: string;
  }): Promise<void> {
    const formData = new FormData();
    formData.append("title", data.title);
    formData.append("default_price", data.default_price);
    formData.append("hover_image", data.image_hover[0]);
    formData.append("image", data.image[0]);
    formData.append("description", data.description);
    formData.append("type", data.type);
    formData.append("category_id", data.category_id);
    formData.append("printfile_width", data.printfile_width);
    formData.append("printfile_height", data.printfile_height);
    formData.append("max_designs", data.max_designs);
    data.gallery_images.forEach((file) => {
      formData.append("gallery_images[]", file);
    });

    await this.api.postForm(`/products/create`, formData);
  }

  public async getOrderById(id: string): Promise<IOrderItem> {
    const res = await this.api.get<IBaseApiResponse<IOrderItem>>(`/orders/${id}`);

    return res.data.data;
  }

  public async getProductAssets(productId: number): Promise<IProductBackofficeAssetItem[]> {
    const res = await this.api.get<IBaseApiResponse<IProductBackofficeAssetItem[]>>(`/products/${productId}/assets`);

    return res.data.data;
  }

  public async getProductAssetById(productId: number, assetId: number): Promise<IProductBackofficeAssetItem> {
    const res = await this.api.get<IBaseApiResponse<IProductBackofficeAssetItem>>(
      `/products/${productId}/assets/${assetId}`
    );

    return res.data.data;
  }

  public async createProductAsset(productId: number, data: any): Promise<void> {
    const formData = new FormData();
    formData.append("title", data.title);
    formData.append("balance", data.balance);
    formData.append("image", data.image[0]);
    formData.append("weight", data.weight);
    formData.append("price", data.price);

    await this.api.postForm(`/products/${productId}/assets/create`, formData);
  }

  public async updateProductAssetById(
    productId: number,
    assetId: number,
    delta: any
  ): Promise<IProductBackofficeAssetItem> {
    const formData = new FormData();

    Object.keys(delta).forEach((key) => {
      formData.append(key, delta[key]);
    });

    const res = await this.api.postForm<IBaseApiResponse<IProductBackofficeAssetItem>>(
      `/products/${productId}/assets/${assetId}/update`,
      formData
    );

    return res.data.data;
  }

  public async getUnPaidSalesOrders(
    page: number,
    perPage = 10,
    filters?: Record<string, string>,
    sort?: Record<string, string>
  ): Promise<{
    meta: IPaginationMeta;
    items: IUnPaidSalesOrderInvoice[];
  }> {
    const queryParams = new URLSearchParams();
    queryParams.append("page", page.toString());
    queryParams.append("perPage", perPage.toString());

    const additionalParams = filters || sort ? assembleFiltersAndSort(filters, sort) : {};

    for (const [key, value] of Object.entries(additionalParams)) {
      queryParams.append(key, value);
    }

    const res = await this.api.get<
      IBaseApiResponse<{
        meta: IPaginationMeta;
        items: IUnPaidSalesOrderInvoice[];
      }>
    >(`/invoices/unpaid-sales-orders?${queryParams.toString()}`);

    return res.data.data;
  }

  public async getInvoiceList(
    page: number,
    perPage = 10,
    dates?: Date[],
    filters?: Record<string, string>,
    sort?: Record<string, string>
  ): Promise<{
    meta: IPaginationMeta;
    items: IInvoice[];
  }> {
    const queryParams = new URLSearchParams();
    queryParams.append("page", page.toString());
    queryParams.append("perPage", perPage.toString());

    const additionalParams = filters || sort ? assembleFiltersAndSort(filters, sort, dates) : {};

    for (const [key, value] of Object.entries(additionalParams)) {
      queryParams.append(key, value);
    }

    const res = await this.api.get<
      IBaseApiResponse<{
        meta: IPaginationMeta;
        items: IInvoice[];
      }>
    >(`/invoices?${queryParams.toString()}`);

    return res.data.data;
  }

  public async makeInvoices(salesOrderIds: number[], groupByClient: boolean) {
    await this.api.post<IBaseApiResponse<any>>(`/invoices/make`, {
      salesOrderIds,
      groupByClient
    });
  }

  public async getInvoicePdf(invoiceId: number, invoiceNumber: string): Promise<void> {
    const res = await this.api.get(`/invoices/${invoiceId}/pdf/get`, {
      responseType: "blob"
    });

    FileDownload(res.data, `${invoiceNumber}.pdf`);
  }

  public async getInvoiceClients(search?: string): Promise<IInvoiceClient[]> {
    const searchParams = new URLSearchParams();

    if (search) {
      searchParams.append("search", search);
    }
    const res = await this.api.get(`/invoices/clients?${searchParams.toString()}`);

    return res.data.data;
  }

  public async getIncomingPayments(page: number): Promise<{
    meta: IPaginationMeta;
    items: IIncomingPayment[];
  }> {
    const queryParams = new URLSearchParams();
    queryParams.append("page", page.toString());
    queryParams.append("perPage", "20");

    const res = await this.api.get(`/invoices/incoming-payments?${queryParams.toString()}`);

    return res.data.data;
  }

  public async registerPayment(clientId: number, amount: number, date?: string): Promise<void> {
    await this.api.post(`/invoices/register-payment`, {
      client_id: clientId,
      amount,
      date
    });
  }

  public async makeCreditInvoice(invoiceNumber: string, amount: number): Promise<void> {
    await this.api.post(`/invoices/make-credit-invoice `, {
      invoice_number: invoiceNumber,
      amount
    });
  }

  public async updatePayment(paymentId: number, delta: any): Promise<void> {
    await this.api.post(`/invoices/update-payment/${paymentId}`, {
      ...delta
    });
  }

  public async exportInvoices(dates?: Date[]): Promise<void> {
    const queryPath = [];

    if (dates) {
      const [startDate, endDate] = dates;

      if (startDate) {
        queryPath.push(moment(startDate).format("YYYY-MM-DD"));
      }

      if (endDate) {
        const isSameAsStartDate = moment(startDate).isSame(endDate);
        if (!isSameAsStartDate) {
          queryPath.push(moment(endDate).format("YYYY-MM-DD"));
        }
      }
    }

    const res = await this.api.get(`/invoices/forAccountant/${queryPath.join("/")}`, {
      responseType: "blob"
    });

    FileDownload(res.data, `invoices.xlsx`);
  }

  public async exportSpecificInvoices(invoiceIds: number[]): Promise<void> {
    const res = await this.api.post(
      `/invoices/download-xlsx`,
      {
        invoiceIds
      },
      {
        responseType: "blob"
      }
    );

    FileDownload(res.data, `invoices.xlsx`);
  }

  public async exportSpecificInvoicesToPdf(invoiceIds: number[]): Promise<void> {
    const res = await this.api.post(
      `/invoices/download-pdf`,
      {
        invoiceIds
      },
      {
        responseType: "blob"
      }
    );

    FileDownload(res.data, `invoices.zip`);
  }

  public async updateDeliveryServiceById(id: number, delta: any): Promise<IDeliveryService> {
    const res = await this.api.post<IBaseApiResponse<IDeliveryService>>(`/delivery/${id}/update`, {
      ...delta
    });

    return res.data.data;
  }

  public async getDeliveryServiceById(id: number): Promise<IDeliveryService> {
    const res = await this.api.get<IBaseApiResponse<IDeliveryService>>(`/delivery/${id}/show`);

    return res.data.data;
  }

  public async updateDeliveryServiceItemById(
    deliveryServiceId: number,
    itemId: number,
    delta: any
  ): Promise<IDeliveryServiceItem> {
    const res = await this.api.post<IBaseApiResponse<IDeliveryServiceItem>>(
      `/delivery/${deliveryServiceId}/deliveryData/${itemId}/update`,
      {
        ...delta
      }
    );

    return res.data.data;
  }

  public async deleteDeliveryServiceItemById(deliveryServiceId: number, itemId: number): Promise<void> {
    await this.api.post<IBaseApiResponse<any>>(`/delivery/${deliveryServiceId}/deliveryData/${itemId}/delete`, {});
  }

  public async createDeliveryServiceItem(deliveryServiceId: number, delta: any): Promise<IDeliveryServiceItem> {
    const res = await this.api.post<IBaseApiResponse<IDeliveryServiceItem>>(`/delivery/${deliveryServiceId}/create`, {
      ...delta
    });

    return res.data.data;
  }

  public async getDeliveryServicesList(
    filters?: Record<string, string>,
    sort?: Record<string, string>
  ): Promise<IDeliveryService[]> {
    const queryParams = new URLSearchParams();
    // queryParams.append("page", page.toString());
    // queryParams.append("perPage", "20");

    const additionalParams = filters || sort ? assembleFiltersAndSort(filters, sort) : {};

    for (const [key, value] of Object.entries(additionalParams)) {
      queryParams.append(key, value);
    }

    const res = await this.api.get<IBaseApiResponse<IDeliveryService[]>>(`/delivery?${queryParams.toString()}`);

    return res.data.data;
  }

  public async getProductListings(): Promise<IProductListingItem[]> {
    return PRODUCT_LISTINGS_MOCK;
  }

  public async getDeliveryServiceItems(
    deliveryServiceId: string,
    page: number,
    filters?: Record<string, string>,
    sort?: Record<string, string>
  ): Promise<{
    meta: IPaginationMeta;
    items: IDeliveryServiceItem[];
  }> {
    const queryParams = new URLSearchParams();
    queryParams.append("page", page.toString());
    queryParams.append("perPage", "20");

    const additionalParams = filters || sort ? assembleFiltersAndSort(filters, sort) : {};

    for (const [key, value] of Object.entries(additionalParams)) {
      queryParams.append(key, value);
    }

    const res = await this.api.get<
      IBaseApiResponse<{
        meta: IPaginationMeta;
        items: IDeliveryServiceItem[];
      }>
    >(`/delivery/${deliveryServiceId}/details?${queryParams.toString()}`);

    return res.data.data;
  }

  public async downloadDeliveryServiceExcel(deliveryServiceId: number): Promise<void> {
    const res = await this.api.get(`/delivery/download/${deliveryServiceId}`, {
      responseType: "blob"
    });

    FileDownload(res.data, `${deliveryServiceId}.xlsx`);
  }

  public async uploadDeliveryServiceExcel(file: File): Promise<void> {
    const formData = new FormData();
    formData.append("file", file);
    await this.api.postForm(`/delivery/upload`, formData);
  }

  public async getProductDeliveryServices(productId: number): Promise<IProductDeliveryService[]> {
    const res = await this.api.get<IBaseApiResponse<IProductDeliveryService[]>>(
      `/products/get-delivery-options/${productId}`
    );

    return res.data.data;
  }

  public async addProductDeliveryService(productId: number, deliveryServiceId: number): Promise<void> {
    await this.api.post<IBaseApiResponse<any>>(`/products/product-delivery-options/add`, {
      material_id: productId,
      delivery_service_id: deliveryServiceId
    });
  }

  public async deleteProductDeliveryService(productDeliveryServiceId: number): Promise<void> {
    await this.api.get<IBaseApiResponse<any>>(`/products/product-delivery-options/${productDeliveryServiceId}/delete`);
  }

  public async updateOrderTrackingNumber(orderId: string, trackingNumber: string): Promise<void> {
    await this.api.post<IBaseApiResponse<any>>(`/orders/${orderId}/deliveryData`, {
      tracking_number: trackingNumber
    });
  }

  public async updateOrderDeliveryData(orderId: string, data: Partial<IOrderDeliveryDataSubmitted>): Promise<void> {
    await this.api.post<IBaseApiResponse<any>>(`/orders/${orderId}/deliveryData`, {
      ...data
    });
  }

  public async getIncomingPaymentById(id: string): Promise<IIncomingPayment> {
    const res = await this.api.get<IBaseApiResponse<any>>(`/invoices/incoming-payment/${id}`);
    return res.data.data;
  }

  public async getNewsById(id: number): Promise<IMessage> {
    const res = await this.api.get<IBaseApiResponse<any>>(`/news/${id}/show`);
    return res.data.data;
  }

  public async createNewsItem(data: Pick<IMessage, "title" | "description" | "image" | "text">): Promise<IMessage> {
    const res = await this.api.postForm<IBaseApiResponse<IMessage>>(`/news/create`, data);
    return res.data.data;
  }

  public async updateNewsItem(
    id: number,
    data: Pick<IMessage, "title" | "description" | "image" | "text">
  ): Promise<IMessage> {
    const res = await this.api.postForm<IBaseApiResponse<any>>(`/news/${id}/update`, {
      ...data,
      ...(data.image === null
        ? {
            image: ""
          }
        : {})
    });
    return res.data.data;
  }

  public async deleteNewsItemById(id: number): Promise<void> {
    await this.api.get<IBaseApiResponse<any>>(`/news/${id}/delete`);
  }

  // ~~~~~~ Downloads

  public async deleteDownloadItemById(id: number): Promise<void> {
    await this.api.get<IBaseApiResponse<any>>(`/downloads/${id}/delete`);
  }

  public async getDownloadItemById(id: number): Promise<IDownloadItem> {
    const res = await this.api.get<IBaseApiResponse<IDownloadItem>>(`/downloads/${id}/show`);
    return res.data.data;
  }

  public async createDownloadItem(
    data: Pick<IDownloadItem, "title" | "description" | "image" | "url">
  ): Promise<IDownloadItem> {
    const res = await this.api.postForm<IBaseApiResponse<any>>(`/downloads/create`, data);
    return res.data.data;
  }

  public async updateDownloadItem(
    id: number,
    data: Pick<IDownloadItem, "title" | "description" | "image" | "url">
  ): Promise<IDownloadItem> {
    const res = await this.api.postForm<IBaseApiResponse<IDownloadItem>>(`/downloads/${id}/update`, data);
    return res.data.data;
  }

  // ~~~~~~ Courses

  public async deleteCoursesItemById(id: number): Promise<void> {
    await this.api.get<IBaseApiResponse<any>>(`/courses/${id}/delete`);
  }

  public async getCoursesItemById(id: number): Promise<ICoursesItem> {
    const res = await this.api.get<IBaseApiResponse<ICoursesItem>>(`/courses/${id}/show`);
    return res.data.data;
  }

  public async createCoursesItem(
    data: Pick<ICoursesItem, "title" | "description" | "image" | "url">
  ): Promise<ICoursesItem> {
    const res = await this.api.postForm<IBaseApiResponse<any>>(`/courses/create`, data);
    return res.data.data;
  }

  public async updateCoursesItem(
    id: number,
    data: Pick<ICoursesItem, "title" | "description" | "image" | "url">
  ): Promise<ICoursesItem> {
    const res = await this.api.postForm<IBaseApiResponse<ICoursesItem>>(`/courses/${id}/update`, data);
    return res.data.data;
  }
}

const instance = new BackofficeApiService();

export default instance;
