import React from "react";

import { Link, Outlet } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";

import logoDark from "../../assets/images/logos/logo-dark.png";
import logoLight from "../../assets/images/logos/logo-light.png";
import { AuthHeroBlock } from "./AuthHeroBlock";
import { TawkMessenger } from "./TawkMessenger";

export const EmptyLayout = () => {
  return (
    <div className="account-pages h-100">
      <Container fluid className="h-100">
        <Row className="h-100">
          <Col md={6}>
            <Row className="justify-content-center my-5 pt-sm-5">
              <Col md={7}>
                <div className="text-center mb-5">
                  <Link to="/dashboard" className="auth-logo">
                    <img src={logoDark} alt="" height="28" className="auth-logo-dark" />
                    <img src={logoLight} alt="" height="28" className="auth-logo-light" />
                  </Link>
                </div>
                <Outlet />
              </Col>
            </Row>
          </Col>
          <Col md={6}>
            <AuthHeroBlock />
          </Col>
        </Row>
      </Container>

      <TawkMessenger />
    </div>
  );
};
