const stripe = require("stripe")(
  "sk_test_51KwAtmBV4trkPjtEegHwZDVVxFudMGuymJA66hcSNOAeds5w7XRRnsuvUbPRq6krsj7qthmYsFf7sUAPrK8Vq4a700vxMUOCmE"
);

export const initMockEngine = () => {
  const orderCounter = window.localStorage.getItem("orderCounter");
  if (!orderCounter) {
    window.localStorage.setItem("orderCounter", "923");
  }
};

export const getMockOrderId = () => {
  const orderCounter = window.localStorage.getItem("orderCounter");
  const newOrderCounter = parseInt(orderCounter as string, 10) + 1;
  window.localStorage.setItem("orderCounter", newOrderCounter.toString());

  return newOrderCounter.toString();
};

export const generateStripePaymentUrl = async (price: number, ids: string[]) => {
  const id = ids.join("/");
  const product = await stripe.products.create({
    name: `Order ${id}`
  });

  const item = await stripe.prices.create({
    currency: "eur",
    unit_amount: price * 100,
    product: product.id
  });

  const paymentLink = await stripe.paymentLinks.create({
    line_items: [
      {
        price: item.id,
        quantity: 1
      }
    ]
  });

  return paymentLink.url;
};
