import { PriceIncreaseType, ProductListingPriceMap } from "../interfaces/product-wizard.interface";

export const applyBulkOptionToProductPriceMap = (
  priceMap: ProductListingPriceMap[],
  type: PriceIncreaseType,
  value: number
) => {
  return priceMap.map((item) => {
    item.map = Object.keys(item.map).reduce((acc, key) => {
      if (!item.map[key]) return acc;
      switch (type) {
        case PriceIncreaseType.Percentage:
          acc[key] = (+(item.map[key] as string) * (1 + value / 100)).toFixed(2);
          break;
        case PriceIncreaseType.Fixed:
          acc[key] = value.toFixed(2);
          break;
        default:
          acc[key] = item.map[key] as string;
          break;
      }

      return acc;
    }, {} as any);
    return item;
  });
};
