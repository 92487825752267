import React, { useMemo } from "react";

import classNames from "classnames";
import { Button } from "reactstrap";

interface Props {
  title: string;
  description: string;
  image?: string;
  link: string;
  isNew?: boolean;
  buttonTitle?: any;
  category?: string;
}

export const LinkInfoCard: React.FC<Props> = ({ category, buttonTitle, title, description, image, link, isNew }) => {
  const [fileName, fileExt] = useMemo(() => {
    if (title.includes(".")) {
      const [first, second] = title.split(".");
      return [first, second];
    }

    return [title, ""];
  }, [title]);

  return (
    <div
      className={classNames("link-info-card", {
        "link-info-card--new": isNew
      })}
    >
      {isNew && <div className="link-info-card__new">New</div>}
      {category && <div className="link-info-card__category">{category}</div>}
      <div className="link-info-card__inner">
        <div className="link-info-card__image">
          {image ? (
            <img src={image} alt={title} />
          ) : (
            <div className="link-info-card__image__placeholder">
              <i className="bx bxs-file-blank" />
            </div>
          )}
        </div>
        <div className="link-info-card__title">
          <span className="link-info-card__title__name">{fileName}</span>
          {fileExt && <span className="link-info-card__title__ext">.{fileExt}</span>}
        </div>
        <div className="link-info-card__description">{description}</div>
        <div className="link-info-card__button">
          <Button href={link} target="_blank" color="primary" variant="contained">
            {buttonTitle ?? (
              <>
                <i className="bx bxs-download me-2" /> Download
              </>
            )}
          </Button>
        </div>
      </div>
    </div>
  );
};
