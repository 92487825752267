import React, { useEffect } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Button, CardBody, Modal } from "reactstrap";
import { object } from "yup";
import * as Yup from "yup";

import { useDeliveryServiceExcelUploadModal } from "../../../hooks/deliveryService/useDeliveryServiceExcelUploadModal";
import { Loader } from "../../common/Loader";

const validationSchema = object().shape({
  file: Yup.mixed().required("This field is required")
});

interface Props {
  show: boolean;
  onUploaded: () => void;
  toggle: () => void;
}

export const UploadDeliveryServiceExcelModal: React.FC<Props> = ({ onUploaded, show, toggle }) => {
  const { handleSubmit, reset, setValue } = useForm({
    defaultValues: {
      file: undefined
    } as {
      file: undefined | File;
    },
    resolver: yupResolver(validationSchema)
  });

  const { loader, onSubmitHandler } = useDeliveryServiceExcelUploadModal(() => {
    onUploaded();
    toggle();
  });

  useEffect(() => {
    reset({
      file: undefined
    });
  }, []);

  return (
    <Modal isOpen={show} toggle={toggle} centered size="md">
      {loader && <Loader />}
      <div className="modal-header">
        <h5 className="modal-title mt-0">Upload excel</h5>
        <button
          type="button"
          onClick={() => {
            toggle();
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form onSubmit={handleSubmit(onSubmitHandler)}>
        <>
          <div className="modal-body">
            <div className="row">
              <div className="col-12">
                <div className="card border">
                  <CardBody>
                    <input
                      type="file"
                      accept=".xlsx, .xls"
                      multiple={false}
                      onChange={(e) => {
                        if (!e.target.files?.length) {
                          setValue("file", undefined);
                          return;
                        }

                        setValue("file", e.target.files[0] as File);
                      }}
                    />
                  </CardBody>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <Button
              color="danger"
              onClick={() => {
                toggle();
              }}
            >
              Cancel
            </Button>
            <Button color="primary" type="submit">
              Save
            </Button>
          </div>
        </>
      </form>
    </Modal>
  );
};
