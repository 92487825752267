import React, { useMemo } from "react";

import { use } from "i18next";

import { IProductAssetGroup, IProductAssetItem } from "../../../../common/interfaces/products.interface";
import { CheckboxFieldInput } from "../../Input/CheckboxFieldInput";

interface Props {
  group: IProductAssetGroup;
  selectedItems: IProductAssetItem[];
  onSelect: (items: IProductAssetItem[]) => void;
}

export const ListingGroupEditFormItem: React.FC<Props> = ({ group, selectedItems, onSelect }) => {
  const isAllSelected = useMemo(() => {
    return group.items.every((item) => selectedItems.some((e) => e.id === item.id));
  }, [selectedItems, group.items]);

  return (
    <div className="listing-group-edit-form__item">
      <div className="listing-group-edit-form__item__title">
        <CheckboxFieldInput
          checked={isAllSelected}
          green
          onChange={(e) => {
            if (e.target.checked) {
              onSelect([...group.items]);
            } else {
              onSelect([]);
            }
          }}
          label={`${group.title} models`}
        />
      </div>
      <div className="listing-group-edit-form__item__list">
        {group.items.map((item) => {
          const isSelected = selectedItems.some((e) => e.id === item.id);
          return (
            <div className="listing-group-edit-form__item__list__item" key={item.id}>
              <CheckboxFieldInput
                checked={isSelected}
                green
                onChange={(e) => {
                  if (e.target.checked) {
                    onSelect([...selectedItems, item]);
                  } else {
                    onSelect(selectedItems.filter((assetItem) => assetItem.id !== item.id));
                  }
                }}
                label={item.title}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};
