import React, { useState } from "react";

import { Link } from "react-router-dom";
import { Card, CardBody } from "reactstrap";

import { InvoicesList } from "./InvoicesList";
import { UnPaidSalesOrderList } from "./UnPaidSalesOrderList";

export const InvoicesManagementPage = () => {
  const [invoiceCreatedIndex, setInvoiceCreatedIndex] = useState(0);

  return (
    <>
      <Card className="border">
        <CardBody>
          <Link className="btn btn-primary btn-sm ms-2" color="primary" to="/backoffice/invoices/incoming-payments">
            Incoming payments
          </Link>
        </CardBody>
      </Card>
      <UnPaidSalesOrderList
        onInvoiceCreated={() => {
          setInvoiceCreatedIndex(invoiceCreatedIndex + 1);
        }}
      />
      <InvoicesList invoiceCreationIndex={invoiceCreatedIndex} />
    </>
  );
};
