import React, { useCallback, useEffect, useState } from "react";

import { toast } from "react-toastify";
import { Card, CardBody, Col, Row } from "reactstrap";

import { INotification, NotificationType } from "../../common/interfaces/notification.interface";
import { getErrorMessage } from "../../common/utils/formatter.util";
import { Loader } from "../../components/common/Loader";
import { NotificationElement } from "../../components/notifications/NotificationElement";
import { useServiceContainer } from "../../hooks/useServiceContainer";

export const NotificationsPage = () => {
  const [items, setItems] = useState<INotification[]>([]);
  const [loader, setLoader] = useState(false);
  const { commonService } = useServiceContainer();

  const fetchList = useCallback(async () => {
    setLoader(true);
    try {
      const data = await commonService.getNotifications(NotificationType.Main, true);
      setItems(data);
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setLoader(false);
    }
  }, []);

  useEffect(() => {
    fetchList().then(() => null);
  }, []);

  return (
    <div className="p-3">
      <h2>Notifications</h2>
      {loader && <Loader />}
      <Row>
        <Card>
          <CardBody>
            <Row>
              {items.length ? (
                items.map((e, idx) => (
                  <Col xs={12}>
                    <NotificationElement key={idx} item={e} />
                  </Col>
                ))
              ) : (
                <p className="p-0 mb-0">You don&apos;t have any notifications yet.</p>
              )}
            </Row>
          </CardBody>
        </Card>
      </Row>
    </div>
  );
};
