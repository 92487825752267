import moment from "moment";
import { textFilter } from "react-bootstrap-table2-filter";

import { IUnPaidSalesOrderInvoice, IUnPaidSalesOrderInvoiceDelivery } from "../interfaces/invoices.interface";

export const UNPAID_SALES_ORDER_TABLE_FILTER_INSTANCES: Record<string, any> = {
  id: null,
  date: null,
  clientName: null,
  amount: null,
  delivery: null
};

export const CLEAR_UNPAID_SALES_ORDER_TABLE_FILTER_VALUES = () => {
  for (const key in UNPAID_SALES_ORDER_TABLE_FILTER_INSTANCES) {
    if (
      UNPAID_SALES_ORDER_TABLE_FILTER_INSTANCES[key] &&
      typeof UNPAID_SALES_ORDER_TABLE_FILTER_INSTANCES[key] === "function"
    ) {
      UNPAID_SALES_ORDER_TABLE_FILTER_INSTANCES[key]("");
    }
  }
};

export const UNPAID_SALES_ORDER_TABLE_COLUMNS = [
  {
    dataField: "id",
    text: "Sales order ID",
    filter: textFilter({
      getFilter: (filter: any) => {
        UNPAID_SALES_ORDER_TABLE_FILTER_INSTANCES.id = filter;
      },

      caseSensitive: true,
      placeholder: " ",
      className: "form-control-sm mt-1"
    }),
    sort: true,
    style: {
      minWidth: "90px"
    }
  },
  {
    dataField: "date",
    text: "Date",
    filter: textFilter({
      getFilter: (filter: any) => {
        UNPAID_SALES_ORDER_TABLE_FILTER_INSTANCES.date = filter;
      },
      caseSensitive: true,
      placeholder: " ",
      className: "form-control-sm mt-1"
    }),
    sort: true,
    style: {
      minWidth: "90px"
    },
    formatter: (cellContent: string) => {
      return moment(cellContent).format("DD.MM.YYYY");
    }
  },
  {
    dataField: "clientName",
    isDummyField: true,
    text: "Client name",
    filter: textFilter({
      getFilter: (filter: any) => {
        UNPAID_SALES_ORDER_TABLE_FILTER_INSTANCES.clientName = filter;
      },
      caseSensitive: true,
      placeholder: " ",
      className: "form-control-sm mt-1"
    }),
    sort: true,
    style: {
      minWidth: "90px"
    },
    formatter: (cellContent: any, row: IUnPaidSalesOrderInvoice) => {
      if (row.customer.company_title) {
        return row.customer.company_title;
      }

      return `${row.customer.name} ${row.customer.surname}`;
    }
  },
  {
    dataField: "amount",
    text: "Amount",
    filter: textFilter({
      getFilter: (filter: any) => {
        UNPAID_SALES_ORDER_TABLE_FILTER_INSTANCES.amount = filter;
      },
      caseSensitive: true,
      placeholder: " ",
      className: "form-control-sm mt-1"
    }),
    sort: true,
    style: {
      minWidth: "90px"
    },
    formatter: (cellContent: string) => {
      return `€${cellContent}`;
    }
  },
  {
    dataField: "delivery",
    text: "Delivery amount",
    filter: textFilter({
      getFilter: (filter: any) => {
        UNPAID_SALES_ORDER_TABLE_FILTER_INSTANCES.delivery = filter;
      },
      caseSensitive: true,
      placeholder: " ",
      className: "form-control-sm mt-1"
    }),
    sort: true,
    style: {
      minWidth: "90px"
    },
    formatter: (cellContent: IUnPaidSalesOrderInvoiceDelivery) => {
      return `€${cellContent.price}`;
    }
  }
];
