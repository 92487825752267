import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import localization from "../../common/i18n/config";
import { INotification } from "../../common/interfaces/notification.interface";
import { ICartOrder } from "../../common/interfaces/orders.interface";
import { changeBodyAttribute, manageBodyClass } from "../../common/utils/dom.util";
import CookiesService from "../../services/CookiesService";
import type { RootState } from "../store";
import {
  APP_ACTION_ADD_ORDER_TO_CART,
  APP_ACTION_GET_NOTIFICATIONS,
  APP_ACTION_GET_ORDER_CART
} from "./ThunkActions/appSliceThunkActions";

interface AppState {
  version: string;
  language: string;
  sidebarType: string;
  showMockupGeneratorModal: boolean;
  introductionSteps: IntroductionSteps;
  orderCart: {
    loader: boolean;
    data: ICartOrder[];
  };
  notifications: {
    loader: boolean;
    data: INotification[];
  };
}

export interface IntroductionSteps {
  layout: boolean;
}

export const appInitialState: AppState = {
  version: "1",
  sidebarType: "default",
  language: CookiesService.get("lng") || localization.language || "en",
  showMockupGeneratorModal: false,
  introductionSteps: {
    layout: false
  },
  orderCart: {
    loader: false,
    data: []
  },
  notifications: {
    loader: false,
    data: []
  }
};

export const appSlice = createSlice({
  name: "app",
  initialState: appInitialState,
  reducers: {
    APP_SET_SELECTED_LANGUAGE: (state: AppState, action: PayloadAction<string>) => {
      state.language = action.payload;
    },
    APP_SET_SHOW_MOCKUP_GENERATOR_MODAL: (state: AppState, action: PayloadAction<boolean>) => {
      state.showMockupGeneratorModal = action.payload;
    },
    APP_SET_SIDEBAR_TYPE: (state: AppState, action: PayloadAction<string>) => {
      state.sidebarType = action.payload;
      switch (action.payload) {
        case "compact":
          manageBodyClass("sidebar-enable", "remove");
          changeBodyAttribute("data-sidebar-size", "md");
          break;
        case "small":
          manageBodyClass("sidebar-enable", "remove");
          changeBodyAttribute("data-sidebar-size", "sm");
          break;
        default:
          changeBodyAttribute("data-sidebar-size", "lg");
          break;
      }
    },

    APP_ADD_ORDERS_TO_CART: (state: AppState, action: PayloadAction<ICartOrder[]>) => {
      state.orderCart.data.push(...action.payload);
    },
    APP_SET_INTRODUCTION_STEP_STATE: (
      state: AppState,
      action: PayloadAction<{
        key: keyof IntroductionSteps;
        value: boolean;
      }>
    ) => {
      state.introductionSteps[action.payload.key] = action.payload.value;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(APP_ACTION_ADD_ORDER_TO_CART.pending, (state) => {
        state.orderCart.loader = true;
      })
      .addCase(APP_ACTION_ADD_ORDER_TO_CART.fulfilled, (state, action) => {
        state.orderCart.data.push(...action.payload);
        state.orderCart.loader = false;
      })
      .addCase(APP_ACTION_GET_ORDER_CART.pending, (state) => {
        state.orderCart.loader = true;
      })
      .addCase(APP_ACTION_GET_ORDER_CART.fulfilled, (state, action) => {
        state.orderCart.data = action.payload;
        state.orderCart.loader = false;
      })
      .addCase(APP_ACTION_GET_NOTIFICATIONS.pending, (state) => {
        state.notifications.loader = true;
      })
      .addCase(APP_ACTION_GET_NOTIFICATIONS.fulfilled, (state, action) => {
        state.notifications.data = action.payload;
        state.notifications.loader = false;
      });
  }
});

export const {
  APP_SET_SELECTED_LANGUAGE,
  APP_SET_SIDEBAR_TYPE,
  APP_ADD_ORDERS_TO_CART,
  APP_SET_SHOW_MOCKUP_GENERATOR_MODAL,
  APP_SET_INTRODUCTION_STEP_STATE
} = appSlice.actions;
//
export const selectSelectedLanguage = (state: RootState) => state.app.language;
export const selectSidebarType = (state: RootState) => state.app.sidebarType;

export default appSlice.reducer;

export * from "./ThunkActions/appSliceThunkActions";
