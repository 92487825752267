import { useEffect, useState } from "react";

import { toast } from "react-toastify";

import { IProductDeliveryService } from "../common/interfaces/products.interface";
import { getErrorMessage } from "../common/utils/formatter.util";
import useDidMountEffect from "./useDidMountEffect";
import { useServiceContainer } from "./useServiceContainer";

export const useProductDeliveryOptions = (productId: number) => {
  const [loader, setLoader] = useState(false);
  const { backofficeService } = useServiceContainer();

  const [items, setItems] = useState<IProductDeliveryService[]>([]);

  const fetchItems = async (_productId: number) => {
    setLoader(true);
    try {
      const res = await backofficeService.getProductDeliveryServices(_productId);
      setItems(res);
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setLoader(false);
    }
  };

  const handleAddServiceItem = async (serviceId: number) => {
    setLoader(true);
    try {
      await backofficeService.addProductDeliveryService(productId, serviceId);

      await fetchItems(productId);
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setLoader(false);
    }
  };

  const handleRemoveServiceItem = async (productDeliveryServiceId: number) => {
    setLoader(true);
    try {
      await backofficeService.deleteProductDeliveryService(productDeliveryServiceId);

      await fetchItems(productId);
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchItems(productId).then(() => null);
  }, [productId]);

  return {
    loader,
    items,
    handleAddServiceItem,
    handleRemoveServiceItem
  };
};
