import {
  IProductListingItemFields,
  IProductListingItemKeyWords,
  ProductWizardState
} from "../../../../common/interfaces/product-wizard.interface";
import {
  IProductCreateSubmitPayload,
  IProductCreateSubmitPayloadListingEntity,
  IProductCreateSubmitPayloadPhotoEntity
} from "../../../../common/interfaces/products.interface";

export const replaceKeywords = (str: string, keywords: IProductListingItemKeyWords): string => {
  let result = str;
  for (const [key, value] of Object.entries(keywords) ?? {}) {
    const regex = new RegExp(`{${key}}`, "ig");
    result = result.replaceAll(regex, value);
  }

  return result;
};

export const buildSubmitBody = (state: ProductWizardState): IProductCreateSubmitPayload => {
  const buildListings = (): IProductCreateSubmitPayloadListingEntity[] => {
    return state.selectedListings.map((listing) => {
      const priceMap = state.pricingData.priceMap.find((target) => target.listingId === listing.id);

      const fields = listing.fields as IProductListingItemFields;

      return {
        id: listing.id,
        items: listing.groups.map((e) => e.items.map((asset) => asset.id)).flat(),
        price_map: Object.entries(priceMap!.map).reduce((acc, [key, value]) => {
          return {
            ...acc,
            [key]: value === null ? null : +value
          };
        }, {}),
        fields: {
          title: replaceKeywords(fields.title, fields.keywords),
          description: replaceKeywords(fields.description, fields.keywords),
          tags: fields.tags.map((tag) => replaceKeywords(tag, fields.keywords)),
          materials: fields.materials.map((material) => replaceKeywords(material, fields.keywords)),
          section: +fields.section,
          shipping_profile_id: +fields.shipping_profile
        }
      };
    });
  };

  const buildPhotos = (): IProductCreateSubmitPayloadPhotoEntity[] => {
    const photos: IProductCreateSubmitPayloadPhotoEntity[] = [];

    for (const mockupFile of state.selectedMockupEntity!.files) {
      const designMockupConnectorTarget = state.designMockupConnector.find(
        (e) => e.mockupId?.toString() === mockupFile.id.toString()
      );

      photos.push({
        design_id: (designMockupConnectorTarget?.designId as number) ?? null,
        mockup_file_id: +mockupFile.id,
        order: mockupFile.order
      } as IProductCreateSubmitPayloadPhotoEntity);
    }

    return photos;
  };

  return {
    product_id: state.selectedProductType!.id,
    shop_id: state.selectedShopItem!.id,
    listings: buildListings(),
    photos: buildPhotos()
  };
};
