export const getObjectDelta = (origin: any, modified: any) => {
  return Object.fromEntries(Object.entries(modified).filter(([key, val]) => val !== origin[key as any]));
};

export const getObjectDeltaWithObjects = (origin: any, modified: any) => {
  return Object.fromEntries(
    Object.entries(modified).filter(([key, val]) => {
      // handle object and arrays
      if (typeof val === "object" && val !== null) {
        return JSON.stringify(val) !== JSON.stringify(origin[key]);
      }

      return val !== origin[key];
    })
  );
};

export const getObjectEntriesDelta = (origin: [string, any][], modified: [string, any][]) => {
  return modified.filter(
    ([key, val]) => val !== origin.find(([originKey, originVal]) => originKey === key && originVal === val)
  );
};
