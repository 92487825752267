import React, { useEffect } from "react";

import { useFormContext } from "react-hook-form";
import { Card, CardBody } from "reactstrap";

import { IDesignItem } from "../../../../common/interfaces/products.interface";
import { useAppDispatch, useAppSelector } from "../../../../store/store";
import { DESIGNS_TOGGLE_ITEM } from "../../../../store/wizards/productDesignsSlice";
import { ASSETS_ACTION_UPDATE_LIST } from "../../../../store/wizards/ThunkActions/assetsSliceThunkActions";
import { DesignSelect } from "../../../common/DesignSelect/DesignSelect";

export const MockupDesignSelect = () => {
  const {
    register,
    setValue,
    setError,
    formState: { errors }
  } = useFormContext();

  const dispatch = useAppDispatch();

  const selectedAssetImage = useAppSelector((state) => {
    return state.productAssets.selectedAsset?.image;
  });

  const selectedAssetType = useAppSelector((state) => {
    return state.productAssets.selectedAsset?.type;
  });

  const selectedDesigns = useAppSelector((state) => {
    return state.productDesigns.selectedDesigns;
  });

  const selectedProduct = useAppSelector((state) => {
    return state.productTypes.selectedProduct;
  });

  useEffect(() => {
    register("selectedDesigns");
  }, []);

  useEffect(() => {
    if (!selectedDesigns) return;
    setValue(
      "selectedDesigns",
      selectedDesigns.map((item) => item.id)
    );
  }, [selectedDesigns]);

  useEffect(() => {
    if (selectedProduct) {
      dispatch(
        ASSETS_ACTION_UPDATE_LIST({
          selectedProduct
        })
      );
    }
  }, [selectedProduct]);

  return (
    <Card>
      <CardBody>
        <p className="wizard-step__description">
          Please select a design for your mockup. You can upload a new file or choose from your existing designs.
        </p>

        <DesignSelect
          scrollableContainerGap={100}
          selectedAssetImage={selectedAssetImage}
          selectedAssetType={selectedAssetType}
          selectedDesigns={selectedDesigns}
          selectedProduct={selectedProduct}
          onDesignUpload={(item) => {
            dispatch(DESIGNS_TOGGLE_ITEM(item));
            setError("selectedDesigns", {
              type: "manual",
              message: ""
            });
          }}
          onItemSelect={(item) => {
            dispatch(DESIGNS_TOGGLE_ITEM(item as IDesignItem));
            setError("selectedDesigns", {
              type: "manual",
              message: ""
            });
          }}
          wizardError={errors.selectedDesigns?.message}
          multiSelect
        />
      </CardBody>
    </Card>
  );
};
