import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { map } from "lodash";
import moment from "moment";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import { toast } from "react-toastify";
import Linkify from "react-linkify";
import { Button, Card, CardBody, Col, Row } from "reactstrap";

import { IOrderComment } from "../../../common/interfaces/order-comments.interface";
import { UserRole } from "../../../common/interfaces/user.interface";
import { showConfirmationModal } from "../../../common/utils/confirm.util";
import { getAttachmentName, getErrorMessage, getImageUrl } from "../../../common/utils/formatter.util";
import useAuth from "../../../hooks/useAuth";
import { useRole } from "../../../hooks/useRole";
import { useServiceContainer } from "../../../hooks/useServiceContainer";
import { Loader } from "../Loader";

interface Props {
  orderId: string;
  userId: number;
}

interface CommentInputProps {
  onSubmit: (text: string, attachments?: File[]) => Promise<boolean>;
}

export const CommentInput: React.FC<CommentInputProps> = ({ onSubmit }) => {
  const [inputValue, setInputValue] = useState("");
  const [attachments, setAttachments] = useState<File[]>([]);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleSubmit = async () => {
    const res = await onSubmit(inputValue, attachments);
    if (!res) return;
    setInputValue("");
    setAttachments([]);
  };

  const onKeyPress = (e: any) => {
    const { key } = e;
    if (key === "Enter") {
      handleSubmit().then(() => null);
    }
  };

  return (
    <>
      <Row>
        <Col>
          <div className="position-relative">
            <textarea
              value={inputValue}
              style={{
                minHeight: "60px"
              }}
              onKeyPress={onKeyPress}
              onChange={(e) => setInputValue(e.target.value)}
              className="form-control chat-input rounded"
              placeholder="Enter Message..."
            />
          </div>
        </Col>
        <Col className="col-auto">
          <input
            ref={fileInputRef}
            type="file"
            style={{ display: "none" }}
            multiple
            onChange={(e) => {
              if (e.target.files) {
                setAttachments([...attachments, ...Array.from(e.target.files)]);
              }
            }}
          />
          <Button
            color="link"
            className="p-0 me-3"
            onClick={() => {
              fileInputRef.current!.value = "";
              fileInputRef.current?.click();
            }}
          >
            <i
              className="bx bx-link-alt"
              style={{
                fontSize: "20px"
              }}
            />
          </Button>
          <Button
            type="button"
            color="primary"
            onClick={handleSubmit}
            className="btn btn-primary chat-send w-md waves-effect waves-light"
          >
            <span className="d-none d-sm-inline-block me-2">Send</span> <i className="mdi mdi-send float-end" />
          </Button>
        </Col>
      </Row>
      <Row>
        <Col className="col-auto">
          {attachments.length > 0 && (
            <div className="chat-attachments">
              {attachments.map((attachment, idx: number) => (
                <div className="chat-attachment-wrapper">
                  <a
                    key={idx}
                    href={URL.createObjectURL(attachment)}
                    target="_blank"
                    className="chat-attachment"
                    rel="noreferrer"
                  >
                    <img src={URL.createObjectURL(attachment)} alt="" />
                    <span>{attachment.name}</span>
                  </a>
                  <button
                    className="chat-attachment__delete"
                    onClick={(e) => {
                      e.stopPropagation();
                      setAttachments(attachments.filter((_, i) => i !== idx));
                    }}
                  >
                    <i className="mdi mdi-delete" />
                  </button>
                </div>
              ))}
            </div>
          )}
        </Col>
      </Row>
    </>
  );
};

export const OrderComment: React.FC<Props> = ({ orderId, userId }) => {
  const { user } = useAuth();
  const { hasRole } = useRole();
  const [loader, setLoader] = useState(false);
  const [messageBox, setMessageBox] = useState<any>(null);
  const [scrolledToBottom, setScrolledToBottom] = useState(false);

  const [messages, setMessages] = useState<IOrderComment[]>([]);

  const { ordersService } = useServiceContainer();

  const isUserComment = useMemo(() => {
    return user!.id === userId;
  }, [user, userId]);

  const fetch = async () => {
    try {
      const res = await ordersService.getOrderComments(orderId);
      setMessages(res);
    } catch (e) {
      toast.error(getErrorMessage(e));
    }
  };

  const handleCommentSubmit = async (text: string, attachments?: File[]) => {
    try {
      if (!text) {
        toast.error("Please enter message text!");
        return false;
      }

      const unReadCommentIds = messages
        .filter((message) => {
          const isMyComment = isUserComment ? message.user_id === userId : message.user_id !== userId;

          return !isMyComment && message.is_read === 0;
        })
        .map((message) => message.id);

      setLoader(true);
      await ordersService.addCommentToOrder(orderId, text, attachments);

      if (unReadCommentIds.length) {
        for (const commentId of unReadCommentIds) {
          // eslint-disable-next-line no-await-in-loop
          await markCommentAsRead(commentId, true).catch(() => null);
        }
      }

      await fetch();

      setTimeout(() => {
        scrollToBottom();
      }, 100);

      return true;
    } catch (e) {
      toast.error(getErrorMessage(e));
      return false;
    } finally {
      setLoader(false);
    }
  };

  const handleCommentDelete = async (commentId: number) => {
    try {
      setLoader(true);
      await ordersService.deleteCommentById(commentId);
      await fetch();
      setTimeout(() => {
        scrollToBottom();
      }, 100);

      toast.success("Comment deleted successfully!");
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setLoader(false);
    }
  };

  const markCommentAsRead = async (id: number, withoutLoader = false) => {
    try {
      if (!withoutLoader) {
        setLoader(true);
      }
      await ordersService.markCommentAsRead(orderId, id);

      if (!withoutLoader) {
        setMessages(
          messages.map((message) => {
            if (message.id === id) {
              message.is_read = 1;
            }
            return message;
          })
        );
      }
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      if (!withoutLoader) {
        setLoader(false);
      }
    }
  };

  const scrollToBottom = useCallback(() => {
    if (messageBox) {
      messageBox.scrollTop = messageBox.scrollHeight + 1000;
    }
  }, [messageBox]);

  useEffect(() => {
    fetch().then(() => null);

    const interval = setInterval(() => {
      fetch().then(() => null);
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (!scrolledToBottom && messages.length) {
      scrollToBottom();
      setScrolledToBottom(true);
    }
  }, [messages]);

  return (
    <Card>
      <CardBody>
        {loader && <Loader />}
        <h3>Chat</h3>
        <div className="chat-conversation p-3">
          <ul className="list-unstyled mb-0">
            <PerfectScrollbar style={{ height: "470px" }} containerRef={(ref) => setMessageBox(ref)}>
              {messages &&
                map(messages, (message) => {
                  const isMyComment = isUserComment ? message.user_id === userId : message.user_id !== userId;
                  return (
                    <li key={message.id} className={isMyComment ? "right" : ""}>
                      <div className="conversation-list d-flex flex-column">
                        <div className="conversation-list__user">
                          {message.userName}
                          {message.is_read === 0 && !isMyComment && (
                            <button
                              className="ctext-wrap__mark-read"
                              onClick={() => {
                                markCommentAsRead(message.id).then(() => null);
                              }}
                            >
                              <i className="bx bx-check-double" /> Mark as read
                            </button>
                          )}
                        </div>
                        <div className="ctext-wrap">
                          <div className="ctext-wrap-content">
                            {hasRole(UserRole.Admin) && (
                              <button
                                className="ctext-wrap-content__delete"
                                type="button"
                                onClick={() => {
                                  showConfirmationModal({
                                    title: "Are you sure?",
                                    message: "Do you really want to delete this message?",
                                    onConfirm: async () => {
                                      await handleCommentDelete(message.id);
                                    },
                                    onCancel: () => {}
                                  });
                                }}
                              >
                                <i className="bx bxs-trash-alt" />
                              </button>
                            )}
                            <p className="mb-0">
                              <Linkify
                                componentDecorator={(decoratedHref, decoratedText, key) => (
                                  <a target="blank" href={decoratedHref} key={key}>
                                    {decoratedText}
                                  </a>
                                )}
                              >
                                {message.text}
                              </Linkify>
                            </p>
                            <span className="d-inline-block font-size-12 text-muted ctext-wrap-content__timestamp">
                              {moment(message.datetime).format("DD-MM-YY HH:mm")}

                              {message.is_read === 1 && <i className="bx bx-check-double ms-2" />}
                            </span>
                            {message.attachments && message.attachments.length ? (
                              <div className="chat-attachments">
                                {message.attachments.map(([attachment], idx: number) => (
                                  <div className="chat-attachment-wrapper" key={idx}>
                                    <a
                                      href={getImageUrl(attachment)}
                                      target="_blank"
                                      className="chat-attachment"
                                      rel="noreferrer"
                                    >
                                      <img src={getImageUrl(attachment)} alt="" />
                                      <span>{getAttachmentName(attachment)}</span>
                                    </a>
                                  </div>
                                ))}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    </li>
                  );
                })}
            </PerfectScrollbar>
          </ul>
        </div>
        <div className="p-3 chat-input-section">
          <CommentInput onSubmit={handleCommentSubmit} />
        </div>
      </CardBody>
    </Card>
  );
};
