import React, { useState } from "react";

import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, CardBody, Modal } from "reactstrap";

import { getErrorMessage } from "../../../common/utils/formatter.util";
import useAuth from "../../../hooks/useAuth";
import { useCheckout } from "../../../hooks/useCheckout";
import { useAppSelector } from "../../../store/store";
import { Loader } from "../../common/Loader";

interface Props {
  show: boolean;
  toggle: () => void;
}

export const CheckoutTypeModal: React.FC<Props> = ({ show, toggle }) => {
  const [loader, setLoader] = useState(false);
  const { executeCheckoutRequest } = useCheckout();
  const checkoutState = useAppSelector((state) => state.checkout);
  const navigate = useNavigate();

  const handleSubmit = async (method: string) => {
    try {
      setLoader(true);
      const res = await executeCheckoutRequest(checkoutState.orderIds, method);
      if (!res) return;

      // TODO: MESSAGE
      toast.success("Checkout successful");
      navigate("/orders/list");
      setLoader(false);
      toggle();
    } catch (e) {
      toast.error(getErrorMessage(e));
      setLoader(false);
    }
  };

  return (
    <Modal isOpen={show} toggle={toggle} centered size="md">
      <div className="modal-header">
        <h5 className="modal-title mt-0">Select checkout type</h5>
        <button
          type="button"
          onClick={() => {
            toggle();
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body pb-5">
        {loader && <Loader />}
        <div className="index-cards">
          <div className="index-cards__inner">
            {checkoutState.paymentMethods.includes("bill") && (
              <div className="card">
                <CardBody>
                  <h5 className="font-size-16 mt-0">Bill</h5>
                  <p className="card-text">
                    TODO: Lorem ipsum dolor sit amet, consectetur adipisicing elit. Distinctio, nostrum.
                  </p>
                </CardBody>
                <div className="card-button">
                  <Button
                    onClick={() => {
                      handleSubmit("bill");
                    }}
                    className="btn btn-info waves-effect waves-light"
                  >
                    Create Bill
                  </Button>
                </div>
              </div>
            )}
            {checkoutState.paymentMethods.includes("stripe") && (
              <div className="card">
                <CardBody>
                  <h5 className="font-size-16 mt-0">Pay now</h5>
                  <p className="card-text">Pay now with your credit card</p>
                </CardBody>
                <div className="card-button">
                  <Button
                    onClick={() => {
                      handleSubmit("stripe");
                    }}
                    className="btn btn-info waves-effect waves-light"
                  >
                    Pay now
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};
