import React, { useEffect, useMemo, useState } from "react";

import classNames from "classnames";
import Dropzone from "react-dropzone";
import { toast } from "react-toastify";
import { Label } from "reactstrap";

import { ExtendedFile } from "../../../common/interfaces/vendor.interface";
import { extendFile } from "../../../common/utils/file.util";
import { Alert } from "../../common/Alert";
import { UploadedFileItem } from "../../common/uploads/UploadedFileItem";

interface Props {
  onSubmit?: (files: ExtendedFile[]) => void;
  onUpload?: (files: ExtendedFile[]) => void;
  hideSubmitButton?: boolean;
  hideAlert?: boolean;
  limitations?: number[];
  multiple?: boolean;
  containerSize?: number;
  description?: any;
  title?: any;
  small?: boolean;
  uploadedFiles?: ExtendedFile[];
  readonly?: boolean;
  alwaysShowTitle?: boolean;
  canUploadMore?: boolean;
  error?: any;
}

export const FileUploadStep: React.FC<Props> = ({
  hideSubmitButton,
  description,
  onSubmit,
  containerSize,
  limitations,
  onUpload,
  hideAlert,
  title,
  small,
  uploadedFiles,
  readonly,
  alwaysShowTitle,
  multiple,
  error,
  canUploadMore
}) => {
  const [files, setFiles] = useState<any>(uploadedFiles ?? []);
  // const [errorAlert, setErrorAlert] = useState("");

  const maxFiles = useMemo(() => {
    if (multiple) return undefined;
    if (!limitations) return 1;

    // get max number from limitations
    return Math.max(...limitations);
  }, [limitations, multiple]);

  const handleFileChange = (_uploadedFiles: File[]) => {
    if (!_uploadedFiles.length) {
      return;
    }
    // console.log("_uploadedFiles.length => ", _uploadedFiles.length);
    // console.log("files.length => ", files.length);
    // @ts-ignore
    if (limitations) {
      const max = Math.max(...limitations) ?? false;
      // console.log("max => ", max);
      if (max && files.length && _uploadedFiles.length) {
        if (max && files.length + _uploadedFiles.length === max) {
          // eslint-disable-next-line no-param-reassign
          canUploadMore = false;
        }

        if (max && files.length + _uploadedFiles.length > max) {
          toast.error(`Max allowed files ${max} exceeded`);
          return;
        }
      }
    }
    for (const uploadedFile of _uploadedFiles) {
      extendFile(uploadedFile);
    }

    const newFiles = canUploadMore ? [...files, ...(_uploadedFiles ?? [])] : _uploadedFiles;

    setFiles(newFiles);
    if (onUpload) {
      onUpload(newFiles as ExtendedFile[]);
    }
  };

  const handleFileDelete = (file: ExtendedFile) => {
    console.log(file);
    const newFiles = files.filter((f: any) => f.preview !== file.preview);
    setFiles(newFiles);
    if (onUpload) {
      onUpload(newFiles);
    }
  };

  const showDropzone = useMemo(() => {
    if (!canUploadMore) {
      return !files.length && !readonly;
    }

    return !readonly;
  }, [files, canUploadMore, readonly]);

  useEffect(() => {
    setFiles(uploadedFiles ?? []);
  }, [uploadedFiles]);

  // @ts-ignore
  return (
    <div
      className={classNames(
        "file-upload",
        {
          "file-upload--small": small,
          "is-invalid": !!error
        },
        "mx-auto",
        `col-${containerSize ?? 4}`
      )}
    >
      {!hideAlert && description && <Alert variant="info">{description}</Alert>}
      {/* {errorAlert && <Alert variant="danger">{errorAlert}</Alert>} */}
      <div className="mb-0 mb-md-3">
        {alwaysShowTitle ? (
          <Label for="printFile" className="form-label">
            {title ?? "Select print file"}
          </Label>
        ) : (
          !files.length && (
            <Label for="printFile" className="form-label">
              {title ?? "Select print file"}
            </Label>
          )
        )}
        {files.length ? (
          <div className={classNames("dropzone-previews", alwaysShowTitle ? "mt-1" : "mt-3")} id="file-previews">
            {files.map((file: any, idx: number) => (
              <UploadedFileItem
                downloadable={readonly}
                file={file}
                key={idx}
                deletable={!readonly}
                onDelete={handleFileDelete}
              />
            ))}
          </div>
        ) : (
          ""
        )}

        {showDropzone ? (
          <Dropzone
            maxFiles={maxFiles}
            multiple={maxFiles ? maxFiles > 1 : multiple}
            onDrop={(acceptedFiles) => {
              handleFileChange(acceptedFiles);
            }}
          >
            {({ getRootProps, getInputProps }) => (
              <div className="dropzone">
                <div className="dz-message needsclick mt-2" {...getRootProps()}>
                  <input {...getInputProps()} />
                  <div className={classNames(small ? "mb-1" : "mb-3")}>
                    <i className="display-4 text-muted bx bxs-cloud-upload" />
                  </div>
                  {small ? <h5>Drop files here or click to upload.</h5> : <h4>Drop files here or click to upload.</h4>}
                </div>
              </div>
            )}
          </Dropzone>
        ) : (
          ""
        )}
      </div>
      {!hideSubmitButton && (
        <div className="mt-3 d-flex justify-content-center">
          <button
            type="button"
            className="btn btn-success waves-effect"
            disabled={!files.length}
            onClick={() => {
              if (onSubmit) {
                onSubmit(files);
              }
            }}
          >
            Submit
          </button>
        </div>
      )}
      {error ? <div className="invalid-feedback d-block">{error}</div> : ""}
    </div>
  );
};
