import React, { useState } from "react";
import { Label, Tooltip } from "reactstrap";
import { Node, NodeConfig } from "konva/lib/Node";
import useSelection from "../hook/useSelection";
import useStage from "../hook/useStage";
import { Group } from "konva/lib/Group";
import useItem from "../hook/useItem";
import Konva from "konva";
import Rect = Konva.Rect;
import { useBus } from "react-bus";
// import { ExtendedFile } from "../../common/interfaces/vendor.interface";
// import { IDesignItem } from "../../common/interfaces/products.interface";

interface Props {
  // assetSrc: string;
  initialDesignName: string;
  // onSave: (data: ExtendedFile, title: string, description: string) => void;
  onCancel: () => void;
  // copyItemTarget?: IDesignItem | null;

  selectedItems: Node<NodeConfig>[];
  clearSelection: ReturnType<typeof useSelection>["clearSelection"];
  stageRef: ReturnType<typeof useStage>["stageRef"];
  onSubmit: (data: File | Blob, title: string) => void;
}


export const ExportBlock:React.FC<Props> = ({onCancel, onSubmit, initialDesignName, selectedItems, clearSelection, stageRef}) => {
  const [designNameTooltipOpen, setDesignNameTooltipOpen] = useState(false);
  const [designName, setDesignName] = useState(initialDesignName);
  const { updateItem } = useItem();
  const bus = useBus()

  const downloadSelected = async (targetFrame?: Node<NodeConfig> | Group) => {
    const frame = targetFrame ?? selectedItems.find((item) => item.attrs["data-item-type"] === "frame");
    if (frame) {
      const stage = frame.getStage()!;
      const originalFillPatternImage = (frame as Rect).fillPatternImage();

      // Remove the fillPatternImage before exporting
      delete (frame as Rect).attrs.fillPatternImage;

      updateItem(frame.id(), (attrs) => frame.attrs);
      setTimeout(async ()=>{
        clearSelection();
        const blob = await stage.toBlob({
          x: frame.getClientRect().x,
          y: frame.getClientRect().y,
          width: frame.attrs.width * stage.scaleX(),
          height: frame.attrs.height * stage.scaleY(),
          pixelRatio: +(1 / stage.scaleX()).toFixed(5),
        });
        if (blob) {
          (frame as Rect).fillPatternImage(originalFillPatternImage);
          updateItem(frame.id(), (attrs) => frame.attrs);
          onSubmit(blob as Blob, designName);
        }
      },0)
    }
  }

  const handleSave = () => {
    const frames = stageRef.current.getChildren()[0].getChildren((item) => item.attrs.name === "content-wrapper");

    frames
      .map(
        (frame) =>
          ((frame as Group).getChildren()[0] as Group).getChildren((item) => item.attrs.name === "label-target")[0]
      )
      .forEach(async (frame) => {
        await downloadSelected(frame as Node<NodeConfig>);
      });
  }

  return (
    <div className="canvas-setting-bar__export">
      <div className="mb-3">
        <Label for="create-design-name" className="form-label">
          Design name
          <Tooltip
            placement="top"
            isOpen={designNameTooltipOpen}
            target="design-name-tt"
            toggle={() => {
              setDesignNameTooltipOpen(!designNameTooltipOpen);
            }}
          >
            You can save a specific design name to later on find it in your library.
          </Tooltip>
          <i className="bx bxs-info-circle ms-2" id="design-name-tt" />
        </Label>
        <input
          className="form-control"
          value={designName}
          onChange={(e) => {
            setDesignName(e.target.value);
          }}
          type="text"
          placeholder="Enter design name"
          id="create-design-name"
        />
      </div>

      <div className="mt-auto d-flex">
        <button
          type="button"
          className="btn btn-info waves-effect w-100"
          onClick={() => {
            bus.emit("IMAGE_WIDGET:CLICK_INPUT")
          }}
        >
          Upload
        </button>
        <button
          type="button"
          disabled={!designName}
          className="btn btn-success waves-effect w-100 ms-3"
          onClick={handleSave}
        >
          Save
        </button>
      </div>
    </div>
  )
}
