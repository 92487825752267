import React, { useMemo, useState } from "react";

import { IOrderTableItem, IOrderTableItemChildren, OrderStatus } from "../../common/interfaces/orders.interface";

export const useOrderManagementTableSelectionControl = (items: IOrderTableItem[]) => {
  const [selectedData, setSelectedData] = useState<IOrderTableItemChildren[]>([]);

  const changeStatusInfoText = useMemo(() => {
    if (!selectedData.length) {
      return "Select at least one order to change status";
    }

    const isStatusesEqual = selectedData.every((item) => item.status === selectedData[0].status);

    if (!isStatusesEqual) {
      return "Select orders with the same status";
    }

    return "";
  }, [selectedData]);

  const printLabelsInfoText = useMemo(() => {
    if (!selectedData.length) {
      return "Select at least one order to print labels";
    }

    const isAllHasDHLDelivery = selectedData.every((item) =>
      (item.delivery_data.deliveryService as string).startsWith("DHL")
    );

    const isAllHasReadyToSendOrProcessingStatus = selectedData.every(
      (item) => item.status === OrderStatus.ReadyToSend || item.status === OrderStatus.Processing
    );

    if (!isAllHasReadyToSendOrProcessingStatus) {
      return 'Select orders with the "Ready to send" or "Processing" status';
    }

    if (!isAllHasDHLDelivery) {
      return "Select orders with the DHL delivery";
    }

    return "";
  }, [selectedData]);

  const requestNewTrackingNumberInfoText = useMemo(() => {
    if (!selectedData.length) {
      return "Select at least one order to request new tracking number";
    }

    const isAllHasDHLDelivery = selectedData.every((item) =>
      (item.delivery_data.deliveryService as string).startsWith("DHL")
    );

    if (!isAllHasDHLDelivery) {
      return "Select orders with the DHL delivery";
    }

    return "";
  }, [selectedData]);

  const tableSelectedRows = useMemo(() => {
    const selectedIds = [];

    const parentMap = items.reduce((acc, item) => {
      return {
        ...acc,
        [item.id]: item.children.map((child) => child.id)
      };
    }, {} as Record<string, string[]>);

    // check if parent is selected
    for (const parent of Object.keys(parentMap)) {
      const children = parentMap[parent];
      const isParentSelected = children.every((child) => selectedData.some((item) => item.id === child));
      if (isParentSelected) {
        selectedIds.push(parent);
      }
    }

    selectedIds.push(...selectedData.map((item) => item.id));

    return selectedIds;
  }, [selectedData, items]);

  const handleSelect = (selectData: IOrderTableItem | IOrderTableItemChildren, isSelected: boolean) => {
    if (isSelected) {
      if ("children" in selectData) {
        setSelectedData([...selectedData, ...selectData.children]);
      } else {
        setSelectedData([...selectedData, selectData]);
      }
    } else if ("children" in selectData) {
      setSelectedData(
        selectedData.filter((item) => {
          const [parentId] = item.id.split("/");
          return parentId !== selectData.id;
        })
      );
    } else {
      setSelectedData(selectedData.filter((item) => item.id !== selectData.id));
    }
  };

  const handleSelectAll = (selected: boolean) => {
    if (selected) {
      setSelectedData(
        items.reduce((acc, item) => {
          return [...acc, ...item.children];
        }, [] as IOrderTableItemChildren[])
      );
    } else {
      setSelectedData([]);
    }
  };

  const resetSelection = () => {
    setSelectedData([]);
  };

  const selectRowData = {
    mode: "checkbox",
    bgColor: "#a3ffa1",
    hideSelectAll: false,
    onSelect: (selectData: IOrderTableItem, isSelected: boolean) => {
      handleSelect(selectData, isSelected);
    },
    onSelectAll: (selected: boolean) => {
      handleSelectAll(selected);
    },
    selectionRenderer: ({ mode, ...rest }: any) => {
      return (
        <>
          <div className="form-check">
            <input className="form-check-input" type={mode} onChange={() => null} {...rest} />
          </div>
        </>
      );
    },
    selected: tableSelectedRows
  } as any;

  return {
    selectedData,
    changeStatusInfoText,
    printLabelsInfoText,
    requestNewTrackingNumberInfoText,
    tableSelectedRows,
    selectRowData,
    handleSelect,
    resetSelection
  };
};
