import { createAsyncThunk } from "@reduxjs/toolkit";

import { ProductDesignState } from "../../../common/interfaces/design-slice.interface";
import { ProductType } from "../../../common/interfaces/products.interface";
import { OrdersApiService } from "../../../services/api";

export const DESIGNS_ACTION_UPDATE_LIST = createAsyncThunk(
  "design/update",
  async (data: { productType: ProductType; productGroupId: number; page: number; search?: string }) => {
    return OrdersApiService.getDesignList(data.productType, data.productGroupId, data.page, data.search);
  },
  {
    condition: (_, { getState }) => {
      const state: any = getState();
      const sliceState = state.productDesigns as ProductDesignState;

      if (sliceState.designList.loader) {
        return false;
      }

      return true;
    }
  }
);
