import { IOrderTableItem } from "../interfaces/orders.interface";

export const populateOrderItemChildrenWithDeliveryData = (orderItem: IOrderTableItem) => {
  return {
    ...orderItem,
    children: orderItem.children.map((child) => ({
      ...child,
      delivery_data: orderItem.delivery_data
    }))
  };
};
