import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { useAppDispatch } from "../store/store";
import useAuth from "./useAuth";
import { useServiceContainer } from "./useServiceContainer";

export const useCheckout = () => {
  const { user } = useAuth();
  const { ordersService } = useServiceContainer();
  const dispatch = useAppDispatch();
  // const [checkoutOrderIds, setCheckoutOrderIds] = useState<number[]>([]);
  const navigate = useNavigate();

  const executeCheckoutRequest = async (orderIds: number[], method: string) => {
    return ordersService.proceedToCheckout(method, orderIds);
  };

  const proceedToCheckout = async (orderIds: number[], method: string) => {
    try {
      if (!user) return;

      // if (user.payment_methods.length > 1) {
      //   dispatch(
      //     INIT_CHECKOUT_MODAL({
      //       orderIds,
      //       paymentMethods: user.payment_methods
      //     })
      //   );
      // } else {
      await executeCheckoutRequest(orderIds, method);
      // }
    } catch (e) {
      toast.error(e.message);
    }
  };

  return {
    proceedToCheckout,
    executeCheckoutRequest
  };
};
