import axios from "axios";
import { saveAs } from "file-saver";
import JSZip from "jszip";

import { IMockupFile } from "../interfaces/mockup.inteface";
import { ExtendedFile } from "../interfaces/vendor.interface";
import { formatBytes } from "./buffer";
import { getImageUrl } from "./formatter.util";

export const extendFile = (file: File) => {
  Object.assign(file, {
    preview: URL.createObjectURL(file),
    formattedSize: formatBytes(file.size)
  });
};

export const downloadBase64File = (base64Data: string, fileName: string) => {
  const downloadLink = document.createElement("a");
  downloadLink.href = base64Data;
  downloadLink.download = fileName;
  downloadLink.click();
};

export const blobToFile = (blob: [string, string]) => {
  const f = new File([blob[0]], blob[1]);
  extendFile(f);
  return f as ExtendedFile;
};

export const toBase64 = (file: ExtendedFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = reject;
  });

export const dataURLtoFile = ([dataurl, filename]: [string, string]) => {
  const arr = dataurl.split(",");
  // @ts-ignore
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[arr.length - 1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  const f = new File([u8arr], filename, { type: mime });
  extendFile(f);
  return f as ExtendedFile;
};

export const urlToFileObject = async (imageUrl: string, fileNameFallback = "file") => {
  const fileName = imageUrl.split("/").pop() || `${fileNameFallback}.${imageUrl.split(".").pop()}`;
  const response = await fetch(imageUrl);
  // here image is url/location of image
  const blob = await response.blob();
  const file = new File([blob], fileName, { type: blob.type });
  return file;
};

export const downloadImage = (url: string, fileNameFallback = "file") => {
  const fileName = url.split("/").pop() || `${fileNameFallback}.${url.split(".").pop()}`;
  const xhr = new XMLHttpRequest();
  xhr.open("GET", url, true);
  xhr.responseType = "blob";
  xhr.onload = function () {
    const urlCreator = window.URL || window.webkitURL;
    const imageUrl = urlCreator.createObjectURL(this.response);
    const tag = document.createElement("a");
    tag.href = imageUrl;
    tag.download = fileName;
    document.body.appendChild(tag);
    tag.click();
    document.body.removeChild(tag);
  };
  xhr.send();
};

export const downloadBase64Image = (base64Data: string, fileName: string) => {
  const downloadLink = document.createElement("a");

  downloadLink.href = base64Data;
  downloadLink.download = fileName;
  downloadLink.click();
};

export const downloadMockupFile = (file: IMockupFile) => {
  const isBase64 = !!file.image;

  if (isBase64) {
    downloadBase64Image(file.image as string, file.name as string);
  } else {
    downloadImage(getImageUrl(file.url!), file.url!.split("/").pop());
  }
};

export const downloadMultipleImagesZipped = async (urls: string[], zipName = "archive") => {
  const zip = new JSZip();

  const promises = urls.map(async (url) => {
    const fileName = url.split("/").pop() || "file";
    const response = await axios.get(url, {
      responseType: "blob"
    });
    zip.file(fileName, response.data, { createFolders: false });
  });

  await Promise.all(promises);

  const zipped = await zip.generateAsync({
    type: "blob"
  });

  saveAs(zipped, zipName);
};

export const downloadMultipleImagesWitNameZipped = async (
  // url, name
  urls: [string, string][],
  zipName = "archive"
) => {
  const zip = new JSZip();

  const promises = urls.map(async ([url, fileName]) => {
    const response = await axios.get(url, {
      responseType: "blob"
    });
    zip.file(fileName, response.data, { createFolders: false });
  });

  await Promise.all(promises);

  const zipped = await zip.generateAsync({
    type: "blob"
  });

  saveAs(zipped, zipName);
};
