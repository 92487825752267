import React from "react";

import { Col, Row } from "reactstrap";

import { Alert } from "../../components/common/Alert";
import { ConnectedShopList } from "../../components/integrations/ConnectedShopList";

export const IntegrationsPage = () => {
  return (
    <div className="p-3">
      <div className="d-flex flex-column">
        <Row>
          <Col xs={6}>
            <h3 className="mb-0">Integrations</h3>
            <p className="mt-3">
              This section manages your Etsy or Shopify (Coming Soon) store integrations. Connect your store, monitor
              integration statuses, and maintain seamless synchronization. Simplify your workflow by managing all your
              integrations in one place.
            </p>
            <Alert variant="danger">
              <strong>IMPORTANT NOTE:</strong> if you delete your shop integration all listing connections to this shop
              will be disconnected and will no longer sync your orders.
            </Alert>
          </Col>
        </Row>

        <ConnectedShopList />
      </div>
    </div>
  );
};
