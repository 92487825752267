import React, { useCallback, useEffect, useMemo, useState } from "react";

import debounce from "lodash.debounce";
import { toast } from "react-toastify";
import { Alert, Button, Col, Row } from "reactstrap";

import { IPaginationMeta } from "../../common/interfaces/api.interface";
import { IMessage } from "../../common/interfaces/messages.interface";
import { getErrorMessage } from "../../common/utils/formatter.util";
import { InputGroup } from "../../components/common/InputGroup";
import { Loader } from "../../components/common/Loader";
import { MessageItem } from "../../components/common/messages/MessageItem";
import { Pagination } from "../../components/common/Pagination";
import useDidMountEffect from "../../hooks/useDidMountEffect";
import { useServiceContainer } from "../../hooks/useServiceContainer";

export const MessagesListPage = () => {
  const [loader, setLoader] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [page, setPage] = useState(0);
  const [items, setItems] = useState<IMessage[]>([]);
  const [paginationMeta, setPaginationMeta] = useState<null | IPaginationMeta>(null);
  const { commonService } = useServiceContainer();

  const fetchList = useCallback(async (_page: number, _search?: string) => {
    setLoader(true);
    try {
      const res = await commonService.getNews(_page + 1, 10, _search);
      setItems(res.items);
      setPaginationMeta(res.meta);
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setLoader(false);
    }
  }, []);

  const debouncedFetchList = useCallback(debounce(fetchList, 500), []);

  useDidMountEffect(() => {
    debouncedFetchList(page, searchInput);
  }, [searchInput]);

  useEffect(() => {
    fetchList(page, searchInput);
  }, [page]);

  return (
    <div className="p-3">
      <div className="d-flex flex-column">
        {loader && <Loader />}
        <h3 className="mb-0">Messages</h3>

        <InputGroup
          className="wizard-step__search mt-2"
          prependAddon={
            <span className="input-group-text bg-white">
              <i className="bx bx-search font-size-18" />
            </span>
          }
          input={(p) => (
            <input
              type="text"
              className="form-control border-start-0"
              placeholder="Search..."
              aria-label="Search"
              value={searchInput}
              onChange={(e) => {
                setSearchInput(e.target.value);
              }}
              {...p}
            />
          )}
        />
      </div>
      <Row className="mt-4">
        {items.length ? (
          items.map((e, idx) => (
            <Col key={idx} md={12} className="mb-4">
              <MessageItem
                item={e}
                isFull
                onMarkAsReadClick={() => {
                  fetchList(page, searchInput);
                }}
              />
            </Col>
          ))
        ) : (
          <Alert color="info">No results found</Alert>
        )}
      </Row>
      <Row>
        {paginationMeta && paginationMeta.lastPage !== 1 && (
          <Col xs={12}>
            <Pagination
              value={page}
              onChange={(e) => {
                setPage(e.selected);
              }}
              pageCount={paginationMeta.lastPage}
            />
          </Col>
        )}
      </Row>
    </div>
  );
};
