import React, { useEffect, useMemo, useState } from "react";

import BootstrapTable from "react-bootstrap-table-next";
import filterFactory from "react-bootstrap-table2-filter";
// @ts-ignore
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { toast } from "react-toastify";
import { Button, Card, CardBody, Col, Row } from "reactstrap";

import { ICardOrderProductItem, ICartOrder } from "../../common/interfaces/orders.interface";
import { getErrorMessage } from "../../common/utils/formatter.util";
import { useCartTableTableExpandControl } from "../../hooks/cartTable/useCartTableTableExpandControl";
import useEventBus from "../../hooks/useEventBus";
import { useServiceContainer } from "../../hooks/useServiceContainer";
import { Alert } from "../common/Alert";
import { Loader } from "../common/Loader";
import { SetupPendingOrdersModal } from "../modals/cart/SetupPendingOrdersModal";

export const PendingCartOrders = () => {
  const [selectedData, setSelectedData] = useState<ICartOrder[]>([]);
  const [items, setItems] = useState<ICartOrder[]>([]);
  const [loader, setLoader] = useState(true);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [showSetupOrdersModal, setShowSetupOrdersModal] = useState(false);
  const { ordersService } = useServiceContainer();
  const eventBus = useEventBus();

  const { expandRowData } = useCartTableTableExpandControl({
    items
  });

  const columns = useMemo(() => {
    return [
      {
        dataField: "salesOrderId",
        text: "Id"
      },
      {
        dataField: "products",
        text: "Product count",
        formatter: (cellContent: ICardOrderProductItem[]) => {
          return <>{cellContent.length}</>;
        }
      },
      {
        dataField: "clientName",
        isDummyField: true,
        text: "Client",
        formatter: (cellContent: any, row: ICartOrder) => {
          return <>{row.deliveryData.fullName}</>;
        }
      },
      {
        dataField: "countryName",
        isDummyField: true,
        text: "Country",
        formatter: (cellContent: any, row: ICartOrder) => {
          return <>{row.deliveryData.country}</>;
        }
      },
      {
        dataField: "amountWithVat",
        text: "Amount with VAT",
        formatter: (cellContent: string) => {
          return <>€{cellContent}</>;
        }
      }
    ];
  }, []);

  const fetchList = async () => {
    setLoader(true);
    try {
      setItems(await ordersService.getCartOrders("pending"));
    } catch (error) {
      toast.error(getErrorMessage(error));
    } finally {
      setLoader(false);
    }
  };

  const handleSetupOrdersClick = () => {
    setShowSetupOrdersModal(true);
  };

  const handlePendingOrderSubmit = () => {
    fetchList().then(() => null);

    eventBus.publish({
      topic: "UpdatePendingOrderCart",
      payload: {}
    });
  };

  const selectRow = {
    mode: "checkbox",
    bgColor: "#a3ffa1",
    onSelect: (selectData: ICartOrder, isSelected: boolean) => {
      if (isSelected) {
        setSelectedData([...selectedData, selectData]);
      } else {
        setSelectedData(selectedData.filter((item) => item.salesOrderId !== selectData.salesOrderId));
      }
    },
    onSelectAll: (selected: boolean) => {
      setIsAllSelected(selected);
      setSelectedData([]);
    },
    selectionRenderer: ({ mode, ...rest }: any) => (
      <>
        <div className="form-check">
          <input className="form-check-input" type={mode} onChange={() => null} {...rest} />
        </div>
      </>
    )
  } as any;

  useEffect(() => {
    fetchList().then(() => null);
  }, []);

  return (
    <div className="data-table">
      <h2>Pending orders</h2>
      <Card className="border">
        {loader ? <Loader /> : ""}
        <CardBody>
          {items.length ? (
            <>
              <Alert variant="warning">
                These orders aren&quot;t finished yet. Please choose a delivery service and then press the{" "}
                <strong>&quot;Setup orders&quot;</strong> button to complete the process.
              </Alert>
              <ToolkitProvider keyField="cartOrderId" columns={columns} data={items} search>
                {(toolkitProps: any) => (
                  <>
                    <Row>
                      <Col xl="12">
                        <div className="table-responsive">
                          <BootstrapTable
                            bootstrap4
                            remote
                            bordered={false}
                            striped={false}
                            expandRow={expandRowData}
                            filter={filterFactory()}
                            selectRow={selectRow}
                            classes="table align-middle"
                            headerWrapperClasses="thead-light"
                            {...toolkitProps.baseProps}
                            keyField="salesOrderId"
                          />
                        </div>
                      </Col>
                    </Row>
                  </>
                )}
              </ToolkitProvider>

              <Button
                className="ms-2 mb-2"
                color="primary"
                onClick={handleSetupOrdersClick}
                disabled={isAllSelected ? false : selectedData.length === 0}
              >
                Setup orders
              </Button>
            </>
          ) : (
            <div className="text-center">
              <Alert variant="info" className="mb-0">
                Your pending orders cart is empty
              </Alert>
            </div>
          )}
        </CardBody>
      </Card>

      <SetupPendingOrdersModal
        show={showSetupOrdersModal}
        onSubmit={handlePendingOrderSubmit}
        toggle={() => {
          setShowSetupOrdersModal(false);
        }}
        orders={isAllSelected ? items : selectedData}
      />
    </div>
  );
};
