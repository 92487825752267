import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { ProductTypesState } from "../../common/interfaces/product-types-slice.interface";
import { IProductItem } from "../../common/interfaces/products.interface";
import { PRODUCT_TYPES_ACTION_UPDATE_LIST } from "./ThunkActions/productTypesSliceThunkActions";

export const productTypesInitialState: ProductTypesState = {
  selectedProduct: null,
  productList: {
    loader: false,
    data: {
      all: [],
      top: [],
      categories: []
    }
  }
};

export const productTypesSlice = createSlice({
  name: "productTypes",
  initialState: productTypesInitialState,
  reducers: {
    PRODUCT_TYPES_SET_SELECTED: (state: ProductTypesState, action: PayloadAction<IProductItem | null>) => {
      state.selectedProduct = action.payload;
    },
    PRODUCT_TYPES_RESET_SELECTED: (state: ProductTypesState) => {
      state.selectedProduct = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(PRODUCT_TYPES_ACTION_UPDATE_LIST.pending, (state) => {
        state.productList.loader = true;
      })
      .addCase(PRODUCT_TYPES_ACTION_UPDATE_LIST.fulfilled, (state, action) => {
        state.productList.data = action.payload;
        state.productList.loader = false;
      });
  }
});

export const { PRODUCT_TYPES_SET_SELECTED, PRODUCT_TYPES_RESET_SELECTED } = productTypesSlice.actions;

export default productTypesSlice.reducer;

export * from "./ThunkActions/productTypesSliceThunkActions";
