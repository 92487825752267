import { useState } from "react";

import { TableChangeState } from "react-bootstrap-table-next";

import { CLEAR_ORDER_HISTORY_FILTER_VALUES } from "../../common/constants/order-history.constant";
import { IPaginationMeta } from "../../common/interfaces/api.interface";
import { parseQuerySort } from "../../common/utils/filter.util";

export const ORDER_MANAGEMENT_DEFAULT_PER_PAGE = 20;

export const ORDER_MANAGEMENT_PAGE_SIZE_OPTIONS = [
  {
    text: "10",
    value: 10
  },
  {
    text: "20",
    value: 20
  },
  {
    text: "50",
    value: 50
  },
  {
    text: "100",
    value: 100
  },
  {
    text: "250",
    value: 250
  },
  {
    text: "500",
    value: 500
  }
];

export const useOrderManagementTableFilters = (
  searchParams: URLSearchParams,
  paginationMeta: IPaginationMeta | null
) => {
  const [filters, setFilters] = useState<any>({});
  const [sortSettings, setSortSettings] = useState<any>(parseQuerySort(Object.fromEntries(searchParams)));
  const [page, setPage] = useState(searchParams.get("page") ? +(searchParams.get("page") as string) : 1);
  const [filterDates, setFilterDates] = useState<Date[]>([]);
  const [perPage, setPerPage] = useState(
    searchParams.get("perPage") ? +(searchParams.get("perPage") as string) : ORDER_MANAGEMENT_DEFAULT_PER_PAGE
  );

  const handleTableChange = (event: string, data: TableChangeState<any>) => {
    switch (event) {
      case "pagination":
        setPage(data.page);
        setPerPage(data.sizePerPage);
        break;
      case "filter":
        setFilters(
          Object.fromEntries(Object.entries(data.filters).map(([column, value]) => [column, value.filterVal]))
        );
        break;
      case "sort":
        setSortSettings({
          [data.sortField]: data.sortOrder
        });
        break;
      default:
        break;
    }
  };

  const defaultSorted = [
    {
      dataField: Object.keys(sortSettings)[0] || "id",
      order: Object.values(sortSettings)[0] || "desc"
    }
  ] as any;

  const pageOptions = {
    sizePerPage: perPage,
    page,
    sizePerPageList: ORDER_MANAGEMENT_PAGE_SIZE_OPTIONS,
    totalSize: paginationMeta?.total || 0,
    custom: true
  };

  const handleClearFilters = () => {
    setFilters({});
    setFilterDates([]);
    CLEAR_ORDER_HISTORY_FILTER_VALUES();
  };

  return {
    filters,
    filterDates,
    setFilterDates,
    sortSettings,
    page,
    perPage,
    defaultSorted,
    pageOptions,
    handleClearFilters,
    handleTableChange
  };
};
