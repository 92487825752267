import { useNavigate } from "react-router-dom";

import { UserRole } from "../common/interfaces/user.interface";
import useAuth from "./useAuth";

export const useRole = () => {
  const { user } = useAuth();
  const navigate = useNavigate();

  const hasRole = (role: UserRole) => {
    if (!user) return false;

    return user.role.includes(role);
  };

  const checkRole = (role: UserRole) => {
    if (!hasRole(role)) {
      navigate("/");
    }
  };

  return {
    role: user?.role,
    checkRole,
    hasRole
  };
};
