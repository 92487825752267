import React from "react";

import { OrderCreationWizard } from "../../components/wizards/order/OrderCreationWizard";

export const CreateOrderPage = () => {
  return (
    <>
      <OrderCreationWizard />
    </>
  );
};
