import React from "react";

import { Button, Modal } from "reactstrap";

import { IDesignItem, IProductItem } from "../../../common/interfaces/products.interface";
import { DesignSelect } from "../../common/DesignSelect/DesignSelect";

interface Props {
  show: boolean;
  toggle: () => void;
  selectedDesigns: IDesignItem[];
  selectedProduct: IProductItem;
  onDesignUpload: (item: IDesignItem) => void;
  onItemSelect: (item: IDesignItem) => void;
  wizardError?: any;
}

export const SelectDesignModal: React.FC<Props> = ({
  wizardError,
  onDesignUpload,
  show,
  toggle,
  selectedDesigns,
  selectedProduct,
  onItemSelect
}) => {
  return (
    <Modal isOpen={show} toggle={toggle} centered size="xl">
      <div className="modal-header">
        <h5 className="modal-title mt-0">Design selector</h5>
        <button
          type="button"
          onClick={() => {
            toggle();
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body wizard-step-scrollable-container-wrapper-ref pb-5">
        <DesignSelect
          selectedDesigns={selectedDesigns}
          disableDynamicScrollableContainerHeight
          selectedProduct={selectedProduct}
          multiSelect
          onDesignUpload={(item) => {
            onDesignUpload(item);
          }}
          onItemSelect={(item) => {
            onItemSelect(item);
          }}
          // wizardError={wizardError}
        />
      </div>
      <div className="modal-footer">
        <Button color="primary" onClick={toggle}>
          Save
        </Button>
      </div>
    </Modal>
  );
};
