import React, { useCallback, useEffect, useState } from "react";

import classNames from "classnames";
import debounce from "lodash.debounce";
import { useMediaQuery } from "react-responsive";
import { toast } from "react-toastify";

import { IDesignItem, IProductItem, ProductType } from "../../../common/interfaces/products.interface";
import { showConfirmationModal } from "../../../common/utils/confirm.util";
import { getErrorMessage } from "../../../common/utils/formatter.util";
import { useServiceContainer } from "../../../hooks/useServiceContainer";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { DESIGNS_ACTION_UPDATE_LIST, DESIGNS_ADD, DESIGNS_UPDATE } from "../../../store/wizards/productDesignsSlice";
import { ASSETS_ACTION_UPDATE_LIST } from "../../../store/wizards/ThunkActions/assetsSliceThunkActions";
import { CreateDesignModal } from "../../modals/create-design/CreateDesignModal";
import { WizardScrollableContainer } from "../../wizards/common/WizardScrollableContainer";
import { InputGroup } from "../InputGroup";
import { Loader } from "../Loader";
import { Pagination } from "../Pagination";
import { ProductRowItems } from "../products/ProductRowItems";

interface Props {
  selectedAssetImage?: string;
  selectedAssetType?: ProductType;
  selectedDesigns?: IDesignItem[] | IDesignItem | null;
  selectedProduct: IProductItem | null;
  multiSelect?: boolean;
  onDesignUpload?: (item: IDesignItem) => void;
  onItemSelect?: (item: IDesignItem) => void;
  wizardError?: any;
  scrollableContainerGap?: number;
  disableDynamicScrollableContainerHeight?: boolean;
}

export const DesignSelect: React.FC<Props> = ({
  selectedDesigns,
  selectedProduct,
  selectedAssetImage,
  onDesignUpload,
  wizardError,
  onItemSelect,
  multiSelect,
  scrollableContainerGap,
  disableDynamicScrollableContainerHeight
}) => {
  const isSmallDesktop = useMediaQuery({
    query: "(max-width: 1300px)"
  });

  const { ordersService } = useServiceContainer();

  const [showCreateDesignModal, setShowCreateDesignModal] = useState(false);
  const [newDesignName, setNewDesignName] = useState("");
  const [designCopyItemTarget, setDesignCopyItemTarget] = useState<IDesignItem | null>(null);
  const [searchInput, setSearchInput] = useState("");
  const [selectedPage, setSelectedPage] = useState(0);
  const dispatch = useAppDispatch();

  const {
    data: designs,
    meta: designsMeta,
    loader: designLoader
  } = useAppSelector((state) => {
    return state.productDesigns.designList;
  });

  const productAssets = useAppSelector((state) => {
    return state.productAssets.assetList.data;
  });

  const handleDesignUpload = async (file: Blob | File, title: string) => {
    try {
      if (!selectedProduct) return;
      const item = await ordersService.createNewDesign(file, title, selectedProduct.id);
      dispatch(DESIGNS_ADD(item));

      if (onDesignUpload) {
        onDesignUpload(item);
      }
    } catch (e) {
      console.error(e, e.response?.data?.data);
      toast.error(getErrorMessage(e));
    }
  };

  const handleTitleChange = async (item: IDesignItem, title: string) => {
    await ordersService.updateDesign(item.id, title);
    dispatch(
      DESIGNS_UPDATE({
        id: item.id,
        title
      })
    );
  };

  const handleDesignDelete = async (item: IDesignItem) => {
    try {
      await ordersService.deleteDesign(item.id);
    } catch (e) {
      console.error(e);
    }
  };

  const fetchList = (type: ProductType, productGroupId: number, page: number, search?: string) => {
    if (selectedProduct) {
      dispatch(
        DESIGNS_ACTION_UPDATE_LIST({
          productType: type,
          productGroupId,
          page,
          search
        })
      );
    }
  };

  const debouncedFetchList = useCallback(debounce(fetchList, 500), [selectedProduct]);

  useEffect(() => {
    setNewDesignName(`Design-${designsMeta ? designsMeta.total + 1 : 1}`);
  }, [designsMeta]);

  useEffect(() => {
    if (selectedProduct) {
      debouncedFetchList(selectedProduct.type, selectedProduct.id, selectedPage, searchInput);
    }
  }, [selectedProduct, selectedPage, searchInput]);

  useEffect(() => {
    if (selectedProduct) {
      dispatch(
        ASSETS_ACTION_UPDATE_LIST({
          selectedProduct
        })
      );
    }
  }, [selectedProduct]);

  return (
    <>
      {designLoader && <Loader />}
      <div className="row">
        <div className="col-12">
          <div className="row mt-2">
            <div className="col-6">
              <InputGroup
                className="wizard-step__search"
                prependAddon={
                  <span className="input-group-text bg-white">
                    <i className="bx bx-search font-size-18" />
                  </span>
                }
                input={(p) => (
                  <input
                    type="text"
                    className="form-control border-start-0"
                    placeholder="Search..."
                    aria-label="Search"
                    value={searchInput}
                    onChange={(e) => {
                      setSearchInput(e.target.value);
                    }}
                    {...p}
                  />
                )}
              />
            </div>
          </div>
          {onItemSelect && <h5 className="mb-3 required mt-4">Select one of your existing designs:</h5>}
          <WizardScrollableContainer
            className={classNames({
              "mt-3": !onItemSelect
            })}
            disableDynamicContainerHeight={disableDynamicScrollableContainerHeight}
            containerGap={scrollableContainerGap}
            error={wizardError}
          >
            <ProductRowItems
              itemsWithControls
              coverImage
              items={designs || []}
              multiSelect={multiSelect}
              firstItem={{
                id: -1,
                title: "Add design",
                image: "plus",
                user_id: 0,
                comment: "",
                productGroup: {
                  id: 0,
                  title: ""
                },
                productId: null,
                type: selectedProduct?.type || ProductType.PHONE_CASE,
                isDeleted: 0
              }}
              onFirstItemClick={() => {
                setShowCreateDesignModal(true);
              }}
              onCopyItemClick={(item) => {
                setDesignCopyItemTarget(item as IDesignItem);
                setShowCreateDesignModal(true);
              }}
              onDeleteItemClick={(item) => {
                showConfirmationModal({
                  title: "Are you sure?",
                  message: "Do you really want to delete this design?",
                  onConfirm: async () => {
                    await handleDesignDelete(item);

                    dispatch(
                      DESIGNS_ACTION_UPDATE_LIST({
                        productType: (selectedProduct as IProductItem).type,
                        productGroupId: (selectedProduct as IProductItem).id,
                        page: selectedPage,
                        search: searchInput
                      })
                    );
                  },
                  onCancel: () => {
                    console.log("cancel");
                  }
                });
              }}
              previewAssetSrc={selectedAssetImage}
              titleEditable
              lgImage
              colSize={isSmallDesktop ? 3 : 2}
              selectedValue={selectedDesigns}
              onTitleChange={handleTitleChange}
              onSelect={(item) => {
                if (onItemSelect) {
                  if (selectedProduct?.max_designs) {
                    if (
                      selectedDesigns &&
                      Array.isArray(selectedDesigns) &&
                      selectedDesigns.length >= selectedProduct.max_designs &&
                      !selectedDesigns.find((d) => d.id === item.id)
                    ) {
                      return;
                    }
                  }
                  onItemSelect(item);
                }
              }}
              selectable={!!onItemSelect}
            />
          </WizardScrollableContainer>

          {designsMeta ? (
            <Pagination
              value={selectedPage}
              onChange={(e) => {
                setSelectedPage(e.selected);
              }}
              pageCount={designsMeta.lastPage}
            />
          ) : (
            ""
          )}
        </div>
      </div>
      <CreateDesignModal
        printfileSize={[selectedProduct?.printfile_width ?? 750, selectedProduct?.printfile_height ?? 1590]}
        designName={newDesignName}
        assetSrc={selectedAssetImage ?? productAssets.length > 0 ? productAssets[0].image : ""}
        show={showCreateDesignModal}
        copyItemTarget={designCopyItemTarget}
        onSubmit={handleDesignUpload}
        toggle={() => {
          setShowCreateDesignModal(!showCreateDesignModal);
          setDesignCopyItemTarget(null);
        }}
      />
    </>
  );
};
