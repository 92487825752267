import React, { useEffect, useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Button, CardBody, Modal } from "reactstrap";
import { object } from "yup";
import * as Yup from "yup";

import { getErrorMessage } from "../../../common/utils/formatter.util";
import { useServiceContainer } from "../../../hooks/useServiceContainer";
import { InputField } from "../../common/InputField";
import { Loader } from "../../common/Loader";

const validationSchema = object().shape({
  tracking_number: Yup.string().required("This field is required")
});

interface Props {
  show: boolean;
  orderId?: string;
  onUpdated: (orderId: string, trackingNumber: string) => void;
  toggle: () => void;
}

export const OrderSetTrackingNumberModal: React.FC<Props> = ({ orderId, onUpdated, show, toggle }) => {
  const [loader, setLoader] = useState(false);
  const { backofficeService } = useServiceContainer();
  const {
    handleSubmit,
    reset,
    register,
    formState: { errors }
  } = useForm({
    defaultValues: {
      tracking_number: ""
    },
    resolver: yupResolver(validationSchema)
  });

  const onSubmit = async (data: any) => {
    if (!orderId) return;
    if (!data.tracking_number) return;

    setLoader(true);

    try {
      await backofficeService.updateOrderTrackingNumber(orderId, data.tracking_number);

      onUpdated(orderId, data.tracking_number);
      toggle();
      toast.success("Tracking successfully number updated");
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    reset({
      tracking_number: ""
    });
  }, [show]);

  return (
    <Modal isOpen={show} toggle={toggle} centered size="md">
      {loader && <Loader />}
      <div className="modal-header">
        <h5 className="modal-title mt-0">Update order #{orderId?.split("/")[0]} tracking number</h5>
        <button
          type="button"
          onClick={() => {
            toggle();
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <>
          <div className="modal-body">
            <div className="row">
              <div className="col-12">
                <div className="card border">
                  <CardBody>
                    <InputField
                      {...register("tracking_number")}
                      error={errors.tracking_number?.message}
                      label="Tracking number"
                      name="tracking_number"
                      required
                      placeholder="Enter tracking number"
                    />
                  </CardBody>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <Button
              color="danger"
              onClick={() => {
                toggle();
              }}
            >
              Cancel
            </Button>
            <Button color="primary" type="submit">
              Save
            </Button>
          </div>
        </>
      </form>
    </Modal>
  );
};
