import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { ProductDesignState } from "../../common/interfaces/design-slice.interface";
import { IDesignItem } from "../../common/interfaces/products.interface";
import { DESIGNS_ACTION_UPDATE_LIST } from "./ThunkActions/designSliceThunkActions";

export const productDesignInitialState: ProductDesignState = {
  selectedDesigns: [],
  designList: {
    loader: false,
    meta: null,
    data: []
  }
};

export const productDesignSlice = createSlice({
  name: "productDesign",
  initialState: productDesignInitialState,
  reducers: {
    DESIGNS_TOGGLE_ITEM: (state: ProductDesignState, action: PayloadAction<IDesignItem | null>) => {
      const index = state.selectedDesigns.findIndex((design) => design.id === action.payload?.id);
      if (index === -1) {
        state.selectedDesigns.push(action.payload!);
      } else {
        state.selectedDesigns.splice(index, 1);
      }
    },
    DESIGNS_SET: (state: ProductDesignState, action: PayloadAction<IDesignItem[]>) => {
      state.selectedDesigns = [...action.payload];
    },
    DESIGNS_RESET_SELECTED: (state: ProductDesignState) => {
      state.selectedDesigns = [];
    },
    DESIGNS_ADD: (state: ProductDesignState, action: PayloadAction<IDesignItem>) => {
      state.designList.data.unshift(action.payload);
    },
    DESIGNS_UPDATE: (state: ProductDesignState, action: PayloadAction<{ id: number; title: string }>) => {
      const target = state.designList.data.find((design) => design.id === action.payload.id);
      if (!target) return;
      target.title = action.payload.title;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(DESIGNS_ACTION_UPDATE_LIST.pending, (state) => {
        state.designList.loader = true;
      })
      .addCase(DESIGNS_ACTION_UPDATE_LIST.fulfilled, (state, action) => {
        state.designList.data = action.payload.items;
        state.designList.meta = action.payload.meta;
        state.designList.loader = false;
      });
  }
});

export const { DESIGNS_SET, DESIGNS_TOGGLE_ITEM, DESIGNS_ADD, DESIGNS_UPDATE, DESIGNS_RESET_SELECTED } =
  productDesignSlice.actions;

export default productDesignSlice.reducer;

export * from "./ThunkActions/designSliceThunkActions";
