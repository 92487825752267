export interface ICoursesItem {
  id: number;
  category: CoursesCategory;
  url: string;
  title: string;
  image: string;
  description: string;
}

export enum CoursesCategory {
  Etsy = "etsy",
  Shopify = "shopify"
}
