import React from "react";

import { Tab, Tabs } from "react-bootstrap";
import { useFormContext } from "react-hook-form";

import { PricingTableEntity, PricingTableEntityItem } from "../../../../common/interfaces/product-wizard.interface";
import { TooltipIcon } from "../../TooltipIcon";
import { PricingTableItem } from "./PricingTableItem";

interface Props {
  items: PricingTableEntity[];
  showDeliveryPrice?: boolean;
  deliveryPrice?: number;
  onPriceChange: (listingId: number, item: PricingTableEntityItem, value: string | null) => void;
}

export const PricingTable: React.FC<Props> = ({ showDeliveryPrice, deliveryPrice, onPriceChange, items }) => {
  const {
    formState: { errors }
  } = useFormContext();

  const handlePriceChange = (listingId: number, item: PricingTableEntityItem, value: string | null) => {
    onPriceChange(listingId, item, value);
  };

  // @ts-ignore
  return (
    <div className="pricing__table">
      <div className="row">
        <div className="col-8">
          <Tabs id="listing-tabs" className="listing-tabs">
            {items.map((tableEntity, idx) => {
              const tableTitle = `Listing ${idx + 1}`;
              return (
                <Tab
                  tabClassName={
                    // @ts-ignore
                    typeof errors?.priceMap?.[idx]?.[item.asset.id] !== "undefined"
                      ? "listing-tabs nav-tabs error"
                      : "listing-tabs nav-tabs"
                  }
                  // @ts-ignore
                  eventKey={idx}
                  // @ts-ignore
                  title={tableTitle}
                  className="listing-tabs nav-tabs"
                >
                  <table>
                    <thead>
                      <tr>
                        <td>Variants</td>
                        <td className="text-center">
                          Retail price
                          <TooltipIcon
                            hoverable
                            id="pt-retail-price-tt"
                            content="Your selling price that includes product and your profit. Delivery costs will be added accordingly to final shipping destination."
                          />
                        </td>
                        <td>
                          Price
                          <TooltipIcon
                            hoverable
                            id="pt-price-tt"
                            content="Product price without shipping costs. To this price will be added shipping costs."
                          />
                        </td>
                        {showDeliveryPrice && <td className="text-center">Estimated delivery price</td>}
                        <td className="text-center">
                          Estimated revenue
                          <TooltipIcon
                            hoverable
                            id="pt-profit-tt"
                            content={
                              showDeliveryPrice
                                ? "Your profit, including shipping costs and excluding Etsy fees"
                                : "Your profit, excluding shipping costs and Etsy fees"
                            }
                          />
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      <>
                        {tableEntity.items.map((item, itemIdx) => {
                          return (
                            <PricingTableItem
                              showDeliveryPrice={showDeliveryPrice}
                              deliveryPrice={deliveryPrice}
                              listingId={tableEntity.listing.id}
                              key={itemIdx}
                              item={item}
                              onPriceChange={(value) => handlePriceChange(tableEntity.listing.id, item, value)}
                            />
                          );
                        })}
                      </>
                    </tbody>
                  </table>
                </Tab>
              );
            })}
          </Tabs>
        </div>
      </div>
    </div>
  );
};
