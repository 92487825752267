import FileDownload from "js-file-download";

import { IBaseApiResponse, IPaginationMeta } from "../../common/interfaces/api.interface";
import { ICountryItem } from "../../common/interfaces/common.interface";
import { IOrderComment } from "../../common/interfaces/order-comments.interface";
import { IOrderSummary, IPossibleDeliveryService } from "../../common/interfaces/order-wizard.interface";
import {
  OrderSummaryData,
  IDeliveryOption,
  ICartOrder,
  IOrderTableItem
} from "../../common/interfaces/orders.interface";
import {
  ICategoryItem,
  IDesignItem,
  IProductAssetItem,
  IProductItem,
  IProductList,
  ProductType
} from "../../common/interfaces/products.interface";
import { populateOrderItemChildrenWithDeliveryData } from "../../common/utils/data-transform.util";
import { assembleFiltersAndSort } from "../../common/utils/filter.util";
import BaseAPIService from "./BaseApiService";

class OrdersApiService extends BaseAPIService {
  constructor() {
    super(`${process.env.REACT_APP_API_BASE_ENDPOINT}/api`);
  }

  public async getProductList(): Promise<IProductList> {
    const res = await this.api.get<
      IBaseApiResponse<{
        all: IProductItem[];
        top: IProductItem[] | null;
        categories: ICategoryItem[];
      }>
    >("/products/list");

    return {
      all: res.data.data.all
        .filter((e) => e.productCount > 0)
        .map((e) => ({
          ...e,
          printfile_width: e.printfile_width ?? 750,
          printfile_height: e.printfile_height ?? 1590
        })),
      top: res.data.data.top || [],
      categories: res.data.data.categories
    };
  }

  public async getAssetList(selectedProduct: IProductItem): Promise<IProductAssetItem[]> {
    const res = await this.api.get<IBaseApiResponse<IProductAssetItem[]>>(`/products/${selectedProduct.id}/assets`);

    return res.data.data
      .filter((e) => {
        if (e.is_deleted) return false;

        if (selectedProduct.ignore_balance) return true;

        const balance = e.balance - e.reserved;
        return balance > 0;
      });
  }

  public async getDesignList(
    productType: ProductType,
    productGroupId: number,
    page?: number,
    search?: string,
    specificIds?: number[]
  ): Promise<{
    meta: IPaginationMeta;
    items: IDesignItem[];
  }> {
    const queryParams = new URLSearchParams();
    if (typeof page !== "undefined") {
      queryParams.append("page", (page + 1).toString());
      queryParams.append("perPage", "17");
    }

    queryParams.append("productType", productType);
    if (search) {
      queryParams.append("search", search);
    }

    if (specificIds) {
      queryParams.append("specific_ids", JSON.stringify(specificIds));
    }

    const res = await this.api.get<
      IBaseApiResponse<{
        meta: IPaginationMeta;
        items: IDesignItem[];
      }>
    >(`/designs/${productGroupId}?${queryParams.toString()}`);

    return res.data.data;
  }

  public async getCountryList(): Promise<ICountryItem[]> {
    const res = await this.api.get<IBaseApiResponse<ICountryItem[]>>(`/countries`);

    return res.data.data;
  }

  public async addOrderToCart(data: OrderSummaryData[]): Promise<ICartOrder[]> {
    const { deliveryData: originDeliveryData } = data[0];
    // delete deliveryData from data
    const dataWithoutDelivery = [...data].map(({ deliveryData, ...rest }) => rest);

    const res = await this.api.post<
      IBaseApiResponse<{
        cartId: number;
        created_at: string;
        updated_at: string;
        orders: ICartOrder[];
      }>
    >("/orders/addToCart", {
      orders: dataWithoutDelivery,
      deliveryData: originDeliveryData
    });

    return res.data.data.orders;
  }

  public async getCartOrders(type?: "active" | "pending"): Promise<ICartOrder[]> {
    const query = new URLSearchParams();

    if (type) {
      query.append("type", type);
    }
    const res = await this.api.get<
      IBaseApiResponse<{
        cartId: number;
        created_at: string;
        updated_at: string;
        orders: ICartOrder[];
      }>
    >(`/orders/cart?${query.toString()}`);

    return res.data.data.orders;
  }

  public async proceedToCheckout(method: string, orderIds: number[]): Promise<boolean> {
    const res = await this.api.post<
      IBaseApiResponse<{
        payment_url: string;
      }>
    >("/orders/checkout", {
      paymentMethod: method,
      ...(orderIds.length ? { salesOrderIds: orderIds } : {})
    });

    if (method === "stripe") {
      window.location.href = res.data.data.payment_url;

      return false;
    }
    return true;
  }

  public async calculateOrderSummary(data: OrderSummaryData[]): Promise<IOrderSummary> {
    const { deliveryData: originDeliveryData } = data[0];
    // delete deliveryData from data
    const dataWithoutDelivery = [...data].map(({ deliveryData, ...rest }) => rest);

    const res = await this.api.post<IBaseApiResponse<IOrderSummary>>("/orders/summary", {
      orders: dataWithoutDelivery,
      deliveryData: originDeliveryData.deliveryOption ? originDeliveryData : null
    });

    return res.data.data;
  }

  public async createNewDesign(
    printFile: Blob | File,
    title: string,
    productId: number,
    description?: string
  ): Promise<IDesignItem> {
    const formData = new FormData();
    formData.append("title", title);
    formData.append("comment", description ?? "");
    formData.append("productGroupId", productId.toString());
    formData.append("productId", "");
    formData.append("file", printFile);
    const res = await this.api.postForm<
      IBaseApiResponse<{
        items: IDesignItem[];
      }>
    >("/designs/create", formData);

    return res.data.data.items[0];
  }

  public async deleteDesign(designId: number): Promise<void> {
    await this.api.get(`/designs/${designId}/delete`);
  }

  public async getOrderHistory<
    T extends boolean = false,
    K = T extends true
      ? null
      : {
          meta: IPaginationMeta;
          items: IOrderTableItem[];
        }
  >(
    page: number,
    perPage = 10,
    dates?: Date[],
    filters?: Record<string, string>,
    sort?: Record<string, string>,
    isAdmin?: boolean,
    isExport?: T
  ): Promise<K> {
    const queryParams = new URLSearchParams();
    if (!isExport) {
      queryParams.append("page", page.toString());
      queryParams.append("perPage", perPage.toString());
    }

    if (isAdmin === true) {
      queryParams.append("isAdmin", "1");
    }

    const additionalParams = filters || sort ? assembleFiltersAndSort(filters, sort, dates) : {};

    for (const [key, value] of Object.entries(additionalParams)) {
      queryParams.append(key, value);
    }

    const baseEndpoint = isExport ? `/orders/list/xlsx` : `/orders/list`;

    const res = await this.api.get<
      IBaseApiResponse<{
        meta: IPaginationMeta;
        items: IOrderTableItem[];
      }>
    >(`${baseEndpoint}?${queryParams.toString()}`, {
      ...(isExport && {
        responseType: "blob"
      })
    });

    if (isExport) {
      FileDownload(res.data as any, `orders.xlsx`);
      return null as unknown as K;
    }

    return {
      ...res.data.data,
      items: res.data.data.items.map((item) => populateOrderItemChildrenWithDeliveryData(item))
    } as unknown as K;
  }

  public async updateDesign(designId: number, title: string): Promise<void> {
    await this.api.post(`/designs/${designId}/update`, {
      title
    });
  }

  public async getDeliveryOptions(productId: number, country: string, weight: number): Promise<IDeliveryOption[]> {
    const res = await this.api.get<IBaseApiResponse<IDeliveryOption[]>>(
      `/orders/deliveryOptions/${productId}/${country}/${weight}`
    );

    return res.data.data;
  }

  public async getOrderComments(orderId: string): Promise<IOrderComment[]> {
    const res = await this.api.get<IBaseApiResponse<IOrderComment[]>>(`/orders/${orderId}/comments`);

    return res.data.data;
  }

  public async addCommentToOrder(orderId: string, text: string, attachments?: File[]): Promise<void> {
    const formData = new FormData();

    formData.append("text", text);
    if (attachments) {
      for (const attachment of attachments) {
        formData.append("attachments[]", attachment);
      }
    }

    await this.api.postForm(`/orders/${orderId}/comments`, formData);
  }

  public async markCommentAsRead(orderId: string, commentId: number): Promise<void> {
    await this.api.post(`/orders/${orderId}/comments/${commentId}/read`);
  }

  public async deleteCommentById(commentId: number): Promise<void> {
    await this.api.get(`/orders/comments/${commentId}/delete`);
  }

  public async deleteOrdersFromCart(salesOrderIds: number[]): Promise<void> {
    await this.api.post(`/orders/remove/fromCart`, {
      sales_order_ids: salesOrderIds
    });
  }

  public async getOrderPossibleDeliveryServices(id: string): Promise<IPossibleDeliveryService[]> {
    const res = await this.api.get<IBaseApiResponse<any>>(`/orders/${id}/possibleDeliveryOptions`);

    return res.data.data;
  }

  public async getMultiOrderPossibleDeliveryServices(
    orderIds: string[],
    deliveryOptionId?: number
  ): Promise<{ order: string; deliveryOptions: IPossibleDeliveryService[] }[]> {
    const res = await this.api.post<
      IBaseApiResponse<
        { order: string; deliveryOptions: IPossibleDeliveryService[]; sales_order_product_ids: number[] }[]
      >
    >(`/orders/possibleDeliveryOptions`, {
      order_ids: orderIds,
      ...(deliveryOptionId && { delivery_option: deliveryOptionId })
    });

    const result = [];

    for (const item of res.data.data) {
      for (const salesOrderId of item.sales_order_product_ids) {
        result.push({
          order: `${item.order}/${salesOrderId}`,
          deliveryOptions: [...item.deliveryOptions]
        });
      }
    }

    return result;
  }

  public async getProductById(id: number): Promise<IProductItem> {
    const res = await this.api.get<IBaseApiResponse<IProductItem>>(`/products/${id}/show`);

    return res.data.data;
  }
}

const instance = new OrdersApiService();

export default instance;
