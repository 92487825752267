import React, { useEffect, useState } from "react";

import { toast } from "react-toastify";
import { Button, Label } from "reactstrap";

import { ICountryItem } from "../../../../common/interfaces/common.interface";
import { IDeliveryOption } from "../../../../common/interfaces/orders.interface";
import { PriceIncreaseType } from "../../../../common/interfaces/product-wizard.interface";
import { IProductItem } from "../../../../common/interfaces/products.interface";
import { useServiceContainer } from "../../../../hooks/useServiceContainer";
import { useAppDispatch, useAppSelector } from "../../../../store/store";
import { PRODUCT_SET_PRICING_FREE_SHIPPING } from "../../../../store/wizards/productSlice";
import { ORDER_ACTION_UPDATE_COUNTRY_LIST } from "../../../../store/wizards/ThunkActions/orderSliceThunkActions";
import { CheckboxFieldInput } from "../../Input/CheckboxFieldInput";
import { TextFieldInput } from "../../Input/TextFieldInput";
import { SelectField } from "../../SelectField";

interface Props {
  onBulkSetClick: (type: PriceIncreaseType, value: number) => void;
  onResetClick: () => void;
  showDeliverySelector?: boolean;
  onDeliveryChange?: (value: IDeliveryOption | null) => void;
  selectedProductItem?: IProductItem;
  onHasFreeShippingChange?: (value: boolean) => void;
  itemWeight?: number;
}

export const PricingHeader: React.FC<Props> = ({
  itemWeight,
  selectedProductItem,
  showDeliverySelector,
  onResetClick,
  onBulkSetClick,
  onHasFreeShippingChange,
  onDeliveryChange
}) => {
  const [hasFreeShipping, setHasFreeShipping] = useState<boolean>(true);
  const [selectedIncreaseType, setSelectedIncreaseType] = useState<string>("fixed");
  const [valueInput, setValueInput] = useState<string>("");
  const [selectedDeliveryCountry, setSelectedDeliveryCountry] = useState<ICountryItem | null>(null);
  const dispatch = useAppDispatch();
  const { ordersService } = useServiceContainer();

  const { data: countryList, loader } = useAppSelector((state) => {
    return state.orderWizard.countryList;
  });

  const increaseTypeOptions = [
    {
      label: "Fixed value",
      value: PriceIncreaseType.Fixed
    },
    {
      label: "Add percentage",
      value: PriceIncreaseType.Percentage
    }
  ];

  const selectedIncreaseTypeOption = increaseTypeOptions.find((e) => e.value === selectedIncreaseType);

  const setClickHandler = () => {
    if (!selectedIncreaseType || valueInput === "") return;

    onBulkSetClick(selectedIncreaseType as PriceIncreaseType, Number(valueInput));
  };

  const resetClickHandler = () => {
    setValueInput("");
    onResetClick();
  };

  const handleDeliveryCountrySelect = async (country: ICountryItem) => {
    try {
      if (!onDeliveryChange) return;
      const options = await ordersService.getDeliveryOptions(selectedProductItem!.id, country.value, itemWeight!);

      let candidate = null;

      const deliveryOptionsSortedByPrice = options.sort((a, b) => a.price! - b.price!);
      const deliveryOptionsWithTracking = deliveryOptionsSortedByPrice.filter((e) => e.has_tracking);

      if (deliveryOptionsWithTracking.length > 0) {
        candidate = {
          ...deliveryOptionsWithTracking[0]
        };
      } else {
        candidate = {
          ...deliveryOptionsSortedByPrice[0]
        };
      }

      onDeliveryChange(candidate);

      if (!candidate) {
        toast.error("No delivery options with tracking available for selected country");
      }
    } catch (e) {
      toast.error("Error while fetching delivery price");
    }
  };

  useEffect(() => {
    if (showDeliverySelector) {
      dispatch(ORDER_ACTION_UPDATE_COUNTRY_LIST());
    }
  }, []);

  useEffect(() => {
    if (selectedDeliveryCountry && selectedProductItem && itemWeight) {
      handleDeliveryCountrySelect(selectedDeliveryCountry);
    }
  }, [selectedDeliveryCountry]);

  useEffect(() => {
    if (!countryList || !countryList.length) return;
    if (onHasFreeShippingChange) {
      onHasFreeShippingChange(hasFreeShipping);
    }

    if (!selectedDeliveryCountry) {
      setSelectedDeliveryCountry(countryList.find((e) => e.value === "US")!);
    }
  }, [hasFreeShipping, countryList]);

  return (
    <div className="pricing__header">
      <div className="row">
        <p className="wizard-step__description">
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          Let's set up your product price! We've set a retail price for each product. To change it, enter your desired
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          value in the "Change retail price" field. You can also check shipping costs by selecting a different
          destination country from the drop-down menu.
        </p>
        {showDeliverySelector && (
          <div className="col-12 mb-3">
            {/* <div className="row"> */}
            {/*  <div className="col-4"> */}
            <div className="d-flex">
              <div className="form-group">
                <Label className="mb-3">Delivery:</Label>
                <CheckboxFieldInput
                  checked={hasFreeShipping}
                  tooltip="Shipping price will be included in your product price"
                  onChange={(e) => {
                    setHasFreeShipping(e.target.checked);
                  }}
                  label="Show price with shipping"
                  green
                />
              </div>
              {/* </div> */}
              {hasFreeShipping && selectedProductItem && itemWeight ? (
                // <div className="col-4">
                <div className="ms-3">
                  <SelectField
                    options={countryList}
                    withoutMarginBottom
                    label="Check shipping price:"
                    placeholder="Select country"
                    menuPlacement="auto"
                    value={countryList.find((item) => item.value === selectedDeliveryCountry?.value)}
                    onChange={(data) => {
                      setSelectedDeliveryCountry(data);
                    }}
                  />
                </div>
              ) : (
                ""
              )}
            </div>
            {/* </div> */}
          </div>
        )}
        <div className="col-9">
          <div className="row">
            <div className="col-8">
              <div className="form-group">
                <Label>Change retail price by:</Label>
                <div className="row">
                  <div className="col-3">
                    <SelectField
                      options={increaseTypeOptions}
                      value={selectedIncreaseTypeOption}
                      menuPlacement="auto"
                      placeholder="Choose section"
                      onChange={(data) => {
                        setSelectedIncreaseType(data.value);
                      }}
                    />
                  </div>
                  <div className="col-2">
                    <TextFieldInput
                      value={valueInput}
                      onKeyPress={(event) => {
                        // numbers and minus
                        if (!/[0-9-.]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      onChange={(e) => {
                        setValueInput(e.target.value);
                      }}
                      placeholder="Value"
                    />
                  </div>
                  <div className="col-5">
                    <Button type="button" color="primary" onClick={setClickHandler}>
                      Set
                    </Button>
                    <Button type="button" color="primary" className="ms-2" onClick={resetClickHandler}>
                      Reset
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
