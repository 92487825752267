import React from "react";

import { Button, Modal } from "reactstrap";

interface Props {
  show: boolean;
  limit: number;
  selectedItems: number;
  toggle: () => void;
}

export const ItemLimitReachedModal: React.FC<Props> = ({ show, limit, selectedItems, toggle }) => {
  const deselectItemsCount = selectedItems - limit;

  return (
    <Modal isOpen={show} toggle={toggle} centered size="sm" className="notification-modal">
      <div className="modal-body">
        <div className="modal-body__icon">
          <i className="bx bx-error" />
        </div>
        <h4>You reached max {limit} items</h4>
        <p>
          Deselect {deselectItemsCount} model{deselectItemsCount > 1 ? "s" : ""} to proceed!
        </p>
      </div>
      <div className="modal-footer">
        <Button
          onClick={() => {
            toggle();
          }}
        >
          Close
        </Button>
      </div>
    </Modal>
  );
};
