import React from "react";

import classNames from "classnames";
import { Label } from "reactstrap";

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: any;
  error?: string;
  hint?: string;
  className?: string;
  clearAll?: boolean;
  onClearAll?: () => void;
}

export const TextFieldInput = React.forwardRef<HTMLInputElement, Props>(
  ({ onClearAll, clearAll, hint, label, className, error, ...props }, ref: any) => {
    return (
      <div className={classNames("form-group", className)}>
        {label && <Label>{label}</Label>}
        {!!clearAll && (
          <button
            className="listing-group-edit-form__header__clear-all"
            onClick={(e) => {
              e.preventDefault();
              // @ts-ignore
              onClearAll(props.name);
            }}
          >
            Clear all
          </button>
        )}
        <input ref={ref} className={classNames("form-control", { "is-invalid": !!error })} {...props} />
        {!!hint && <small className="form-text text-muted">{hint}</small>}
        {!!error && <div className="invalid-feedback">{error}</div>}
      </div>
    );
  }
);
