import React, { useState } from "react";

import BootstrapTable from "react-bootstrap-table-next";
import filterFactory from "react-bootstrap-table2-filter";
// @ts-ignore
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { Link } from "react-router-dom";
import { Button, Card, CardBody, Col, Row } from "reactstrap";

import { IDeliveryService } from "../../../common/interfaces/backoffice.interface";
import { UploadDeliveryServiceExcelModal } from "../../../components/backoffice/modals/UploadDeliveryServiceExcelModal";
import { Loader } from "../../../components/common/Loader";
import { useDeliveryServiceTable } from "../../../hooks/deliveryService/useDeliveryServiceTable";

export const DeliveryServicesPage = () => {
  const { items, loader, fetchList, toggleItemStatus, handleExcelDownload, onTableChange } = useDeliveryServiceTable();

  const [showUploadModal, setShowUploadModal] = useState(false);

  const columns = [
    {
      dataField: "id",
      text: "Id",
      sort: false,
      style: {
        minWidth: "90px"
      }
    },
    {
      dataField: "title",
      text: "Title",
      style: {
        maxWidth: "200px"
      },
      classes: "text-truncate",
      sort: false
    },
    {
      dataField: "is_api",
      text: "Is API",
      sort: false
    },
    {
      dataField: "delivery_count",
      text: "Delivery count",
      sort: false
    },
    {
      text: "Actions",
      dataField: "is_deleted",
      formatter: (cellContent: any, row: IDeliveryService) => {
        return (
          <>
            <Button
              size="sm"
              color={row.is_deleted ? "success" : "danger"}
              onClick={() => {
                toggleItemStatus(row.id, row.is_deleted === 1).then(() => null);
              }}
            >
              {row.is_deleted ? "Activate" : "Deactivate"}
            </Button>

            <Button
              size="sm"
              color="primary"
              className="ms-1"
              onClick={() => {
                handleExcelDownload(row.id).then(() => null);
              }}
            >
              Download xlsx
            </Button>

            <Link className="btn btn-primary btn-sm ms-1" color="primary" to={`/backoffice/delivery/${row.id}/edit`}>
              Edit
            </Link>
          </>
        );
      }
    }
  ];

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc"
    }
  ] as any;

  return (
    <div className="data-table">
      <Card className="border">
        {loader && <Loader />}
        <CardBody>
          <ToolkitProvider keyField="id" columns={columns} data={items} search>
            {(toolkitProps: any) => {
              return (
                <>
                  <Row className="mb-2">
                    <Col xl="12">
                      <div className="d-inline-flex me-3">
                        <Button
                          color="primary"
                          onClick={() => {
                            setShowUploadModal(true);
                          }}
                        >
                          Upload excel
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl="12">
                      <div className="table-responsive">
                        <BootstrapTable
                          bootstrap4
                          remote
                          onTableChange={onTableChange}
                          bordered={false}
                          striped={false}
                          defaultSorted={defaultSorted}
                          filter={filterFactory()}
                          classes="table align-middle table-nowrap table-sm"
                          headerWrapperClasses="thead-light"
                          {...toolkitProps.baseProps}
                          data={items}
                          keyField="id"
                        />
                      </div>
                    </Col>
                  </Row>
                </>
              );
            }}
          </ToolkitProvider>
        </CardBody>
      </Card>

      <UploadDeliveryServiceExcelModal
        show={showUploadModal}
        onUploaded={() => {
          fetchList();
        }}
        toggle={() => {
          setShowUploadModal(false);
        }}
      />
    </div>
  );
};
