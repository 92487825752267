import React from "react";

import { Node, NodeConfig } from "konva/lib/Node";
import { Accordion } from "react-bootstrap";

import widgetList from "../config/widget.json";
import useSelection from "../hook/useSelection";
import useStage from "../hook/useStage";
import Widget, { WidgetKind } from "./Widget";
import AlignWidget from "./widgetList/AlignWidget";
import ColorPaletteWidget from "./widgetList/ColorPaletteWidget";
import ExportWidget from "./widgetList/ExportWidget";
import FrameWidget from "./widgetList/FrameWidget";
import IconWidget from "./widgetList/IconWidget";
import ImageWidget from "./widgetList/ImageWidget";
import LineWidget from "./widgetList/LineWidget";
import ShapeWidget from "./widgetList/ShapeWidget";
import TextWidget from "./widgetList/TextWidget";
import { ExportBlock } from "./ExportBlock";
import BackgroundWidget from "./widgetList/BackgroundWidget";

export type SettingBarProps = {
  selectedItems: Node<NodeConfig>[];
  clearSelection: ReturnType<typeof useSelection>["clearSelection"];
  stageRef: ReturnType<typeof useStage>["stageRef"];
};

export type SettingBarFullProps = SettingBarProps & {
  initialDesignName: string;
  onSubmit: (data: File | Blob, title: string) => void;
  onCancel: () => void;
  printfileSize: [number, number];
};

const Widgets = {
  colorPalette: (data: WidgetKind & SettingBarProps) => <ColorPaletteWidget data={data} />,
  align: (data: WidgetKind & SettingBarProps) => <AlignWidget data={data} />,
  image: (data: WidgetKind & SettingBarProps, printfileSize: [number, number] ) => <ImageWidget stageRef={data.stageRef} printfileSize={printfileSize}/>,
  frame: (data: WidgetKind & SettingBarProps) => <FrameWidget />,
  shape: (data: WidgetKind & SettingBarProps) => <ShapeWidget />,
  background: (data: WidgetKind & SettingBarProps) => <BackgroundWidget data={data} />,
  text: (data: WidgetKind & SettingBarProps) => <TextWidget />,
  line: (data: WidgetKind & SettingBarProps) => <LineWidget />,
  icon: (data: WidgetKind & SettingBarProps) => <IconWidget />,
  export: (data: WidgetKind & SettingBarProps) => <ExportWidget data={data} />
};

export type WidgetIDList = keyof typeof Widgets;

const SettingBar: React.FC<SettingBarFullProps> = ({initialDesignName,onCancel, onSubmit, printfileSize, ...settingProps}) => (
  <aside className={"canvas-setting-bar"}>
    <Accordion>
      {(widgetList as WidgetKind[]).map((data) => (
        <Widget key={`widget-${data.id}`} data={{ ...data, ...settingProps }}>
          {Widgets[data.id] && Widgets[data.id]({ ...data, ...settingProps }, printfileSize)}
        </Widget>
      ))}
    </Accordion>

    <ExportBlock initialDesignName={initialDesignName} onSubmit={onSubmit} onCancel={onCancel} {...settingProps} />
  </aside>
);

export default SettingBar;
