import FileDownload from "js-file-download";

import { IBaseApiResponse, IPaginationMeta } from "../../common/interfaces/api.interface";
import { CoursesCategory, ICoursesItem } from "../../common/interfaces/courses.interface";
import { DownloadCategory, IDownloadItem } from "../../common/interfaces/downloads.interface";
import { IMessage } from "../../common/interfaces/messages.interface";
import { INotification, NotificationType } from "../../common/interfaces/notification.interface";
import BaseAPIService from "./BaseApiService";

class CommonApiService extends BaseAPIService {
  constructor() {
    super(`${process.env.REACT_APP_API_BASE_ENDPOINT}/api`);
  }

  public async downloadZipArchiveFromStorageUrls(
    storageUrls: [string, string?][],
    archiveName = "archive"
  ): Promise<void> {
    const formattedStorageUrls = storageUrls.map(([url, ...rest]) => {
      if (url.startsWith("upload")) {
        return [`storage/${url}`, ...rest];
      }

      return [url, ...rest];
    });

    const res = await this.api.post(
      `/utils/create-zip`,
      {
        storage_url: formattedStorageUrls,
        archive_name: archiveName
      },
      {
        responseType: "blob"
      }
    );

    FileDownload(res.data, `${archiveName}.zip`);
  }

  public async getNotifications(type?: NotificationType, all = false): Promise<INotification[]> {
    const res = await this.api.get<
      IBaseApiResponse<{
        meta: IPaginationMeta;
        items: INotification[];
      }>
    >(`/notifications${all ? "-all" : ""}${type ? `/${type}` : ""}?perPage=100`);

    return res.data.data.items;
  }

  public async readNotifications(notificationIds: number[]) {
    await this.api.post<void>(`/notifications/read`, {
      notification_ids: notificationIds
    });
  }

  public async getNews(
    page = 1,
    perPage = 10,
    search?: string
  ): Promise<{
    meta: IPaginationMeta;
    items: IMessage[];
  }> {
    const query = new URLSearchParams();

    query.set("page", page.toString());
    query.set("perPage", perPage.toString());
    if (search) {
      query.set("search", search);
    }
    const res = await this.api.get<
      IBaseApiResponse<{
        meta: IPaginationMeta;
        items: IMessage[];
      }>
    >(`/news/all?${query.toString()}`);

    return res.data.data;
  }

  public async getDownloads(
    page = 1,
    perPage = 10,
    search?: string,
    category?: DownloadCategory
  ): Promise<{
    meta: IPaginationMeta;
    items: IDownloadItem[];
  }> {
    const query = new URLSearchParams();

    query.set("page", page.toString());
    query.set("perPage", perPage.toString());

    if (search) {
      query.set("search", search);
    }

    if (category) {
      query.set("category", category);
    }
    const res = await this.api.get<
      IBaseApiResponse<{
        meta: IPaginationMeta;
        items: IDownloadItem[];
      }>
    >(`/downloads/all?${query.toString()}`);

    return res.data.data;
  }

  public async getCourses(
    page = 1,
    perPage = 10,
    search?: string,
    category?: CoursesCategory
  ): Promise<{
    meta: IPaginationMeta;
    items: ICoursesItem[];
  }> {
    const query = new URLSearchParams();

    query.set("page", page.toString());
    query.set("perPage", perPage.toString());

    if (search) {
      query.set("search", search);
    }

    if (category) {
      query.set("category", category);
    }
    const res = await this.api.get<
      IBaseApiResponse<{
        meta: IPaginationMeta;
        items: ICoursesItem[];
      }>
    >(`/courses/all?${query.toString()}`);

    return res.data.data;
  }
}

const instance = new CommonApiService();

export default instance;
