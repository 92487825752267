import { useCallback, useEffect, useState } from "react";

import { TableChangeState } from "react-bootstrap-table-next";
import { toast } from "react-toastify";

import { IDeliveryService } from "../../common/interfaces/backoffice.interface";
import { getErrorMessage } from "../../common/utils/formatter.util";
import { useServiceContainer } from "../useServiceContainer";

export const useDeliveryServiceTable = () => {
  const [items, setItems] = useState<IDeliveryService[]>([]);
  const [loader, setLoader] = useState(false);
  const { backofficeService } = useServiceContainer();
  const [filters, setFilters] = useState<any>({});
  const [sortSettings, setSortSettings] = useState<any>({});
  const handleExcelDownload = async (id: number) => {
    setLoader(true);

    try {
      await backofficeService.downloadDeliveryServiceExcel(id);
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setLoader(false);
    }
  };

  const toggleItemStatus = useCallback(
    async (targetId: number, state: boolean) => {
      setLoader(true);
      try {
        await backofficeService.updateDeliveryServiceById(targetId, { is_deleted: state ? 0 : 1 });
        setItems(
          items.map((item) => {
            if (item.id === targetId) {
              item.is_deleted = state ? 0 : 1;
            }
            return item;
          })
        );
      } catch (e) {
        toast.error(getErrorMessage(e));
      } finally {
        setLoader(false);
      }
    },
    [items]
  );

  const fetchList = async (_filters: Record<string, string>, _sort: Record<string, string>) => {
    setLoader(true);
    try {
      const res = await backofficeService.getDeliveryServicesList(_filters, _sort);

      setItems(res);
      // setPaginationMeta(res.meta);
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setLoader(false);
    }
  };

  const onTableChange = (event: string, data: TableChangeState<any>) => {
    switch (event) {
      case "filter":
        setFilters(
          Object.fromEntries(Object.entries(data.filters).map(([column, value]) => [column, value.filterVal]))
        );
        break;
      case "sort":
        setSortSettings({
          [data.sortField]: data.sortOrder
        });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    fetchList(filters, sortSettings).then(() => null);
  }, [filters, sortSettings]);

  return {
    items,
    loader,
    toggleItemStatus,
    handleExcelDownload,
    onTableChange,
    fetchList: () => fetchList(filters, sortSettings)
  };
};
