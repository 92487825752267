import React from "react";

import { IProductListingItem } from "../../common/interfaces/products.interface";
import { getImageUrl } from "../../common/utils/formatter.util";

interface Props {
  listing: IProductListingItem;
}
export const ProductDetailSummary: React.FC<Props> = ({ listing }) => {
  return (
    <div className="py-3">
      <div className="row">
        <div className="col-3 mb-3">
          <div className="card d-flex flex-row">
            <img
              src={getImageUrl(listing.product.image)}
              alt={listing.product.title}
              style={{
                maxHeight: "150px",
                objectFit: "contain"
              }}
            />
            <div className="card-body bg-white d-flex flex-column justify-content-center">
              <h5 className="card-title">Product</h5>

              <p className="card-text">{listing.product.title}</p>
            </div>
          </div>
        </div>
        {listing.photos.map((photo, idx) => (
          <div className="col-3 mb-3" key={idx}>
            <div className="card d-flex flex-row">
              <img
                src={getImageUrl(photo.mockup_file)}
                alt="Mockup"
                style={{
                  maxHeight: "150px",
                  objectFit: "contain"
                }}
              />
              <div className="card-body bg-white d-flex flex-column justify-content-center">
                {/* <h5 className="card-title">Design</h5> */}
                {/* <p className="card-text">Design #{photo.design_id}</p> */}

                <h5 className="card-title">Photo</h5>
                <p className="card-text">Photo #{idx + 1}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
