
const MAX_CANVAS_WIDTH = 1000;
const MAX_CANVAS_HEIGHT = 600;

export const getSizeWithAspectRatio = (width: number, height: number) => {
  if (width > MAX_CANVAS_WIDTH) {
    const ratio = MAX_CANVAS_WIDTH / width;
    width = MAX_CANVAS_WIDTH;
    height = height * ratio;
  }

  if (height > MAX_CANVAS_HEIGHT) {
    const ratio = MAX_CANVAS_HEIGHT / height;
    height = MAX_CANVAS_HEIGHT;
    width = width * ratio;
  }

  return {width, height};
}
