import React, { useState } from "react";

import BootstrapTable, { TableChangeState } from "react-bootstrap-table-next";
import filterFactory, { selectFilter, textFilter } from "react-bootstrap-table2-filter";
import paginationFactory, { PaginationProvider, PaginationListStandalone } from "react-bootstrap-table2-paginator";
// @ts-ignore
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { useParams } from "react-router-dom";
import { Button, Card, CardBody, Col, Row } from "reactstrap";

import { IDeliveryServiceItem } from "../../../common/interfaces/backoffice.interface";
import { EditDeliveryServiceForm } from "../../../components/backoffice/forms/EditDeliveryServiceForm";
import { UpdateDeliveryServiceItemModal } from "../../../components/backoffice/modals/UpdateDeliveryServiceItemModal";
import { Loader } from "../../../components/common/Loader";
import { useDeliveryServiceItemTable } from "../../../hooks/deliveryService/useDeliveryServiceItemTable";

export const DeliveryServiceDetailPage = () => {
  const { deliveryServiceId } = useParams();
  const [selectedItem, setSelectedItem] = useState<IDeliveryServiceItem | null>(null);
  const [showDeliveryServiceItemModal, setShowDeliveryServiceItemModal] = useState(false);

  const { items, page, paginationMeta, onTableChange, onItemUpdate, onItemCreate, loader, handleItemDelete } =
    useDeliveryServiceItemTable(deliveryServiceId!);

  const columns = [
    {
      dataField: "id",
      text: "Id",
      sort: true,
      filter: textFilter({ caseSensitive: true, placeholder: " ", className: "form-control-sm mt-1" })
    },
    {
      dataField: "country_code",
      text: "Country code",
      classes: "text-truncate",
      sort: true,
      filter: textFilter({ caseSensitive: true, placeholder: " ", className: "form-control-sm mt-1" })
    },
    {
      dataField: "weight_from",
      text: "Weight from",
      sort: true,
      filter: textFilter({ caseSensitive: true, placeholder: " ", className: "form-control-sm mt-1" })
    },
    {
      dataField: "weight_to",
      text: "Weight to",
      sort: true,
      filter: textFilter({ caseSensitive: true, placeholder: " ", className: "form-control-sm mt-1" })
    },
    {
      dataField: "delivery_time",
      text: "Delivery time",
      sort: true,
      filter: textFilter({ caseSensitive: true, placeholder: " ", className: "form-control-sm mt-1" })
    },
    {
      dataField: "price",
      text: "Price",
      sort: true,
      filter: textFilter({ caseSensitive: true, placeholder: " ", className: "form-control-sm mt-1" })
    },
    {
      dataField: "is_tracking",
      text: "Is tracking",
      sort: true,
      filter: selectFilter({
        options: {
          1: "Yes",
          0: "No"
        },
        placeholder: " ",
        className: "form-control-sm mt-1"
      }),
      formatter: (cellContent: number) => {
        return cellContent ? "Yes" : "No";
      }
    },
    {
      text: "Actions",
      isDummyField: true,
      formatter: (cellContent: any, row: IDeliveryServiceItem) => {
        return (
          <>
            <Button
              size="sm"
              color="primary"
              onClick={() => {
                setSelectedItem(row);
                setShowDeliveryServiceItemModal(true);
              }}
            >
              Update
            </Button>
            <Button className="ms-1" size="sm" color="danger" onClick={() => handleItemDelete(row.id)}>
              Delete
            </Button>
          </>
        );
      }
    }
  ];

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc"
    }
  ] as any;

  const pageOptions = {
    sizePerPage: 20,
    page,
    totalSize: paginationMeta?.total || 0,
    custom: true
  };

  return (
    <div className="data-table">
      <Card className="border">
        <CardBody>{deliveryServiceId && <EditDeliveryServiceForm itemId={+deliveryServiceId} />}</CardBody>
      </Card>

      <h3>Delivery service items</h3>

      <Card className="border">
        {loader && <Loader />}
        <CardBody>
          <PaginationProvider pagination={paginationFactory(pageOptions)}>
            {({ paginationProps, paginationTableProps }: any) => (
              <ToolkitProvider keyField="id" columns={columns} data={items} search>
                {(toolkitProps: any) => {
                  return (
                    <>
                      <Row className="mb-3">
                        <Col xl="12">
                          <div className="d-inline-flex me-3">
                            <Button
                              color="primary"
                              onClick={() => {
                                setSelectedItem(null);
                                setShowDeliveryServiceItemModal(true);
                              }}
                            >
                              Add new
                            </Button>
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col xl="12">
                          <div className="table-responsive">
                            <BootstrapTable
                              bootstrap4
                              remote
                              onTableChange={onTableChange}
                              bordered={false}
                              striped={false}
                              defaultSorted={defaultSorted}
                              filter={filterFactory()}
                              classes="table align-middle table-nowrap table-sm"
                              headerWrapperClasses="thead-light"
                              {...toolkitProps.baseProps}
                              {...paginationTableProps}
                              data={items}
                              keyField="id"
                            />
                          </div>
                        </Col>
                      </Row>

                      <Row className="align-items-md-center mt-30">
                        <Col className="inner-custom-pagination d-flex">
                          <div className="text-md-right ms-auto">
                            <PaginationListStandalone {...paginationProps} />
                          </div>
                        </Col>
                      </Row>
                    </>
                  );
                }}
              </ToolkitProvider>
            )}
          </PaginationProvider>
        </CardBody>
      </Card>

      <UpdateDeliveryServiceItemModal
        show={showDeliveryServiceItemModal}
        item={selectedItem}
        serviceId={+deliveryServiceId!}
        onUpdated={onItemUpdate}
        onCreated={onItemCreate}
        toggle={() => {
          setShowDeliveryServiceItemModal(false);
          setSelectedItem(null);
        }}
      />
    </div>
  );
};
