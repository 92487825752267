import React from "react";

import classNames from "classnames";

import { INotification, NotificationType } from "../../common/interfaces/notification.interface";
import { timeSince } from "../../common/utils/formatter.util";
import { useServiceContainer } from "../../hooks/useServiceContainer";

interface Props {
  item: INotification;
}

export const NotificationElement: React.FC<Props> = ({ item: notification }) => {
  const { commonService } = useServiceContainer();

  const handleNotificationClick = async () => {
    if (notification.read_at == null) {
      await commonService.readNotifications([notification.id]);
    }

    window.location.href = notification.url;
  };
  return (
    <div
      role="button"
      onClick={handleNotificationClick}
      className={classNames("text-reset notification-item d-block active", {
        "notification-item--read": notification.read_at != null
      })}
    >
      <div className="d-flex">
        <div className="avatar-xs me-3">
          <span className="avatar-title bg-primary rounded-circle font-size-16">
            <i
              className={classNames("mdi", {
                "mdi-file-document-outline": notification.notification_type === NotificationType.Invoices,
                "mdi-newspaper": notification.notification_type === NotificationType.News,
                "mdi-forum": notification.notification_type === NotificationType.Main
              })}
            />
          </span>
        </div>
        <div className="flex-1">
          <h6 className="mt-0 mb-1">{notification.notification}</h6>
          <div className="font-size-12 text-muted">
            <p className="mb-0">
              <i className="mdi mdi-clock-outline" /> {timeSince(notification.created_at)}{" "}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
