import React, { useCallback, useEffect, useMemo, useState } from "react";

import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Card, CardBody, Col, Row, Table } from "reactstrap";

import { IProductBackofficeAssetItem } from "../../../common/interfaces/backoffice.interface";
import { getErrorMessage, getImageUrl } from "../../../common/utils/formatter.util";
import { CreateProductAssetModal } from "../../../components/backoffice/modals/CreateProductAssetModal";
import { Loader } from "../../../components/common/Loader";
import { useServiceContainer } from "../../../hooks/useServiceContainer";

export const ProductAssetsManagement = () => {
  const { id } = useParams();

  const [assets, setAssets] = useState<IProductBackofficeAssetItem[]>([]);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [loader, setLoader] = useState(false);
  // const [perPage, setPerPage] = useState(DEFAULT_PER_PAGE);
  const { backofficeService } = useServiceContainer();

  const toggleEntity = useCallback(
    async (assetId: number, state: boolean) => {
      if (!id) return;
      setLoader(true);
      try {
        await backofficeService.updateProductAssetById(+id, assetId, { is_deleted: state ? 0 : 1 });
        setAssets(
          assets.map((item) => {
            if (item.id === assetId) {
              item.is_deleted = state ? 0 : 1;
            }
            return item;
          })
        );
      } catch (e) {
        console.error(e);
      } finally {
        setLoader(false);
      }
    },
    [assets]
  );


    const updateOrder = useCallback(
        // @ts-ignore
        async (newOrder) => {
            setLoader(true);
            try {
                await backofficeService.saveProductAssetListOrder(newOrder);
            } catch (e) {
                console.error(e);
            } finally {
                await fetch();
                setLoader(false);
            }
        },
        []
    );

    // @ts-ignore
    const handleDragStart = (e, columnIndex) => {
        e.dataTransfer.setData("ord", columnIndex);
    };
    // @ts-ignore
    const handleDrag = (e) => {
        e.target.classList.add("over");
    };
    // @ts-ignore
    const handleDragEnd = (e) => {
        e.target.classList.remove("over");
    };

    // @ts-ignore
    const allowDrop = (e, columnIndex) => {
        e.preventDefault();
    };

    // @ts-ignore
    const handleDragOver = (e, columnIndex) => {
        e.stopPropagation();
        e.preventDefault();
        e.target.parentElement.classList.remove("over");
        const dragIndex = e.dataTransfer.getData("ord");
        if (dragIndex !== columnIndex) {
            const newColumns = [...assets];
            const [draggedColumn] = newColumns.splice(dragIndex, 1);
            newColumns.splice(columnIndex, 0, draggedColumn);
            setAssets(newColumns);
        }
    };

  const fetch = async () => {
    try {
      if (!id) return;
      setLoader(true);
      const res = await backofficeService.getProductAssets(+id);
      setAssets(res);
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    fetch().then(() => null);
  }, []);

  return (
    <div className="data-table">
      <h3>
        <Link to={`/backoffice/products/${id}/edit`} className="btn btn-primary btn-sm me-3 mb-1">
          <i className="bx bx-arrow-back" /> Back
        </Link>
        Product #{id} assets management
      </h3>
      <Col md={12}>
        <Card>
          <CardBody>
            <button
              type="button"
              className="btn btn-success waves-effect w-100 ms-3"
              onClick={() => {
                setShowCreateModal(true);
              }}
            >
              Create new asset
            </button>
          </CardBody>
        </Card>
      </Col>
      <Row>
        <Card className="border">
          {loader && <Loader />}
          <CardBody>
            <Col md={12}>
              <Card>
                <CardBody>
                  <div className="table-responsive">
                    <Table className="table mb-0">
                      <thead>
                        <tr>
                          <CardBody>
                            <button
                              type="button"
                              className="btn btn-success waves-effect"
                              onClick={() => {
                                updateOrder(assets);
                              }}
                            >
                              Save Order
                            </button>
                          </CardBody>
                        </tr>
                        <tr>
                          <th> </th>
                          <th>Id</th>
                          <th>Title</th>
                          <th>Weight</th>
                          <th>Balance</th>
                          <th>Image</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {assets.map((item, index) => (
                          <tr
                            key={item.id}
                            draggable
                            onDragStart={(e) => handleDragStart(e, index)}
                            onDrag={(e) => handleDrag(e)}
                            onDragOver={(e) => allowDrop(e, index)}
                            onDrop={(e) => handleDragOver(e, index)}
                            onDragEnd={(e) => handleDragEnd(e)}
                            // onDragLeave={(e) => handleDragLeave(e)}
                          >
                              <td>
                                  <i className="fas fa-grip-vertical"></i>
                              </td>
                              <td>{item.id}</td>
                            <td>{item.title}</td>
                            <td>{item.weight}</td>
                            <td>{item.balance}</td>
                            <td>
                              <a href={getImageUrl(item.image)} target="_blank" rel="noreferrer">
                                <img
                                  style={{
                                    width: 70
                                  }}
                                  src={getImageUrl(item.image)}
                                  alt={item.title}
                                />
                              </a>
                            </td>
                            <td>
                              <div className="d-flex flex-nowrap">
                                <Button
                                  size="sm"
                                  color={item.is_deleted ? "success" : "danger"}
                                  onClick={() => {
                                    toggleEntity(item.id, item.is_deleted === 1).then(() => null);
                                  }}
                                >
                                  {item.is_deleted ? "Activate" : "Deactivate"}
                                </Button>
                                <Link
                                  to={`/backoffice/products/${id}/assets/${item.id}/edit`}
                                  className="btn btn-sm ms-2 btn-primary"
                                >
                                  Edit
                                </Link>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </CardBody>
        </Card>
      </Row>
      {id && (
        <CreateProductAssetModal
          show={showCreateModal}
          toggle={() => {
            setShowCreateModal(!showCreateModal);
            fetch().then(() => null);
          }}
          productId={+id}
        />
      )}
    </div>
  );
};
