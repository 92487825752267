import React, { useCallback, useEffect, useMemo, useState } from "react";

import classNames from "classnames";
import moment from "moment/moment";
import BootstrapTable, { TableChangeState } from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import paginationFactory, { PaginationProvider, PaginationListStandalone } from "react-bootstrap-table2-paginator";
// @ts-ignore
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { toast } from "react-toastify";
import { Button, Card, CardBody, Col, Row } from "reactstrap";

import { IPaginationMeta } from "../../common/interfaces/api.interface";
import { IInvoice } from "../../common/interfaces/invoices.interface";
import { getErrorMessage } from "../../common/utils/formatter.util";
import { Loader } from "../../components/common/Loader";
import { useServiceContainer } from "../../hooks/useServiceContainer";

export const InvoicesList = () => {
  const [items, setItems] = useState<IInvoice[]>([]);
  const [page, setPage] = useState(1);
  const [paginationMeta, setPaginationMeta] = useState<null | IPaginationMeta>(null);
  const [loader, setLoader] = useState(false);
  const { backofficeService } = useServiceContainer();
  const [filters, setFilters] = useState<any>({});
  const [sortSettings, setSortSettings] = useState<any>({});

  const columns = [
    {
      dataField: "invoice_number",
      text: "Invoice ID",
      filter: textFilter({ caseSensitive: true, placeholder: " ", className: "form-control-sm mt-1" }),
      sort: true,
      style: {
        minWidth: "90px"
      }
    },
    {
      dataField: "due",
      text: "Due date",
      filter: textFilter({ caseSensitive: true, placeholder: " ", className: "form-control-sm mt-1" }),
      sort: true,
      style: {
        minWidth: "90px"
      }
    },
    {
      dataField: "balance",
      text: "Balance",
      filter: textFilter({ caseSensitive: true, placeholder: " ", className: "form-control-sm mt-1" }),
      sort: true,
      style: {
        minWidth: "90px"
      },
      formatter: (cellContent: string) => {
        return `€${cellContent}`;
      }
    },
    {
      dataField: "fully_paid",
      text: "Fully paid",
      formatter: (cellContent: boolean) => {
        return (
          <span
            className={classNames("badge", {
              "bg-success": cellContent,
              "bg-danger": !cellContent
            })}
          >
            {cellContent ? "Yes" : "No"}
          </span>
        );
      }
    },
    {
      dataField: "actions",
      isDummyField: true,
      text: "Actions",
      formatter: (cellContent: any, row: IInvoice) => {
        return (
          <Button
            color="primary"
            size="sm"
            type="button"
            onClick={async () => {
              setLoader(true);
              try {
                await backofficeService.getInvoicePdf(row.id, row.invoice_number);
              } catch (e) {
                toast.error(getErrorMessage(e));
              } finally {
                setLoader(false);
              }
            }}
          >
            Download PDF
          </Button>
        );
      },
      sort: false
    }
  ];

  const fetchList = async (selectedPage: number, _filters: Record<string, string>, _sort: Record<string, string>) => {
    setLoader(true);
    try {
      const res = await backofficeService.getInvoiceList(selectedPage, 10, undefined, _filters, _sort);

      setItems(res.items);
      setPaginationMeta(res.meta);
    } catch (e) {
      console.error(e);
    } finally {
      setLoader(false);
    }
  };

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc"
    }
  ] as any;

  const pageOptions = {
    sizePerPage: 20,
    page,
    totalSize: paginationMeta?.total || 0,
    custom: true
  };

  const onTableChange = (event: string, data: TableChangeState<any>) => {
    switch (event) {
      case "pagination":
        setPage(data.page);
        break;
      case "filter":
        setFilters(
          Object.fromEntries(Object.entries(data.filters).map(([column, value]) => [column, value.filterVal]))
        );
        break;
      case "sort":
        setSortSettings({
          [data.sortField]: data.sortOrder
        });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    fetchList(page, filters, sortSettings).then(() => null);
  }, [page, filters, sortSettings]);

  return (
    <div className="data-table">
      <Card className="border">
        {loader && <Loader />}
        <CardBody>
          <h3>Invoices list</h3>
          <PaginationProvider pagination={paginationFactory(pageOptions)}>
            {({ paginationProps, paginationTableProps }: any) => (
              <ToolkitProvider keyField="id" columns={columns} data={items} search>
                {(toolkitProps: any) => {
                  return (
                    <>
                      <Row>
                        <Col xl="12">
                          <div className="table-responsive">
                            <BootstrapTable
                              bootstrap4
                              remote
                              onTableChange={onTableChange}
                              bordered={false}
                              // selectRow={selectRowData}
                              striped={false}
                              defaultSorted={defaultSorted}
                              filter={filterFactory()}
                              classes="table align-middle table-nowrap table-sm"
                              headerWrapperClasses="thead-light"
                              {...toolkitProps.baseProps}
                              {...paginationTableProps}
                              data={items}
                              keyField="id"
                            />
                          </div>
                        </Col>
                      </Row>

                      <Row className="align-items-md-center mt-30">
                        <Col className="inner-custom-pagination d-flex">
                          <div className="text-md-right ms-auto">
                            <PaginationListStandalone {...paginationProps} />
                          </div>
                        </Col>
                      </Row>
                    </>
                  );
                }}
              </ToolkitProvider>
            )}
          </PaginationProvider>
        </CardBody>
      </Card>
    </div>
  );
};
