import React, { useCallback, useEffect, useState } from "react";

import debounce from "lodash.debounce";
import { Tab, Tabs } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { toast } from "react-toastify";

import { IProductSelectedListingItem } from "../../../../common/interfaces/product-wizard.interface";
import { IProductAssetGroup, IProductListingPresetItem } from "../../../../common/interfaces/products.interface";
import { getErrorMessage } from "../../../../common/utils/formatter.util";
import { useCommonProductListingOptions } from "../../../../hooks/productWizard/useCommonProductListingOptions";
import { useServiceContainer } from "../../../../hooks/useServiceContainer";
import { useAppDispatch, useAppSelector } from "../../../../store/store";
import {
  PRODUCT_ADD_LISTING_GROUP,
  PRODUCT_DELETE_LISTING_GROUP_PRESET_BY_ID,
  PRODUCT_DESELECT_LISTING_ITEM,
  PRODUCT_SET_ASSET_GROUPS,
  PRODUCT_SET_LISTING_GROUP_PRESETS,
  PRODUCT_SET_SELECTED_LISTING_FIELD_VALUE,
  PRODUCT_TOGGLE_LISTING_ITEM,
  PRODUCT_UPDATE_LISTING_GROUP
} from "../../../../store/wizards/productSlice";
import { ListingForm } from "../../../common/ProductWizard/listings/ListingForm";
import { ListingGroups } from "../../../common/ProductWizard/listings/ListingGroups";

export const ProductDetailsStep = () => {
  const dispatch = useAppDispatch();
  const { productsApiService } = useServiceContainer();

  const listingGroups = useAppSelector((state) => {
    return state.productWizard.data.listingGroupsPresets;
  });

  const allGroups = useAppSelector((state) => {
    return state.productWizard.data.assetGroups;
  });

  const selectedListings = useAppSelector((state) => {
    return state.productWizard.selectedListings;
  });

  // console.log("selectedListings", selectedListings);

  const selectedShop = useAppSelector((state) => {
    return state.productWizard.selectedShopItem;
  });

  const selectedProduct = useAppSelector((state) => {
    return state.productWizard.selectedProductType;
  });

  const designConnectorData = useAppSelector((state) => {
    return {
      designMockupConnector: state.productWizard.designMockupConnector,
      selectedMockupEntity: state.productWizard.selectedMockupEntity
    };
  });

  const { sectionOptions, shippingProfiles } = useCommonProductListingOptions(selectedShop?.id ?? null);

  const {
    register,
    setValue,
    setError,
    formState: { errors }
  } = useFormContext();

  const handleListingSelect = (item: IProductListingPresetItem, state: boolean) => {
    dispatch(PRODUCT_TOGGLE_LISTING_ITEM(item));
  };

  const handleListingDeselect = (listingId: number) => {
    dispatch(PRODUCT_DESELECT_LISTING_ITEM(listingId));
  };

  const setSelectedListingValues = (data: any) => {
    setValue("selectedListings", data);
  };

  const fetchListingGroups = async () => {
    try {
      if (!selectedProduct) return;
      if (listingGroups.length) return;
      const { presets, groups } = await productsApiService.getDefaultListingGroupPresets(selectedProduct.id);
      dispatch(PRODUCT_SET_LISTING_GROUP_PRESETS(presets));
      dispatch(PRODUCT_SET_ASSET_GROUPS(groups));
    } catch (e) {
      toast.error(getErrorMessage(e));
    }
  };

  const debounceSetValue = useCallback(debounce(setSelectedListingValues, 500), []);

  useEffect(() => {
    register("selectedListings");
  }, [register]);

  useEffect(() => {
    debounceSetValue(Object.fromEntries(selectedListings.map((item) => [item.id, item.fields])));
  }, [selectedListings]);

  useEffect(() => {
    fetchListingGroups().then(() => null);
  }, []);

  const handleListingCreate = async (title: string) => {
    const created = await productsApiService.addCustomListingGroupPreset(selectedProduct!.id, title);

    dispatch(PRODUCT_ADD_LISTING_GROUP(created));

    return created;
  };

  const handleListingUpdate = async (listingId: number, assetIds?: number[]) => {
    await productsApiService.updateCustomListingGroupPreset(listingId, assetIds);
  };

  const handlePostListingUpdate = (listingId: number, newGroups: IProductAssetGroup[]) => {
    dispatch(
      PRODUCT_UPDATE_LISTING_GROUP({
        listingId,
        groups: newGroups
      })
    );
  };

  const handleListingDelete = async (listingId: number) => {
    await productsApiService.deleteCustomListingGroupPreset(listingId);
    dispatch(
      PRODUCT_DELETE_LISTING_GROUP_PRESET_BY_ID({
        id: listingId
      })
    );
  };

  const createMockupDesignConnectorStorageItem = async () => {
    try {
      if (!designConnectorData.selectedMockupEntity || !designConnectorData.designMockupConnector) return;

      await productsApiService.createMockupDesignConnectorStorageItem(
        designConnectorData.selectedMockupEntity.id,
        designConnectorData.designMockupConnector.map((e) => e.designId),
        Object.fromEntries(
          designConnectorData.designMockupConnector.map((item) => [item.designId.toString(), item.mockupId!.toString()])
        )
      );
    } catch (e) {
      toast.error(getErrorMessage(e));
    }
  };

  const handleListingFormFieldUpdate = (
    listingId: number,
    field: keyof IProductSelectedListingItem["fields"] | any,
    value: any
  ) => {
    dispatch(
      PRODUCT_SET_SELECTED_LISTING_FIELD_VALUE({
        field,
        value,
        listingId
      })
    );
  };

  useEffect(() => {
    createMockupDesignConnectorStorageItem();
  }, []);

  // @ts-ignore
  return (
    <>
      <div className="mt-3">
        <ListingGroups
          allGroups={allGroups}
          groups={listingGroups}
          selectedListings={selectedListings}
          onSelect={handleListingSelect}
          onCreateNewListing={handleListingCreate}
          onListingUpdate={handleListingUpdate}
          postListingUpdate={handlePostListingUpdate}
          onListingDelete={handleListingDelete}
        />

        <div className="row mt-4">
          <Tabs id="listing-tabs" className="mb-3 listing-tabs">
            {selectedListings.map((item, idx) => (
              <Tab
                // @ts-ignore
                eventKey={item.id}
                tabClassName={
                  // @ts-ignore
                  typeof errors?.selectedListings?.[item.id] !== "undefined"
                    ? "listing-tabs nav-tabs error"
                    : "listing-tabs nav-tabs"
                }
                // @ts-ignore
                title={listingGroups.find((e) => e.id === item.id).title}
                // @ts-ignore
                className="listing-tabs nav-tabs"
              >
                <div className="col-12 mb-2" key={idx}>
                  <ListingForm
                    hideHeader
                    sectionOptions={sectionOptions}
                    shippingProfiles={shippingProfiles}
                    selectedItem={item}
                    onDelete={handleListingDeselect}
                    onFieldUpdate={handleListingFormFieldUpdate}
                    presetItem={listingGroups.find((e) => e.id === item.id) as IProductListingPresetItem}
                  />
                </div>
              </Tab>
            ))}
          </Tabs>
        </div>
      </div>
    </>
  );
};
