import React from "react";

import copy from "copy-to-clipboard";
import { toast } from "react-toastify";

interface Props {
  children: any;
  copyContent: string;
}

export const CopyToClipboard: React.FC<Props> = ({ children, copyContent }) => {
  return (
    <span
      className="copy-to-clipboard"
      role="button"
      onClick={() => {
        copy(copyContent);

        toast.success("Copied to clipboard");
      }}
    >
      {children}
    </span>
  );
};
