import React, { useEffect, useMemo, useState } from "react";

import BootstrapTable from "react-bootstrap-table-next";
import filterFactory from "react-bootstrap-table2-filter";
// @ts-ignore
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Card, CardBody, Col, Row } from "reactstrap";

import { ICardOrderProductItem, ICartOrder } from "../../common/interfaces/orders.interface";
import { getErrorMessage } from "../../common/utils/formatter.util";
import { useCartTableTableExpandControl } from "../../hooks/cartTable/useCartTableTableExpandControl";
import useAuth from "../../hooks/useAuth";
import { useCheckout } from "../../hooks/useCheckout";
import useEventBus from "../../hooks/useEventBus";
import { useServiceContainer } from "../../hooks/useServiceContainer";
import { APP_ACTION_GET_ORDER_CART } from "../../store/app/ThunkActions/appSliceThunkActions";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { Alert } from "../common/Alert";
import { Loader } from "../common/Loader";
import { MethodSelect } from "../common/MethodSelect/MethodSelect";

export const UnPaidCartOrders = () => {
  const [selectedData, setSelectedData] = useState<ICartOrder[]>([]);
  const [selectedMethod, setSelectedMethod] = useState<string>("");
  const { user } = useAuth();
  const [items, setItems] = useState<ICartOrder[]>([]);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [loader, setLoader] = useState(false);
  const dispatch = useAppDispatch();
  const eventBus = useEventBus();
  const { proceedToCheckout } = useCheckout();
  const { ordersService } = useServiceContainer();
  const { expandRowData } = useCartTableTableExpandControl({
    items
  });

  const isPayButtonDisabled = useMemo(() => {
    if (!selectedMethod) return true;
    if (!items.length) return true;

    if (selectedData.length || isAllSelected) return false;

    return true;
  }, [selectedData, selectedMethod, items]);

  const isDeleteButtonDisabled = useMemo(() => {
    if (!items.length) return true;

    return isAllSelected ? false : selectedData.length === 0;
  }, [isAllSelected, items, selectedData]);

  const navigate = useNavigate();

  const columns = useMemo(() => {
    return [
      {
        dataField: "salesOrderId",
        text: "Id"
      },
      {
        dataField: "products",
        text: "Product count",
        formatter: (cellContent: ICardOrderProductItem[]) => {
          return <>{cellContent.length}</>;
        }
      },
      {
        dataField: "clientName",
        isDummyField: true,
        text: "Client",
        formatter: (cellContent: any, row: ICartOrder) => {
          return <>{row.deliveryData.fullName}</>;
        }
      },
      {
        dataField: "countryName",
        isDummyField: true,
        text: "Country",
        formatter: (cellContent: any, row: ICartOrder) => {
          return <>{row.deliveryData.country}</>;
        }
      },
      {
        dataField: "deliveryAmount",
        isDummyField: true,
        text: "Delivery amount",
        formatter: (cellContent: any, row: ICartOrder) => {
          return <>€{row.deliveryData.deliveryAmountWithVAT}</>;
        }
      },
      {
        dataField: "totalAmountWithDeliveryWithVat",
        text: "Total with VAT",
        formatter: (cellContent: string) => {
          return <>€{cellContent}</>;
        }
      }
    ];
  }, []);

  const fetchList = async () => {
    setLoader(true);
    try {
      setItems(await ordersService.getCartOrders("active"));
    } catch (error) {
      toast.error(getErrorMessage(error));
    } finally {
      setLoader(false);
    }
  };

  const handlePayButton = async () => {
    try {
      setLoader(true);

      if (isAllSelected) {
        await proceedToCheckout(
          items.map((e) => e.salesOrderId),
          selectedMethod
        );
      } else {
        await proceedToCheckout(
          selectedData.map((e) => e.salesOrderId),
          selectedMethod
        );
      }

      if (selectedMethod === "bill") {
        dispatch(APP_ACTION_GET_ORDER_CART());
        toast.success(`Thank you! Your orders are moved to production!`);
        navigate("/orders/list");
      }
    } catch (e) {
      toast.error(e.message);
    }
  };

  const handleDeleteFromCardButton = async () => {
    const canBeDeleted = isAllSelected ? false : selectedData.length === 0;
    if (canBeDeleted) return;
    setLoader(true);
    try {
      const salesOrderIds = !isAllSelected ? selectedData.map((e) => e.salesOrderId) : items.map((e) => e.salesOrderId);
      await ordersService.deleteOrdersFromCart(salesOrderIds);
      dispatch(APP_ACTION_GET_ORDER_CART());
      setSelectedData([]);
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setLoader(false);
    }
  };

  const selectRow = {
    mode: "checkbox",
    bgColor: "#a3ffa1",
    onSelect: (selectData: ICartOrder, isSelected: boolean) => {
      if (isSelected) {
        setSelectedData([...selectedData, selectData]);
      } else {
        setSelectedData(selectedData.filter((item) => item.salesOrderId !== selectData.salesOrderId));
      }
    },
    onSelectAll: (selected: boolean) => {
      setIsAllSelected(selected);
      setSelectedData([]);
    },
    selectionRenderer: ({ mode, ...rest }: any) => (
      <>
        <div className="form-check">
          <input className="form-check-input" type={mode} onChange={() => null} {...rest} />
        </div>
      </>
    )
  } as any;

  useEffect(() => {
    const pendingCartOrderUpdateListener = eventBus.subscribe("UpdatePendingOrderCart", fetchList);
    return () => {
      pendingCartOrderUpdateListener.unsubscribe();
    };
  }, []);

  useEffect(() => {
    fetchList().then(() => null);
  }, []);

  return (
    <div className="data-table">
      <h2>Cart</h2>
      <Card className="border">
        {loader ? <Loader /> : ""}
        <CardBody>
          {items.length ? (
            <>
              <Alert variant="warning">
                To process your orders, please complete the payment. Once the orders are paid, we will move them to the
                &quot;Ready to Print&quot; status.
              </Alert>
              <ToolkitProvider keyField="cartOrderId" columns={columns} data={items} search>
                {(toolkitProps: any) => (
                  <>
                    <Row>
                      <Col xl="12">
                        <div className="table-responsive">
                          <BootstrapTable
                            bootstrap4
                            remote
                            bordered={false}
                            striped={false}
                            expandRow={expandRowData}
                            filter={filterFactory()}
                            selectRow={selectRow}
                            classes="table align-middle"
                            headerWrapperClasses="thead-light"
                            {...toolkitProps.baseProps}
                            keyField="salesOrderId"
                          />
                        </div>
                      </Col>
                    </Row>
                  </>
                )}
              </ToolkitProvider>

              <div className="mt-3">
                <h5 className="mb-3">Payment method</h5>

                <MethodSelect
                  items={user!.payment_methods}
                  onItemSelect={(item) => {
                    setSelectedMethod(item);
                  }}
                />

                <Button color="primary" onClick={handlePayButton} disabled={isPayButtonDisabled}>
                  {isAllSelected ? "Pay for all orders" : `Pay for selected orders`}
                </Button>
                <Button
                  color="primary"
                  className="ms-2"
                  onClick={handleDeleteFromCardButton}
                  disabled={isDeleteButtonDisabled}
                >
                  Delete selected orders from cart
                </Button>
              </div>
            </>
          ) : (
            <div className="text-center">
              <Alert variant="info" className="mb-0">
                Your unpaid orders cart is empty
              </Alert>
            </div>
          )}
        </CardBody>
      </Card>
    </div>
  );
};
