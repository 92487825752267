import React, { useCallback, useEffect, useMemo, useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { isEmpty } from "lodash";
import debounce from "lodash.debounce";
import { FormProvider, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Button } from "reactstrap";
import * as Yup from "yup";

import {
  LISTING_PRICE_MAP_VALIDATION_SCHEMA,
  SELECTED_LISTING_VALIDATION_SCHEMA
} from "../../common/constants/product-wizard.constant";
import {
  IProductSelectedListingItem,
  PriceIncreaseType,
  PricingTableEntity,
  PricingTableEntityItem
} from "../../common/interfaces/product-wizard.interface";
import {
  IProductAssetGroup,
  IProductListingItem,
  IProductListingPresetItem,
  IProductSectionOption,
  IProductShippingProfileItem
} from "../../common/interfaces/products.interface";
import { getErrorMessage } from "../../common/utils/formatter.util";
import { useCommonProductListingOptions } from "../../hooks/productWizard/useCommonProductListingOptions";
import { useServiceContainer } from "../../hooks/useServiceContainer";
import { Loader } from "../common/Loader";
import { ListingForm } from "../common/ProductWizard/listings/ListingForm";
import { ListingGroups } from "../common/ProductWizard/listings/ListingGroups";
import { PricingHeader } from "../common/ProductWizard/pricing/PricingHeader";
import { PricingTable } from "../common/ProductWizard/pricing/PricingTable";

const VALIDATION_SCHEMA = Yup.object().shape({
  selectedListings: Yup.lazy((value) => {
    if (!isEmpty(value)) {
      const validationObject = SELECTED_LISTING_VALIDATION_SCHEMA({
        includeKeywords: false,
        includeSectionAndShippingProfile: true
      });
      const newEntries = Object.keys(value).reduce(
        (acc, val) => ({
          ...acc,
          [val]: Yup.object(validationObject)
        }),
        {}
      );

      return Yup.object().shape(newEntries);
    }
    return Yup.array().min(1).required();
  }),
  priceMap: LISTING_PRICE_MAP_VALIDATION_SCHEMA
});

interface Props {
  listing: IProductListingItem;
  onListingFieldsSubmit: (listingId: number) => void;
  onListingPriceMapSubmit: (listingId: number) => void;
  onListingModelsSubmit: (listingId: number, items?: number[]) => void;
  onListingFieldUpdate: (listingId: number, field: keyof IProductSelectedListingItem["fields"], value: any) => void;
  onListingPriceMapBulkSet: (listingId: number, type: PriceIncreaseType, value: number) => void;
  onListingPriceMapReset: (listingId: number) => void;
  onListingPriceMapValueUpdate: (listingId: number, item: PricingTableEntityItem, value: string | null) => void;
}

interface ProductDetailListingFormProps {
  presetItem: IProductListingPresetItem;
  sectionOptions: IProductSectionOption[];
  shippingProfiles: IProductShippingProfileItem[];
  onListingFieldUpdate: (listingId: number, field: keyof IProductSelectedListingItem["fields"], value: any) => void;
  handleListingUpdate: (id: number) => void;
  handleListingPriceMapUpdate: (id: number) => void;
  selectedItem: IProductSelectedListingItem;
  listingPriceMap: Record<string, number | null>;
  onPriceMapChange: (listingId: number, item: PricingTableEntityItem, value: string | null) => void;
  onPriceMapBulkSet: (listingId: number, type: PriceIncreaseType, value: number) => void;
  onPriceMapReset: (listingId: number) => void;
}
export const ProductDetailListingForm: React.FC<ProductDetailListingFormProps> = ({
  sectionOptions,
  shippingProfiles,
  onListingFieldUpdate,
  selectedItem,
  presetItem,
  handleListingUpdate,
  handleListingPriceMapUpdate,
  listingPriceMap,
  onPriceMapChange,
  onPriceMapBulkSet,
  onPriceMapReset
}) => {
  const [selectedTab, setSelectedTab] = useState<"details" | "price-map">("details");

  const handleTabSwitch = (tab: "details" | "price-map") => {
    setSelectedTab(tab);
  };

  const pricingTableItems = useMemo(() => {
    const allAssets = selectedItem.groups.flatMap((e) => e.items);

    const allVariants = [
      {
        listing: selectedItem,
        items: allAssets.map((assetItem) => {
          const price = listingPriceMap[assetItem.id];
          return {
            asset: assetItem,
            price: price ? price.toString() : ""
          };
        })
      }
    ];

    return allVariants as PricingTableEntity[];
  }, [selectedItem]);

  return (
    <div className="col-12 mb-2">
      <div className="listing-form__header">
        <div className="listing-form__header__title">
          <div className="listing-form__header__title__text">{presetItem.title}</div>
          <span className="separator">|</span>
          <div className="listing-form__header__title__models">{presetItem.groups.map((e) => e.title).join(", ")}</div>
        </div>

        <div className="listing-form__header__tabs">
          <button
            type="button"
            className={selectedTab === "details" ? "active" : ""}
            onClick={() => handleTabSwitch("details")}
          >
            <span>Details</span>
          </button>
          <button
            type="button"
            className={selectedTab === "price-map" ? "active" : ""}
            onClick={() => handleTabSwitch("price-map")}
          >
            <span>Price map</span>
          </button>
        </div>
      </div>

      {selectedTab === "details" && (
        <>
          <ListingForm
            sectionOptions={sectionOptions}
            hideHeader
            hideKeywords
            shippingProfiles={shippingProfiles}
            onFieldUpdate={onListingFieldUpdate}
            // hideKeywords
            selectedItem={selectedItem}
            presetItem={presetItem}
          />

          <div className="d-flex justify-content-center">
            <Button
              color="primary"
              type="button"
              onClick={() => {
                handleListingUpdate(selectedItem.id);
              }}
            >
              Update
            </Button>
          </div>
        </>
      )}

      {selectedTab === "price-map" && (
        <>
          <div className="mt-3">
            <PricingHeader
              onBulkSetClick={(type, value) => onPriceMapBulkSet(selectedItem.id, type, value)}
              onResetClick={() => onPriceMapReset(selectedItem.id)}
            />
          </div>

          <PricingTable items={pricingTableItems} onPriceChange={onPriceMapChange} />

          <div className="d-flex justify-content-center mt-4">
            <Button
              color="primary"
              type="button"
              onClick={() => {
                handleListingPriceMapUpdate(selectedItem.id);
              }}
            >
              Update
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export const ProductDetailListings: React.FC<Props> = ({
  onListingFieldsSubmit,
  onListingPriceMapSubmit,
  onListingFieldUpdate,
  listing,
  onListingPriceMapValueUpdate,
  onListingModelsSubmit,
  onListingPriceMapBulkSet,
  onListingPriceMapReset
}) => {
  const { productsApiService } = useServiceContainer();
  const [loader, setLoader] = useState(false);
  const [allGroups, setAllGroups] = useState<IProductAssetGroup[]>([]);
  const [allPresets, setAllPresets] = useState<IProductListingPresetItem[]>([]);

  const methods = useForm({
    resolver: yupResolver(VALIDATION_SCHEMA)
  });

  const { sectionOptions, shippingProfiles } = useCommonProductListingOptions(listing.shop_id);

  const selectedListings: (IProductSelectedListingItem & {
    priceMap: Record<string, number | null>;
  })[] = useMemo(() => {
    return listing.listings.map((l, idx) => {
      return {
        id: l.id,
        fields: {
          title: l.fields.title,
          description: l.fields.description,
          tags: l.fields.tags,
          materials: l.fields.materials ?? [],
          section: l.fields.section.toString(),
          shipping_profile: l.fields.shipping_profile_id.toString(),
          keywords: {
            keyword1: (l.fields as any).keywords?.keyword1 ?? "",
            keyword2: (l.fields as any).keywords?.keyword2 ?? ""
          }
        },
        groups: allGroups.map((g) => {
          return {
            id: g.id,
            title: g.title,
            items: g.items.filter((i) => l.items.includes(i.id))
          };
        }),
        priceMap: l.price_map
      };
    });
  }, [allPresets, listing]);

  const groups: IProductListingPresetItem[] = useMemo(() => {
    return listing.listings.map((l, idx) => {
      return {
        id: l.id,
        title: `Listing ${idx + 1}`,
        groups: allGroups
          .filter((e) => {
            const atLeastOne = e.items.find((i) => l.items.includes(i.id));
            return atLeastOne !== undefined;
          })
          .map((g) => {
            return {
              id: g.id,
              title: g.title,
              items: g.items.filter((i) => l.items.includes(i.id))
            };
          })
      };
    });
  }, [listing, allGroups]);

  const fetchDefaultGroups = async () => {
    try {
      if (!listing) return;
      const { presets, groups: assetGroups } = await productsApiService.getDefaultListingGroupPresets(
        listing.product_id
      );
      setAllPresets(presets);
      setAllGroups(assetGroups);
    } catch (e) {
      toast.error(getErrorMessage(e));
    }
  };

  const setSelectedListingValues = (data: any) => {
    methods.setValue("selectedListings", data);
  };

  const setPriceMapValues = (data: any) => {
    methods.setValue("priceMap", data);
  };

  const debounceSetValue = useCallback(debounce(setSelectedListingValues, 0), []);
  const debounceSetPriceMapValue = useCallback(debounce(setPriceMapValues, 0), []);

  const handleListingUpdate = async (id: number) => {
    const isValid = await methods.trigger(`selectedListings.${id}`);

    if (isValid) {
      onListingFieldsSubmit(id);
    }
  };

  const handleListingPriceMapUpdate = async (id: number) => {
    const isValid = await methods.trigger(`priceMap.${id}`);

    if (isValid) {
      onListingPriceMapSubmit(id);
    }
  };

  const handleListingItemsUpdate = async (id: number, items?: number[]) => {
    if (!items) return;

    onListingModelsSubmit(id, items);
  };

  const onSubmit = (data: any) => {};

  useEffect(() => {
    fetchDefaultGroups().then(() => null);
  }, []);

  useEffect(() => {
    debounceSetValue(Object.fromEntries(selectedListings.map((item) => [item.id, item.fields])));
    debounceSetPriceMapValue(Object.fromEntries(selectedListings.map((item) => [item.id, item.priceMap])));
  }, [selectedListings]);

  useEffect(() => {
    methods.register("selectedListings");
  }, [methods.register]);

  return (
    <div className="mt-3">
      {loader && <Loader />}
      <ListingGroups
        allGroups={allGroups}
        groups={groups}
        hideSelectCheckbox
        selectedListings={selectedListings}
        onSelect={() => null}
        onListingUpdate={handleListingItemsUpdate}
        onListingDelete={async () => {}}
        postListingUpdate={async () => {}}
      />

      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="row mt-4">
            {selectedListings.map((item, idx) => (
              <ProductDetailListingForm
                key={idx}
                sectionOptions={sectionOptions}
                shippingProfiles={shippingProfiles}
                onListingFieldUpdate={onListingFieldUpdate}
                onPriceMapReset={onListingPriceMapReset}
                onPriceMapChange={onListingPriceMapValueUpdate}
                onPriceMapBulkSet={onListingPriceMapBulkSet}
                handleListingUpdate={handleListingUpdate}
                handleListingPriceMapUpdate={handleListingPriceMapUpdate}
                selectedItem={item}
                listingPriceMap={item.priceMap}
                presetItem={groups.find((e) => e.id === item.id) as IProductListingPresetItem}
              />
            ))}
          </div>
        </form>
      </FormProvider>
    </div>
  );
};
