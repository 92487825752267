export const getMockupAssetTypeBasedOnAssetTitle = (title: string) => {
  if (title.toLowerCase().includes("iphone")) {
    return "iphone";
  }

  if (title.toLowerCase().includes("samsung")) {
    return "samsung";
  }

  if (title.toLowerCase().includes("pixel")) {
    return "pixel";
  }

  return "iphone";
};
