import React, { useMemo } from "react";

import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";

export const SwaggerApiDoc = () => {
  const url = useMemo(() => {
    return `${process.env.REACT_APP_API_BASE_ENDPOINT}/storage/ApiDoc/createAlerioPrint.yaml`;
  }, []);
  return <SwaggerUI url={url} />;
};
