import React, { useEffect, useMemo, useState } from "react";

import errorsToRecord from "@hookform/resolvers/io-ts/dist/errorsToRecord";
import { yupResolver } from "@hookform/resolvers/yup";
import { isEmpty } from "lodash";
import { FormProvider } from "react-hook-form";
import getValidateError from "react-hook-form/dist/logic/getValidateError";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";

import {
  LISTING_PRICE_MAP_VALIDATION_SCHEMA,
  SELECTED_LISTING_VALIDATION_SCHEMA
} from "../../../common/constants/product-wizard.constant";
import { ProductWizardStep, STEPS_DATA } from "../../../common/interfaces/product-wizard.interface";
import { getErrorMessage } from "../../../common/utils/formatter.util";
import { useCustomForm } from "../../../hooks/useCustomForm";
import { useServiceContainer } from "../../../hooks/useServiceContainer";
import { useAppSelector } from "../../../store/store";
import { PRODUCT_RESET_WIZARD, PRODUCT_SET_WIZARD_ACTIVE_STEP } from "../../../store/wizards/productSlice";
import { Loader } from "../../common/Loader";
import { VideoGuide } from "../../common/VideoGuide";
import { WizardStepControls } from "../common/WizardStepControls";
import { WizardStepNavigation } from "../common/WizardStepNavigation";
import { ProductDesignStep } from "./steps/ProductDesignStep";
import { ProductDetailsStep } from "./steps/ProductDetailsStep";
import { ProductIntegrationStep } from "./steps/ProductIntegrationStep";
import { ProductMockupStep } from "./steps/ProductMockupStep";
import { ProductPricingStep } from "./steps/ProductPricingStep";
import { ProductSelectionStep } from "./steps/ProductSelectionStep";
import { buildSubmitBody } from "./utils/submit-body-builder.util";

const STEPS_MAP = {
  [ProductWizardStep.PRODUCT_SELECTION]: {
    next: ProductWizardStep.INTEGRATION_SELECTION,
    previous: null
  },
  [ProductWizardStep.INTEGRATION_SELECTION]: {
    next: ProductWizardStep.MOCKUP_SETTINGS,
    previous: ProductWizardStep.PRODUCT_SELECTION
  },
  // [ProductWizardStep.DESIGN_SETTINGS]: {
  //   next: ProductWizardStep.MOCKUP_SETTINGS,
  //   previous: ProductWizardStep.INTEGRATION_SELECTION
  // },
  [ProductWizardStep.MOCKUP_SETTINGS]: {
    next: ProductWizardStep.DETAILS,
    previous: ProductWizardStep.INTEGRATION_SELECTION
  },
  [ProductWizardStep.DETAILS]: {
    next: ProductWizardStep.PRICING,
    previous: ProductWizardStep.MOCKUP_SETTINGS
  },
  [ProductWizardStep.PRICING]: {
    next: null,
    previous: ProductWizardStep.DETAILS
  }
};

export const ProductCreationWizard = () => {
  const dispatch = useDispatch();
  const methods = useCustomForm({
    schema: Yup.object().shape({})
  });

  const [loader, setLoader] = useState(false);
  const { productsApiService } = useServiceContainer();
  const navigate = useNavigate();

  const { handleSubmit, updateSchema, reset, getValues } = methods;

  const productInstance = useAppSelector((state) => state.productWizard);

  const activeStep = useMemo(() => {
    return productInstance?.activeWizardStep as ProductWizardStep;
  }, [productInstance]);

  const stepContent = useMemo(() => {
    // if (orderInstance.status === OrderWizardInstanceStatus.SUBMITTED) {
    //   return <ProductSummaryStep />;
    // }
    switch (activeStep) {
      case ProductWizardStep.PRODUCT_SELECTION:
        return <ProductSelectionStep />;
      case ProductWizardStep.INTEGRATION_SELECTION:
        return <ProductIntegrationStep />;
      // case ProductWizardStep.DESIGN_SETTINGS:
      //   return <ProductDesignStep />;
      case ProductWizardStep.MOCKUP_SETTINGS:
        return <ProductMockupStep />;
      case ProductWizardStep.DETAILS:
        return <ProductDetailsStep />;
      case ProductWizardStep.PRICING:
        return <ProductPricingStep />;
      default:
        return <>Not implemented</>;
    }
  }, [activeStep, productInstance]);

  const onSubmit = () => {
    // @ts-ignore
    const current = STEPS_MAP[activeStep];
    if (!current) return;
    if (current.next) {
      dispatch(PRODUCT_SET_WIZARD_ACTIVE_STEP(current.next));
    } else {
      handleCreate().then(() => null);
      // reset();
    }
    // onMove(current.next as T);
  };

  const handleCreate = async () => {
    try {
      setLoader(true);

      await productsApiService.createProductListing(buildSubmitBody(productInstance));

      toast.success("Product created successfully");

      navigate("/products");

      dispatch(PRODUCT_RESET_WIZARD());
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setLoader(false);
    }
  };

  const handleActiveStepChange = (stepIdx: ProductWizardStep) => {
    dispatch(PRODUCT_SET_WIZARD_ACTIVE_STEP(stepIdx));
  };

  useEffect(() => {
    switch (activeStep) {
      case ProductWizardStep.PRODUCT_SELECTION:
        updateSchema(
          Yup.object().shape({
            productType: Yup.string().required("Please select product")
          })
        );
        break;
      case ProductWizardStep.INTEGRATION_SELECTION:
        updateSchema(
          Yup.object().shape({
            shopItemId: Yup.string().required("Please select store")
          })
        );
        break;
      // case ProductWizardStep.DESIGN_SETTINGS:
      //   updateSchema(
      //     Yup.object().shape({
      //       selectedDesigns: Yup.array()
      //         .min(1, "Please select at least one design")
      //         .max(5, "Please select no more than 5 designs")
      //         .required("Please select at least one design")
      //     })
      //   );
      //   break;
      case ProductWizardStep.MOCKUP_SETTINGS:
        updateSchema(
          Yup.object().shape({
            selectedDesigns: Yup.array()
              .min(1, "Please select at least one design")
              .max(5, "Please select no more than 5 designs")
              .required("Please select at least one design"),
            selectedMockupEntity: Yup.string().required("Please select mockup folder"),

            designMockupConnector: Yup.lazy((value) => {
              if (!isEmpty(value)) {
                const validationObject = {
                  mockupId: Yup.number().required("Please select image")
                };

                const newEntries = Object.keys(value).reduce(
                  (acc, val) => ({
                    ...acc,
                    [val]: Yup.object(validationObject)
                  }),
                  {}
                );

                return Yup.object()
                  .shape(newEntries)
                  .test("unique-mockupId", "Mockup file must be unique", (obj) => {
                    if (!obj) return true; // Skip validation if obj is undefined or null.
                    // @ts-ignore
                    const mockupIds = Object.values(obj).map((entry) => entry.mockupId);

                    const uniqueMockupIds = new Set(mockupIds);
                    const isUnique = uniqueMockupIds.size === mockupIds.length;

                    if (!isUnique) {
                      return false; // Ensures the test fails when IDs are not unique.
                    }

                    return true; // Pass the test if all IDs are unique.
                  });
              }

              return Yup.mixed().required("Please provide mockup data"); // Custom error message for empty case.
            })
          })
        );
        break;
      case ProductWizardStep.DETAILS:
        updateSchema(
          Yup.object().shape({
            selectedListings: Yup.lazy((value) => {
              if (!isEmpty(value)) {
                const validationObject = SELECTED_LISTING_VALIDATION_SCHEMA({
                  includeKeywords: true,
                  includeSectionAndShippingProfile: true
                });
                const newEntries = Object.keys(value).reduce(
                  (acc, val) => ({
                    ...acc,
                    [val]: Yup.object(validationObject)
                  }),
                  {}
                );

                return Yup.object().shape(newEntries);
              }
              return Yup.array().min(1).required();
            })
          })
        );
        break;
      case ProductWizardStep.PRICING:
        updateSchema(
          Yup.object().shape({
            priceMap: LISTING_PRICE_MAP_VALIDATION_SCHEMA
          })
        );
        break;
      default:
    }
  }, [activeStep]);

  const wizardPassedSteps = useMemo(() => {
    const steps = [];

    if (!productInstance) return [];

    if (productInstance?.selectedProductType && activeStep !== ProductWizardStep.PRODUCT_SELECTION) {
      steps.push(ProductWizardStep.PRODUCT_SELECTION);
    }

    if (productInstance?.selectedShopItem && activeStep !== ProductWizardStep.INTEGRATION_SELECTION) {
      steps.push(ProductWizardStep.INTEGRATION_SELECTION);
    }

    // if (productInstance?.selectedDesigns.length && activeStep !== ProductWizardStep.DESIGN_SETTINGS) {
    //   steps.push(ProductWizardStep.DESIGN_SETTINGS);
    // }

    if (
      productInstance?.selectedMockupEntity &&
      productInstance?.selectedDesigns.length &&
      activeStep !== ProductWizardStep.MOCKUP_SETTINGS
    ) {
      steps.push(ProductWizardStep.MOCKUP_SETTINGS);
    }

    if (
      productInstance?.selectedListings.length &&
      productInstance.selectedListings.every(
        (e) =>
          e.fields.title &&
          e.fields.description &&
          e.fields.tags.length &&
          e.fields.section &&
          e.fields.shipping_profile
      ) &&
      activeStep !== ProductWizardStep.DETAILS
    ) {
      steps.push(ProductWizardStep.DETAILS);
    }

    return steps;
  }, [productInstance]);

  return (
    <div className="d-flex flex-column flex-grow-1">
      {loader ? <Loader fixed /> : null}

      {productInstance ? (
        <>
          <div className="wizard-step wizard-step-scrollable-container-wrapper-ref">
            <WizardStepNavigation
              stepsData={STEPS_DATA}
              activeStep={activeStep}
              onStepChange={handleActiveStepChange}
              passedSteps={wizardPassedSteps}
              video={<VideoGuide title="How to create product" videoId="sY90CS182IA" className="ms-3" />}
            />
            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(onSubmit)}>
                {stepContent}
                {/* {orderInstance?.status !== OrderWizardInstanceStatus.SUBMITTED ? ( */}
                <WizardStepControls
                  firstStep={ProductWizardStep.PRODUCT_SELECTION}
                  stepsMap={STEPS_MAP}
                  activeStep={activeStep}
                  lastStepIsSubmit
                  nextStepBtnFixed
                  onMove={handleActiveStepChange}
                  rightPadding
                />
              </form>
            </FormProvider>
            {/*  </div> */}
            {/* </div> */}
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
};
