import React, { useEffect, useState } from "react";

import classNames from "classnames";

interface Props {
  children: any;
  onClick: (s: boolean) => void;
}

export const CollapseHeading: React.FC<Props> = ({ children, onClick }) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    onClick(isOpen);
  }, [isOpen]);

  return (
    <div className="collapse-heading">
      {children}

      <button
        type="button"
        className="btn btn-link p-0 text-decoration-none ms-auto"
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <i
          className={classNames("bx", {
            "bx-chevron-down": !isOpen,
            "bx-chevron-up": isOpen
          })}
        />
      </button>
    </div>
  );
};
