import React, { useCallback, useEffect, useState } from "react";

import debounce from "lodash.debounce";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useFormContext } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Button } from "reactstrap";

import useDidMountEffect from "../../../../hooks/useDidMountEffect";
import { useServiceContainer } from "../../../../hooks/useServiceContainer";
import { useAppDispatch, useAppSelector } from "../../../../store/store";
import {
  PRODUCT_MOVE_MOCKUP_ENTITY_FILE,
  PRODUCT_SET_DESIGN_MOCKUP_CONNECTOR,
  PRODUCT_SET_SELECTED_DESIGNS,
  PRODUCT_SET_SELECTED_MOCKUP_ENTITY
} from "../../../../store/wizards/productSlice";
import { ProductDesignConnector } from "../../../common/ProductWizard/design-connector/ProductDesignConnector";
import { MockupOrderSetup } from "../../../common/ProductWizard/mockup-order/MockupOrderSetup";
import { MockupListItems } from "../../../MockupList/MockupListItems";
import { WizardErrorContainer } from "../../common/WizardErrorContainer";
import {toast} from "react-toastify";

export const ProductMockupStep = () => {
  const dispatch = useAppDispatch();
  const [loader, setLoader] = useState(false);

  const navigate = useNavigate();

  const selectedMockupEntity = useAppSelector((state) => {
    return state.productWizard.selectedMockupEntity;
  });

  const designMockupConnector = useAppSelector((state) => {
    return state.productWizard.designMockupConnector;
  });

  const selectedProductType = useAppSelector((state) => {
    return state.productWizard.selectedProductType;
  });

  const selectedDesigns = useAppSelector((state) => {
    return state.productWizard.selectedDesigns;
  });

  const { productsApiService, ordersService } = useServiceContainer();

  const {
    register,
    setValue,
    setError,
    formState: { errors }
  } = useFormContext();

  const populateDesignConnectorFromStorage = async (mockupFolderId: string) => {
    try {
      if (!selectedProductType) return;
      setLoader(true);
      const data = await productsApiService.getMockupDesignConnectorStorageItem(mockupFolderId);
      const designs = await ordersService.getDesignList(
        selectedProductType.type,
        selectedProductType.id,
        undefined,
        undefined,
        data.selected_design_ids
      );

      dispatch(PRODUCT_SET_SELECTED_DESIGNS(designs.items));
      dispatch(
        PRODUCT_SET_DESIGN_MOCKUP_CONNECTOR(
          designs.items.map((design) => ({
            designId: +design.id,
            mockupId: data.connections[design.id.toString()] ? +data.connections[design.id.toString()] : null
          }))
        )
      );
    } catch (e) {
      dispatch(PRODUCT_SET_SELECTED_DESIGNS([]));
    } finally {
      setLoader(false);
    }
  };

  const populateDesignConnectorFromStorageDebounced = useCallback(
    debounce(populateDesignConnectorFromStorage, 200),
    []
  );

  useEffect(() => {
    register("selectedMockupEntity");
    register("selectedListings");
    register("selectedDesigns");
  }, [register]);

  useEffect(() => {
    setValue("selectedMockupEntity", selectedMockupEntity?.id ?? null);
  }, [selectedMockupEntity]);

  useEffect(() => {
    setValue(
      "designMockupConnector",
      Object.fromEntries(
        designMockupConnector.map((e) => [
          e.designId,
          {
            mockupId: e.mockupId ?? null
          }
        ])
      )
    );
    setError("designMockupConnector", {
      type: "manual",
      message: ""
    });
  }, [designMockupConnector]);

  useEffect(() => {
    if (selectedMockupEntity) {
      dispatch(
        PRODUCT_SET_DESIGN_MOCKUP_CONNECTOR(
          selectedDesigns.map((e) => {
            const connector = designMockupConnector.find((c) => c.designId === e.id);
            const mockupFile = connector?.mockupId
              ? selectedMockupEntity?.files.find((f) => f.id === connector.mockupId!)
              : null;
            return {
              designId: e.id,
              mockupId: mockupFile && connector ? connector.mockupId : null
            };
          })
        )
      );
    }
  }, [selectedDesigns, selectedMockupEntity]);

  useEffect(() => {
    if (!selectedDesigns) return;
    setValue(
      "selectedDesigns",
      selectedDesigns.map((e) => e.id)
    );
    setError("selectedDesigns", {
      type: "manual",
      message: ""
    });
  }, [selectedDesigns]);

  useDidMountEffect(() => {
    if (selectedMockupEntity) {
      populateDesignConnectorFromStorageDebounced(selectedMockupEntity.id);
    }
  }, [selectedMockupEntity]);

  return (
    <>
        {
            // @ts-ignore
            (errors.selectedMockupEntity?.message) ? toast.error(errors.selectedMockupEntity?.message):""
        }
      <p className="wizard-step__description">
        To proceed, please select an existing design/mockup folder or create a new one
      </p>
      <div className="mt-2">
        <Button
          color="primary"
          onClick={() => {
            const searchParams = new URLSearchParams();
            searchParams.set("productId", selectedProductType!.id.toString());
            searchParams.set("designIds", selectedDesigns.map((e) => e.id).join(","));
            searchParams.set("return_to", "/products/create");
            searchParams.set("store", "product");
            navigate(`/mockup?${searchParams.toString()}`);
            // http://localhost:3002/mockup?productId=120&designIds=989&type=auto&return_to=/products/create
          }}
        >
          Add new mockup
        </Button>
      </div>
      <div className="mt-3 product-mockup-step">
        <WizardErrorContainer error={errors.selectedMockupEntity?.message}>
          <MockupListItems
            // itemsDraggable
            hideDefaultPreviewItems
            // itemsOrderable
            hideDownloadButton
            // onItemDrop={(item, container: { design: IDesignItem }) => {
            //   if (!container?.design) return;
            //   dispatch(
            //     PRODUCT_UPDATE_DESIGN_MOCKUP_CONNECTOR_ITEM({
            //       designId: container.design.id,
            //       mockupId: +item.file.id
            //     })
            //   );
            // }}
            onItemMove={(file, cursor) => {
              dispatch(
                PRODUCT_MOVE_MOCKUP_ENTITY_FILE({
                  fileId: file.id,
                  cursor
                })
              );
            }}
            // onItemDragMove={(current, target) => {
            //   dispatch(
            //     PRODUCT_DRAG_MOVE_MOCKUP_ENTITY_FILE({
            //       current,
            //       target
            //     })
            //   );
            //   console.log("drag move", current, target);
            // }}
            mockupPreviewBlock={
              <>
                <DndProvider backend={HTML5Backend}>
                  <ProductDesignConnector loader={loader} />
                  <MockupOrderSetup />
                </DndProvider>
              </>
            }
            selectedProduct={selectedProductType}
            selectedEntity={
              selectedMockupEntity
                ? {
                    ...selectedMockupEntity,
                    files: [...selectedMockupEntity.files]?.sort((a, b) => a.order! - b.order!) ?? []
                  }
                : null
            }
            onEntitySelect={(item) => {
              setError("selectedMockupEntity", {
                type: "manual",
                message: ""
              });
              dispatch(
                PRODUCT_SET_SELECTED_MOCKUP_ENTITY(
                  item
                    ? {
                        ...item,
                        files: item.files.map((e, idx) => ({
                          ...e,
                          order: idx + 1
                        }))
                      }
                    : null
                )
              );
            }}
          />
        </WizardErrorContainer>
      </div>
    </>
  );
};
