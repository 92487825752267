import React, { useCallback, useEffect, useMemo, useState } from "react";

import classNames from "classnames";
import moment from "moment/moment";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import BootstrapTable, { TableChangeState } from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import paginationFactory, { PaginationProvider, PaginationListStandalone } from "react-bootstrap-table2-paginator";
// @ts-ignore
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { toast } from "react-toastify";
import { Button, Card, CardBody, Col, Row } from "reactstrap";

import {
  CLEAR_INVOICES_LIST_TABLE_FILTER_VALUES,
  INVOICES_LIST_TABLE_COLUMNS
} from "../../../common/constants/invoices-list-table.constant";
import { IPaginationMeta } from "../../../common/interfaces/api.interface";
import { IInvoice } from "../../../common/interfaces/invoices.interface";
import { getErrorMessage } from "../../../common/utils/formatter.util";
import { MakeCreditInvoiceModal } from "../../../components/backoffice/modals/MakeCreditInvoiceModal";
import { RegisterPaymentModal } from "../../../components/backoffice/modals/RegisterPaymentModal";
import { DatePickerField } from "../../../components/common/DatePickerField";
import { Loader } from "../../../components/common/Loader";
import { TooltipIcon } from "../../../components/common/TooltipIcon";
import { useGenericTableSelectionControl } from "../../../hooks/genericTableSelection/useGenericTableSelectionControl";
import { useServiceContainer } from "../../../hooks/useServiceContainer";

interface Props {
  invoiceCreationIndex: number;
}

export const InvoicesList: React.FC<Props> = ({ invoiceCreationIndex }) => {
  const [items, setItems] = useState<IInvoice[]>([]);
  const [filterDates, setFilterDates] = useState<Date[]>([]);
  const [page, setPage] = useState(1);
  const [paginationMeta, setPaginationMeta] = useState<null | IPaginationMeta>(null);
  const [loader, setLoader] = useState(false);
  const { backofficeService } = useServiceContainer();
  const [filters, setFilters] = useState<any>({});
  const [sortSettings, setSortSettings] = useState<any>({});
  const { selectRowData, selectedData } = useGenericTableSelectionControl(items);
  const [showRegisterPaymentModal, setShowRegisterPaymentModal] = useState(false);
  const [showMakeCreditInvoiceModal, setShowMakeCreditInvoiceModal] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState(null as IInvoice | null);

  const columns = [
    ...INVOICES_LIST_TABLE_COLUMNS,
    {
      dataField: "actions",
      isDummyField: true,
      text: "Actions",
      formatter: (cellContent: any, row: IInvoice) => {
        return (
          <>
            <TooltipIcon autohide id={`invoice-register-payment-${row.id}`} content="Register invoice">
              <Button
                color="primary"
                size="sm"
                id={`invoice-register-payment-${row.id}`}
                type="button"
                onClick={async () => {
                  setSelectedInvoice(row);
                  setShowRegisterPaymentModal(true);
                }}
              >
                <i className="bx bx-file" />
              </Button>
            </TooltipIcon>

            <TooltipIcon autohide id={`invoice-make-credit-invoice-${row.id}`} content="Make credit invoice">
              <Button
                id={`invoice-make-credit-invoice-${row.id}`}
                color="primary"
                size="sm"
                className="ms-1"
                type="button"
                onClick={async () => {
                  setSelectedInvoice(row);
                  setShowMakeCreditInvoiceModal(true);
                }}
              >
                <i className="bx bxs-dollar-circle" />
              </Button>
            </TooltipIcon>

            <Button
              color="primary"
              size="sm"
              className="ms-1"
              type="button"
              onClick={async () => {
                setLoader(true);
                try {
                  await backofficeService.getInvoicePdf(row.id, row.invoice_number);
                } catch (e) {
                  toast.error(getErrorMessage(e));
                } finally {
                  setLoader(false);
                }
              }}
            >
              Download <i className="bx bxs-file-pdf" />
            </Button>
          </>
        );
      },
      sort: false
    }
  ];

  const fetchList = async (
    selectedPage: number,
    _dates: Date[],
    _filters: Record<string, string>,
    _sort: Record<string, string>
  ) => {
    setLoader(true);
    try {
      const res = await backofficeService.getInvoiceList(selectedPage, 10, _dates, _filters, _sort);

      setItems(res.items);
      setPaginationMeta(res.meta);
    } catch (e) {
      console.error(e);
    } finally {
      setLoader(false);
    }
  };

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc"
    }
  ] as any;

  const pageOptions = {
    sizePerPage: 20,
    page,
    totalSize: paginationMeta?.total || 0,
    custom: true
  };

  const onTableChange = (event: string, data: TableChangeState<any>) => {
    switch (event) {
      case "pagination":
        setPage(data.page);
        break;
      case "filter":
        setFilters(
          Object.fromEntries(Object.entries(data.filters).map(([column, value]) => [column, value.filterVal]))
        );
        break;
      case "sort":
        setSortSettings({
          [data.sortField]: data.sortOrder
        });
        break;
      default:
        break;
    }
  };

  const handleExcelExport = async (forAll = false) => {
    if (!forAll && !selectedData.length) return;

    try {
      setLoader(true);

      if (forAll) {
        await backofficeService.exportInvoices(filterDates);
      } else {
        await backofficeService.exportSpecificInvoices(selectedData.map((e) => e.id));
      }
    } catch (e) {
      toast.error(await getErrorMessage(e));
    } finally {
      setLoader(false);
    }
  };

  const handlePdfExport = async () => {
    if (!selectedData.length) return;

    try {
      setLoader(true);

      await backofficeService.exportSpecificInvoicesToPdf(selectedData.map((e) => e.id));
    } catch (e) {
      toast.error(await getErrorMessage(e));
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchList(page, filterDates, filters, sortSettings).then(() => null);
  }, [page, filters, sortSettings, invoiceCreationIndex, filterDates]);

  return (
    <div className="data-table">
      <Card className="border">
        {loader && <Loader />}

        <RegisterPaymentModal
          show={showRegisterPaymentModal}
          onSubmit={() => {
            setSelectedInvoice(null);
            fetchList(page, filterDates, filters, sortSettings).then(() => null);
          }}
          toggle={() => {
            setShowRegisterPaymentModal(false);
            setSelectedInvoice(null);
          }}
          selectedData={selectedInvoice}
        />

        <MakeCreditInvoiceModal
          show={showMakeCreditInvoiceModal}
          onSubmit={() => {
            setSelectedInvoice(null);
            fetchList(page, filterDates, filters, sortSettings).then(() => null);
          }}
          toggle={() => {
            setShowMakeCreditInvoiceModal(false);
            setSelectedInvoice(null);
          }}
          selectedData={selectedInvoice}
        />

        <CardBody>
          <Row>
            <Col xl={3}>
              <DatePickerField
                label="Filter by date"
                value={filterDates}
                options={{
                  mode: "range"
                }}
                onChange={(dates: Date[]) => {
                  if (dates.length !== 2) return;

                  setFilterDates(dates);
                }}
              />
            </Col>
            <Col xl="auto" className="d-flex">
              <div className="d-inline-flex align-items-center me-3">
                <Button
                  color="primary"
                  // disabled={!!selectedData.length}
                  onClick={() => {
                    setFilterDates([]);
                    CLEAR_INVOICES_LIST_TABLE_FILTER_VALUES();
                  }}
                >
                  Clear filters
                </Button>
              </div>

              <div className="d-inline-flex align-items-center me-3">
                <Button
                  color="primary"
                  // disabled={!!selectedData.length}
                  onClick={() => {
                    handleExcelExport(true);
                  }}
                >
                  Export to excel
                </Button>
              </div>

              <div className="d-inline-flex align-items-center me-3">
                <Button
                  color="primary"
                  disabled={!selectedData.length}
                  onClick={() => {
                    handleExcelExport(false);
                  }}
                >
                  Export selected to excel
                </Button>
              </div>

              <div className="d-inline-flex align-items-center">
                <Button
                  color="primary"
                  disabled={!selectedData.length}
                  onClick={() => {
                    handlePdfExport();
                  }}
                >
                  Export selected invoices to pdf
                </Button>
              </div>
            </Col>
          </Row>

          <h3>Invoices list</h3>
          <PaginationProvider pagination={paginationFactory(pageOptions)}>
            {({ paginationProps, paginationTableProps }: any) => (
              <ToolkitProvider keyField="id" columns={columns} data={items} search>
                {(toolkitProps: any) => {
                  return (
                    <>
                      <Row className="mb-2">
                        <Col md="12" className="mb-2">
                          Selected orders length: {selectedData.length}
                        </Col>
                      </Row>

                      <Row>
                        <Col xl="12">
                          <div className="table-responsive">
                            <BootstrapTable
                              bootstrap4
                              remote
                              onTableChange={onTableChange}
                              bordered={false}
                              selectRow={selectRowData}
                              striped={false}
                              defaultSorted={defaultSorted}
                              filter={filterFactory()}
                              classes="table align-middle table-nowrap table-sm"
                              headerWrapperClasses="thead-light"
                              {...toolkitProps.baseProps}
                              {...paginationTableProps}
                              data={items}
                              keyField="id"
                            />
                          </div>
                        </Col>
                      </Row>

                      <Row className="align-items-md-center mt-30">
                        <Col className="inner-custom-pagination d-flex">
                          <div className="text-md-right ms-auto">
                            <PaginationListStandalone {...paginationProps} />
                          </div>
                        </Col>
                      </Row>
                    </>
                  );
                }}
              </ToolkitProvider>
            )}
          </PaginationProvider>
        </CardBody>
      </Card>
    </div>
  );
};
