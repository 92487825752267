import React, { useCallback, useEffect, useMemo, useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Alert, Button, CardBody, Modal } from "reactstrap";
import { object, string } from "yup";

import { IPossibleDeliveryService } from "../../../common/interfaces/order-wizard.interface";
import { IOrderItem, OrderStatusMap } from "../../../common/interfaces/orders.interface";
import { getErrorMessage } from "../../../common/utils/formatter.util";
import { useServiceContainer } from "../../../hooks/useServiceContainer";
import { Loader } from "../../common/Loader";
import { SelectField } from "../../common/SelectField";

const validationSchema = object().shape({
  newDeliveryService: string().required("This field is required")
});

interface Props {
  show: boolean;
  order: IOrderItem;
  onUpdated: () => void;
  toggle: () => void;
}

export const UpdateOrderDeliveryServiceModal: React.FC<Props> = ({ onUpdated, order, show, toggle }) => {
  const {
    formState: { errors },
    handleSubmit,
    setValue,
    reset,
    watch,
    setError
  } = useForm({
    defaultValues: {
      newDeliveryService: ""
    },
    resolver: yupResolver(validationSchema)
  });

  const newDeliveryService = watch("newDeliveryService");

  const [possibleDeliveryServices, setPossibleDeliveryServices] = useState<IPossibleDeliveryService[]>([]);

  const [loader, setLoader] = useState(true);

  const { backofficeService } = useServiceContainer();

  const possibleDeliveryServiceOptions = useMemo(() => {
    return possibleDeliveryServices.map((deliveryService) => ({
      label: `${deliveryService.name} | €${deliveryService.price}`,
      value: deliveryService.id.toString()
    }));
  }, [possibleDeliveryServices]);

  const selectedDeliveryService = useMemo(() => {
    if (!newDeliveryService) return null;
    return possibleDeliveryServiceOptions.find((option) => option.value === newDeliveryService);
  }, [newDeliveryService, possibleDeliveryServiceOptions]);

  const loadPossibleDeliveryServices = useCallback(async () => {
    try {
      setLoader(true);

      const res = await backofficeService.getOrderPossibleDeliveryServices(order.id);

      setPossibleDeliveryServices(res);
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setLoader(false);
    }
  }, []);

  const onSubmitHandler = async (data: { newDeliveryService: string }) => {
    try {
      await backofficeService.updateOrderDeliveryService(order.id, +data.newDeliveryService);
      toggle();
      onUpdated();

      toast.success("Order delivery service updated successfully");
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    reset();
    loadPossibleDeliveryServices().then(() => null);
  }, []);

  return (
    <Modal isOpen={show} toggle={toggle} centered size="md">
      {loader && <Loader />}
      <div className="modal-header">
        <h5 className="modal-title mt-0">Update order delivery service</h5>
        <button
          type="button"
          onClick={() => {
            toggle();
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form onSubmit={handleSubmit(onSubmitHandler)}>
        <div className="modal-body">
          <div className="row">
            <div className="col-12">
              <div className="card border">
                <CardBody>
                  <SelectField
                    options={possibleDeliveryServiceOptions}
                    label="New delivery service"
                    error={errors.newDeliveryService?.message}
                    value={selectedDeliveryService}
                    placeholder="Search.."
                    onChange={(data) => {
                      setValue("newDeliveryService", data.value);
                      setError("newDeliveryService", {
                        type: "manual",
                        message: ""
                      });
                    }}
                  />
                </CardBody>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <Button
            color="danger"
            onClick={() => {
              toggle();
            }}
          >
            Cancel
          </Button>
          <Button color="primary" type="submit">
            Save
          </Button>
        </div>
      </form>
    </Modal>
  );
};
