import React from "react";

import classNames from "classnames";
import moment from "moment/moment";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Comparator, dateFilter, selectFilter, textFilter } from "react-bootstrap-table2-filter";

import { OrderTrackingNumberSetter } from "../../components/backoffice/common/OrderTrackingNumberSetter";
import { TooltipIcon } from "../../components/common/TooltipIcon";
import { ICountryItem } from "../interfaces/common.interface";
import {
  IOrderTableItem,
  IOrderTableItemChildren,
  ORDER_STATUSES,
  OrderProductAsset,
  OrderStatus
} from "../interfaces/orders.interface";
import { IDesignItem, IProductItem } from "../interfaces/products.interface";
import { formatProductType, getImageUrl, getOrderStatusColor } from "../utils/formatter.util";

export const ORDER_HISTORY_FILTER_INSTANCES: Record<string, any> = {
  id: null,
  creation_date: null,
  status: null,
  clientName: null,
  tracking_number: null,
  awb_number: null,
  product: null,
  product_asset: null,
  premium_bag: null,
  country: null,
  is_paid: null,
  unread_comment_count: null
};

export const CLEAR_ORDER_HISTORY_FILTER_VALUES = () => {
  for (const key in ORDER_HISTORY_FILTER_INSTANCES) {
    if (ORDER_HISTORY_FILTER_INSTANCES[key] && typeof ORDER_HISTORY_FILTER_INSTANCES[key] === "function") {
      if (key === "creation_date") {
        ORDER_HISTORY_FILTER_INSTANCES[key]({
          comparator: Comparator.EQ,
          date: ""
        });
      } else {
        ORDER_HISTORY_FILTER_INSTANCES[key]("");
      }
    }
  }
};

export const ORDER_HISTORY_COLUMNS = (defaultFilterValues?: Record<string, string>, isBackoffice = false) => {
  return [
    {
      dataField: "id",
      text: "Id",
      filter: textFilter({
        caseSensitive: true,
        placeholder: " ",
        className: "form-control-sm mt-1",
        defaultValue: defaultFilterValues?.id,
        getFilter: (filter) => {
          ORDER_HISTORY_FILTER_INSTANCES.id = filter;
        }
      }),
      sort: true
    },
    {
      dataField: "creation_date",
      text: "Date",
      // filter: dateFilter({
      //   comparators: undefined,
      //   comparatorClassName: "d-none",
      //   dateClassName: "form-control-sm mt-1",
      //   getFilter: (filter) => {
      //     ORDER_HISTORY_FILTER_INSTANCES.creation_date = filter;
      //   },
      //   ...(defaultFilterValues?.creation_date
      //     ? {
      //         defaultValue: {
      //           comparator: Comparator.EQ,
      //           date: moment(defaultFilterValues.creation_date as string, "YYYY-MM-DD")
      //             .endOf("day")
      //             .toDate()
      //         }
      //       }
      //     : {})
      // }),
      sort: true,
      formatter: (cellContent: string) => {
        return moment(cellContent).format("DD.MM.YYYY");
      }
    },
    {
      dataField: "status",
      isDummyField: true,
      text: "Status",
      filter: selectFilter({
        options: ORDER_STATUSES,
        placeholder: " ",
        defaultValue: defaultFilterValues?.status,
        className: "form-control-sm mt-1",
        getFilter: (filter) => {
          ORDER_HISTORY_FILTER_INSTANCES.status = filter;
        }
      }),
      sort: true,
      formatter: (cellContent: any, row: IOrderTableItem) => {
        if (row.children.length !== 1) return "";

        const { status } = row.children[0];
        return (
          <span className={classNames("badge", `bg-${getOrderStatusColor(status)}`)}>{ORDER_STATUSES[status]}</span>
        );
      }
    },
    ...(isBackoffice
      ? [
          {
            dataField: "userName",
            text: "Client name",
            filter: textFilter({
              caseSensitive: true,
              placeholder: " ",
              className: "form-control-sm mt-1",
              defaultValue: defaultFilterValues?.userName,
              getFilter: (filter) => {
                ORDER_HISTORY_FILTER_INSTANCES.userName = filter;
              }
            }),
            sort: true,
            formatter: (cellContent: string) => {
              return (
                <span
                  style={{
                    wordBreak: "break-word",
                    maxWidth: "200px",
                    whiteSpace: "normal"
                  }}
                >
                  {cellContent}
                </span>
              );
            }
          }
        ]
      : []),
    {
      dataField: "clientName",
      text: "Full name",
      filter: textFilter({
        caseSensitive: true,
        placeholder: " ",
        className: "form-control-sm mt-1",
        defaultValue: defaultFilterValues?.clientName,
        getFilter: (filter) => {
          ORDER_HISTORY_FILTER_INSTANCES.clientName = filter;
        }
      }),
      sort: true,
      formatter: (cellContent: string) => {
        return (
          <span
            style={{
              wordBreak: "break-word",
              maxWidth: "200px",
              whiteSpace: "normal"
            }}
          >
            {cellContent}
          </span>
        );
      }
    },
    {
      dataField: "tracking_number",
      text: "Tracking number",
      filter: textFilter({
        caseSensitive: true,
        placeholder: " ",
        className: "form-control-sm mt-1",
        defaultValue: defaultFilterValues?.tracking_number,
        getFilter: (filter) => {
          ORDER_HISTORY_FILTER_INSTANCES.tracking_number = filter;
        }
      }),
      sort: false,
      formatter: (cellContent: string, row: IOrderTableItem) => {
        if (cellContent) {
          return row.tracking_url ? (
            <a href={row.tracking_url} target="_blank" rel="noreferrer">
              {cellContent}
            </a>
          ) : (
            cellContent
          );
        }

        if (isBackoffice && !row.delivery_data.deliveryServiceIsApi) {
          const { id } = row.children[0];
          return <OrderTrackingNumberSetter orderId={id} />;
        }

        if (!isBackoffice && row.delivery_data.deliveryInfo) {
          return <TooltipIcon id={`delivery-info-tt-${row.id}`} content={row.delivery_data.deliveryInfo} />;
        }

        return "-";
      }
    },
    {
      dataField: "awb_number",
      text: "AWB number",
      filter: textFilter({
        caseSensitive: true,
        placeholder: " ",
        className: "form-control-sm mt-1",
        defaultValue: defaultFilterValues?.awb_number,
        getFilter: (filter) => {
          ORDER_HISTORY_FILTER_INSTANCES.awb_number = filter;
        }
      }),
      sort: false,
      formatter: (cellContent: string) => {
        return cellContent || "-";
      }
    },
    {
      dataField: "product",
      isDummyField: true,
      text: "Product",
      filter: textFilter({
        caseSensitive: true,
        placeholder: " ",
        className: "form-control-sm mt-1",
        defaultValue: defaultFilterValues?.product,
        getFilter: (filter) => {
          ORDER_HISTORY_FILTER_INSTANCES.product = filter;
        }
      }),
      sort: true,
      formatter: (cellContent: any, row: IOrderTableItem) => {
        if (row.children.length !== 1) return "";

        const { product } = row.children[0];
        return product.title;
        // return `${formatProductType(product.type) || product.type}`;
      }
    },
    {
      dataField: "product_asset",
      isDummyField: true,
      text: "Asset type",
      filter: textFilter({
        caseSensitive: true,
        placeholder: " ",
        className: "form-control-sm mt-1",
        defaultValue: defaultFilterValues?.product_asset,
        getFilter: (filter) => {
          ORDER_HISTORY_FILTER_INSTANCES.product_asset = filter;
        }
      }),
      sort: true,
      formatter: (cellContent: any, row: IOrderTableItem) => {
        if (row.children.length !== 1) return "";

        const { product_asset: productAsset, count, id } = row.children[0];
        return (
          <div
            className={classNames("d-flex", {
              "me-2": count > 1
            })}
          >
            <span style={{ marginTop: "2px" }}>{productAsset.label}</span>{" "}
            {count > 1 && (
              <TooltipIcon id={`count-tt-${id.replace("/", "-")}`} content="Multiple same product">
                <span id={`count-tt-${id.replace("/", "-")}`} className="badge bg-primary ms-1">
                  x{count}
                </span>
              </TooltipIcon>
            )}
          </div>
        );
      }
    },
    {
      dataField: "gift_message",
      isDummyField: true,
      text: "GM",
      formatter: (cellContent: any, row: IOrderTableItem) => {
        if (row.children.length !== 1) return "";

        const { gift_message: giftMessage } = row.children[0];
        return (
          <span
            className={classNames("badge", {
              "bg-success": giftMessage,
              "bg-danger": !giftMessage
            })}
          >
            {giftMessage ? "Yes" : "No"}
          </span>
        );
      }
    },
    {
      dataField: "premium_bag",
      isDummyField: true,
      text: "PB",
      filter: selectFilter({
        options: {
          true: "Yes",
          false: "No"
        },
        placeholder: " ",
        defaultValue: defaultFilterValues?.premium_bag,
        className: "form-control-sm mt-1",
        getFilter: (filter) => {
          ORDER_HISTORY_FILTER_INSTANCES.premium_bag = filter;
        }
      }),
      sort: true,
      formatter: (cellContent: any, row: IOrderTableItem) => {
        if (row.children.length !== 1) return "";

        const { premium_bag: premiumBag } = row.children[0];
        return (
          <span
            className={classNames("badge", {
              "bg-success": premiumBag,
              "bg-danger": !premiumBag
            })}
          >
            {premiumBag ? "Yes" : "No"}
          </span>
        );
      }
    },
    {
      dataField: "country",
      text: "Country",
      filter: textFilter({
        caseSensitive: true,
        placeholder: " ",
        className: "form-control-sm mt-1",
        defaultValue: defaultFilterValues?.country,
        getFilter: (filter) => {
          ORDER_HISTORY_FILTER_INSTANCES.country = filter;
        }
      }),
      sort: true,
      formatter: (cellContent: ICountryItem) => {
        return cellContent.label;
      }
    },
    {
      dataField: "is_paid",
      text: "Paid",
      filter: selectFilter({
        options: {
          1: "Yes",
          0: "No"
        },
        placeholder: " ",
        defaultValue: defaultFilterValues?.is_paid,
        className: "form-control-sm mt-1",
        getFilter: (filter) => {
          ORDER_HISTORY_FILTER_INSTANCES.is_paid = filter;
        }
      }),
      sort: true,
      formatter: (cellContent: number) => {
        const isPaid = cellContent === 1 || cellContent === 3;
        return (
          <span
            className={classNames("badge", {
              "bg-success": isPaid,
              "bg-danger": !isPaid
            })}
          >
            {isPaid ? "Yes" : "No"}
          </span>
        );
      }
    },
    {
      dataField: "unread_comment_count",
      text: "CM",
      sort: true,
      filter: selectFilter({
        options: {
          true: "Yes",
          false: "No"
        },
        placeholder: " ",
        defaultValue: defaultFilterValues?.premium_bag,
        className: "form-control-sm mt-1",
        getFilter: (filter) => {
          ORDER_HISTORY_FILTER_INSTANCES.unread_comment_count = filter;
        }
      }),
      formatter: (cellContent: number, row: IOrderTableItem) => {
        const hasComment = cellContent > 0;
        const isGroup = row.children.length > 1;
        const lastComment = row.children[0].comment[row.children[0].comment.length - 1];
        return !isGroup ? (
          hasComment && lastComment ? (
            <OverlayTrigger
              placement="right"
              overlay={
                <Tooltip id={`comment_${lastComment.id}`} className="hide-arrow">
                  {(lastComment as any).user_name}: {lastComment.text}
                </Tooltip>
              }
            >
              <span className={classNames("badge", "bg-success")}>Yes</span>
            </OverlayTrigger>
          ) : (
            <span className={classNames("badge", "bg-danger")}>No</span>
          )
        ) : (
          <span
            className={classNames("badge", {
              "bg-success": hasComment,
              "bg-danger": !hasComment
            })}
          >
            {hasComment ? "Yes" : "No"}
          </span>
        );
      }
    },
    {
      dataField: "print_file",
      isDummyField: true,
      text: "Print file",
      formatter: (cellContent: any, row: IOrderTableItem) => {
        if (row.children.length !== 1) return "";

        const { design } = row.children[0];
        return (
          <a href={getImageUrl(design.print_file)} target="_blank" rel="noreferrer" className="text-decoration-none">
            <img src={getImageUrl(design.print_file)} alt="Print file" />
          </a>
        );
      },
      sort: false
    }
  ];
};

export const ORDER_HISTORY_COLUMNS_CHILDREN = () => {
  return [
    {
      dataField: "id",
      text: "Id"
    },
    {
      dataField: "status",
      text: "Status",
      formatter: (cellContent: OrderStatus) => {
        return (
          <span className={classNames("badge", `bg-${getOrderStatusColor(cellContent)}`)}>
            {ORDER_STATUSES[cellContent]}
          </span>
        );
      }
    },
    {
      dataField: "product",
      text: "Product",
      formatter: (cellContent: IProductItem) => {
        return `[${cellContent.title}] ${formatProductType(cellContent.type) || cellContent.type}`;
      }
    },
    {
      dataField: "product_asset",
      text: "Asset type",
      formatter: (cellContent: OrderProductAsset, row: IOrderTableItemChildren) => {
        return (
          <div
            className={classNames("d-flex", {
              "me-2": row.count > 1
            })}
          >
            <span style={{ marginTop: "2px" }}>{cellContent.label}</span>{" "}
            {row.count > 1 && (
              <TooltipIcon id={`count-tt-${row.id.replace("/", "-")}`} content="Multiple same product">
                <span id={`count-tt-${row.id.replace("/", "-")}`} className="badge bg-primary ms-1">
                  x{row.count}
                </span>
              </TooltipIcon>
            )}
          </div>
        );
      }
    },
    {
      dataField: "gift_message",
      text: "Gift message",
      style: {
        maxWidth: "100px"
      },
      formatter: (cellContent: string | null) => {
        return (
          <span
            className={classNames("badge", {
              "bg-success": cellContent,
              "bg-danger": !cellContent
            })}
          >
            {cellContent ? "Yes" : "No"}
          </span>
        );
      }
    },
    {
      dataField: "premium_bag",
      text: "Premium bag",
      style: {
        maxWidth: "100px"
      },
      formatter: (cellContent: boolean) => {
        return (
          <span
            className={classNames("badge", {
              "bg-success": cellContent,
              "bg-danger": !cellContent
            })}
          >
            {cellContent ? "Yes" : "No"}
          </span>
        );
      }
    },
    {
      dataField: "unread_comment_count",
      text: "Comments",
      isDummyField: true,
      formatter: (cellContent: any, row: IOrderTableItemChildren) => {
        const hasComment = row.comment.length > 0;
        const lastComment = row.comment[row.comment.length - 1];
        return hasComment && lastComment ? (
          <OverlayTrigger
            placement="right"
            overlay={
              <Tooltip id={`comment_${lastComment.id}`} className="hide-arrow">
                {(lastComment as any).user_name}: {lastComment.text}
              </Tooltip>
            }
          >
            <span className={classNames("badge", "bg-success")}>Yes</span>
          </OverlayTrigger>
        ) : (
          <span className={classNames("badge", "bg-danger")}>No</span>
        );
      }
    },
    {
      dataField: "design",
      text: "Print file",
      formatter: (
        cellContent: Pick<IDesignItem, "id" | "title"> & {
          print_file: string;
        }
      ) => {
        return (
          <a
            href={getImageUrl(cellContent.print_file)}
            target="_blank"
            rel="noreferrer"
            className="text-decoration-none"
          >
            <img src={getImageUrl(cellContent.print_file)} alt="Print file" />
          </a>
        );
      }
    }
  ];
};
