import React from "react";

const SERVICES = [
  "We offer the best prices for products on the market.",
  "Experience fast processing times.",
  "Enjoy a free product mockup generator.",
  "Access complimentary courses designed for Etsy sellers.",
  "Plus, a whole bunch of other useful goodies!"
];

export const AuthHeroBlock = () => {
  return (
    <div className="auth-hero">
      <div className="auth-hero__description">
        <h2>Welcome!</h2>
        <h3>We&apos;re thrilled to have you here!</h3>
      </div>

      <div className="auth-hero__list">
        <h4 className="auth-hero__list__title">
          Here&apos;s why other POD sellers love <br /> our services:
        </h4>
        <ul>
          {SERVICES.map((e, idx) => (
            <li key={idx}>
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                <circle id="Ellipse_1" data-name="Ellipse 1" cx="9" cy="9" r="9" fill="#ffcd8b" />
                <g id="Group_20" data-name="Group 20" transform="translate(6.115 7.334)">
                  <line
                    id="Line_8"
                    data-name="Line 8"
                    y2="3.325"
                    transform="translate(0 0.784) rotate(-45)"
                    fill="none"
                    stroke="#425563"
                    strokeLinecap="round"
                    strokeWidth="2"
                  />
                  <line
                    id="Line_9"
                    data-name="Line 9"
                    y2="4.433"
                    transform="translate(5.486) rotate(45)"
                    fill="none"
                    stroke="#425563"
                    strokeLinecap="round"
                    strokeWidth="2"
                  />
                </g>
              </svg>

              {e}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
