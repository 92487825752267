/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { FieldValues, useForm, UseFormProps, UseFormReturn } from "react-hook-form";
import * as Yup from "yup";
import { AnyObject, ISchema, Maybe } from "yup";

type WidenSchema<T> = T extends (...args: any[]) => any
  ? T
  : { [K in keyof T]: T[K] extends Record<string, unknown> ? WidenSchema<T[K]> : any };

type Shape<T extends Maybe<AnyObject>, C = any> = {
  [field in keyof T]-?: ISchema<T[field], C> | any;
};

export type UseCustomFormSchema<TFieldValues> = Yup.ObjectSchema<
  Shape<object | undefined, Partial<WidenSchema<TFieldValues>>>,
  object
>;

export type UseCustomFormReturn<TFieldValues extends FieldValues = FieldValues, TContext = any> = UseFormReturn<
  TFieldValues,
  TContext
> & {
  updateSchema: (newSchema: UseCustomFormSchema<TFieldValues>) => void;
};

/**
 * A custom useForm hook that allows dynamic validation schemas
 * @example
 * // Let's say we have a form with fields described by the following interface
 * interface FormValues {
 *  name: string
 *  age: number
 * }
 *
 * // And we setup the form with the following schema (only validating the name)
 * const { updateSchema, ...otherMethods } = useCustomForm<FormValues>({
 *  schema: Yup.object().shape({})
 * })
 *
 * // We can then update the schema to additionally validate the age field by calling
 * // the updateSchema method somewhere in the application
 * updateSchema(Yup.object().shape({ age: Yup.number().required() }))
 *
 * // The final validation schema will be
 * Yup.object().shape({
 *   name: Yup.string().required(),
 *   age: Yup.number().required()
 * })
 */
export const useCustomForm = <TFieldValues extends FieldValues = FieldValues, TContext = any>(
  props?: Omit<UseFormProps<TFieldValues, TContext>, "resolver"> & {
    schema?: UseCustomFormSchema<TFieldValues>;
  }
): UseCustomFormReturn<TFieldValues> => {
  const [schema, setSchema] = useState<UseCustomFormSchema<TFieldValues> | null>(props?.schema ?? null);

  const updateSchema = useCallback((newSchema: UseCustomFormSchema<TFieldValues>) => {
    setSchema((oldSchema) => {
      if (oldSchema) {
        return Yup.object().shape({
          // ...oldSchema.fields,
          ...newSchema.fields
        }) as UseCustomFormSchema<TFieldValues>;
      }
      return newSchema;
    });
  }, []);

  const form = useForm({
    ...props,
    resolver: schema ? yupResolver<any>(schema) : undefined
  });

  return {
    ...form,
    updateSchema
  };
};
