import React from "react";

import { ProductCreationWizard } from "../../components/wizards/product/ProductCreationWizard";

export const CreateProductPage = () => {
  return (
    <>
      <ProductCreationWizard />
    </>
  );
};
