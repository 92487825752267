import React, { useCallback, useEffect, useMemo, useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Alert, Button, CardBody, Modal } from "reactstrap";
import { object, string } from "yup";

import { OrderStatusMap } from "../../../common/interfaces/orders.interface";
import { getErrorMessage } from "../../../common/utils/formatter.util";
import { useServiceContainer } from "../../../hooks/useServiceContainer";
import { Loader } from "../../common/Loader";
import { SelectField } from "../../common/SelectField";

const validationSchema = object().shape({
  newStatus: string().required("This field is required")
});

interface Props {
  show: boolean;
  currentStatus?: string;
  onUpdated: (newStatus: string) => void;
  orderIds: string[];
  toggle: () => void;
}

export const UpdateOrderStatusModal: React.FC<Props> = ({ onUpdated, currentStatus, orderIds, show, toggle }) => {
  const {
    formState: { errors },
    handleSubmit,
    setValue,
    reset,
    watch,
    setError
  } = useForm({
    defaultValues: {
      newStatus: ""
    },
    resolver: yupResolver(validationSchema)
  });

  const newStatus = watch("newStatus");

  const [loader, setLoader] = useState(true);

  const [statusMap, setStatusMap] = useState<OrderStatusMap | null>(null);

  const { backofficeService } = useServiceContainer();

  const loadStatusMap = useCallback(async () => {
    try {
      if (statusMap) return;
      setLoader(true);

      const res = await backofficeService.getOrderChangeStatusMap();

      setStatusMap(res);
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setLoader(false);
    }
  }, [statusMap]);

  const canChangeStatus = useMemo(() => {
    if (!statusMap) return false;
    if (!currentStatus) return false;

    const currentStatusObj = statusMap[currentStatus];
    if (!currentStatusObj) return false;

    return true;
  }, [statusMap, currentStatus]);

  const statusOptions = useMemo(() => {
    if (!canChangeStatus) return [];

    return statusMap![currentStatus!].map((status) => ({
      label: status,
      value: status
    }));
  }, [statusMap, canChangeStatus, currentStatus]);

  const selectedStatus = useMemo(() => {
    if (!newStatus) return null;
    return statusOptions.find((option) => option.value === newStatus);
  }, [newStatus, statusOptions]);

  const onSubmitHandler = async (data: { newStatus: string }) => {
    try {
      if (!orderIds) return;
      setLoader(true);
      await backofficeService.updateOrdersStatus(data.newStatus, orderIds);
      toggle();
      onUpdated(data.newStatus);
      reset();

      toast.success("Orders status updated successfully");
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    reset();
    loadStatusMap().then(() => null);
  }, []);

  return (
    <Modal isOpen={show} toggle={toggle} centered size="md">
      {loader && <Loader />}
      <div className="modal-header">
        <h5 className="modal-title mt-0">Update order status</h5>
        <button
          type="button"
          onClick={() => {
            toggle();
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form onSubmit={handleSubmit(onSubmitHandler)}>
        <div className="modal-body">
          {canChangeStatus ? (
            <div className="row">
              <div className="col-12">
                <div className="card border">
                  <CardBody>
                    <SelectField
                      options={statusOptions}
                      label="New status"
                      error={errors.newStatus?.message}
                      value={selectedStatus}
                      placeholder="Search.."
                      onChange={(data) => {
                        setValue("newStatus", data.value);
                        setError("newStatus", {
                          type: "manual",
                          message: ""
                        });
                      }}
                    />
                  </CardBody>
                </div>
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col-12">
                <Alert color="warning" className="text-center">
                  {`You can't change status from ${currentStatus}`}
                </Alert>
              </div>
            </div>
          )}
        </div>
        <div className="modal-footer">
          <Button
            color="danger"
            onClick={() => {
              toggle();
            }}
          >
            Cancel
          </Button>
          <Button color="primary" type="submit">
            Save
          </Button>
        </div>
      </form>
    </Modal>
  );
};
