export interface Address {
  name: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  zipCode: string;
  country: string;
}

const parseAddress = (str: string): Address => {
  const lines = str.split("\n");

  const name = lines[0]; // always first line
  const addressLine1 = lines[1]; // always second line
  const addressLine2 = lines.length > 4 ? lines[2] : ""; // always third line if more than 4 lines
  const country = lines[lines.length - 1]; // always last line
  const cityAndZip = lines[lines.length - 2]; // always second to last line
  const [city, zip] = parseZipAndCity(cityAndZip);

  return {
    name,
    addressLine1,
    addressLine2,
    city: city ?? "",
    zipCode: zip ?? "",
    country
  };
};

// SHEPPARTON VIC 3630
// AUCKLAND 1024
// PASSAIC, NJ 07055

const parseZipAndCity = (str: string) => {
  // PASSAIC, NJ 07055
  if (str.includes(",")) {
    const [cityPart, zipPart] = str.split(",").map((part) => part.trim());
    return [cityPart, zipPart];
  }

  const parts = str.split(" ");
  const numberPart = parts.find((part) => !Number.isNaN(+part));

  if (parts.length === 0 || parts.length === 1 || !numberPart) {
    return [];
  }

  if (parts.length === 2) {
    const zipCodePart = parts.find((part) => !Number.isNaN(+part));
    if (zipCodePart) {
      return [str.replace(zipCodePart, "").trim(), zipCodePart];
    }
  }

  const numberPartIndex = parts.findIndex((part) => !Number.isNaN(+part));
  const numberPartPrefix = parts[numberPartIndex - 1];
  const numberPartPostfix = parts[numberPartIndex + 1];

  const isZipValid = (zip: string) => zip.length >= 2 && zip.length <= 4;

  if (numberPartPrefix && isZipValid(numberPartPrefix)) {
    const zip = `${numberPartPrefix} ${numberPart}`;
    return [str.replace(zip, "").trim(), zip];
  }

  if (numberPartPostfix && isZipValid(numberPartPostfix)) {
    const zip = `${numberPart} ${numberPartPostfix}`;
    return [str.replace(zip, "").trim(), zip];
  }

  return [];
};

export { parseAddress };
