import React from "react";

import classNames from "classnames";

interface Props {
  fullBlind?: boolean;
  fixed?: boolean;
}

export const Loader: React.FC<Props> = ({ fullBlind, fixed }) => {
  return (
    <div
      className={classNames("loader", {
        "loader--full-blind": fullBlind,
        "loader--fixed": fixed
      })}
    >
      <div id="status">
        <div className="spinner-chase">
          <div className="chase-dot" />
          <div className="chase-dot" />
          <div className="chase-dot" />
          <div className="chase-dot" />
          <div className="chase-dot" />
          <div className="chase-dot" />
        </div>
      </div>
    </div>
  );
};
