export const transformHistoryData = (data: any, columnLabels: Record<string, string>) => {
  if (!data || data === "null") return [];

  try {
    const parsedData = typeof data === "string" ? JSON.parse(data) : data;
    const transformedData = Object.keys(parsedData).map((key) => {
      const label = columnLabels[key] || key;
      const value = parsedData[key];
      return [label, value];
    });
    return transformedData as [string, any][];
  } catch (error) {
    console.error(error);
    return [];
  }
};
