import { Node, NodeConfig } from "konva/lib/Node";

import useHotkeyFunc from "./useHotkeyFunc";
import useItem from "./useItem";
import useModal from "./useModal";
import useSelection from "./useSelection";
import useStage from "./useStage";
import useTransformer from "./useTransformer";
import { StageData } from "../../store/canvasImageEditor/currentStageData";

const useTool = (
  stage: ReturnType<typeof useStage>,
  modal: ReturnType<typeof useModal>,
  selectedItems: ReturnType<typeof useSelection>["selectedItems"],
  setSelectedItems: ReturnType<typeof useSelection>["setSelectedItems"],
  transformer: ReturnType<typeof useTransformer>,
  createStageDataObject: (item: Node<NodeConfig>) => StageData,
  onSelectItem: ReturnType<typeof useSelection>["onSelectItem"]
) => {
  const { updateItem } = useItem();
  const hotkeyFunc = useHotkeyFunc();
  const onClickHotkeyButton = () => {
    modal.openModal();
  };

  const getClickCallback = (id: string) => () => {
    switch (id) {
      case "select-all":
        return hotkeyFunc.selectAll(stage, onSelectItem);
      case "flip-horizontally":
        return hotkeyFunc.flipHorizontally(selectedItems);
      case "flip-vertically":
        return hotkeyFunc.flipVertically(selectedItems);
      case "layer-up":
        return hotkeyFunc.layerUp(selectedItems);
      case "layer-down":
        return hotkeyFunc.layerDown(selectedItems);
      case "delete-selected":
        return hotkeyFunc.deleteItems(selectedItems, setSelectedItems, transformer.transformerRef);
      case "zoom-in":
        return hotkeyFunc.zoom(stage, 1);
      case "zoom-out":
        return hotkeyFunc.zoom(stage, -1);
      case "reset-zoom":
        return hotkeyFunc.resetZoom(stage);
      case "hotkey":
        return onClickHotkeyButton();
      default:
        return null;
    }
  };

  return {
    onClickHotkeyButton,
    getClickCallback
  };
};

export default useTool;
