import { createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit";

import type { OverrideItemData } from "../../canvasImageEditor/hook/useItem";
import type { RootState } from "../store";

export const STAGE_PREFIX = "STAGE";

export type StageData = {
  id: string;
  attrs: OverrideItemData<any>;
  shouldBeSelected?: boolean;
  className: string;
  children?: StageData[];
};

export const stageDataEntity = createEntityAdapter<StageData>();

export const stageDataSlice = createSlice({
  name: STAGE_PREFIX,
  initialState: stageDataEntity.setAll(stageDataEntity.getInitialState(), []),
  reducers: {
    addItem(state, action) {
      if (Array.isArray(action.payload)) {
        stageDataEntity.addMany(state, action.payload);
        return;
      }
      stageDataEntity.addOne(state, action.payload);
    },
    updateItem(state, action: PayloadAction<StageData | StageData[]>) {
      if (Array.isArray(action.payload)) {
        action.payload.forEach((item) => {
          delete item.attrs.image;
        });

        stageDataEntity.updateMany(
          state,
          action.payload.map((item) => ({
            id: item.id,
            changes: item.attrs
          }))
        );
        return;
      }

      delete action.payload.attrs.image;

      stageDataEntity.updateOne(state, {
        id: action.payload.id,
        changes: action.payload
      });
    },
    removeItem(state, action) {
      if (Array.isArray(action.payload)) {
        stageDataEntity.removeMany(state, action.payload);
        return;
      }
      stageDataEntity.removeOne(state, action.payload);
    },
    clearItems(state, action) {
      stageDataEntity.removeAll(state);
    }
  }
});

const stageDataReducer = stageDataSlice.reducer;

export const stageDataSelector = stageDataEntity.getSelectors((state: RootState) => state.currentStageData);
export const stageDataAction = stageDataSlice.actions;
export default stageDataReducer;
