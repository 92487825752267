import React, { useEffect, useMemo, useState } from "react";

import { useFormContext } from "react-hook-form";

import { IDeliveryOption } from "../../../../common/interfaces/orders.interface";
import {
  PriceIncreaseType,
  PricingTableEntity,
  PricingTableEntityItem
} from "../../../../common/interfaces/product-wizard.interface";
import { useAppDispatch, useAppSelector } from "../../../../store/store";
import {
  PRODUCT_BULK_SET_PRICING_MAP_VALUE,
  PRODUCT_SET_PRICING_MAP,
  PRODUCT_SET_PRICING_MAP_VALUE
} from "../../../../store/wizards/productSlice";
import { Alert } from "../../../common/Alert";
import { PricingHeader } from "../../../common/ProductWizard/pricing/PricingHeader";
import { PricingTable } from "../../../common/ProductWizard/pricing/PricingTable";

export const ProductPricingStep = () => {
  const selectedListings = useAppSelector((state) => {
    return state.productWizard.selectedListings;
  });

  const priceMap = useAppSelector((state) => {
    return state.productWizard.pricingData.priceMap;
  });

  const selectedProductItem = useAppSelector((state) => {
    return state.productWizard.selectedProductType;
  });

  const dispatch = useAppDispatch();
  const [shouldShowDeliveryPrice, setShouldShowDeliveryPrice] = useState<boolean>(false);
  const [selectedDeliveryOption, setSelectedDeliveryOption] = useState<IDeliveryOption | null>(null);

  const {
    register,
    setValue,
    formState: { errors },
    setError
  } = useFormContext();

  const onBulkSetClick = (type: PriceIncreaseType, value: number) => {
    dispatch(
      PRODUCT_BULK_SET_PRICING_MAP_VALUE({
        type,
        value
      })
    );
  };

  const resetForm = () => {
    const allVariants = selectedListings.map((listing) => ({
      items: listing.groups.flatMap((group) => group.items),
      listingId: listing.id
    }));

    const defaultRetailPrice = selectedProductItem!.default_retail_price;

    dispatch(
      PRODUCT_SET_PRICING_MAP(
        allVariants.map((item) => ({
          items: item.items.map((assetItem) => ({
            assetId: assetItem.id,
            value: defaultRetailPrice ? defaultRetailPrice.toString() : assetItem.price_with_vat.toString()
          })),
          listingId: item.listingId
        }))
      )
    );
  };

  const onResetClick = () => {
    resetForm();
  };

  const handlePriceChange = (listingId: number, item: PricingTableEntityItem, value: string | null) => {
    if (value) {
      setError(`priceMap.${item.asset.id}`, {
        type: "manual",
        message: ""
      });
    }
    dispatch(
      PRODUCT_SET_PRICING_MAP_VALUE({
        listingId,
        assetId: item.asset.id,
        value
      })
    );
  };

  const handleDeliveryChange = (option: IDeliveryOption | null) => {
    setSelectedDeliveryOption(option);
  };

  const pricingTableItems = useMemo(() => {
    const allVariants = selectedListings.map((listing) => ({
      listing,
      items: listing.groups.flatMap((group) =>
        group.items.map((assetItem) => ({
          group,
          asset: assetItem,
          price: priceMap.find((e) => e.listingId === listing.id)?.map[assetItem.id]
        }))
      )
    }));

    return allVariants as PricingTableEntity[];
  }, [selectedListings, priceMap]);

  const itemWeight = useMemo(() => {
    const firstListing = selectedListings[0];
    const firstItem = firstListing.groups[0].items[0];
    return firstItem.weight!;
  }, []);

  useEffect(() => {
    register("priceMap");
  }, [register]);

  useEffect(() => {
    resetForm();
  }, []);

  useEffect(() => {
    setValue("priceMap", Object.fromEntries(priceMap.map((item) => [item.listingId, item.map])));
  }, [priceMap]);

  return (
    <>
      <div className="pricing mt-3">
        <PricingHeader
            showDeliverySelector
            itemWeight={itemWeight}
            selectedProductItem={selectedProductItem!}
            onBulkSetClick={onBulkSetClick}
            onResetClick={onResetClick}
            onDeliveryChange={handleDeliveryChange}
            onHasFreeShippingChange={(v) => {
              setShouldShowDeliveryPrice(v);
            }}
        />
        <p>*All prices are indicated with VAT (21%)</p>
        <PricingTable
            items={pricingTableItems}
            showDeliveryPrice={shouldShowDeliveryPrice}
            deliveryPrice={selectedDeliveryOption?.price_with_vat! ?? undefined}
            onPriceChange={handlePriceChange}
        />
      </div>
    </>
  );
};
