import React, { useMemo } from "react";

import assetPreviewStyles from "../style/asset-preview.module.css";
import { getExternalImageUrl } from "../../common/utils/formatter.util";
import { getSizeWithAspectRatio } from "../util/framesize.util";

interface Props {
  src: string;
  size: {
    width: number;
    height: number;
  }
}


export const AssetPreview: React.FC<Props> = ({src, size}) => {
  const baseUrl = useMemo(() => {
    return process.env.REACT_APP_API_BASE_ENDPOINT;
  }, []);

  const {
    width,
    height
  } = getSizeWithAspectRatio(size.width, size.height);

  return (
    <>
      <div className={assetPreviewStyles.canvasAssetPreviewSizeLabel}>
       <strong> Minimum print file requirements:</strong> <br/>
        W: {size.width}px H: {size.height}px | Aspect Ratio: {(size.height / size.width).toFixed(2)}
      </div>
      <div className={assetPreviewStyles.canvasAssetPreview} style={{
        width: 1000,
        height: 800
      }}>
        <img src={getExternalImageUrl(src, baseUrl)}  style={{
          width: width,
          height: height
        }} />
      </div>
    </>
  );
};
