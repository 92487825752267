import React from "react";

import { Button, Col, Row } from "reactstrap";

import { InputGroup } from "../InputGroup";

export interface SearchBarButtonItem {
  label: string;
  value: any;
}

interface Props {
  searchInput: string;
  selectedButton: SearchBarButtonItem | null;
  buttons: SearchBarButtonItem[];
  onSearch: (search: string) => void;
  onButtonClick: (btn: SearchBarButtonItem) => void;
}

export const SearchBarWithButtons: React.FC<Props> = ({
  selectedButton,
  searchInput,
  buttons,
  onSearch,
  onButtonClick
}) => {
  return (
    <Row className="search-bar-with-buttons">
      <Col xs={6}>
        <InputGroup
          className="wizard-step__search"
          prependAddon={
            <span className="input-group-text bg-white">
              <i className="bx bx-search font-size-18" />
            </span>
          }
          input={(p) => (
            <input
              type="text"
              className="form-control border-start-0"
              placeholder="Search..."
              aria-label="Search"
              value={searchInput}
              onChange={(e) => {
                onSearch(searchInput);
              }}
              {...p}
            />
          )}
        />
      </Col>
      <Col xs={6}>
        <div className="d-flex align-items-center search-bar-with-buttons__buttons">
          {buttons.map((btn, idx) => (
            <Button
              key={idx}
              className="me-2"
              color={selectedButton?.value === btn.value ? "primary" : "secondary"}
              onClick={() => {
                onButtonClick(btn);
              }}
            >
              {btn.label}
            </Button>
          ))}
        </div>
      </Col>
    </Row>
  );
};
