import React, { useEffect, useMemo, useState } from "react";

import { selectOptions } from "@testing-library/user-event/dist/select-options";
import classNames from "classnames";
import { each, isEmpty } from "lodash";
import { useDrop } from "react-dnd";
import { useFormContext } from "react-hook-form";
import { Alert, Button } from "reactstrap";
import { array } from "yup";

import { IDesignItem, IProductItem } from "../../../../common/interfaces/products.interface";
import { getImageUrl } from "../../../../common/utils/formatter.util";
import { useAppDispatch, useAppSelector } from "../../../../store/store";
import {
  PRODUCT_SET_SELECTED_DESIGN,
  PRODUCT_UPDATE_DESIGN_MOCKUP_CONNECTOR_ITEM,
  productWizardSlice
} from "../../../../store/wizards/productSlice";
import { SelectDesignModal } from "../../../modals/select-design/SelectDesignModal";
import { Loader } from "../../Loader";
import { SelectField, SelectOption } from "../../SelectField";

interface ProductDesignConnectorEntityProps {
  design: IDesignItem;
  mockupFiles: SelectOption[];
  value: SelectOption | null;
  onChange: (data: SelectOption) => void;
  onDeleteClick: (item: IDesignItem) => void;
}

interface Props {
  loader: boolean;
}

export const ProductDesignConnectorEntity: React.FC<ProductDesignConnectorEntityProps> = ({
  design,
  mockupFiles,
  value,
  onChange,
  onDeleteClick
}) => {

  const mockupConnector = useAppSelector((state) => state.productWizard.designMockupConnector);
  const [filteredMockupFiles, setFilteredMockupFiles] = useState([]);

  useEffect(() => {
    if(mockupFiles.length>0){
      // @ts-ignore
      setFilteredMockupFiles(mockupFiles);
    }
  }, [mockupFiles]);

  //@ts-ignore
  const hasDuplicates = (arr) => arr.length !== new Set(arr).size;

  useEffect(() => {
    // @ts-ignore
    const arr3 = [];
    // @ts-ignore
    let arr2 = [];
    mockupConnector.forEach((e) => {
      if (e.mockupId) {
        arr3.push(e.mockupId.toString());
      }
    });
    if (arr3.length > 0) {
      // @ts-ignore
      arr2 = mockupFiles.filter((item) => !arr3.includes(item.value));
      // @ts-ignore
      setFilteredMockupFiles(arr2);
    }
  }, [mockupConnector]);

  const {
    formState: { errors },
    setError
  } = useFormContext();

  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: "MOCKUP_FILE",
    drop: () => ({ design }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  }));

  const isActive = canDrop && isOver;
  let opacity = "1";
  if (isActive) {
    opacity = "0.6";
  }

  // @ts-ignore
  return (
    <div
      ref={drop}
      className={classNames("mockup-preview h-100 fit-image")}
      style={{
        opacity
      }}
    >
      <div className="card border h-100">
        <button className="mockup-preview__delete" onClick={() => onDeleteClick(design)} type="button">
          <i className="bx bx-trash-alt" />
        </button>
        <div className="mockup-preview__image">
          <img className="card-img-top img-fluid rounded-top" src={getImageUrl(design.image)} alt={design.title} />
        </div>
        <div className="mockup-preview__text">{design.title}</div>
        <div className="mockup-preview__select">
          <SelectField
            options={filteredMockupFiles}
            value={value}
            error={
              // @ts-ignore
              errors?.designMockupConnector?.[design.id]?.mockupId?.message || errors?.designMockupConnector?.message
            }
            onChange={(data) => {
              // eslint-disable-next-line no-param-reassign
              setError(`designMockupConnector.${design.id}.mockupId`, {
                type: "manual",
                message: ""
              });
              onChange(data);
            }}
          />
          <small>
            <i className="mdi mdi-selection-multiple" /> Select or drop file here
          </small>
        </div>
      </div>
    </div>
  );
};

export const ProductDesignConnector: React.FC<Props> = ({ loader }) => {
  const [showDesignSelector, setShowDesignSelector] = useState(false);
  const selectedDesigns = useAppSelector((state) => state.productWizard.selectedDesigns);
  const selectedProduct = useAppSelector((state) => state.productWizard.selectedProductType as IProductItem);
  const selectedMockupFolder = useAppSelector((state) => state.productWizard.selectedMockupEntity);
  const dispatch = useAppDispatch();
  const data = useAppSelector((state) => state.productWizard.designMockupConnector);

  const mockupFiles = useMemo(() => {
    if (!selectedMockupFolder) return [];

    return selectedMockupFolder.files.map((e) => ({
      value: e.id.toString(),
      label: e.url ? e.url.split("/").pop() : (e.name as string)
    }));
  }, [selectedMockupFolder]);

  const {
    formState: { errors },
    setError
  } = useFormContext();

  const handleChange = (design: IDesignItem, selectedValue: SelectOption) => {
    dispatch(
      PRODUCT_UPDATE_DESIGN_MOCKUP_CONNECTOR_ITEM({
        designId: design.id,
        mockupId: +selectedValue.value
      })
    );
  };

  return (
    <div className="position-relative">
      {loader && <Loader />}
      <Alert>
        Please assign the print file to the mockup image. Click the + button to select a print file, then drag and drop
        the generated mockup image(s) into the selected print file.
      </Alert>
      <div>
        {loader && <Loader />}
        <h6>Print files</h6>
        {/* <div className=" mb-2 ml-2"> */}
        {/*  <Button */}
        {/*    type="button" */}
        {/*    onClick={() => { */}
        {/*      setShowDesignSelector(true); */}
        {/*    }} */}
        {/*    color={errors.selectedDesigns?.message ? "danger" : "primary"} */}
        {/*  > */}
        {/*    Select designs ({selectedDesigns.length}/5) */}
        {/*  </Button> */}
        {/*  {errors.selectedDesigns?.message ? ( */}
        {/*    <div className="invalid-feedback d-block">{errors.selectedDesigns?.message as any}</div> */}
        {/*  ) : ( */}
        {/*    "" */}
        {/*  )} */}
        {/* </div> */}
      </div>
      <div className="row mb-4">
        <div className="col-3 mb-3">
          <div className={classNames("mockup-preview h-100 fit-image")}>
            <div
              className="card border h-100 justify-content-center cursor-pointer"
              role="button"
              onClick={() => {
                setShowDesignSelector(true);
              }}
            >
              <div className="mockup-preview__text">
                <div className="mockup-preview__text__icon">
                  <i className="bx bxs-plus-circle" />
                </div>
                <div>
                  Select designs <br /> ({selectedDesigns.length}/5)
                </div>
                {errors.selectedDesigns?.message ? (
                  <div className="invalid-feedback d-block">{errors.selectedDesigns?.message as any}</div>
                ) : (
                  ""
                )}
                {/* <Button */}
                {/*  type="button" */}
                {/*  onClick={() => { */}
                {/*    setShowDesignSelector(true); */}
                {/*  }} */}
                {/* > */}
                {/*  Select designs <br/> ({selectedDesigns.length}/5) */}
                {/* </Button> */}
              </div>
            </div>
          </div>
        </div>
        {selectedDesigns.map((designItem, idx) => {
          const connector = data?.find((e) => +e.designId === designItem.id);
          return (
            <div className="col-3 mb-3" key={designItem.id}>
              <ProductDesignConnectorEntity
                design={designItem}
                mockupFiles={mockupFiles}
                onDeleteClick={(item) => {
                  // toggle item
                  dispatch(PRODUCT_SET_SELECTED_DESIGN(item));
                }}
                value={mockupFiles.find((e) => e.value === connector?.mockupId?.toString()) ?? null}
                onChange={(selectedValue) => handleChange(designItem, selectedValue)}
              />
            </div>
          );
        })}
      </div>

      <SelectDesignModal
        show={showDesignSelector}
        toggle={() => setShowDesignSelector(!showDesignSelector)}
        selectedDesigns={selectedDesigns}
        selectedProduct={selectedProduct}
        onDesignUpload={(item) => {
          dispatch(PRODUCT_SET_SELECTED_DESIGN(item));
          setError("selectedDesigns", {
            type: "manual",
            message: ""
          });
        }}
        onItemSelect={(item) => {
          dispatch(PRODUCT_SET_SELECTED_DESIGN(item));
          setError("selectedDesigns", {
            type: "manual",
            message: ""
          });
        }}
        wizardError={errors.selectedDesigns?.message}
      />
    </div>
  );
};
