import { useDropzone } from "react-dropzone";
import classNames from "classnames";
import React, { useCallback, useEffect, useState } from "react";
import { nanoid } from "nanoid";
import useImageAsset from "../hook/useImageAsset";
import { ImageAssetListItem } from "../../store/canvasImageEditor/imageAssetList";
import { getFramePos } from "../view/frame";
import { StageData } from "../../store/canvasImageEditor/currentStageData";
import useStage from "../hook/useStage";
import useItem from "../hook/useItem";
import { toast } from "react-toastify";
import useSelection from "../hook/useSelection";

interface Props {
  children: any;
  initiallyHide?: boolean;
  stage: ReturnType<typeof useStage>;
  printfileSize: [number, number]; // [width, height]
}

export const CanvasEditorDropZone: React.FC<Props> = ({children, stage, initiallyHide, printfileSize}) => {
  const { setImageAsset, imageAssetList } = useImageAsset();
  // const [isDropZoneInitiallyVisible, setIsDropZoneInitiallyVisible] = useState(!initiallyHide);
  const [isDropZoneInitiallyVisible, setIsDropZoneInitiallyVisible] = useState(false);
  const { createItem, stageData,  } = useItem();

  const insertImage = (data: { [key: string]: any }) => {
    const imageSrc = new Image();
    let source = data.src;

    source = data.src;

    imageSrc.onload = () => {
      let width = imageSrc.width;
      let height = imageSrc.height;
      const position = getFramePos(stage.stageRef!.current!, undefined, width, height);
      const newImage: StageData = {
        id: nanoid(),
        shouldBeSelected: true,
        attrs: {
          name: "label-target",
          "data-item-type": "image",
          x: position.x,
          y: position.y,
          width,
          height,
          src: data.src,
          zIndex: 0,
          brightness: 0,
          // _filters: ["Brighten"],
          updatedAt: Date.now()
        },
        className: "sample-image",
        children: []
      };

      // if image is 2x smaller than printfile size, then show notification
      const widthRatio = width / printfileSize[0];
      const heightRatio = height / printfileSize[1];

      if (widthRatio < 0.5 || heightRatio < 0.5) {
        toast.warn("Image is too small, please upload a larger image, to avoid pixelation.");
      }

      createItem(newImage);
    };
    imageSrc.src = source;
  };

  const onDrop = useCallback((acceptedFiles: File[]) => {
    acceptedFiles.forEach((file) => {
      const result: ImageAssetListItem[] = [
        {
          type: "image",
          id: nanoid(),
          name: "imported image",
          src: window.URL.createObjectURL(file),
          // iso string
          createdAt: new Date().toISOString(),
        }
      ];
      setImageAsset(result);
      insertImage(result[0])
      setIsDropZoneInitiallyVisible(false)
    })

  }, [])
  const {
    getRootProps,
    getInputProps,
    isDragAccept
  } = useDropzone({noClick: !isDropZoneInitiallyVisible, onDrop, accept: {'image/*': []}});

  // useDidMountEffect(()=>{
  //   if (stageData.length > 1 && isDropZoneInitiallyVisible) {
  //     setIsDropZoneInitiallyVisible(false);
  //   }
  // },[stageData])

  return (
    <div className="canvas-dropzone" {...getRootProps()}>
      {
        isDragAccept || isDropZoneInitiallyVisible ? (
          <div className="dropzone">
            <div className="dz-message needsclick mt-2">
              <input {...getInputProps()} />
              <div className={classNames("mb-1")}>
                <i className="display-4 text-muted bx bxs-cloud-upload" />
              </div>
              <h5>Drop files here or click to upload image</h5>
            </div>
          </div>
        ) : ""
      }

      {children}
    </div>
  )
}
