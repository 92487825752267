import React, { useEffect, useMemo, useState } from "react";

import BootstrapTable, { TableChangeState } from "react-bootstrap-table-next";
import filterFactory from "react-bootstrap-table2-filter";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone
} from "react-bootstrap-table2-paginator";
// @ts-ignore
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { Link, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Card, CardBody, Col, Row } from "reactstrap";

import { ORDER_HISTORY_COLUMNS } from "../../../common/constants/order-history.constant";
import { IPaginationMeta } from "../../../common/interfaces/api.interface";
import { OrderTrackingNumberUpdatedMessage } from "../../../common/interfaces/event-bus.interface";
import { IOrderTableItem, IOrderTableItemChildren, OrderStatus } from "../../../common/interfaces/orders.interface";
import { assembleFiltersAndSort, parseQueryFilters } from "../../../common/utils/filter.util";
import { getErrorMessage, replaceWhiteSpacesToUnderscores } from "../../../common/utils/formatter.util";
import { OrderPrintLabelsModal } from "../../../components/backoffice/modals/OrderPrintLabelsModal";
import { UpdateOrderStatusModal } from "../../../components/backoffice/modals/UpdateOrderStatusModal";
import { DatePickerField } from "../../../components/common/DatePickerField";
import { Loader } from "../../../components/common/Loader";
import { TooltipIcon } from "../../../components/common/TooltipIcon";
import { useOrderManagementTableExpandControl } from "../../../hooks/orderManagementTable/useOrderManagementTableExpandControl";
import {
  ORDER_MANAGEMENT_DEFAULT_PER_PAGE,
  useOrderManagementTableFilters
} from "../../../hooks/orderManagementTable/useOrderManagementTableFilters";
import { useOrderManagementTableSelectionControl } from "../../../hooks/orderManagementTable/useOrderManagementTableSelectionControl";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import useEventBus from "../../../hooks/useEventBus";
import { useServiceContainer } from "../../../hooks/useServiceContainer";
import backofficeApiService from "../../../services/api/BackofficeApiService";

export const OrdersManagementPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const eventBus = useEventBus();

  const [items, setItems] = useState<IOrderTableItem[]>([]);

  const [paginationMeta, setPaginationMeta] = useState<null | IPaginationMeta>(null);
  const [showUpdateStatusModal, setShowUpdateStatusModal] = useState(false);
  const [showGenerateLabelsModal, setShowGenerateLabelsModal] = useState(false);
  const [loader, setLoader] = useState(false);

  const {
    selectedData,
    requestNewTrackingNumberInfoText,
    printLabelsInfoText,
    changeStatusInfoText,
    selectRowData,
    tableSelectedRows,
    resetSelection,
    handleSelect
  } = useOrderManagementTableSelectionControl(items);

  const { expandRowData } = useOrderManagementTableExpandControl({
    items,
    onItemSelect: handleSelect,
    tableSelectedRows
  });

  const {
    filters,
    filterDates,
    sortSettings,
    perPage,
    page,
    pageOptions,
    defaultSorted,
    setFilterDates,
    handleTableChange: filtersHandleTableChange,
    handleClearFilters
  } = useOrderManagementTableFilters(searchParams, paginationMeta);

  const { ordersService, commonService } = useServiceContainer();

  const columns = useMemo(() => {
    return [
      ...ORDER_HISTORY_COLUMNS(parseQueryFilters(Object.fromEntries(searchParams)), true),
      {
        dataField: "children",
        text: "Actions",
        formatter: (cellContent: IOrderTableItemChildren[]) => {
          if (cellContent.length !== 1) return "";

          const item = cellContent[0];

          return (
            <Link
              className="btn btn-primary btn-sm ms-2"
              color="primary"
              to={`/backoffice/orders/${encodeURIComponent(item.id)}`}
            >
              View
            </Link>
          );
        },
        sort: false
      }
    ];
  }, []);

  const fetchList = async (
    selectedPage: number,
    _perPage = ORDER_MANAGEMENT_DEFAULT_PER_PAGE,
    _dates: Date[],
    _filters: Record<string, string>,
    _sort: Record<string, string>
  ) => {
    setLoader(true);
    try {
      const res = await ordersService.getOrderHistory(selectedPage, _perPage, _dates, _filters, _sort, true);

      setItems(res.items);
      setPaginationMeta(res.meta);
    } catch (e) {
      console.error(e);
    } finally {
      setLoader(false);
    }
  };

  const downloadPrintFiles = async () => {
    setLoader(true);
    try {
      const files: [string, string][] = selectedData
        .filter((e) => !!e.design.print_file)
        .map((e) => [
          e.design.print_file,
          `${e.id.replace("/", "-")}_${replaceWhiteSpacesToUnderscores(e.product_asset.value)}.png`
        ]);

      await commonService.downloadZipArchiveFromStorageUrls(files);
    } catch (e) {
      console.error(e);
    } finally {
      setLoader(false);
    }
  };

  const handleExcelExport = async (_dates: Date[], _filters: Record<string, string>, _sort: Record<string, string>) => {
    setLoader(true);
    try {
      await ordersService.getOrderHistory(1, 10, _dates, _filters, _sort, true, true);
    } catch (e) {
      console.error(e);
    } finally {
      setLoader(false);
    }
  };

  const requestNewTrackingLabels = async () => {
    setLoader(true);
    try {
      await backofficeApiService.requestNewTrackingNumbers(selectedData.map((item) => item.id));
      await fetchList(page, perPage, filterDates, filters, sortSettings);
      toast.success("Tracking numbers successfully updated for selected orders");
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setLoader(false);
    }
  };

  const onTableChange = (event: string, data: TableChangeState<any>) => {
    filtersHandleTableChange(event, data);
  };

  const orderTrackingNumberUpdateHandler = (message: OrderTrackingNumberUpdatedMessage) => {
    setItems((pv) => {
      return pv.map((item) => {
        if (item.children[0].id === message.orderId) {
          return {
            ...item,
            tracking_number: message.trackingNumber
          };
        }

        return item;
      });
    });
  };

  useEffect(() => {
    const trackingNumberUpdateListener = eventBus.subscribe(
      "OrderTrackingNumberUpdated",
      orderTrackingNumberUpdateHandler
    );
    return () => {
      trackingNumberUpdateListener.unsubscribe();
    };
  }, []);

  useDidMountEffect(() => {
    fetchList(page, perPage, filterDates, filters, sortSettings).then(() => null);
  }, [page, perPage, filters, filterDates, sortSettings]);

  useEffect(() => {
    setSearchParams({
      ...assembleFiltersAndSort(filters, sortSettings),
      perPage: perPage.toString()
    });
  }, [filters, sortSettings, perPage, page]);

  return (
    <div className="data-table">
      <Card className="border">
        {loader && <Loader />}
        <CardBody>
          <PaginationProvider pagination={paginationFactory(pageOptions)}>
            {({ paginationProps, paginationTableProps }: any) => (
              <ToolkitProvider keyField="id" columns={columns} data={items}>
                {(toolkitProps: any) => (
                  <>
                    <Row className="mb-2">
                      <Col md="12" className="mb-2">
                        Selected orders length: {selectedData.length}
                      </Col>
                      <Col md="12">
                        <div className="d-inline-flex me-3">
                          <Button
                            color="primary"
                            disabled={selectedData.length === 0}
                            onClick={() => {
                              downloadPrintFiles().then(() => null);
                            }}
                          >
                            Download print files (.zip)
                          </Button>
                        </div>
                        <div className="d-inline-flex align-items-center me-3">
                          <Button
                            color="primary"
                            disabled={!!changeStatusInfoText.length}
                            onClick={() => {
                              setShowUpdateStatusModal(true);
                            }}
                          >
                            Update status
                          </Button>
                          {!!changeStatusInfoText.length && (
                            <TooltipIcon id="update-status-tt" placement="right" content={changeStatusInfoText}>
                              <i
                                className="bx bx-error"
                                id="update-status-tt"
                                style={{
                                  color: "#f56e50",
                                  fontSize: "16px"
                                }}
                              />
                            </TooltipIcon>
                          )}
                        </div>

                        <div className="d-inline-flex align-items-center me-3">
                          <Button
                            color="primary"
                            disabled={!!printLabelsInfoText.length}
                            onClick={() => {
                              setShowGenerateLabelsModal(true);
                            }}
                          >
                            Print labels
                          </Button>
                          {!!printLabelsInfoText.length && (
                            <TooltipIcon id="print-labels-tt" placement="right" content={printLabelsInfoText}>
                              <i
                                className="bx bx-error"
                                id="print-labels-tt"
                                style={{
                                  color: "#f56e50",
                                  fontSize: "16px"
                                }}
                              />
                            </TooltipIcon>
                          )}
                        </div>

                        <div className="d-inline-flex align-items-center me-3">
                          <Button
                            color="primary"
                            disabled={!!requestNewTrackingNumberInfoText.length}
                            onClick={() => {
                              requestNewTrackingLabels().then(() => null);
                            }}
                          >
                            Request new tracking number
                          </Button>
                          {!!requestNewTrackingNumberInfoText.length && (
                            <TooltipIcon
                              id="request-new-tracking-number-tt"
                              placement="right"
                              content={requestNewTrackingNumberInfoText}
                            >
                              <i
                                className="bx bx-error"
                                id="request-new-tracking-number-tt"
                                style={{
                                  color: "#f56e50",
                                  fontSize: "16px"
                                }}
                              />
                            </TooltipIcon>
                          )}
                        </div>

                        <div className="d-inline-flex align-items-center">
                          <Button
                            color="primary"
                            // disabled={!!selectedData.length}
                            onClick={() => {
                              handleExcelExport(filterDates, filters, sortSettings).then(() => null);
                            }}
                          >
                            Export to excel
                          </Button>
                        </div>
                      </Col>
                    </Row>

                    <Row className="mb-4">
                      <Col xl={3}>
                        <DatePickerField
                          className="mb-0"
                          label="Filter by date"
                          value={filterDates}
                          options={{
                            mode: "range"
                          }}
                          onChange={(dates: Date[]) => {
                            if (dates.length !== 2) return;

                            setFilterDates(dates);
                          }}
                        />
                      </Col>
                      <Col xl={3} className="d-flex align-items-end">
                        <Button color="primary" onClick={handleClearFilters}>
                          Clear filters
                        </Button>
                      </Col>
                    </Row>

                    <Row>
                      <Col xl="12">
                        <div className="table-responsive">
                          <BootstrapTable
                            bootstrap4
                            remote
                            expandRow={expandRowData}
                            onTableChange={onTableChange}
                            bordered={false}
                            striped={false}
                            selectRow={selectRowData}
                            defaultSorted={defaultSorted}
                            filter={filterFactory()}
                            classes="table align-middle table-nowrap table-sm"
                            headerWrapperClasses="thead-light"
                            {...toolkitProps.baseProps}
                            {...paginationTableProps}
                            keyField="id"
                          />
                        </div>
                      </Col>
                    </Row>

                    <Row className="align-items-md-center mt-30">
                      <Col className="inner-custom-pagination d-flex">
                        <div className="d-inline">
                          <SizePerPageDropdownStandalone {...paginationProps} />
                        </div>
                        <div className="text-md-right ms-auto">
                          <PaginationListStandalone {...paginationProps} />
                        </div>
                      </Col>
                    </Row>
                  </>
                )}
              </ToolkitProvider>
            )}
          </PaginationProvider>
        </CardBody>
      </Card>
      x
      <UpdateOrderStatusModal
        onUpdated={() => {
          resetSelection();
          fetchList(page, perPage, filterDates, filters, sortSettings).then(() => null);
        }}
        show={showUpdateStatusModal}
        toggle={() => setShowUpdateStatusModal(false)}
        orderIds={selectedData.map((item) => item.id)}
        currentStatus={selectedData[0]?.status}
      />
      <OrderPrintLabelsModal
        onGenerate={() => {
          fetchList(page, perPage, filterDates, filters, sortSettings).then(() => null);
        }}
        show={showGenerateLabelsModal}
        toggle={() => setShowGenerateLabelsModal(false)}
        orderIds={selectedData.map((item) => item.id)}
        canPrintAWB={selectedData.every((item) => item.status === OrderStatus.ReadyToSend)}
      />
    </div>
  );
};
