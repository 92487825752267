import React, { useCallback, useMemo } from "react";

import { Navigate, Route, Routes, useLocation } from "react-router-dom";

import useAuth from "../../hooks/useAuth";
import { SwaggerApiDoc } from "../../pages/SwaggerApiDoc";
import { Layout } from "../layout";
import { EmptyLayout } from "../layout/EmptyLayout";
import PrivateRoute from "./PrivateRoute";
import { AppRoute, routes } from "./routes";

export const Router: React.FC = () => {
  const auth = useAuth();
  // const [subscription] = useSubscription();
  const location = useLocation();

  const assembleRoutes = useCallback(
    (layout: AppRoute[], keyPrefix: string) => {
      return layout.map((e, idx) =>
        e.private ? (
          <Route
            key={`${keyPrefix}_${idx}`}
            path={e.path}
            element={<PrivateRoute role={e.role}>{e.component}</PrivateRoute>}
          />
        ) : (
          <Route key={idx} path={e.path} element={e.component} />
        )
      );
    },
    [auth]
  );

  const dashRoutes = useMemo(() => assembleRoutes(routes.dashRoutes, ""), [auth]);
  const authRoutes = useMemo(() => assembleRoutes(routes.authRoutes, "auth"), [auth]);
  const systemRoutes = useMemo(() => assembleRoutes(routes.systemRoutes, "system"), [auth]);

  return (
    <>
      <Routes key={location.pathname} location={location}>
        <Route path="*" element={<Navigate replace to="/" />} />
        {/* <Route path="/" element={<EmptyLayout />}> */}
        {/*  {systemRoutes} */}
        {/* </Route> */}
        <Route path="/" element={<Layout />}>
          {dashRoutes}
        </Route>
        <Route path="/auth" element={<EmptyLayout />}>
          {authRoutes}
        </Route>
        <Route path="/api/apidoc" element={<SwaggerApiDoc />} />
      </Routes>
    </>
  );
};
