import { confirmAlert } from "react-confirm-alert";

const BASE_OPTIONS = {
  closeOnEscape: true,
  closeOnClickOutside: true,
  keyCodeForClose: [8, 32],
  willUnmount: () => {},
  afterClose: () => {},
  onClickOutside: () => {},
  onKeypress: () => {},
  onKeypressEscape: () => {},
  overlayClassName: "confirm-overlay"
};

interface Props {
  onConfirm: () => void;
  onCancel: () => void;
  title: string;
  message: string;
}

export const showConfirmationModal = (props: Props) => {
  return confirmAlert({
    ...BASE_OPTIONS,
    title: props.title,
    message: props.message,
    buttons: [
      {
        label: "Yes",
        onClick: props.onConfirm
      },
      {
        label: "No",
        onClick: props.onCancel
      }
    ]
  });
};
