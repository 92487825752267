import React from "react";

import { Col, Row } from "reactstrap";

import { PendingCartOrders } from "../../components/cart/PendingCartOrders";
import { UnPaidCartOrders } from "../../components/cart/UnPaidCartOrders";

export const CartPage = () => {
  return (
    <>
      <Row>
        <Col xs={12} xl={6}>
          <PendingCartOrders />
        </Col>
        <Col xs={12} xl={6}>
          <UnPaidCartOrders />
        </Col>
      </Row>
    </>
  );
};
