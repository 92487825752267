import React, { useCallback, useMemo, useState } from "react";

import classNames from "classnames";
import { useDispatch } from "react-redux";
import { Button } from "reactstrap";

import { PREMIUM_BAG_PRICE_USD } from "../../../common/constants/prices.constant";
import { OrderInstance } from "../../../common/interfaces/order-wizard.interface";
import { getImageUrl } from "../../../common/utils/formatter.util";
import { getMockupAssetTypeBasedOnAssetTitle } from "../../../common/utils/mockup.util";
import { ORDER_EDIT_ORDER, ORDER_REMOVE_ORDER, ORDER_SET_COUNT } from "../../../store/wizards/orderSlice";
import { MockupPreviewModal } from "../../modals/mockup-preview/MockupPreviewModal";

interface Props {
  items: OrderInstance[];
  extended?: boolean;
}

export const OrderSummaryCart: React.FC<Props> = ({ items, extended }) => {
  const dispatch = useDispatch();
  const [expandedOrder, setExpandedOrder] = useState<OrderInstance | null>(null);
  const [mockupPreviewModalOpen, setMockupPreviewModalOpen] = useState(false);
  const [mockupPreviewModalOrder, setMockupPreviewModalOrder] = useState<OrderInstance | null>(null);
  const toggleOrder = (order: OrderInstance) => {
    if (expandedOrder?.index === order.index) {
      setExpandedOrder(null);
    } else {
      setExpandedOrder(order);
    }
  };

  const handleCountChangeClick = (
    clickEvent: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    orderInstance: OrderInstance
  ) => {
    clickEvent.stopPropagation();
    const target = clickEvent.currentTarget as HTMLButtonElement;
    const { action } = target.dataset;
    let newCount = orderInstance.count;

    switch (action) {
      case "minus":
        newCount = orderInstance.count === 1 ? 1 : orderInstance.count - 1;
        break;
      case "plus":
        newCount = orderInstance.count + 1;
        break;
      default:
        break;
    }

    dispatch(
      ORDER_SET_COUNT({
        orderIndex: orderInstance.index,
        count: newCount
      })
    );
  };

  const isOrderExpanded = (orderId: number) => {
    return expandedOrder?.index === orderId;
  };

  const canPreviewMockupForOrder = useCallback((order: OrderInstance) => {
    return order.selectedProductType && order.selectedDesign && order.selectedProductAsset;
  }, []);

  return (
    <>
      <table
        className={classNames("wizard-summary__table", {
          "wizard-summary__table--extended": extended
        })}
      >
        {items.map((order, idx) => {
          const orderSubFields = [
            ...(order.selectedProductAsset ? [["Phone model", order.selectedProductAsset.title]] : []),
            ...(order.selectedDesign
              ? [
                  [
                    "Design",
                    <>
                      <img src={getImageUrl(order.selectedDesign.image)} alt="" className="xs-img" />
                      {order.selectedDesign.title}
                    </>
                  ]
                ]
              : []),
            [
              "Include premium bag",
              order.premiumBag ? "Yes" : "No",
              order.premiumBag ? `€${PREMIUM_BAG_PRICE_USD}` : ""
            ]
          ];
          return (
            <React.Fragment key={order.index}>
              <tr
                role="button"
                className={classNames("cursor-pointer", "wizard-summary__table__row", {
                  "wizard-summary__table__row--selected": isOrderExpanded(order.index)
                })}
                onClick={() => {
                  if (orderSubFields.length) toggleOrder(order);
                }}
              >
                <td>
                  <div className="d-flex align-items-center">
                    <span className="wizard-summary__table__row__index">{idx + 1}.</span>
                    <div className="wizard-summary__table__row__title">
                      <img
                        src={getImageUrl(order.selectedProductType?.image)}
                        alt={order.selectedProductType?.title}
                        className="wizard-summary__table__row__image small-img"
                      />
                      {order.selectedProductType?.title}
                    </div>

                    {canPreviewMockupForOrder(order) && extended && (
                      <Button
                        color="primary"
                        size="sm"
                        className="ms-2 py-0 px-2"
                        onClick={(e) => {
                          e.stopPropagation();
                          setMockupPreviewModalOrder(order);
                          setMockupPreviewModalOpen(true);
                        }}
                      >
                        Preview
                      </Button>
                    )}
                  </div>
                </td>
                {extended ? (
                  <td>
                    <div className="wizard-summary__table__row__count">
                      <button
                        className="wizard-summary__table__row__count__btn"
                        type="button"
                        data-action="minus"
                        onClick={(e) => handleCountChangeClick(e, order)}
                      >
                        <i className="bx bx-minus" />
                      </button>
                      <div className="wizard-summary__table__row__count__value">{order.count}</div>
                      <button
                        className="wizard-summary__table__row__count__btn"
                        type="button"
                        data-action="plus"
                        onClick={(e) => handleCountChangeClick(e, order)}
                      >
                        <i className="bx bx-plus" />
                      </button>
                    </div>
                  </td>
                ) : (
                  ""
                )}

                <td>
                  <div className="wizard-summary__table__row__controls">
                    {order.selectedProductAsset ? (
                      <strong className="wizard-summary__table__row__price">
                        € {(order.selectedProductAsset.price * order.count).toFixed(2)}
                      </strong>
                    ) : (
                      ""
                    )}
                    <span
                      role="button"
                      className="wizard-summary__table__row__control-button"
                      onClick={(e) => {
                        e.stopPropagation();
                        dispatch(ORDER_EDIT_ORDER(order.index));
                      }}
                    >
                      <span className="bx bxs-pencil" />
                    </span>
                    {/* {order.index !== 1 ? ( */}
                    <span
                      role="button"
                      className="wizard-summary__table__row__control-button wizard-summary__table__row__control-button--remove"
                      onClick={(e) => {
                        e.stopPropagation();
                        dispatch(ORDER_REMOVE_ORDER(order.index));
                      }}
                    >
                      <i className="bx bxs-x-circle" />
                    </span>
                    <span role="button" className="wizard-summary__table__row__control-button">
                      <span
                        className={classNames("bx", {
                          "bx-chevron-down": !isOrderExpanded(order.index),
                          "bx-chevron-up": isOrderExpanded(order.index)
                        })}
                      />
                    </span>
                  </div>
                </td>
              </tr>
              {isOrderExpanded(order.index) && orderSubFields.length ? (
                <tr className="wizard-summary__table__sub-row">
                  <td colSpan={extended ? 4 : 3}>
                    <table>
                      {orderSubFields.map((subField) => {
                        return (
                          <tr className="sub-row__item">
                            <td className="sub-row__item__title">{subField[0]}:</td>
                            <td className="sub-row__item__value">{subField[1]}</td>
                            <td className="sub-row__item__second-value">
                              {subField[2] ? <strong>{subField[2]}</strong> : ""}
                            </td>
                          </tr>
                        );
                      })}
                    </table>
                  </td>
                </tr>
              ) : (
                ""
              )}
            </React.Fragment>
          );
        })}
      </table>

      <MockupPreviewModal
        show={mockupPreviewModalOpen}
        data={
          mockupPreviewModalOrder
            ? {
                productId: mockupPreviewModalOrder.selectedProductType!.id,
                designId: mockupPreviewModalOrder.selectedDesign!.id,
                assetType: getMockupAssetTypeBasedOnAssetTitle(
                  mockupPreviewModalOrder.selectedProductAsset!.title
                ) as any
              }
            : null
        }
        toggle={() => {
          setMockupPreviewModalOpen(!mockupPreviewModalOpen);
          setMockupPreviewModalOrder(null);
        }}
      />
    </>
  );
};
