import React, { useEffect, useRef, useState } from "react";

import classNames from "classnames";
import { WithTranslation, withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Col, Dropdown, DropdownMenu, DropdownToggle, Row } from "reactstrap";
import SimpleBar from "simplebar-react";

import { NotificationType } from "../../../common/interfaces/notification.interface";
import { getErrorMessage } from "../../../common/utils/formatter.util";
import { useServiceContainer } from "../../../hooks/useServiceContainer";
import { APP_ACTION_GET_NOTIFICATIONS } from "../../../store/app/ThunkActions/appSliceThunkActions";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { NotificationElement } from "../../notifications/NotificationElement";
import { Loader } from "../Loader";

// i18n
const TIMEOUT_SECONDS = 30;

interface Props {
  isNews?: boolean;
}

const NotificationDropdown = (props: Props & WithTranslation) => {
  const [timer, setTimer] = useState(TIMEOUT_SECONDS);
  const intervalRef = useRef<NodeJS.Timeout>();
  const { commonService } = useServiceContainer();

  const [menu, setMenu] = useState(false);
  const [loader, setLoader] = useState(false);
  const dispatch = useAppDispatch();
  const { data: notificationData, loader: notificationLoader } = useAppSelector((state) => {
    if (props.isNews) {
      return {
        loader: state.app.notifications.loader,
        data: state.app.notifications.data.filter(
          (notification) => notification.notification_type === NotificationType.News
        )
      };
    }

    return {
      loader: state.app.notifications.loader,
      data: state.app.notifications.data.filter(
        (notification) => notification.notification_type !== NotificationType.News
      )
    };
  });

  const initTimer = () => {
    setTimer(TIMEOUT_SECONDS);
    intervalRef.current = setInterval(() => {
      setTimer((t) => {
        if (t - 1 === 0) {
          clearInterval(intervalRef.current);
          return 0;
        }

        return t - 1;
      });
    }, 1000);
  };

  const handleMarkAsReadClick = async () => {
    setLoader(true);
    try {
      await commonService.readNotifications(notificationData.map((notification) => notification.id));
      dispatch(APP_ACTION_GET_NOTIFICATIONS());
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (!props.isNews) {
      dispatch(APP_ACTION_GET_NOTIFICATIONS());
      initTimer();
    }
    return () => {
      clearInterval(intervalRef.current);
    };
  }, []);

  useEffect(() => {
    if (timer === 0) {
      dispatch(APP_ACTION_GET_NOTIFICATIONS());
      initTimer();
    }
  }, [timer]);

  return (
    <>
      <Dropdown isOpen={menu} toggle={() => setMenu(!menu)} className="dropdown d-inline-block" tag="li">
        <DropdownToggle
          className="btn px-3 header-item noti-icon"
          tag="button"
          id={props.isNews ? "page-header-messages-dropdown" : "page-header-notifications-dropdown"}
        >
          <i
            className={classNames("mdi", {
              "mdi-bell": !props.isNews,
              "mdi-email": props.isNews
            })}
          />
          {notificationData.length ? (
            <span className="badge bg-danger rounded-pill">{notificationData.length}</span>
          ) : (
            ""
          )}
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu dropdown-menu-lg p-0 dropdown-menu-end">
          {loader || notificationLoader ? <Loader /> : null}
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0"> {props.t(props.isNews ? "Messages" : "Notifications")} </h6>
              </Col>
              <div className="col-auto">
                <button className="btn btn-link btn-sm p-0" onClick={handleMarkAsReadClick} type="button">
                  Mark as read
                </button>
              </div>
              .
            </Row>
          </div>

          <SimpleBar style={{ height: "230px" }}>
            {notificationData.length ? (
              notificationData.map((notification, idx) => <NotificationElement item={notification} key={idx} />)
            ) : (
              <div className="text-center p-4">
                <p>You have no unread {props.isNews ? "messages" : "notifications"}</p>
              </div>
            )}
          </SimpleBar>
          <div className="p-2 border-top d-grid">
            <Link
              className="btn btn-sm btn-link font-size-14 btn-block text-center"
              to={props.isNews ? "/messages" : "/notifications"}
            >
              <i className="mdi mdi-arrow-right-circle me-1" /> {props.t("View all")}{" "}
            </Link>
          </div>
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

export default withTranslation()(NotificationDropdown);
