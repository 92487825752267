import { IPaginationMeta } from "./api.interface";
import { ICountryItem } from "./common.interface";
import {
  ICustomDesignItem,
  IDesignItem,
  IProductAssetItem,
  IProductItem,
  IProductList,
  IProductMaterial
} from "./products.interface";

export enum OrderWizardStep {
  ORDER_PRODUCT_SELECTION = "OrderWizard::product_selection",
  ORDER_PRODUCT_SETTINGS = "OrderWizard::product_settings",
  ORDER_DESIGN_SETTINGS = "OrderWizard::design_settings",
  ORDER_DELIVERY_SETTINGS = "OrderWizard::delivery_settings"
  // ORDER_PAYMENT = "OrderWizard::payment",
  // ORDER_SUMMARY = "OrderWizard::summary"
}

export enum OrderWizardInstanceStatus {
  DRAFT = "draft",
  SUBMITTED = "submitted"
}

export const STEPS_DATA = [
  {
    title: "Product",
    stepIdx: OrderWizardStep.ORDER_PRODUCT_SELECTION
  },
  {
    title: "Details",
    stepIdx: OrderWizardStep.ORDER_PRODUCT_SETTINGS
  },
  {
    title: "Design",
    stepIdx: OrderWizardStep.ORDER_DESIGN_SETTINGS
  },
  // {
  //   title: "Summary",
  //   stepIdx: OrderWizardStep.ORDER_SUMMARY
  // },
  {
    title: "Complete order",
    stepIdx: OrderWizardStep.ORDER_DELIVERY_SETTINGS
  }
  // {
  //   title: "Payment",
  //   stepIdx: OrderWizardStep.ORDER_PAYMENT
  // }
];

export interface OrderDeliveryData {
  fullName: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state?: string;
  zipCode: string;
  country: string;
  phone: string;
  email?: string;
  deliveryOption: string | number;
  paymentMethod: string;
}

export type OrderDeliveryDataWithAmount = OrderDeliveryData & {
  deliveryAmount: number;
  deliveryAmountWithVAT: number;
};

export type IOrderDeliveryDataSubmitted = OrderDeliveryData & {
  deliveryService?: string;
  deliveryAmount?: number;
  deliveryServiceIsApi: boolean;
  deliveryInfo?: string;
};

export interface OrderInstance {
  activeWizardStep: OrderWizardStep;
  status: OrderWizardInstanceStatus;
  index: number;
  selectedProductType: IProductItem | null;
  selectedProductAsset: IProductAssetItem | null;
  selectedProductMaterial: IProductMaterial | null;
  selectedDesign: IDesignItem | null;
  premiumBag: boolean;
  giftMessage: string;
  count: number;
  customPrintFile: {
    image: [string, string]; // base64, name
    title: string;
    description: string;
  } | null;
}

export interface OrderWizardState {
  selectedIndex: number;
  orders: OrderInstance[];
  deliveryData: OrderDeliveryData;
  deliveryDataInvalidKeys: (keyof OrderDeliveryData)[];
  countryList: {
    loader: boolean;
    data: ICountryItem[];
  };
  summaryData: IOrderSummary | null;
}

export interface IOrderSummary {
  shipping: number;
  total: number;
  totalWithVat: number;
  totalWeight: number;
}

export interface IPossibleDeliveryService {
  id: number;
  image: string;
  name: string;
  price: number;
}
