import React, { useMemo } from "react";

import classNames from "classnames";
import { Label } from "reactstrap";

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  error?: string;
}

export const RadioFieldInput = React.forwardRef<HTMLInputElement, Props>(({ label, error, ...props }, ref: any) => {
  const id = useMemo(() => Math.random().toString(36).substr(2, 9), []);
  return (
    <div className="form-check mb-2 mx-1">
      <input ref={ref} className={classNames("form-check-input", { "is-invalid": !!error })} {...props} id={id} />
      {label && <Label htmlFor={id}>{label}</Label>}
      {!!error && <div className="invalid-feedback">{error}</div>}
    </div>
  );
});
