import React, { useEffect, useMemo, useState } from "react";

import moment from "moment";
import BootstrapTable, { TableChangeState } from "react-bootstrap-table-next";
import filterFactory from "react-bootstrap-table2-filter";
import paginationFactory, { PaginationListStandalone, PaginationProvider } from "react-bootstrap-table2-paginator";
// @ts-ignore
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Card, CardBody, Col, Row } from "reactstrap";

import { IProductAssetGroup, IProductListingPresetItem } from "../../../common/interfaces/products.interface";
import { formatProductType, getErrorMessage } from "../../../common/utils/formatter.util";
import { UpdateListingTemplateTitleModal } from "../../../components/backoffice/modals/UpdateListingTemplateTitleModal";
import { UpdateListingTemplateValuesModal } from "../../../components/backoffice/modals/UpdateListingTemplateValuesModal";
import { Loader } from "../../../components/common/Loader";
import { SelectField } from "../../../components/common/SelectField";
import { UpdateListingGroupsModal } from "../../../components/modals/listings/UpdateListingGroupsModal";
import { useServiceContainer } from "../../../hooks/useServiceContainer";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { PRODUCT_UPDATE_LISTING_GROUP } from "../../../store/wizards/productSlice";
import { PRODUCT_TYPES_ACTION_UPDATE_LIST, PRODUCT_TYPES_SET_SELECTED } from "../../../store/wizards/productTypesSlice";

export const ListingsTemplateManagementPage = () => {
  const [loader, setLoader] = useState(false);
  const [showUpdateGroupModal, setShowUpdateGroupModal] = useState(false);
  const [showUpdateFormValuesModal, setShowUpdateFormValuesModal] = useState(false);
  const [showUpdateTitleModal, setShowUpdateTitleModal] = useState(false);
  const [selectedPreset, setSelectedPreset] = useState<IProductListingPresetItem | null>(null);

  const { productsApiService } = useServiceContainer();
  const [data, setData] = useState<{
    listingGroupsPresets: IProductListingPresetItem[];
    assetGroups: IProductAssetGroup[];
  }>({
    listingGroupsPresets: [],
    assetGroups: []
  });
  const dispatch = useAppDispatch();

  const {
    productList: { data: productTypes, loader: productTypesLoader },
    selectedProduct
  } = useAppSelector((state) => state.productTypes);

  const productTypesOptions = useMemo(() => {
    return productTypes.all.map((item) => ({
      label: `[${formatProductType(item.type)}] ${item.title}`,
      value: item.id.toString()
    }));
  }, [productTypes]);

  const handleProductTypeChange = (selected: any) => {
    const product = productTypes.all.find((e) => e.id.toString() === selected.value);
    if (product) {
      dispatch(PRODUCT_TYPES_SET_SELECTED(product));
    }
  };

  const fetchList = async (productId: number) => {
    try {
      setLoader(true);
      const res = await productsApiService.getDefaultListingGroupPresets(productId, true);
      setData({
        listingGroupsPresets: res.presets,
        assetGroups: res.groups
      });
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setLoader(false);
    }
  };

  const handleCreatePresetItem = async () => {
    try {
      if (!selectedProduct) return;
      const title = window.prompt("Enter new preset title");
      if (!title) return;

      // const templateTitle = window.prompt("Enter template title");
      // if (!templateTitle) return;

      const res = await productsApiService.addDefaultListingGroupPreset(selectedProduct.id, title, {
        name: `${title} Default`
      });
      await fetchList(selectedProduct.id);
      toast.success("New preset successfully created");

      setSelectedPreset(res);
      setShowUpdateGroupModal(true);
    } catch (e) {
      console.error(e);
      toast.error(getErrorMessage(e));
    }
  };

  const handleDeletePreset = async (presetId: number) => {
    try {
      await productsApiService.deleteListingGroupPresetById(presetId);
      fetchList(selectedProduct!.id).then(() => null);
      toast.success("Preset successfully deleted");
    } catch (e) {
      console.error(e);
      toast.error(getErrorMessage(e));
    }
  };

  const columns = [
    {
      dataField: "id",
      text: "Id",
      sort: false,
      style: {
        maxWidth: "90px"
      }
    },
    {
      dataField: "title",
      text: "Title",
      sort: false,
      style: {
        maxWidth: "150px"
      }
    },
    {
      dataField: "groups",
      text: "Groups",
      sort: false,
      style: {
        maxWidth: "150px"
      },
      formatter: (cellContent: IProductAssetGroup[], row: IProductListingPresetItem) => {
        return cellContent.map((e) => e.title).join(", ");
      }
    },
    // {
    //   dataField: "template",
    //   text: "Default template values",
    //   sort: false,
    //   // style: {
    //   //   maxWidth: "150px"
    //   // },
    //   formatter: (cellContent: IProductListingTemplate, row: IProductListingPresetItem) => {
    //     const { fields } = cellContent;
    //     const values = fields
    //       ? Object.keys(fields).map((e) => (
    //           <>
    //             <strong>{e}</strong>:{" "}
    //             {Array.isArray((fields as any)[e])
    //               ? (fields as any)[e].join(", ")
    //               : typeof fields === "object"
    //               ? JSON.stringify((fields as any)[e])
    //               : (fields as any)[e]}
    //           </>
    //         ))
    //       : [];
    //     return values.length ? (
    //       <>
    //         {values.map((e, i) => (
    //           <div key={i}>{e}</div>
    //         ))}
    //       </>
    //     ) : (
    //       "No form values"
    //     );
    //   }
    // },
    {
      text: "Actions",
      dataField: "id",
      formatter: (cellContent: any, row: IProductListingPresetItem) => {
        return (
          <>
            <Button
              color="primary"
              size="sm"
              onClick={() => {
                setSelectedPreset(row);
                setShowUpdateGroupModal(true);
              }}
              role="button"
            >
              Update groups
            </Button>
            <Button
              color="primary"
              className="ms-1"
              size="sm"
              onClick={() => {
                setSelectedPreset(row);
                setShowUpdateFormValuesModal(true);
              }}
              role="button"
            >
              Update template values
            </Button>
            <Button
              color="primary"
              size="sm"
              className="ms-1"
              onClick={() => {
                setSelectedPreset(row);
                setShowUpdateTitleModal(true);
              }}
              role="button"
            >
              Update title
            </Button>
            <Button
              color="danger"
              size="sm"
              className="ms-1"
              onClick={() => {
                if (window.confirm("Are you sure you want to delete this preset?")) {
                  handleDeletePreset(row.id).then(() => null);
                }
              }}
              role="button"
            >
              Delete
            </Button>
          </>
        );
      }
    }
  ];

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc"
    }
  ] as any;

  const onTableChange = (event: string, eventData: TableChangeState<any>) => {
    switch (event) {
      default:
        break;
    }
  };

  const handleListingPresetTemplateUpdate = () => {
    fetchList(selectedProduct!.id).then(() => null);
  };

  const handleListingPresetGroupsUpdate = async (newGroups: IProductAssetGroup[]) => {
    try {
      if (!selectedPreset) return;
      await productsApiService.updateCustomListingGroupPreset(
        selectedPreset.id,
        newGroups.map((group) => group.items.map((asset) => asset.id)).flat()
      );

      setShowUpdateGroupModal(false);
      fetchList(selectedProduct!.id).then(() => null);
      toast.success("Listing groups successfully updated");
    } catch (e) {
      console.error(e);
      toast.error(getErrorMessage(e));
    }
  };

  useEffect(() => {
    dispatch(PRODUCT_TYPES_ACTION_UPDATE_LIST());
  }, []);

  useEffect(() => {
    if (selectedProduct) {
      fetchList(selectedProduct.id).then(() => null);
    }
  }, [selectedProduct]);

  return (
    <div className="data-table">
      <h3>
        <Link to="/backoffice/listings" className="btn btn-primary btn-sm me-3 mb-1">
          <i className="bx bx-arrow-back" /> Back
        </Link>
        Listing template management
      </h3>
      <Card className="border">
        {productTypesLoader || loader ? <Loader /> : null}
        <CardBody>
          <ToolkitProvider keyField="id" columns={columns} data={data.listingGroupsPresets} search>
            {(toolkitProps: any) => {
              return (
                <>
                  <Row>
                    <Col xs={4}>
                      <SelectField
                        options={productTypesOptions}
                        placeholder="Select product type"
                        required
                        value={productTypesOptions.find((e) => e.value === selectedProduct?.id.toString()) || null}
                        onChange={handleProductTypeChange}
                      />
                    </Col>
                    <Col xs={4}>
                      <Button type="button" onClick={handleCreatePresetItem} color="primary">
                        Add new preset
                      </Button>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl="12">
                      <div className="table-responsive">
                        <BootstrapTable
                          bootstrap4
                          remote
                          onTableChange={onTableChange}
                          bordered={false}
                          striped={false}
                          defaultSorted={defaultSorted}
                          filter={filterFactory()}
                          classes="table align-middle table-nowrap"
                          headerWrapperClasses="thead-light"
                          {...toolkitProps.baseProps}
                          data={data.listingGroupsPresets}
                          keyField="id"
                        />
                      </div>
                    </Col>
                  </Row>
                </>
              );
            }}
          </ToolkitProvider>
        </CardBody>
      </Card>

      <UpdateListingGroupsModal
        allGroups={data.assetGroups}
        show={showUpdateGroupModal}
        listingItem={selectedPreset}
        toggle={() => {
          setShowUpdateGroupModal(!showUpdateGroupModal);
        }}
        onSubmit={handleListingPresetGroupsUpdate}
      />

      <UpdateListingTemplateValuesModal
        selectedPreset={selectedPreset}
        show={showUpdateFormValuesModal}
        toggle={() => {
          setShowUpdateFormValuesModal(!showUpdateFormValuesModal);
        }}
        onSubmit={handleListingPresetTemplateUpdate}
      />

      <UpdateListingTemplateTitleModal
        listingItem={selectedPreset}
        show={showUpdateTitleModal}
        toggle={() => {
          setShowUpdateTitleModal(!showUpdateTitleModal);
        }}
        onSubmit={handleListingPresetTemplateUpdate}
      />
    </div>
  );
};
