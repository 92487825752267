import React, { useEffect, useMemo, useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Button, CardBody, Modal } from "reactstrap";
import { object, string } from "yup";

import { IWarehouseItem } from "../../../common/interfaces/warehouse.interface";
import { getErrorMessage } from "../../../common/utils/formatter.util";
import { useServiceContainer } from "../../../hooks/useServiceContainer";
import { InputField } from "../../common/InputField";
import { Loader } from "../../common/Loader";

const validationSchema = object().shape({
  count: string().required("This field is required")
});

interface Props {
  show: boolean;
  action: "add" | "remove";
  warehouseItem: IWarehouseItem | null;
  onUpdated: () => void;
  toggle: () => void;
}

export const UpdateWarehouseItemBalanceModal: React.FC<Props> = ({
  action,
  onUpdated,
  warehouseItem,
  show,
  toggle
}) => {
  const {
    formState: { errors },
    handleSubmit,
    register,
    reset
  } = useForm({
    defaultValues: {
      count: ""
    },
    resolver: yupResolver(validationSchema)
  });

  const [loader, setLoader] = useState(false);

  const { backofficeService } = useServiceContainer();

  const title = useMemo(() => {
    switch (action) {
      case "add":
        return "Add items";
      case "remove":
        return "Remove items";
      default:
        return "Update warehouse item";
    }
  }, [action]);

  const onSubmitHandler = async (data: { count: string }) => {
    try {
      if (!warehouseItem) return;
      setLoader(true);
      await backofficeService.updateWarehouseItemBalance(warehouseItem.id, action, +data.count);

      toast.success("Warehouse item balance updated successfully");
      toggle();
      onUpdated();
      reset();
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    reset();
  }, []);

  return (
    <Modal isOpen={show} toggle={toggle} centered size="md">
      {loader && <Loader />}
      <div className="modal-header">
        <h5 className="modal-title mt-0">{title}</h5>
        <button
          type="button"
          onClick={() => {
            toggle();
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form onSubmit={handleSubmit(onSubmitHandler)}>
        {warehouseItem && (
          <>
            <div className="modal-body">
              <div className="row">
                <div className="col-12">
                  <div className="card border">
                    <CardBody>
                      <InputField
                        {...register("count")}
                        error={errors.count?.message}
                        label="Enter count"
                        name="count"
                        type="number"
                        placeholder="Enter count"
                      />
                    </CardBody>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <Button
                color="danger"
                onClick={() => {
                  toggle();
                }}
              >
                Cancel
              </Button>
              <Button color="primary" type="submit">
                Save
              </Button>
            </div>
          </>
        )}
      </form>
    </Modal>
  );
};
