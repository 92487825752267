import React from "react";

import { useNavigate } from "react-router-dom";

import { GenerateMockupModal } from "../components/modals/generate-mockup-new/GenerateMockupModal";

export const MockupGeneratorPage = () => {
  const navigate = useNavigate();
  return (
    <GenerateMockupModal
      show
      toggle={() => {
        navigate(-1);
      }}
      onSubmit={(d) => {
        console.log(d);
      }}
    />
  );
};
