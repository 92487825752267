import React from "react";

import classNames from "classnames";
import { BtnBold, BtnItalic, Editor, EditorProvider, Toolbar, BtnUnderline, BtnLink } from "react-simple-wysiwyg";
import { Label } from "reactstrap";

interface Props {
  label?: string;
  value: string;
  name: string;
  error?: any;
  onChange: (e: string) => void;
  editorStyle?: any;
}

const htmlStringHasText = (htmlString: string) => {
  const regex = /<[^>]*>/g;
  const result = htmlString.replace(regex, "").trim();
  return result.length > 0;
};

const formatHtmlText = (htmlString: string) => {
  // add target="_blank" to all links
  const doc = new DOMParser().parseFromString(htmlString, "text/html");
  const links = doc.querySelectorAll("a");
  links.forEach((link) => {
    link.setAttribute("target", "_blank");
  });

  let formattedString = doc.body.innerHTML;

  // message always should be wrapped in <p> tag
  if (!formattedString.startsWith("<p>")) {
    formattedString = `<p>${formattedString}</p>`;
  }

  console.log(formattedString);

  return formattedString;
};

// const BtnLink = createButton("Link", "🔗", ({ $selection }) => {
//   if ($selection?.nodeName === "A") {
//     document.execCommand("unlink");
//   } else {
//     // eslint-disable-next-line no-alert
//     const selection = document.getSelection();
//     document.execCommand("createLink", false, prompt("URL", "") || undefined);
//     // selection?.anchorNode.parentElement.target = "_blank";
//   }
// });

export const RichTextEditor: React.FC<Props> = ({ editorStyle, error, label, name, value, onChange }) => {
  return (
    <div className="mb-3">
      {label ? (
        <Label for={name} className={classNames("form-label")}>
          {label}
        </Label>
      ) : (
        ""
      )}
      <EditorProvider>
        <Editor
          containerProps={editorStyle ? { style: editorStyle } : {}}
          value={value}
          onChange={(e) => {
            onChange(htmlStringHasText(e.target.value) ? formatHtmlText(e.target.value) : "");
          }}
        >
          <Toolbar>
            <BtnBold />
            <BtnItalic />
            <BtnUnderline />
            <BtnLink />
          </Toolbar>
        </Editor>
      </EditorProvider>
      {error && <div className="invalid-feedback d-block">{error}</div>}
    </div>
  );
};
