import { useContext } from "react";

import { DndContext, useDrag } from "react-dnd";

export const useDragIfAvailable: typeof useDrag = (...args) => {
  // This is an ugly hack to make `useDrag` not throw if a `DndContext` is not available.
  // See: https://github.com/react-dnd/react-dnd/issues/2212
  //
  const { dragDropManager } = useContext(DndContext as any);
  const useHook: typeof useDrag = dragDropManager ? useDrag : () => [{} as any, () => null, () => null];
  return useHook(...args);
};
