import React from "react";
import "./styles/theme.scss";
import "./styles/sitecore/index.scss";
import "react-confirm-alert/src/react-confirm-alert.css";

import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";

import App from "./App";
import initializeUser from "./common/utils/user.util";
import { initMockEngine } from "./mock.engine";
import reportWebVitals from "./reportWebVitals";
import { store } from "./store/store";
import "intro.js/introjs.css";

// const initApp = async () => {
//   initLang();
//   const query = new URLSearchParams(window.location.search);
//
//   if (query.get("accessToken") && query.get("refreshToken")) {
//     TokenService.setAccessToken(query.get("accessToken") as string);
//     TokenService.setRefreshToken(query.get("refreshToken") as string);
//   }
//
//   await initializeUser();
// };

const initApp = async () => {
  initMockEngine();

  await initializeUser();
};

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
initApp().then(() =>
  root.render(
    <Provider store={store}>
      <App />
    </Provider>
  )
);

reportWebVitals();
