import React from "react";

import { Link } from "react-router-dom";
import { Row, Col, BreadcrumbItem } from "reactstrap";

interface Props {
  title: string;
  items: Array<{ title: string; link?: string }>;
  backButton?: any;
}

const Breadcrumb: React.FC<Props> = ({ title, items, backButton }) => {
  const itemLength = items.length;

  return (
    <Row>
      <Col xs="12">
        <div className="page-title-box d-flex align-items-center justify-content-between">
          <h4 className="mb-0 font-size-18">
            {backButton}
            {title}
          </h4>
          <div className="page-title-right">
            <ol className="breadcrumb m-0">
              {items.map((item, key) => (
                <BreadcrumbItem key={key} active={key + 1 === itemLength}>
                  <Link to={item.link ?? "#"}>{item.title}</Link>
                </BreadcrumbItem>
              ))}
            </ol>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default Breadcrumb;
