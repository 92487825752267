import React, { useMemo, useState } from "react";

import classNames from "classnames";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { IMessage } from "../../../common/interfaces/messages.interface";
import { getErrorMessage, getImageUrl, timeSince } from "../../../common/utils/formatter.util";
import { useServiceContainer } from "../../../hooks/useServiceContainer";
import { APP_ACTION_GET_NOTIFICATIONS } from "../../../store/app/ThunkActions/appSliceThunkActions";
import { useAppDispatch } from "../../../store/store";
import { Loader } from "../Loader";

interface Props {
  item: IMessage;
  onMarkAsReadClick?: () => void;
  isFull?: boolean;
}

export const MessageItem: React.FC<Props> = ({ item, isFull, onMarkAsReadClick }) => {
  const [loader, setLoader] = useState(false);
  const { commonService } = useServiceContainer();
  const dispatch = useAppDispatch();
  const isNewMessage = useMemo(() => {
    if (!item) return false;
    const now = new Date();
    const created = new Date(item.created_at);
    const diff = now.getTime() - created.getTime();
    const diffInHours = diff / (1000 * 3600);
    const diffInDays = diffInHours / 24;
    return !item.read_at && diffInDays < 14;
  }, [item]);

  const navigate = useNavigate();

  const handleMarkAsRead = async () => {
    setLoader(true);
    try {
      await commonService.readNotifications([item.notification_id]);
      dispatch(APP_ACTION_GET_NOTIFICATIONS());
      if (onMarkAsReadClick) {
        onMarkAsReadClick();
      }
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setLoader(false);
    }
  };

  const additionalAttributes = {
    ...(!isFull
      ? {
          role: "button",
          onClick: () => {
            navigate("/messages");
          }
        }
      : {})
  };

  return (
    <div
      className={classNames("message-item", {
        "message-item--read": item.read_at,
        "message-item--new": isNewMessage,
        "message-item--has-image": !!item.image,
        "message-item--full": isFull
      })}
      {...additionalAttributes}
    >
      {loader && <Loader />}
      <div className="message-item__header">
        {isNewMessage && <div className="message-item__header__new">new</div>}
        <div className="message-item__header__timestamp">{moment(item.created_at).format("DD.MM.YYYY hh:mm A")}</div>
        <div className="message-item__header__read-state">
          {item.read_at ? (
            <i className="bx bx-check-double ms-2" />
          ) : (
            <button type="button" onClick={handleMarkAsRead}>
              <i className="bx bx-check-double" /> Mark as read
            </button>
          )}
        </div>
      </div>
      <div className="message-item__body">
        <div className="message-item__body__content">
          <h3 className="message-item__title">{item.title}</h3>
          <div
            className="message-item__description"
            dangerouslySetInnerHTML={{
              __html: isFull ? item.text : item.description
            }}
          />
        </div>
        {item.image && (
          <div className="message-item__image">
            <img src={getImageUrl(item.image)} alt={item.title} />
          </div>
        )}
      </div>
    </div>
  );
};
