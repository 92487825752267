import React, { useEffect, useMemo, useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import * as Yup from "yup";

import { ICustomerItemWithUser } from "../../../common/interfaces/backoffice.interface";
import { getErrorMessage } from "../../../common/utils/formatter.util";
import { getObjectDelta } from "../../../common/utils/object.util";
import { CheckboxFieldInput } from "../../../components/common/Input/CheckboxFieldInput";
import { InputField } from "../../../components/common/InputField";
import { Loader } from "../../../components/common/Loader";
import { useServiceContainer } from "../../../hooks/useServiceContainer";

const validationSchema = Yup.object().shape({
  name: Yup.string(),
  surname: Yup.string(),
  reg_nr: Yup.string(),
  vat_nr: Yup.string(),
  email: Yup.string(),
  phone: Yup.string(),
  company_title: Yup.string(),
  will_pay_after: Yup.boolean()
});

export const CustomerEditPage = () => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset
  } = useForm({
    defaultValues: {} as any,
    resolver: yupResolver(validationSchema)
  });

  const [customerData, setCustomerData] = useState<null | ICustomerItemWithUser>(null);

  const [loader, setLoader] = useState(true);
  const { id } = useParams();
  const { backofficeService } = useServiceContainer();
  const navigate = useNavigate();

  const userValue = useMemo(() => {
    if (!customerData) return "-";

    const user = customerData.users[0] ?? undefined;

    if (!user) return "-";

    return `[${user.id}] ${user.name}`;
  }, [customerData]);

  const fetch = async () => {
    try {
      if (!id) return;
      setLoader(true);
      const data = await backofficeService.getCustomerById(+id);
      setCustomerData(data);
    } catch (e) {
      toast.error(getErrorMessage(e));
      navigate("/");
    } finally {
      setLoader(false);
    }
  };

  const onSubmitHandler = async (data: any) => {
    try {
      if (!customerData || !id) return;
      setLoader(true);
      const delta = getObjectDelta(customerData, {
        name: data.name,
        surname: data.surname,
        reg_nr: data.reg_nr,
        vat_nr: data.vat_nr,
        email: data.email,
        phone: data.phone,
        company_title: data.company_title,
        will_pay_after: data.will_pay_after ? 1 : 0
      });

      const updatedCostumer = await backofficeService.updateCustomerById(+id, delta);

      setCustomerData(updatedCostumer);

      toast.success("User updated successfully");
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setLoader(false);
    }
  };

  const updateFormState = async (data: ICustomerItemWithUser) => {
    reset({
      name: data.name,
      surname: data.surname,
      reg_nr: data.reg_nr,
      vat_nr: data.vat_nr,
      email: data.email,
      phone: data.phone,
      company_title: data.company_title,
      will_pay_after: data.will_pay_after
    });
  };

  useEffect(() => {
    if (customerData) {
      updateFormState(customerData).then(() => null);
    }
  }, [customerData]);

  useEffect(() => {
    fetch().then(() => null);
  }, [id]);

  return (
    <Row>
      {loader && <Loader />}
      <Col xs={12}>
        <Card>
          <CardBody>
            {customerData && (
              <>
                <h3>Customer #{customerData.id}</h3>
                <form onSubmit={handleSubmit(onSubmitHandler)}>
                  <InputField label="User" name="user" disabled readOnly value={userValue} />

                  <InputField {...register("name")} error={errors.name?.message} label="Name" name="name" />
                  <InputField {...register("surname")} error={errors.surname?.message} label="Surname" name="surname" />
                  <InputField
                    {...register("company_title")}
                    error={errors.company_title?.message}
                    label="Company title"
                    name="company_title"
                  />
                  <InputField {...register("reg_nr")} error={errors.reg_nr?.message} label="Reg nr" name="reg_nr" />
                  <InputField {...register("vat_nr")} error={errors.vat_nr?.message} label="Vat nr" name="vat_nr" />
                  <InputField {...register("email")} error={errors.email?.message} label="Email" name="email" />
                  <InputField {...register("phone")} error={errors.phone?.message} label="Phone" name="phone" />

                  <CheckboxFieldInput
                    {...register("will_pay_after")}
                    error={errors.will_pay_after?.message}
                    label="Will pay after"
                    name="will_pay_after"
                  />

                  <Button type="submit" color="success">
                    Save
                  </Button>
                  <Button
                    type="button"
                    color="primary"
                    className="ms-2"
                    onClick={() => {
                      updateFormState(customerData).then(() => null);
                    }}
                  >
                    Reset
                  </Button>
                </form>
              </>
            )}
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};
