import React from "react";

import { Comparator, textFilter } from "react-bootstrap-table2-filter";
import { Link } from "react-router-dom";

import { CopyToClipboard } from "../../components/common/CopyToClipboard";
import { ICustomerItem } from "../interfaces/backoffice.interface";

export const USER_TABLE_FILTER_INSTANCES: Record<string, any> = {
  id: null,
  name: null,
  called: null,
  customer: null,
  email: null,
  phone: null
};

export const CLEAR_USER_TABLE_FILTER_VALUES = () => {
  for (const key in USER_TABLE_FILTER_INSTANCES) {
    if (USER_TABLE_FILTER_INSTANCES[key] && typeof USER_TABLE_FILTER_INSTANCES[key] === "function") {
      if (key === "date") {
        USER_TABLE_FILTER_INSTANCES[key]({
          comparator: Comparator.EQ,
          date: ""
        });
      } else {
        USER_TABLE_FILTER_INSTANCES[key]("");
      }
    }
  }
};

export const USER_TABLE_COLUMNS = [
  {
    dataField: "id",
    text: "Id",
    sort: true,
    style: {
      maxWidth: "90px"
    },
    filter: textFilter({
      getFilter: (filter: any) => {
        USER_TABLE_FILTER_INSTANCES.id = filter;
      },
      caseSensitive: true,
      placeholder: " ",
      className: "form-control-sm mt-1"
    })
  },
  {
    dataField: "name",
    text: "Name",
    style: {
      maxWidth: "200px"
    },
    classes: "text-truncate",
    filter: textFilter({
      getFilter: (filter: any) => {
        USER_TABLE_FILTER_INSTANCES.name = filter;
      },
      caseSensitive: true,
      placeholder: " ",
      className: "form-control-sm mt-1"
    }),
    formatter: (cellContent: string) => {
      return <CopyToClipboard copyContent={cellContent}>{cellContent}</CopyToClipboard>;
    },
    sort: true
  },
  {
    dataField: "called",
    text: "Called",
    filter: textFilter({
      getFilter: (filter: any) => {
        USER_TABLE_FILTER_INSTANCES.called = filter;
      },
      caseSensitive: true,
      placeholder: " ",
      className: "form-control-sm mt-1"
    }),
    sort: true
  },
  {
    dataField: "customer",
    text: "Customer",
    style: {
      maxWidth: "200px"
    },
    classes: "text-truncate",
    filter: textFilter({
      getFilter: (filter: any) => {
        USER_TABLE_FILTER_INSTANCES.customer = filter;
      },
      caseSensitive: true,
      placeholder: " ",
      className: "form-control-sm mt-1"
    }),
    sort: true,
    formatter: (cellContent: ICustomerItem[]) => {
      const customer = cellContent[0];
      return (
        <>
          {customer ? (
            <Link to={`/backoffice/customers/${customer.id}/edit`}>
              {customer.name} {customer.surname}
            </Link>
          ) : (
            "-"
          )}
        </>
      );
    }
  },
  {
    dataField: "email",
    text: "Email",
    filter: textFilter({
      getFilter: (filter: any) => {
        USER_TABLE_FILTER_INSTANCES.email = filter;
      },
      caseSensitive: true,
      placeholder: " ",
      className: "form-control-sm mt-1"
    }),
    sort: true,
    formatter: (cellContent: string) => {
      return <CopyToClipboard copyContent={cellContent}>{cellContent}</CopyToClipboard>;
    }
  },
  {
    dataField: "phone",
    text: "Phone",
    filter: textFilter({
      getFilter: (filter: any) => {
        USER_TABLE_FILTER_INSTANCES.phone = filter;
      },
      caseSensitive: true,
      placeholder: " ",
      className: "form-control-sm mt-1"
    }),
    sort: true
  }
];
