import { createAsyncThunk } from "@reduxjs/toolkit";

import { ProductTypesState } from "../../../common/interfaces/product-types-slice.interface";
import { OrdersApiService } from "../../../services/api";

export const PRODUCT_TYPES_ACTION_UPDATE_LIST = createAsyncThunk(
  "order/updateProductList",
  async () => {
    return OrdersApiService.getProductList();
  },
  {
    condition: (_, { getState }) => {
      const state: any = getState();
      const sliceState = state.productTypes as ProductTypesState;

      if (sliceState.productList.loader || sliceState.productList.data.all.length) {
        return false;
      }

      return true;
    }
  }
);
