import React, { useEffect, useRef } from "react";

import Flatpickr, { DateTimePickerProps } from "react-flatpickr";
import { FormGroup, InputGroup, Label } from "reactstrap";

type Props = Partial<DateTimePickerProps> & {
  error?: any;
  label?: string;
};

export const DatePickerField: React.FC<Props> = ({ onChange, label, error, value, className, ...rest }) => {
  const fpRef = useRef<any>(null);

  useEffect(() => {
    if (Array.isArray(value) && value.length < 2) {
      fpRef.current.flatpickr.clear();
    }
  }, [value]);

  return (
    <FormGroup className={className ?? "mb-4"}>
      {label && <Label>{label}</Label>}
      <InputGroup>
        <Flatpickr
          ref={fpRef}
          className="form-control d-block"
          placeholder="DD/MM/YYYY"
          value={value}
          onChange={onChange}
          {...rest}
          options={{
            dateFormat: "d/m/Y",
            ...(rest.options ? rest.options : {})
          }}
        />
      </InputGroup>
      {error && <div className="invalid-feedback">{error}</div>}
    </FormGroup>
  );
};
