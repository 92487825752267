import React, { useState } from "react";

interface Props {
  images: string[];
}

export const ImageGallery: React.FC<Props> = ({ images }) => {
  const [selectedImageIdx, setSelectedImageIdx] = useState<number>(0);
  return (
    <div className="image-gallery">
      <div className="image-gallery__selected">
        <img src={images[selectedImageIdx]} alt="Selected" />
      </div>
      <div className="image-gallery__list">
        {images.map((image, idx) => (
          <div
            key={idx}
            className={`image-gallery__list__item ${selectedImageIdx === idx ? "selected" : ""}`}
            onClick={() => setSelectedImageIdx(idx)}
            role="button"
          >
            <img src={image} alt="Selected" />
          </div>
        ))}
      </div>
    </div>
  );
};
