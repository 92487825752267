import React from "react";

import { Dashboard } from "../components/dashboard/Dashboard";
import { DashboardIntroduction } from "../components/dashboard/DashboardIntroduction";
import useAuth, { LoggedUser } from "../hooks/useAuth";

export const DashboardPage: React.FC = () => {
  const { user } = useAuth<LoggedUser>();

  // return <>{!user.is_introduction_passed ? <DashboardIntroduction /> : <Dashboard />}</>;
  return <Dashboard />;
};
