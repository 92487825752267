import React, { useEffect, useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Card, Col, Row } from "reactstrap";
import * as Yup from "yup";

import { getErrorMessage } from "../../common/utils/formatter.util";
import { InputField } from "../../components/common/InputField";
import { useServiceContainer } from "../../hooks/useServiceContainer";

const validationSchema = Yup.object().shape({
  email: Yup.string().email().required(),
  password: Yup.string().required(),
  password_confirmation: Yup.string()
    .test("passwords-match", "Passwords must match", function (value) {
      return this.parent.password === value;
    })
    .required()
});

function Loader() {
  return null;
}

export const PasswordResetConfirmationPage = () => {
  const {
    register,
    setValue,
    formState: { errors },
    handleSubmit
  } = useForm({
    defaultValues: {} as any,
    resolver: yupResolver(validationSchema)
  });

  const [loader, setLoader] = useState(false);
  const { authService } = useServiceContainer();
  const navigate = useNavigate();
  const [token, setToken] = useState("");
  const [searchParams] = useSearchParams();

  const onSubmitHandler = async (data: any) => {
    try {
      setLoader(true);

      await authService.confirmPasswordReset(token, data);

      toast.success("Password successfully changed!");
      navigate("/auth/login");
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (!searchParams.has("token") || !searchParams.has("email")) {
      navigate("/auth/login");
    } else {
      setValue("email", searchParams.get("email")!);
      setToken(searchParams.get("token")!);
    }
  }, []);

  return (
    <>
      <Card className="overflow-hidden">
        {loader && <Loader />}
        <Row className="g-0">
          <Col lg={12}>
            <div className="p-lg-5 p-4">
              <div>
                <h5>Reset Password</h5>
              </div>

              <div className="mt-2 pt-3">
                <form onSubmit={handleSubmit(onSubmitHandler)}>
                  <InputField
                    required
                    {...register("email")}
                    error={errors.email?.message}
                    readOnly
                    label="Email"
                    type="email"
                    disabled
                    name="email"
                  />

                  <InputField
                    required
                    {...register("password")}
                    error={errors.password?.message}
                    label="Password"
                    type="password"
                    name="password"
                  />

                  <InputField
                    required
                    {...register("password_confirmation")}
                    error={errors.password_confirmation?.message}
                    label="Confirm password"
                    type="password"
                    name="password_confirmation"
                  />

                  <div className="mt-4 text-end">
                    <button className="btn btn-primary w-md waves-effect waves-light" type="submit">
                      Reset
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </Col>
        </Row>
      </Card>
      <div className="mt-5 text-center">
        <p>
          Remember It ?{" "}
          <Link to="/auth/login" className="fw-semibold text-decoration-underline">
            {" "}
            Login{" "}
          </Link>{" "}
        </p>
        <p>
          © {new Date().getFullYear()} <b>Alerio</b>
        </p>
      </div>
    </>
  );
};
