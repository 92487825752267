import { EventBusMessages } from "../common/interfaces/event-bus.interface";
import { PublishOptions, SubscribeOptions, Message, Handler } from "./useEventBus.types";

const defaultPublishOptions: PublishOptions = {
  targetOrigin: "*",
  targetWindow: window
};

const defaultSubscribeOptions: SubscribeOptions = {
  targetWindow: window
};

const useEventBus = () => {
  const publish = <Topic extends keyof EventBusMessages>(
    message: Message<EventBusMessages, Topic>,
    options: PublishOptions = defaultPublishOptions
  ) => {
    options.targetWindow.postMessage(message, options.targetOrigin);
  };

  const subscribe = <Topic extends keyof EventBusMessages>(
    topic: Topic,
    handler: Handler<EventBusMessages[Topic]>,
    options: SubscribeOptions = defaultSubscribeOptions
  ) => {
    const messageEventHandler = (event: MessageEvent<Message<EventBusMessages, Topic>>) =>
      subscriptionHandler(event, topic, handler);

    const attachEventListener = () => {
      options.targetWindow.addEventListener("message", messageEventHandler);
    };
    const detachEventListener = () => {
      options.targetWindow.removeEventListener("message", messageEventHandler);
    };

    attachEventListener();
    return { unsubscribe: detachEventListener };
  };

  const subscriptionHandler = <Topic extends keyof EventBusMessages>(
    event: MessageEvent<Message<EventBusMessages, Topic>>,
    topic: Topic,
    handler: Handler<EventBusMessages[Topic]>
  ) => {
    if (event.data.topic === topic) {
      handler(event.data.payload);
    }
  };

  return { publish, subscribe };
};

export default useEventBus;
