import React, { useEffect, useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Button, CardBody, Modal } from "reactstrap";
import { object, string } from "yup";

import { IProductListingPresetItem } from "../../../common/interfaces/products.interface";
import { getErrorMessage } from "../../../common/utils/formatter.util";
import { useServiceContainer } from "../../../hooks/useServiceContainer";
import { InputField } from "../../common/InputField";
import { Loader } from "../../common/Loader";

const validationSchema = object().shape({
  title: string().required("This field is required")
});

interface Props {
  show: boolean;
  listingItem: IProductListingPresetItem | null;
  toggle: () => void;
  onSubmit: () => void;
}

export const UpdateListingTemplateTitleModal: React.FC<Props> = ({ onSubmit, listingItem, show, toggle }) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset
  } = useForm({
    defaultValues: {
      code: "",
      title: ""
    },
    resolver: yupResolver(validationSchema)
  });

  const [loader, setLoader] = useState(false);

  const { productsApiService } = useServiceContainer();

  useEffect(() => {
    if (listingItem) {
      reset({
        title: listingItem.title
      });
    } else {
      reset();
    }
  }, [listingItem]);

  const onSubmitHandler = async (data: { title: string }) => {
    try {
      if (!listingItem) return;
      setLoader(true);
      await productsApiService.updateDefaultListingPreset(listingItem.id, {
        title: data.title
      });
      toggle();
      reset();
      onSubmit();
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setLoader(false);
    }
  };

  return (
    <Modal isOpen={show} toggle={toggle} centered size="lg">
      {loader && <Loader />}
      <div className="modal-header">
        <h5 className="modal-title mt-0">Update listing template title</h5>
        <button
          type="button"
          onClick={() => {
            toggle();
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form onSubmit={handleSubmit(onSubmitHandler)}>
        <div className="modal-body">
          <div className="row">
            <div className="col-12">
              <div className="card border">
                <CardBody>
                  <InputField
                    {...register("title")}
                    error={errors.title?.message}
                    label="Title"
                    name="title"
                    required
                    placeholder="Enter title"
                  />
                </CardBody>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <Button
            color="danger"
            onClick={() => {
              toggle();
            }}
          >
            Cancel
          </Button>
          <Button color="primary" type="submit">
            Save
          </Button>
        </div>
      </form>
    </Modal>
  );
};
