import React, { useMemo } from "react";

import classNames from "classnames";
import { Label } from "reactstrap";

import { makeId } from "../../../common/utils/random.util";
import { TooltipIcon } from "../TooltipIcon";

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: any;
  error?: any;
  tooltip?: string;
  green?: boolean;
  hideCheckbox?: boolean;
}

export const CheckboxFieldInput = React.forwardRef<HTMLInputElement, Props>(
  ({ hideCheckbox, green, tooltip, label, error, ...props }, ref: any) => {
    const tooltipId = useMemo(() => {
      if (!tooltip) return "";
      return makeId(7);
    }, [tooltip]);

    return (
      <div
        className={classNames("form-check mb-1 mx-1", {
          "form-check--green": green,
          "form-check--without-checkbox": hideCheckbox
        })}
      >
        <Label>
          {!hideCheckbox && (
            <input
              type="checkbox"
              ref={ref}
              className={classNames("form-check-input", { "is-invalid": !!error })}
              {...props}
            />
          )}
          {label}

          {tooltip && (
            <span className="ms-1">
              <TooltipIcon id={tooltipId} content={tooltip} />
            </span>
          )}
        </Label>

        {error ? <div className="invalid-feedback d-block">{error}</div> : ""}
      </div>
    );
  }
);
