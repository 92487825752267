export interface INotification {
  id: number;
  user_id: number;
  notification_type: NotificationType;
  module: number;
  module_id: number;
  action: number;
  sub_action: any;
  notification: string;
  url: string;
  read_at: string | null;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
}

export enum NotificationType {
  Main = 1,
  Invoices = 2,
  News = 3
}
