import React, { useEffect, useState } from "react";

import { toast } from "react-toastify";
import { Modal } from "reactstrap";

import { getErrorMessage } from "../../../common/utils/formatter.util";
import { useServiceContainer } from "../../../hooks/useServiceContainer";
import { Loader } from "../../common/Loader";
import { AssetPreview } from "../create-design/AssetPreview";

interface Props {
  show: boolean;
  data: {
    productId: number;
    designId: number;
    assetType: "iphone" | "samsung";
  } | null;
  toggle: () => void;
}

export const MockupPreviewModal: React.FC<Props> = ({ show, toggle, data }) => {
  const { mockupService } = useServiceContainer();
  const [loader, setLoader] = useState(false);

  const [generatedMockup, setGeneratedMockup] = useState<any>(null);

  const generatePreview = async () => {
    try {
      setLoader(true);
      if (!data) return;

      const { productId, designId, assetType } = data;
      const res = await mockupService.generateMockup({
        product_id: productId,
        design_ids: [designId],
        sku_number: "PREVIEW",
        mockup_settings: {
          asset_type: assetType,
          asset_color: "white",
          mockup_background_color: "white",
          options: {
            show_numbers: false
          }
        }
      });

      setGeneratedMockup(`${res.files[0].image as string}`);
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (show && data) {
      generatePreview().then(() => null);
    } else {
      setGeneratedMockup(null);
    }
  }, [show]);

  return (
    <Modal isOpen={show} toggle={toggle} centered size="lg">
      {loader && <Loader />}
      <div className="modal-header">
        <h5 className="modal-title mt-0">Mockup preview</h5>
        <button
          type="button"
          onClick={() => {
            toggle();
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body pb-5">
        <div className="asset-preview-wrapper">
          {generatedMockup && (
            <div className="image-preview">
              <img src={generatedMockup} alt="preview" />
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};
