import React, { useEffect, useMemo, useState } from "react";

import { Card, CardBody } from "reactstrap";

import { IProductItem } from "../../common/interfaces/products.interface";
import { formatProductType } from "../../common/utils/formatter.util";
import { DesignSelect } from "../../components/common/DesignSelect/DesignSelect";
import { Loader } from "../../components/common/Loader";
import { SelectField } from "../../components/common/SelectField";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { PRODUCT_TYPES_SET_SELECTED } from "../../store/wizards/productTypesSlice";
import { PRODUCT_TYPES_ACTION_UPDATE_LIST } from "../../store/wizards/ThunkActions/productTypesSliceThunkActions";

export const PrintFilesPage = () => {
  const dispatch = useAppDispatch();
  const {
    productList: { data: productTypes, loader: productTypesLoader },
    selectedProduct
  } = useAppSelector((state) => state.productTypes);

  const productTypesOptions = useMemo(() => {
    const uniqueProductsByType: IProductItem[] = productTypes.all.filter(
      (item, index, self) => index === self.findIndex((t) => t.type === item.type)
    );

    return uniqueProductsByType.map((item) => ({
      label: `${formatProductType(item.type)}`,
      value: item.id.toString()
    }));
  }, [productTypes]);

  const handleProductTypeChange = (data: any) => {
    const product = productTypes.all.find((e) => e.id.toString() === data.value);
    if (product) {
      dispatch(PRODUCT_TYPES_SET_SELECTED(product));
    }
  };

  useEffect(() => {
    dispatch(PRODUCT_TYPES_ACTION_UPDATE_LIST());
  }, []);

  return (
    <div className="position-relative d-flex flex-column flex-grow-1">
      {productTypesLoader ? <Loader /> : null}
      <div className="row">
        <div className="col-4">
          <SelectField
            options={productTypesOptions}
            placeholder="Select product type"
            required
            value={productTypesOptions.find((e) => e.value === selectedProduct?.id.toString()) || null}
            onChange={handleProductTypeChange}
          />
        </div>
      </div>
      <div className="row flex-grow-1">
        {selectedProduct && (
          <div className="col-12">
            <Card className="h-100">
              <CardBody>
                <h3>Print files</h3>

                <DesignSelect selectedProduct={selectedProduct} multiSelect />
              </CardBody>
            </Card>
          </div>
        )}
      </div>
    </div>
  );
};
