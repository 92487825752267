import React, { useEffect, useState } from "react";

import { useFormContext } from "react-hook-form";
import { Alert } from "reactstrap";

import { extendFile } from "../../../../common/utils/file.util";
import { FileUploadStep } from "../../create-design/FileUploadStep";

export const MockupFileUpload: React.FC = () => {
  const {
    register,
    setValue,
    setError,
    watch,
    formState: { errors }
  } = useFormContext();

  const uploadedFiles = watch("uploadedFiles");

  useEffect(() => {
    register("uploadedFiles");
  }, []);

  return (
    <div className="card border">
      <div className="p-3">
        {errors.uploadedFiles?.message ? <Alert color="danger">{errors.uploadedFiles.message as string}</Alert> : ""}
        <FileUploadStep
          hideSubmitButton
          alwaysShowTitle
          limitations={[10]}
          title="Mockup files:"
          canUploadMore
          onUpload={async (files) => {
            for (const file of files) {
              extendFile(file);
            }

            setError("uploadedFiles", {
              type: "manual",
              message: ""
            });
            setValue("uploadedFiles", files);
          }}
          uploadedFiles={uploadedFiles}
          containerSize={12}
          description={<>Upload your mockup files up to 10 files are allowed</>}
        />
      </div>
    </div>
  );
};
