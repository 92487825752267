import React from "react";

import { Modal } from "reactstrap";

import { AssetPreview } from "../create-design/AssetPreview";

interface Props {
  show: boolean;
  image?: string;
  title: string;
  toggle: () => void;
  previewAssetSrc?: string;
}

export const PreviewModal: React.FC<Props> = ({ show, toggle, image, title, previewAssetSrc }) => {
  return (
    <Modal isOpen={show} toggle={toggle} centered size="auto">
      <div className="modal-header">
        <h5 className="modal-title mt-0">{title}</h5>
        <button
          type="button"
          onClick={() => {
            toggle();
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body pb-5">
        <div className="asset-preview-wrapper">
          {previewAssetSrc ? <AssetPreview assetSrc={previewAssetSrc} /> : ""}
          <div className="image-preview">
            <img src={image} alt="preview" />
          </div>
        </div>
      </div>
    </Modal>
  );
};
