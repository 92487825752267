import { IBaseApiResponse } from "../../common/interfaces/api.interface";
import {
  IGenerateMockupRequest,
  IMockupEntity,
  IMockupAvailableSettings,
  IUploadMockupsRequest
} from "../../common/interfaces/mockup.inteface";
import BaseAPIService from "./BaseApiService";

const replaceInvalidCharacters = (str: string) => {
  return str.replace(/[^a-zA-Z0-9-]/g, "_");
};

class MockupApiService extends BaseAPIService {
  constructor() {
    super(`${process.env.REACT_APP_API_BASE_ENDPOINT}/api/mockups`);
  }

  public async getMockupSettings(productId: number): Promise<IMockupAvailableSettings> {
    const res = await this.api.get<IBaseApiResponse<IMockupAvailableSettings>>(`settings?productId=${productId}`);

    return res.data.data;
  }

  public async getMockupList(productId: number): Promise<IMockupEntity[]> {
    const res = await this.api.get<IBaseApiResponse<IMockupEntity[]>>(`list?productId=${productId}`);

    return res.data.data.map((e) => ({
      // replace . with _ to avoid issues with selectors, also replace whitespace with _
      ...e,
      id: replaceInvalidCharacters(e.id.toString())
      // files: e.files?.map((f) => ({
      //   ...f,
      //   id: f.id.toString().replace(/\./g, "_").replace(/\s/g, "_")
      // }))
    }));
  }

  public async generateMockup(payload: IGenerateMockupRequest): Promise<IMockupEntity> {
    const res = await this.api.post<IBaseApiResponse<IMockupEntity>>(`generate`, payload);

    return res.data.data;
  }

  public async uploadMockups(payload: IUploadMockupsRequest): Promise<IMockupEntity> {
    const formData = new FormData();

    formData.append("sku_number", payload.sku_number);
    formData.append("product_id", payload.product_id.toString());
    payload.files.forEach((file) => {
      formData.append("files[]", file);
    });

    const res = await this.api.postForm<IBaseApiResponse<IMockupEntity>>(`existing`, formData);

    return res.data.data;
  }

  public async deleteMockupFolderById(id: string): Promise<void> {
    await this.api.get(`${id}/delete`);
  }
}

const instance = new MockupApiService();

export default instance;
