import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";

import { IShopItem } from "../../common/interfaces/integrations.interface";
import { IMockupEntity, IMockupFile } from "../../common/interfaces/mockup.inteface";
import {
  IProductDesignMockupConnectorItem,
  IProductSelectedListingItem,
  PriceIncreaseType,
  ProductListingPriceMap,
  ProductWizardState,
  ProductWizardStep
} from "../../common/interfaces/product-wizard.interface";
import {
  IDesignItem,
  IProductAssetGroup,
  IProductItem,
  IProductListingPresetItem,
  IProductListingTemplate,
  ProductListingTemplateFields
} from "../../common/interfaces/products.interface";
import { applyBulkOptionToProductPriceMap } from "../../common/utils/product-price-map.util";

export const productWizardInitialState: ProductWizardState = {
  activeWizardStep: ProductWizardStep.PRODUCT_SELECTION,
  selectedProductType: null,
  // selectedProductAsset: null,
  selectedMockupEntity: null,
  selectedDesigns: [],
  designMockupConnector: [],
  selectedListings: [],
  selectedShopItem: null,
  selectedProductMaterial: null,
  pricingData: {
    freeShipping: false,
    priceMap: []
  },
  data: {
    listingGroupsPresets: [],
    assetGroups: [],
    listingGroupTemplates: []
  }
};

export const DEFAULT_LISTING_FIELDS = {
  title: "",
  description: "",
  tags: [],
  materials: [],
  section: "",
  shipping_profile: "",
  keywords: {
    keyword1: "",
    keyword2: ""
  }
};

export const productWizardSlice = createSlice({
  name: "order",
  initialState: productWizardInitialState,
  reducers: {
    PRODUCT_SET_LISTING_GROUP_PRESETS: (
      state: ProductWizardState,
      action: PayloadAction<IProductListingPresetItem[]>
    ) => {
      state.data.listingGroupsPresets = action.payload;
    },
    PRODUCT_DELETE_LISTING_GROUP_PRESET_BY_ID: (
      state: ProductWizardState,
      action: PayloadAction<{
        id: number;
      }>
    ) => {
      state.data.listingGroupsPresets = state.data.listingGroupsPresets.filter((item) => item.id !== action.payload.id);
      state.selectedListings = state.selectedListings.filter((item) => item.id !== action.payload.id);
    },
    PRODUCT_SET_ASSET_GROUPS: (state: ProductWizardState, action: PayloadAction<IProductAssetGroup[]>) => {
      state.data.assetGroups = action.payload;
    },
    PRODUCT_RESET_WIZARD: (state: ProductWizardState) => {
      state.activeWizardStep = ProductWizardStep.PRODUCT_SELECTION;
      state.selectedProductType = null;
      // state.selectedProductAsset = null;
      state.selectedListings = [];
      state.selectedShopItem = null;
      state.selectedDesigns = [];
      state.selectedProductMaterial = null;
      state.designMockupConnector = [];
      state.pricingData = {
        freeShipping: false,
        priceMap: []
      };
      state.data = {
        listingGroupsPresets: [],
        assetGroups: [],
        listingGroupTemplates: []
      };
    },
    PRODUCT_SET_WIZARD_ACTIVE_STEP: (state: ProductWizardState, action: PayloadAction<ProductWizardStep>) => {
      state.activeWizardStep = action.payload;
    },
    PRODUCT_SET_SELECTED_SHOP_ITEM: (state: ProductWizardState, action: PayloadAction<IShopItem | null>) => {
      state.selectedShopItem = action.payload;
    },
    PRODUCT_TOGGLE_LISTING_ITEM: (state: ProductWizardState, action: PayloadAction<IProductListingPresetItem>) => {
      if (state.selectedListings.find((item) => item.id === action.payload.id)) {
        state.selectedListings = state.selectedListings.filter((item) => item.id !== action.payload.id);
      } else {
        state.selectedListings = [
          ...state.selectedListings,
          {
            ...action.payload,
            fields: { ...DEFAULT_LISTING_FIELDS }
          }
        ];
      }
    },
    PRODUCT_DESELECT_LISTING_ITEM: (state: ProductWizardState, action: PayloadAction<number>) => {
      state.selectedListings = state.selectedListings.filter((item) => item.id !== action.payload);
    },
    PRODUCT_SET_SELECTED_LISTING_FIELD_VALUE: (
      state: ProductWizardState,
      action: PayloadAction<{
        listingId: number;
        field: keyof IProductSelectedListingItem["fields"];
        value: any;
      }>
    ) => {
      const listing = state.selectedListings.find((item) => item.id === action.payload.listingId);
      if (listing) {
        listing.fields[action.payload.field] = action.payload.value;
      }
    },
    PRODUCT_SET_SELECTED_PRODUCT_TYPE: (state: ProductWizardState, action: PayloadAction<IProductItem | null>) => {
      state.selectedProductType = action.payload;
      state.selectedMockupEntity = null;
      // state.selectedProductAsset = null;
      state.selectedDesigns = [];
    },
    PRODUCT_SET_SELECTED_MOCKUP_ENTITY: (state: ProductWizardState, action: PayloadAction<IMockupEntity | null>) => {
      state.selectedMockupEntity = action.payload;
    },
    PRODUCT_MOVE_MOCKUP_ENTITY_FILE: (
      state: ProductWizardState,
      action: PayloadAction<{
        fileId: number;
        cursor: 1 | -1;
      }>
    ) => {
      // write an algorithm to move file in array
      // change order property of target file also change order property of file that is on the target position
      // if cursor is 1 then move file down, if cursor is -1 then move file up
      // TODO:

      if (state.selectedMockupEntity && state.selectedMockupEntity.files) {
        const { fileId, cursor } = action.payload;
        const { files } = state.selectedMockupEntity;
        const index = files.findIndex((file) => file.id === fileId);

        // Check if the file can be moved
        if ((index === 0 && cursor === -1) || (index === files.length - 1 && cursor === 1)) {
          return;
        }

        // Swap the file with the file at the target position
        const targetIndex = index + cursor;
        const temp = files[index];
        files[index] = files[targetIndex];
        files[targetIndex] = temp;

        // Update the order property
        files[index].order = index;
        files[targetIndex].order = targetIndex;
      }
    },
    PRODUCT_SWAP_MOCKUP_ENTITY_FILE: (
      state: ProductWizardState,
      action: PayloadAction<{
        fileId: number;
        targetFileId: number;
      }>
    ) => {
      if (!state.selectedMockupEntity) return;

      const { files } = state.selectedMockupEntity;
      const fileIndex = files.findIndex((file) => +file.id === action.payload.fileId);
      const targetFileIndex = files.findIndex((file) => +file.id === action.payload.targetFileId);

      if (fileIndex === -1 || targetFileIndex === -1) return;

      // const newFiles = [...files];
      const tempOrder = state.selectedMockupEntity.files[fileIndex].order;
      state.selectedMockupEntity.files[fileIndex].order = state.selectedMockupEntity.files[targetFileIndex].order;
      state.selectedMockupEntity.files[targetFileIndex].order = tempOrder;
    },
    PRODUCT_DRAG_MOVE_MOCKUP_ENTITY_FILE: (
      state: ProductWizardState,
      action: PayloadAction<{
        current: IMockupFile;
        target: IMockupFile;
      }>
    ) => {
      // write an algorithm to move file in array via order property
      // change order property of target file also change order property of file that is on the target position
      // TODO:
      // example with array of numbers:
      // const currentIndex = renderedItems.indexOf(draggedItem);
      // const targetIndex = renderedItems.indexOf(item);
      //
      // if (currentIndex !== -1 && targetIndex !== -1) {
      //   const newItems = [...renderedItems];
      //   newItems.splice(currentIndex, 1);
      //   newItems.splice(targetIndex, 0, draggedItem);
      //   setTemporaryItems(newItems);
      // }

      if (state.selectedMockupEntity && state.selectedMockupEntity.files) {
        const { current, target } = action.payload;
        const { files } = state.selectedMockupEntity;
        const currentIndex = files.findIndex((file) => file.id === current.id);
        const targetIndex = files.findIndex((file) => file.id === target.id);

        if (currentIndex !== -1 && targetIndex !== -1) {
          // Remove the current file from its current position
          const [removed] = files.splice(currentIndex, 1);
          // Insert the current file at the position of the target file
          files.splice(targetIndex, 0, removed);

          // Update the order property of all files
          files.forEach((file, index) => {
            file.order = index;
          });
        }
      }
    },
    PRODUCT_SET_SELECTED_DESIGN: (state: ProductWizardState, action: PayloadAction<IDesignItem | null>) => {
      if (action.payload) {
        if (state.selectedDesigns.find((item) => item.id === action.payload!.id)) {
          state.selectedDesigns = state.selectedDesigns.filter((item) => item.id !== action.payload!.id);
        } else {
          state.selectedDesigns = [...state.selectedDesigns, action.payload];
        }
      } else {
        state.selectedDesigns = [];
      }
    },
    PRODUCT_SET_SELECTED_DESIGNS: (state: ProductWizardState, action: PayloadAction<IDesignItem[]>) => {
      state.selectedDesigns = action.payload;
    },
    PRODUCT_UPDATE_LISTING_GROUP: (
      state: ProductWizardState,
      action: PayloadAction<{
        listingId: number;
        groups: IProductAssetGroup[];
      }>
    ) => {
      const listingGroup = state.data.listingGroupsPresets.find((group) => group.id === action.payload.listingId);
      const selectedListing = state.selectedListings.find((item) => item.id === action.payload.listingId);
      if (selectedListing) {
        selectedListing.groups = action.payload.groups;
      }
      if (listingGroup) {
        listingGroup.groups = action.payload.groups;
      }
    },
    PRODUCT_ADD_LISTING_GROUP: (state: ProductWizardState, action: PayloadAction<IProductListingPresetItem>) => {
      state.data.listingGroupsPresets.push(action.payload);
    },
    PRODUCT_SET_OR_UPDATE_DESIGN_MOCKUP_CONNECTOR: (
      state: ProductWizardState,
      action: PayloadAction<IProductDesignMockupConnectorItem[]>
    ) => {
      const copy = _.cloneDeep(state.designMockupConnector);

      const newItems = [];

      for (const item of action.payload) {
        const existing = copy.find((e) => e.designId === item.designId);

        if (existing) {
          item.mockupId = existing.mockupId;
        }

        newItems.push(item);
      }

      state.designMockupConnector = newItems;
    },
    PRODUCT_SET_DESIGN_MOCKUP_CONNECTOR: (
      state: ProductWizardState,
      action: PayloadAction<IProductDesignMockupConnectorItem[]>
    ) => {
      // const copy = _.cloneDeep(state.designMockupConnector);
      //
      // const newItems = [];
      //
      // for (const item of action.payload) {
      //   const existing = copy.find((e) => e.designId === item.designId);
      //
      //   if (existing) {
      //     item.mockupId = existing.mockupId;
      //   }
      //
      //   newItems.push(item);
      // }

      state.designMockupConnector = action.payload;
    },
    PRODUCT_UPDATE_DESIGN_MOCKUP_CONNECTOR_ITEM: (
      state: ProductWizardState,
      action: PayloadAction<{
        designId: number;
        mockupId: number;
      }>
    ) => {
      const item = state.designMockupConnector.find((e) => e.designId === action.payload.designId);
      if (item) {
        item.mockupId = action.payload.mockupId;
      }
    },
    PRODUCT_ADD_LISTING_TEMPLATE: (state: ProductWizardState, action: PayloadAction<IProductListingTemplate>) => {
      state.data.listingGroupTemplates.push(action.payload);
    },
    PRODUCT_UPDATE_LISTING_TEMPLATE: (
      state: ProductWizardState,
      action: PayloadAction<{
        id: number;
        fields: ProductListingTemplateFields;
      }>
    ) => {
      const template = state.data.listingGroupTemplates.find((item) => item.id === action.payload.id);
      if (template) {
        template.fields = action.payload.fields;
      }
    },
    PRODUCT_DELETE_LISTING_TEMPLATE: (
      state: ProductWizardState,
      action: PayloadAction<{
        id: number;
      }>
    ) => {
      state.data.listingGroupTemplates = state.data.listingGroupTemplates.filter(
        (item) => item.id !== action.payload.id
      );
    },
    PRODUCT_SET_LISTING_TEMPLATES: (state: ProductWizardState, action: PayloadAction<IProductListingTemplate[]>) => {
      state.data.listingGroupTemplates = action.payload;
    },
    PRODUCT_SET_PRICING_FREE_SHIPPING: (state: ProductWizardState, action: PayloadAction<boolean>) => {
      state.pricingData.freeShipping = action.payload;
    },
    PRODUCT_RESET_PRICING_MAP: (state: ProductWizardState, action: PayloadAction<void>) => {
      state.pricingData.priceMap = [];
    },
    PRODUCT_SET_PRICING_MAP_VALUE: (
      state: ProductWizardState,
      action: PayloadAction<{
        listingId: number;
        assetId: number;
        value: string | null;
      }>
    ) => {
      const candidate = state.pricingData.priceMap.find((item) => item.listingId === action.payload.listingId);
      if (candidate) {
        candidate.map[action.payload.assetId] = action.payload.value;
      } else {
        state.pricingData.priceMap.push({
          listingId: action.payload.listingId,
          map: {
            [action.payload.assetId]: action.payload.value
          }
        });
      }
    },
    PRODUCT_SET_PRICING_MAP: (
      state: ProductWizardState,
      action: PayloadAction<
        {
          listingId: number;
          items: {
            assetId: number;
            value: string | null;
          }[];
        }[]
      >
    ) => {
      state.pricingData.priceMap = action.payload.reduce((acc, item) => {
        acc.push({
          listingId: item.listingId,
          map: item.items.reduce((acc2, item2) => {
            acc2[item2.assetId] = item2.value;
            return acc2;
          }, {} as any)
        });
        return acc;
      }, [] as ProductListingPriceMap[]);
      // state.pricingData.priceMap = action.payload.reduce((acc, item) => {
      //   acc[item.assetId] = item.value;
      //   return acc;
      // }, {} as any);
    },
    PRODUCT_BULK_SET_PRICING_MAP_VALUE: (
      state: ProductWizardState,
      action: PayloadAction<{
        type: PriceIncreaseType;
        value: number;
      }>
    ) => {
      state.pricingData.priceMap = applyBulkOptionToProductPriceMap(
        state.pricingData.priceMap,
        action.payload.type,
        action.payload.value
      );
    }
  },
  extraReducers: (builder) => {
    // builder
    //   .addCase(ORDER_ACTION_UPDATE_COUNTRY_LIST.pending, (state) => {
    //     state.countryList.loader = true;
    //   })
    //   .addCase(ORDER_ACTION_UPDATE_COUNTRY_LIST.fulfilled, (state, action) => {
    //     state.countryList.data = action.payload;
    //     state.countryList.loader = false;
    //   });
  }
});

export const {
  PRODUCT_RESET_WIZARD,
  PRODUCT_SET_WIZARD_ACTIVE_STEP,
  PRODUCT_SET_SELECTED_SHOP_ITEM,
  PRODUCT_SET_SELECTED_DESIGN,
  PRODUCT_TOGGLE_LISTING_ITEM,
  PRODUCT_SET_SELECTED_MOCKUP_ENTITY,
  // PRODUCT_SET_SELECTED_PRODUCT_ASSET,
  PRODUCT_UPDATE_LISTING_GROUP,
  PRODUCT_SET_SELECTED_PRODUCT_TYPE,
  PRODUCT_ADD_LISTING_GROUP,
  PRODUCT_SET_SELECTED_LISTING_FIELD_VALUE,
  PRODUCT_SET_PRICING_FREE_SHIPPING,
  PRODUCT_SET_PRICING_MAP_VALUE,
  PRODUCT_BULK_SET_PRICING_MAP_VALUE,
  PRODUCT_RESET_PRICING_MAP,
  PRODUCT_SET_PRICING_MAP,
  PRODUCT_SET_LISTING_GROUP_PRESETS,
  PRODUCT_SET_ASSET_GROUPS,
  PRODUCT_DELETE_LISTING_GROUP_PRESET_BY_ID,
  PRODUCT_ADD_LISTING_TEMPLATE,
  PRODUCT_SET_LISTING_TEMPLATES,
  PRODUCT_UPDATE_LISTING_TEMPLATE,
  PRODUCT_DELETE_LISTING_TEMPLATE,
  PRODUCT_SET_DESIGN_MOCKUP_CONNECTOR,
  PRODUCT_UPDATE_DESIGN_MOCKUP_CONNECTOR_ITEM,
  PRODUCT_MOVE_MOCKUP_ENTITY_FILE,
  PRODUCT_DRAG_MOVE_MOCKUP_ENTITY_FILE,
  PRODUCT_SWAP_MOCKUP_ENTITY_FILE,
  PRODUCT_SET_SELECTED_DESIGNS,
  PRODUCT_SET_OR_UPDATE_DESIGN_MOCKUP_CONNECTOR,
  PRODUCT_DESELECT_LISTING_ITEM
} = productWizardSlice.actions;

export default productWizardSlice.reducer;

// export * from "./ThunkActions/orderSliceThunkActions";
