import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { CheckoutState } from "../../common/interfaces/checkout-slice.interface";

export const checkoutInitialState: CheckoutState = {
  modalOpen: false,
  paymentMethods: [],
  orderIds: []
};

export const checkoutSlice = createSlice({
  name: "checkout",
  initialState: checkoutInitialState,
  reducers: {
    INIT_CHECKOUT_MODAL: (
      state: CheckoutState,
      action: PayloadAction<{
        paymentMethods: string[];
        orderIds: number[];
      }>
    ) => {
      state.modalOpen = true;
      state.paymentMethods = action.payload.paymentMethods;
      state.orderIds = action.payload.orderIds;
    },
    CLOSE_CHECKOUT_MODAL: (state: CheckoutState) => {
      state.modalOpen = false;
      state.paymentMethods = [];
      state.orderIds = [];
    }
  }
});

export const { INIT_CHECKOUT_MODAL, CLOSE_CHECKOUT_MODAL } = checkoutSlice.actions;

export default checkoutSlice.reducer;
