import moment from "moment";

export const assembleFiltersAndSort = (
  filters?: Record<
    string,
    | string
    | {
        date: Date;
      }
  >,
  sort?: any,
  dates?: Date[]
): Record<string, string> => {
  const filterQuery: [string, string][] = filters
    ? Object.keys(filters)
        .filter((key) => {
          const value = filters[key];
          if (typeof value === "object") {
            if (value.date) return true;
          } else if (typeof value === "string") {
            return true;
          }

          return false;
        })
        .map((key) => {
          const value = filters[key];
          if (typeof value === "object" && value.date) {
            return [`filter[${key}]`, `${moment(value.date).format("YYYY-MM-DD")}` as string];
          }

          return [`filter[${key}]`, value as string];
        })
    : [];

  const sortQuery: [string, string][] = sort
    ? Object.keys(sort).map((key) => {
        return [`sort[${key}]`, `${sort[key]}`];
      })
    : [];

  return {
    ...assembleDateFilters(dates),
    ...Object.fromEntries(filterQuery),
    ...Object.fromEntries(sortQuery)
  };
};

export const assembleDateFilters = (dates?: Date[]) => {
  const result: any = {};
  if (dates) {
    const [startDate, endDate] = dates;

    if (startDate) {
      result["filter[date_from]"] = moment(startDate).format("YYYY-MM-DD");
    }

    if (endDate) {
      const isSameAsStartDate = moment(startDate).isSame(endDate);
      if (!isSameAsStartDate) {
        result["filter[date_to]"] = moment(endDate).format("YYYY-MM-DD");
      }
    }
  }

  return result;
};

export const parseQueryFilters = (filters?: Record<string, string>): Record<string, string> => {
  const result: Record<string, string> = {};

  if (!filters) {
    return result;
  }

  Object.keys(filters).forEach((key) => {
    const [prefix, suffix] = key.split("[");
    if (prefix === "filter") {
      result[suffix.slice(0, -1)] = filters[key];
    } else if (prefix === "sort") {
      // skip
    } else {
      result[key] = filters[key];
    }
  });

  return result;
};

export const parseQuerySort = (filters?: Record<string, string>): Record<string, string> => {
  // filters = {"filter[key]: "value", "filter[key2]": "value2", "sort[key3]": "value3"}
  // result = {"key": "value", "key2": "value2"}

  const result: Record<string, string> = {};

  if (!filters) {
    return result;
  }

  Object.keys(filters).forEach((key) => {
    const [prefix, suffix] = key.split("[");
    if (prefix === "sort") {
      result[suffix.slice(0, -1)] = filters[key];
    }
  });

  return result;
};
