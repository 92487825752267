import React, { useState } from "react";

import classNames from "classnames";
import YouTube from "react-youtube";
import { Button, Modal } from "reactstrap";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  title: string;
  videoId: string;
}

export const VideoGuide: React.FC<Props> = ({ title, videoId, className, ...rest }) => {
  const [showModal, setShowModal] = useState(false);
  const ref = React.createRef<YouTube>();

  const onModalToggle = () => {
    setShowModal(!showModal);
  };

  return (
    <div className={classNames("video-guide", className)} {...rest}>
      <Button
        color="primary"
        className="video-guide__btn"
        onClick={() => {
          onModalToggle();
        }}
        type="button"
      >
        <i className="bx bxl-youtube" /> Show video guide
      </Button>

      <Modal isOpen={showModal} toggle={onModalToggle} centered size="auto">
        <div className="modal-header">
          <h5 className="modal-title mt-0">{title}</h5>
          <button
            type="button"
            onClick={() => {
              onModalToggle();
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body pb-5">
          <YouTube
            ref={ref}
            videoId={videoId}
            opts={{
              height: "457",
              width: "750",
              playerVars: {
                // https://developers.google.com/youtube/player_parameters
                autoplay: 1
              }
            }}
          />
        </div>
      </Modal>
    </div>
  );
};
