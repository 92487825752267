import React, { useEffect } from "react";

import { useFormContext } from "react-hook-form";
import { toast } from "react-toastify";

import { useAppDispatch, useAppSelector } from "../../../../store/store";
import { PRODUCT_SET_SELECTED_SHOP_ITEM } from "../../../../store/wizards/productSlice";
import { SelectableShopList } from "../../../integrations/SelectableShopList";
import { WizardScrollableContainer } from "../../common/WizardScrollableContainer";

export const ProductIntegrationStep = () => {
  const dispatch = useAppDispatch();
  const selectedShopItem = useAppSelector((state) => {
    return state.productWizard.selectedShopItem;
  });

  const {
    register,
    setValue,
    setError,
    formState: { errors }
  } = useFormContext();

  useEffect(() => {
    register("shopItemId");
  }, [register]);

  useEffect(() => {
    setValue("shopItemId", selectedShopItem?.id);
  }, [selectedShopItem]);

    useEffect(() => {
        if (Object.keys(errors).length > 0) {
            if (errors.shopItemId?.message) {
                // @ts-ignore
                toast.error(errors.shopItemId?.message)
            }
        }
    }, [errors]);

  return (
    <>
      <p className="wizard-step__description">
        To continue, please choose one of your connected shops from the list below. If you do not have any shops here,
        please connect them on the Integrations section on the main left menu.
      </p>
      <div className="mt-3">
        <WizardScrollableContainer error={errors.shopItemId?.message} withoutMaxHeight>
          <SelectableShopList
            onSelect={(item) => {
              setError("shopItemIds", {
                type: "manual",
                message: ""
              });
              dispatch(PRODUCT_SET_SELECTED_SHOP_ITEM(item));
            }}
            selectedItems={selectedShopItem ? [selectedShopItem] : []}
          />
        </WizardScrollableContainer>
      </div>
    </>
  );
};
