import { useEffect, useState } from "react";

import { toast } from "react-toastify";

import { IProductSectionOption, IProductShippingProfileItem } from "../../common/interfaces/products.interface";
import { getErrorMessage } from "../../common/utils/formatter.util";
import { useServiceContainer } from "../useServiceContainer";

export const useCommonProductListingOptions = (shopId: number | null) => {
  const [sectionOptions, setSectionOptions] = useState<IProductSectionOption[]>([]);
  const [shippingProfiles, setShippingProfiles] = useState<IProductShippingProfileItem[]>([]);
  const { productsApiService } = useServiceContainer();
  const fetchSectionOptions = async () => {
    try {
      const sections = await productsApiService.getEtsySectionOptions(shopId!);
      setSectionOptions(sections);
    } catch (e) {
      toast.error(getErrorMessage(e));
    }
  };

  const fetchShippingProfiles = async () => {
    try {
      const items = await productsApiService.getEtsyShippingProfiles(shopId!);
      setShippingProfiles(items);
    } catch (e) {
      toast.error(getErrorMessage(e));
    }
  };

  useEffect(() => {
    if (shopId !== null) {
      fetchSectionOptions().then(() => null);
      fetchShippingProfiles().then(() => null);
    }
  }, [shopId]);

  return {
    sectionOptions,
    shippingProfiles
  };
};
