import { CoursesCategory } from "../interfaces/courses.interface";

export const COURSES_CATEGORIES = [
  {
    label: "Etsy",
    value: CoursesCategory.Etsy
  },
  {
    label: "Shopify",
    value: CoursesCategory.Shopify
  }
];
