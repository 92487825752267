import React, { useState } from "react";

import { toast } from "react-toastify";
import { Collapse } from "reactstrap";

import { Address, parseAddress } from "../../../common/utils/address-parser.util";
import { TooltipIcon } from "../../common/TooltipIcon";

interface Props {
  onInsert: (address: Address) => void;
}

export const InsertAddress: React.FC<Props> = ({ onInsert }) => {
  const [address, setAddress] = useState("");

  // const parsedAddress = useMemo(() => {
  //   if (!address) return null;
  //   return parseAddress(address);
  // }, [address]);

  return (
    <div className="insert-address mb-3">
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label htmlFor="etsy-adr">Enter address from your e-shop</label>
      <div className="insert-address__input-wrapper">
        <textarea
          autoComplete="new-password"
          className="form-control"
          value={address}
          name="etsy-input"
          onChange={(e) => {
            setAddress(e.target.value);
          }}
          placeholder="Enter address from ETSY"
          id="etsy-adr"
        />
        <div className="insert-address__input-wrapper__addon">
          <button
            type="button"
            className="insert-address__button btn btn-primary btn-sm"
            onClick={() => {
              try {
                const parsedAddress = parseAddress(address);
                onInsert(parsedAddress);
              } catch (e) {
                toast.error("Invalid address format.");
              }
            }}
          >
            Insert address
          </button>
        </div>
      </div>
    </div>
  );
};
