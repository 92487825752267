import React, { useMemo, useState } from "react";

import BootstrapTable from "react-bootstrap-table-next";
import filterFactory from "react-bootstrap-table2-filter";
import paginationFactory, { PaginationListStandalone, PaginationProvider } from "react-bootstrap-table2-paginator";
// @ts-ignore
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown
} from "reactstrap";

import {
  IProductListingItem,
  IProductListingItemDeliveryOptions,
  IProductListingItemProduct,
  IProductListingItemUser,
  ProductListingItemInventoryStatus,
  ProductListingItemStatus
} from "../../../common/interfaces/products.interface";
import { getImageUrl } from "../../../common/utils/formatter.util";
import { calculateModelCount } from "../../../common/utils/product-listings.util";
import { Loader } from "../../../components/common/Loader";
import { TooltipIcon } from "../../../components/common/TooltipIcon";
import { useListingManagementTable } from "../../../hooks/listingManagement/useListingManagementTable";

export const ListingsManagementPage = () => {
  const {
    items,
    loader,
    onTableChange,
    pageOptions,
    ListingStatusControlModalComponent,
    openListingStatusControlModal,
    handleListingDelete
  } = useListingManagementTable();

  const navigate = useNavigate();

  const columns = useMemo(() => {
    return [
      {
        dataField: "product",
        text: "Product",
        // filter: textFilter({ caseSensitive: true, placeholder: " ", className: "form-control-sm mt-1" }),
        formatter: (cellContent: IProductListingItemProduct, row: IProductListingItem) => {
          const firstPhoto = row.photos?.sort((a, b) => a.order - b.order)[0] ?? null;
          return (
            <div className="product-listings-table__base">
              {firstPhoto && (
                <div className="product-listings-table__base__img">
                  <img src={getImageUrl(firstPhoto.mockup_file)} alt={cellContent.title} />
                </div>
              )}
              <div className="product-listings-table__base__right">
                <div className="product-listings-table__base__right__title">{cellContent.title}</div>
                <div className="product-listings-table__base__right__description">{row.listings.length} listings</div>
                <div className="product-listings-table__base__right__description">
                  {calculateModelCount(row.listings)} phone models
                </div>
              </div>
            </div>
          );
        },
        sort: false
      },
      {
        dataField: "user",
        text: "User",
        formatter: (cellContent: IProductListingItemUser) => {
          return `${cellContent.name}`;
        }
      },
      {
        dataField: "inventory_status",
        text: "Inventory",
        // filter: selectFilter({
        //   options: {
        //     1: "All in Stock",
        //     0: "Out of Stock"
        //   },
        //   placeholder: " ",
        //   className: "form-control-sm mt-1"
        // }),
        formatter: (cellContent: ProductListingItemInventoryStatus) => {
          switch (cellContent) {
            case ProductListingItemInventoryStatus.InStock:
              return "All in Stock";
            case ProductListingItemInventoryStatus.OutOfStock:
              return "Out of Stock";
            default:
              return "-";
          }
        },
        sort: false
      },
      {
        dataField: "status",
        text: "Status",
        isDummyField: true,
        // filter: selectFilter({
        //   options: {
        //     0: "Unpublished",
        //     1: "Published"
        //   },
        //   placeholder: " ",
        //   className: "form-control-sm mt-1"
        // }),
        formatter: (cellContent: any, row: IProductListingItem) => {
          const totalCount = row.listings.length;
          const publishedCount = row.listings.filter((e) => e.status).length;
          const allPublished = publishedCount === totalCount;
          const className = allPublished ? "btn-success" : "btn-secondary";
          return (
            <TooltipIcon
              id={`listing-status-control-${row.id}`}
              autohide
              content="To activate or deactivate your listing, press on this button."
            >
              <button
                id={`listing-status-control-${row.id}`}
                className={`btn ${className} btn-sm`}
                type="button"
                onClick={() => {
                  openListingStatusControlModal(row);
                }}
              >
                {allPublished
                  ? totalCount === 1
                    ? "Listing active"
                    : `${publishedCount}/${totalCount} - listings active`
                  : totalCount === 1
                  ? "Listing draft"
                  : `${publishedCount}/${totalCount} - listing active`}
                <span className="ms-1 bx bxs-pencil" />
              </button>
            </TooltipIcon>
          );
        },
        sort: false
      },
      {
        dataField: "status",
        text: "",
        formatter: (cellContent: any, row: IProductListingItem) => {
          return (
            <div className="product-listings-table__actions">
              <div className="product-listings-table__actions__buttons">
                <Link to={`/products/${row.id}?return_to=/backoffice/listings`}>
                  <i className="bx bxs-pencil" />
                </Link>
              </div>

              <div className="product-listings-table__actions__menu">
                <UncontrolledDropdown>
                  <DropdownToggle type="button" className="a" color="">
                    <i className="mdi mdi-dots-vertical" />
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-end">
                    <DropdownItem
                      onClick={() => {
                        openListingStatusControlModal(row);
                      }}
                    >
                      Listing status control
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => {
                        handleListingDelete(row);
                      }}
                    >
                      <span className="text-danger">Delete</span>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            </div>
          );
        },
        sort: false
      }
    ];
  }, []);

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc"
    }
  ] as any;

  return (
    <div className="data-table">
      <Card className="border">
        {loader && <Loader />}
        <CardBody>
          <PaginationProvider pagination={paginationFactory(pageOptions)}>
            {({ paginationProps, paginationTableProps }: any) => (
              <ToolkitProvider keyField="id" columns={columns} data={items} search>
                {(toolkitProps: any) => {
                  return (
                    <>
                      <Row className="mb-2">
                        <Col xl="12">
                          <div className="d-inline-flex me-3">
                            <Button
                              color="primary"
                              onClick={() => {
                                navigate("/backoffice/listings/templates");
                              }}
                            >
                              Update templates
                            </Button>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col xl="12">
                          <div className="table-responsive">
                            <BootstrapTable
                              bootstrap4
                              remote
                              onTableChange={onTableChange}
                              bordered={false}
                              striped={false}
                              defaultSorted={defaultSorted}
                              filter={filterFactory()}
                              classes="table align-middle table-nowrap table-sm"
                              headerWrapperClasses="thead-light"
                              {...toolkitProps.baseProps}
                              {...paginationTableProps}
                              data={items}
                              keyField="id"
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row className="align-items-md-center mt-30">
                        <Col className="inner-custom-pagination d-flex">
                          <div className="text-md-right ms-auto">
                            <PaginationListStandalone {...paginationProps} />
                          </div>
                        </Col>
                      </Row>
                    </>
                  );
                }}
              </ToolkitProvider>
            )}
          </PaginationProvider>
        </CardBody>
      </Card>

      {ListingStatusControlModalComponent}
    </div>
  );
};
