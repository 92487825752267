import React, { useEffect } from "react";

import { useDispatch } from "react-redux";
import { Outlet } from "react-router-dom";

import { UserRole } from "../../common/interfaces/user.interface";
import useAuth from "../../hooks/useAuth";
import { useRole } from "../../hooks/useRole";
import { APP_SET_SHOW_MOCKUP_GENERATOR_MODAL } from "../../store/app/appSlice";
import { useAppSelector } from "../../store/store";
import { CLOSE_CHECKOUT_MODAL } from "../../store/wizards/checkoutSlice";
import { LayoutIntroduction } from "../introduction-steps/LayoutIntroduction";
import { CheckoutTypeModal } from "../modals/checkout-type/CheckOutTypeModal";
import { GenerateMockupModal } from "../modals/generate-mockup-new/GenerateMockupModal";
import { BackOfficeLayoutAddon } from "./BackOfficeLayoutAddon";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { TawkMessenger } from "./TawkMessenger";

export const Layout = () => {
  const showMockupGeneratorModal = useAppSelector((state) => state.app.showMockupGeneratorModal);
  const checkoutModal = useAppSelector((state) => state.checkout);
  const dispatch = useDispatch();
  const { hasRole } = useRole();
  const { user } = useAuth();

  useEffect(() => {
    // if (window.Tawk_API && user) {
    //   console.log("setting tawk");
    //   (window.Tawk_API as any).setAttributes(
    //     {
    //       name: user.name,
    //       email: user.email
    //     },
    //     function (error: any) {}
    //   );
    // }
  }, []);

  return (
    <>
      {/* <Loader /> */}

      <div id="layout-wrapper">
        <Header />
        <Sidebar />

        <div className="main-content overflow-visible">
          <div className="page-content">
            <div className="container-fluid">
              <Outlet />
            </div>
          </div>

          <GenerateMockupModal
            show={showMockupGeneratorModal}
            toggle={() => {
              dispatch(APP_SET_SHOW_MOCKUP_GENERATOR_MODAL(false));
            }}
            onSubmit={(d) => {}}
          />

          <CheckoutTypeModal
            show={checkoutModal.modalOpen}
            toggle={() => {
              dispatch(CLOSE_CHECKOUT_MODAL());
            }}
          />

          {hasRole(UserRole.Admin) ? <BackOfficeLayoutAddon /> : ""}
        </div>
      </div>

      <LayoutIntroduction />

      <TawkMessenger />
    </>
  );
};
