import { createAsyncThunk } from "@reduxjs/toolkit";

import { ProductAssetsState } from "../../../common/interfaces/product-assets-slice.interface";
import { IProductItem } from "../../../common/interfaces/products.interface";
import { OrdersApiService } from "../../../services/api";

export const ASSETS_ACTION_UPDATE_LIST = createAsyncThunk(
  "assets/update",
  async (data: { selectedProduct: IProductItem }) => {
    return OrdersApiService.getAssetList(data.selectedProduct);
  },
  {
    condition: (_, { getState }) => {
      const state: any = getState();
      const sliceState = state.productAssets as ProductAssetsState;

      if (sliceState.assetList.loader) {
        return false;
      }

      return true;
    }
  }
);
