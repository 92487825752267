import React from "react";

import classNames from "classnames";

import { Variant } from "../../common/interfaces/boostrap-base";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  variant: Variant;
  children?: any;
}

export const Alert: React.FC<Props> = ({ variant, children, className, ...rest }) => {
  return (
    <div className={classNames("alert", `alert-${variant}`, className)} {...rest} role="alert">
      {children}
    </div>
  );
};
