import React from "react";

import { useDispatch } from "react-redux";

import useEventBus from "../../hooks/useEventBus";
import { BACKOFFICE_SET_ORDER_SET_TRACKING_NUMBER_MODAL_STATE } from "../../store/backoffice/backofficeSlice";
import { useAppSelector } from "../../store/store";
import { OrderSetTrackingNumberModal } from "../backoffice/modals/OrderSetTrackingNumberModal";

export const BackOfficeLayoutAddon = () => {
  const eventBus = useEventBus();
  const dispatch = useDispatch();
  const orderSetTrackingNumberModal = useAppSelector((state) => state.backoffice.orderSetTrackingNumberModal);

  return (
    <>
      <OrderSetTrackingNumberModal
        orderId={orderSetTrackingNumberModal.orderId}
        show={orderSetTrackingNumberModal.show}
        onUpdated={(orderId: string, trackingNumber: string) => {
          eventBus.publish({
            topic: "OrderTrackingNumberUpdated",
            payload: {
              orderId,
              trackingNumber
            }
          });
        }}
        toggle={() => {
          dispatch(
            BACKOFFICE_SET_ORDER_SET_TRACKING_NUMBER_MODAL_STATE({
              orderId: "",
              show: false
            })
          );
        }}
      />
    </>
  );
};
