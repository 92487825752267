import axios, { AxiosHeaders, AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";

import { STORAGE_KEYS } from "../../common/constants/storage-values.constant";
import { TokenService } from "../TokenService";

class BaseAPIService {
  protected api: AxiosInstance;

  constructor(baseURL: string) {
    this.api = axios.create({
      baseURL
    });

    this.api.interceptors.request.use((config) => {
      const token = localStorage.getItem(STORAGE_KEYS.AUTH_TOKEN);

      if (!config.headers) {
        config.headers = new AxiosHeaders();
      }

      config.headers["Content-Type"] =
        config.headers && config.headers["Content-Type"] !== undefined
          ? config.headers["Content-Type"]
          : "application/json";

      config.headers!.Authorization =
        config.headers && config.headers.Authorization !== undefined ? config.headers.Authorization : `Bearer ${token}`;

      return config;
    });

    this.api.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error.config.headers?.Authorization && error.response?.status === 401) {
          TokenService.removeAccessToken();
          window.location.reload();

          throw error;
        }
        throw error;
      }
    );
  }

  protected request<T = any>(config: AxiosRequestConfig): Promise<T> {
    return this.api
      .request<T>(config)
      .then((response: AxiosResponse<T>) => response.data)
      .catch((error) => {
        throw new Error(`Request failed: ${error}`);
      });
  }

  public get<T = any>(url: string, config?: AxiosRequestConfig): Promise<T> {
    return this.request<T>({ ...config, url, method: "GET" });
  }

  public post<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T> {
    return this.request<T>({ ...config, url, method: "POST", data });
  }

  public put<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T> {
    return this.request<T>({ ...config, url, method: "PUT", data });
  }

  public patch<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T> {
    return this.request<T>({ ...config, url, method: "PATCH", data });
  }

  public delete<T = any>(url: string, config?: AxiosRequestConfig): Promise<T> {
    return this.request<T>({ ...config, url, method: "DELETE" });
  }
}

export default BaseAPIService;
