import React, { useCallback, useId, useMemo, useState } from "react";

import classNames from "classnames";
import { CardBody, Tooltip } from "reactstrap";

import { downloadImage } from "../../../common/utils/file.util";
import { getImageUrl } from "../../../common/utils/formatter.util";
import { Loader } from "../Loader";
import { TooltipIcon } from "../TooltipIcon";
import type { ProductRowItemBase } from "./ProductRowItems";

interface Props<T extends ProductRowItemBase> {
  item: T;
  selectable?: boolean;
  colSize?: number;
  onSelect?: (item: T) => void;
  onItemClick?: (item: T) => void;
  isSelected: boolean;
  titleEditable?: boolean;
  onTitleChange?: (item: T, title: string) => Promise<void>;
  imageHoverable?: boolean;
  itemsWithControls?: boolean;
  onCopyItemClick?: (item: T) => void;
  onDeleteItemClick?: (item: T) => void;
  isLastItem?: boolean;
  onFirstItemClick?: (item: T) => void;
  onShowPreview?: (item: T) => void;
  renderTooltip?: (item: T) => React.ReactNode;
  hidePrice?: boolean;
}

export const ProductRowItem = <T extends ProductRowItemBase>({
  onItemClick,
  item,
  selectable,
  isSelected,
  colSize,
  imageHoverable,
  titleEditable,
  onFirstItemClick,
  itemsWithControls,
  onCopyItemClick,
  onDeleteItemClick,
  onTitleChange,
  onSelect,
  isLastItem,
  onShowPreview,
  renderTooltip,
  hidePrice
}: Props<T>) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [editTitle, setEditTitle] = useState(false);
  const [newTitle, setNewTitle] = useState(item.title);
  const isNewEntityItem = useMemo(() => item.id === -1, [item.id]);

  const handleTitleEditClick = useCallback(async () => {
    if (editTitle) {
      setLoader(true);
      if (onTitleChange) {
        await onTitleChange(item, newTitle);
      }
      setEditTitle(!editTitle);
      setLoader(false);
    } else {
      setEditTitle(true);
    }
  }, [item, newTitle, editTitle]);

  const cardProps = {
    ...(selectable || onItemClick || onFirstItemClick
      ? {
          onClick: () => {
            if (isNewEntityItem && onFirstItemClick) {
              onFirstItemClick(item);
              return;
            }
            if (onItemClick) {
              onItemClick(item);
            }
            if (selectable && onSelect) {
              onSelect(item);
            }
          },
          role: "button"
        }
      : {})
  };

  return (
    <div className={classNames(`col-${colSize ?? 2}`, "p-1")}>
      <div
        className={classNames("card border product-row-items__list__item", {
          selected: selectable && isSelected,
          "new-entity": isNewEntityItem,
          "mb-0": isLastItem
        })}
        {...(cardProps as any)}
      >
        {loader && <Loader />}
        <span className="product-row-items__list__item__select-icon">
          <i className="bx bx-check" />
        </span>
        {itemsWithControls && !isNewEntityItem && (
          <>
            <button
              type="button"
              className="product-row-items__list__item__icon product-row-items__list__item__icon--show-full"
              onClick={(e) => {
                e.stopPropagation();
                if (onShowPreview) {
                  onShowPreview(item);
                }
                // setShowPreviewModal(true);
                // setPreviewImage(getImageUrl(item.image));
              }}
            >
              <i className="mdi mdi-eye" />
            </button>
            <button
              type="button"
              className="product-row-items__list__item__icon product-row-items__list__item__icon--download"
              onClick={(e) => {
                e.stopPropagation();
                downloadImage(getImageUrl(item.image), "design");
                // setShowPreviewModal(true);
                // setPreviewImage(getImageUrl(item.image));
              }}
            >
              <i className="bx bxs-download" />
            </button>
            <button
              type="button"
              className="product-row-items__list__item__icon product-row-items__list__item__icon--copy"
              onClick={(e) => {
                e.stopPropagation();
                if (onCopyItemClick) {
                  onCopyItemClick(item);
                }
              }}
            >
              <i className="mdi mdi-content-copy" />
            </button>
            <button
              type="button"
              className="product-row-items__list__item__icon product-row-items__list__item__icon--delete"
              onClick={(e) => {
                e.stopPropagation();
                if (onDeleteItemClick) {
                  onDeleteItemClick(item);
                }
              }}
            >
              <i className="mdi mdi-delete" />
            </button>
          </>
        )}
        <div className="card__image-container">
          {isNewEntityItem ? (
            <>
              <i className="bx bxs-plus-circle" />
            </>
          ) : (
            <>
              <img
                className={classNames("card-img-top img-fluid rounded-top")}
                src={getImageUrl(item.image)}
                alt={item.title}
              />
              {imageHoverable && item.image_hover && (
                <img className="hoverable-image" src={getImageUrl(item.image_hover)} alt={item.title} />
              )}
            </>
          )}

          {renderTooltip ? (
            <div className="card__image-container__tooltip">
              <TooltipIcon id={`pri-${item.id}`} content={renderTooltip(item)} hoverable />
            </div>
          ) : (
            ""
          )}
        </div>
        <CardBody>
          <div className="product-row-items__list__item__title-container">
            {editTitle ? (
              <input
                type="text"
                value={newTitle}
                onChange={(e) => {
                  setNewTitle(e.target.value);
                }}
              />
            ) : (
              <h5>{item.title}</h5>
            )}
            {titleEditable && !isNewEntityItem && (
              <button
                type="button"
                className={classNames("title-edit", {
                  "title-edit--save": editTitle
                })}
                onClick={(e) => {
                  e.stopPropagation();
                  handleTitleEditClick().then(() => null);
                }}
              >
                {editTitle ? <i className="bx bx-check" /> : <i className="bx bx-pencil" />}
              </button>
            )}
          </div>
          {item.price && !hidePrice && (
            <h6 className="product-row-items__list__item__price">
              <span className="price-label">Price</span>{" "}
              <span className="price-value">
                {item.price.min !== item.price.max ? (
                  <>
                    € {(+item.price.min).toFixed(2)} - {(+item.price.max).toFixed(2)}
                  </>
                ) : (
                  <>€ {(+item.price.min).toFixed(2)}</>
                )}
              </span>{" "}
            </h6>
          )}
        </CardBody>
      </div>
    </div>
  );
};
