import React from "react";

import { useFormContext } from "react-hook-form";
import { Button, Card, CardBody } from "reactstrap";

import { TextFieldInput } from "../../../common/Input/TextFieldInput";
import { FileUploadStep } from "../../create-design/FileUploadStep";

interface Props {
  // mockupFiles: ExtendedFile[];
  // onSubmit: () => void;
}

export const CustomMockupSettings: React.FC<Props> = ({}) => {
  const {
    register,
    watch,
    formState: { errors }
  } = useFormContext();

  const uploadedFiles = watch("uploadedFiles");

  return (
    <div className="row">
      <div className="col-7">
        <Card className="h-100 mb-0">
          <CardBody>
            <FileUploadStep
              hideSubmitButton
              alwaysShowTitle
              multiple
              title="Mockup files:"
              readonly
              uploadedFiles={uploadedFiles}
              containerSize={12}
            />
          </CardBody>
        </Card>
      </div>
      <div className="col-5">
        <Card className="h-100 mb-0">
          <CardBody className="d-flex flex-column">
            <TextFieldInput {...register("skuNumber")} label="SKU Number" error={errors.skuNumber?.message as any} />

            {/* <div className="mt-3 mx-auto"> */}
            {/*  <Button color="success" type="submit"> */}
            {/*    Submit */}
            {/*  </Button> */}
            {/* </div> */}
          </CardBody>
        </Card>
      </div>
    </div>
  );
};
