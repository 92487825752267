import { IShopItem } from "./integrations.interface";
import { IMockupEntity } from "./mockup.inteface";
import {
  IDesignItem,
  IProductAssetGroup,
  IProductAssetItem,
  IProductItem,
  IProductListingPresetItem,
  IProductListingTemplate,
  IProductMaterial
} from "./products.interface";

export enum ProductWizardStep {
  PRODUCT_SELECTION = "ProductWizard::product_selection",
  INTEGRATION_SELECTION = "ProductWizard::integration_selection",
  // DESIGN_SETTINGS = "ProductWizard::design_settings",
  MOCKUP_SETTINGS = "ProductWizard::mockup_settings",
  DETAILS = "ProductWizard::details",
  PRICING = "ProductWizard::pricing"
}

export interface IProductSelectedListingItem {
  id: number;
  fields: IProductListingItemFields;
  groups: IProductAssetGroup[];
}

export type ISubmittedProductListingFields = Omit<
  IProductListingItemFields,
  "keywords" | "shipping_profile" | "section"
> & {
  shipping_profile_id: number;
  section: number;
};

export interface IProductListingItemFields {
  title: string;
  description: string;
  tags: string[];
  materials: string[];
  section: string;
  shipping_profile: string;
  keywords: IProductListingItemKeyWords;
}

export type IProductListingItemKeyWords = {
  keyword1: string;
  keyword2: string;
};

export interface IProductDesignMockupConnectorItem {
  designId: number;
  mockupId?: number | null;
}

export interface ProductWizardState {
  activeWizardStep: ProductWizardStep;
  selectedProductType: IProductItem | null;
  selectedShopItem: IShopItem | null;
  selectedMockupEntity: IMockupEntity | null;
  designMockupConnector: IProductDesignMockupConnectorItem[];
  selectedListings: IProductSelectedListingItem[];
  // selectedProductAsset: IProductAssetItem | null;
  selectedProductMaterial: IProductMaterial | null;
  selectedDesigns: IDesignItem[];
  pricingData: {
    freeShipping: boolean;
    priceMap: ProductListingPriceMap[];
  };
  data: {
    listingGroupsPresets: IProductListingPresetItem[];
    assetGroups: IProductAssetGroup[];
    listingGroupTemplates: IProductListingTemplate[];
  };
}
export interface ProductListingPriceMap {
  listingId: number;
  map: Record<string, number | string | null>; // key: product id, value: price
}

export const STEPS_DATA = [
  {
    title: "Product",
    stepIdx: ProductWizardStep.PRODUCT_SELECTION
  },
  {
    title: "Integration",
    stepIdx: ProductWizardStep.INTEGRATION_SELECTION
  },
  // {
  //   title: "Design",
  //   stepIdx: ProductWizardStep.DESIGN_SETTINGS
  // },
  {
    title: "Mockup",
    stepIdx: ProductWizardStep.MOCKUP_SETTINGS
  },
  {
    title: "Details",
    stepIdx: ProductWizardStep.DETAILS
  },
  {
    title: "Pricing",
    stepIdx: ProductWizardStep.PRICING
  }
];

export enum PriceIncreaseType {
  Percentage = "percent",
  Fixed = "fixed"
}

export interface PricingTableEntity {
  listing: IProductSelectedListingItem;
  items: PricingTableEntityItem[];
}

export interface PricingTableEntityItem {
  asset: IProductAssetItem;
  price: string;
  group: Omit<IProductAssetGroup, "items">;
}
