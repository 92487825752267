import React from "react";

// i18n

import Footer from "./Footer";
import SidebarContent from "./SidebarContent";

const Sidebar = () => {
  return (
    <>
      <div className="vertical-menu">
        <div className="h-100 d-flex flex-column">
          <SidebarContent />
          <Footer />
        </div>
        <div className="sidebar-background" />
      </div>
    </>
  );
};

export default Sidebar;
