import React, { useEffect, useState } from "react";

import moment from "moment/moment";
import BootstrapTable, { TableChangeState } from "react-bootstrap-table-next";
import filterFactory from "react-bootstrap-table2-filter";
import paginationFactory, { PaginationProvider, PaginationListStandalone } from "react-bootstrap-table2-paginator";
// @ts-ignore
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Card, CardBody, Col, Row } from "reactstrap";

import { IPaginationMeta } from "../../../common/interfaces/api.interface";
import { IUserItemWithCustomer } from "../../../common/interfaces/backoffice.interface";
import { ICoursesItem } from "../../../common/interfaces/courses.interface";
import { getErrorMessage } from "../../../common/utils/formatter.util";
import { Loader } from "../../../components/common/Loader";
import { useServiceContainer } from "../../../hooks/useServiceContainer";

export const CoursesManagementPage = () => {
  const [loader, setLoader] = useState(false);
  const [items, setItems] = useState<ICoursesItem[]>([]);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [paginationMeta, setPaginationMeta] = useState<null | IPaginationMeta>(null);
  const { commonService, backofficeService } = useServiceContainer();
  const navigate = useNavigate();

  const handleItemDelete = async (item: ICoursesItem) => {
    try {
      setLoader(true);
      await backofficeService.deleteCoursesItemById(item.id);
      await fetchList(page, search);
      toast.success("Item successfully deleted");
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setLoader(false);
    }
  };

  const columns = [
    {
      dataField: "id",
      text: "Id",
      sort: false,
      style: {
        maxWidth: "90px"
      }
    },
    {
      dataField: "title",
      text: "Title",
      sort: false,
      style: {
        maxWidth: "150px"
      }
    },
    {
      dataField: "created_at",
      text: "Creation time",
      sort: false,
      formatter: (cellContent: string) => {
        return moment(cellContent).format("DD.MM.YYYY HH:mm");
      }
    },
    {
      text: "Actions",
      dataField: "is_deleted",
      formatter: (cellContent: any, row: ICoursesItem) => {
        return (
          <>
            <Button
              color="danger"
              size="sm"
              onClick={() => {
                handleItemDelete(row);
              }}
              role="button"
            >
              Delete
            </Button>
            <Link className="btn btn-primary btn-sm ms-2" color="primary" to={`/backoffice/courses/${row.id}/edit`}>
              Edit
            </Link>
          </>
        );
      }
    }
  ];

  const fetchList = async (selectedPage: number, _search?: string) => {
    setLoader(true);
    try {
      const res = await commonService.getCourses(selectedPage, 10, _search);

      setItems(res.items);
      setPaginationMeta(res.meta);
    } catch (e) {
      console.error(e);
    } finally {
      setLoader(false);
    }
  };

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc"
    }
  ] as any;

  const pageOptions = {
    sizePerPage: 20,
    page,
    totalSize: paginationMeta?.total || 0,
    custom: true
  };

  const onTableChange = (event: string, data: TableChangeState<any>) => {
    switch (event) {
      case "pagination":
        setPage(data.page);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    fetchList(page, search).then(() => null);
  }, [page, search]);

  return (
    <div className="data-table">
      <Card className="border">
        {loader && <Loader />}
        <CardBody>
          <PaginationProvider pagination={paginationFactory(pageOptions)}>
            {({ paginationProps, paginationTableProps }: any) => (
              <ToolkitProvider keyField="id" columns={columns} data={items} search>
                {(toolkitProps: any) => {
                  return (
                    <>
                      <Row className="mb-2">
                        <Col xl="auto" className="d-flex">
                          <div className="d-inline-flex align-items-center me-3">
                            <Button
                              color="primary"
                              onClick={() => {
                                navigate("/backoffice/courses/create");
                              }}
                            >
                              Create new
                            </Button>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col xl="12">
                          <div className="table-responsive">
                            <BootstrapTable
                              bootstrap4
                              remote
                              onTableChange={onTableChange}
                              bordered={false}
                              striped={false}
                              defaultSorted={defaultSorted}
                              filter={filterFactory()}
                              classes="table align-middle table-nowrap table-sm"
                              headerWrapperClasses="thead-light"
                              {...toolkitProps.baseProps}
                              {...paginationTableProps}
                              data={items}
                              keyField="id"
                            />
                          </div>
                        </Col>
                      </Row>

                      <Row className="align-items-md-center mt-30">
                        <Col className="inner-custom-pagination d-flex">
                          <div className="text-md-right ms-auto">
                            <PaginationListStandalone {...paginationProps} />
                          </div>
                        </Col>
                      </Row>
                    </>
                  );
                }}
              </ToolkitProvider>
            )}
          </PaginationProvider>
        </CardBody>
      </Card>
    </div>
  );
};
