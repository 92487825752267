import React from "react";

import { ToastContainer } from "react-toastify";

import { Router } from "./components/router/Router";
import { ServiceContainerProvider } from "./providers/ServiceContainerProvider";

import "react-toastify/dist/ReactToastify.css";
import "cropperjs/dist/cropper.css";
import { BrowserRouter } from "react-router-dom";
import { Provider as BusProvider } from "react-bus";

function App() {
  return (
    <BrowserRouter>
      <BusProvider>
        <ServiceContainerProvider>
          <Router />
          <ToastContainer />
        </ServiceContainerProvider>
      </BusProvider>
    </BrowserRouter>
  );
}

export default App;
