import React, { useState } from "react";

import { WithTranslation, withTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";

import { getImageUrl } from "../../../common/utils/formatter.util";
import useAuth from "../../../hooks/useAuth";
import { useIntroductionSteps } from "../../../hooks/useIntroductionSteps";
import { useServiceContainer } from "../../../hooks/useServiceContainer";
import { setUser } from "../../../store/user/userSlice";

const ProfileMenu = (props: WithTranslation) => {
  const [menu, setMenu] = useState(false);
  const { user } = useAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { authService } = useServiceContainer();
  const { setEnabled: setLayoutIntroductionEnabled } = useIntroductionSteps("layout");

  const processLogout = async () => {
    await authService.logout();
    dispatch(setUser(null));

    setTimeout(() => {
      navigate("/auth/login");
    }, 100);
  };

  if (!user) {
    return <></>;
  }

  return (
    <>
      <Dropdown isOpen={menu} toggle={() => setMenu(!menu)} className="d-inline-block">
        <DropdownToggle className="btn header-item " id="page-header-user-dropdown" tag="button">
          <img
            className="rounded-circle header-profile-user"
            src={getImageUrl(user.image)}
            alt={`${user.name} profile`}
          />
          <span className="d-none d-xl-inline-block ms-2 me-1">{user.name}</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem tag="a" href="/user/edit">
            <i className="bx bx-user font-size-16 align-middle me-1" />
            {props.t("Profile")}{" "}
          </DropdownItem>
          <DropdownItem
            tag="a"
            to="#"
            onClick={() => {
              setLayoutIntroductionEnabled(true);
            }}
          >
            <i className="bx bx-help-circle font-size-16 align-middle me-1" />
            {props.t("Start tutorial")}{" "}
          </DropdownItem>
          {/* <DropdownItem tag="a" href="#"> */}
          {/*  <span className="badge bg-success float-end">11</span> */}
          {/*  <i className="bx bx-wrench font-size-16 align-middle me-1" /> */}
          {/*  {props.t("Settings")} */}
          {/* </DropdownItem> */}
          {/* <div className="dropdown-divider" /> */}
          <Link
            to="#"
            onClick={() => {
              processLogout().then(() => null);
            }}
            className="dropdown-item"
          >
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t("Logout")}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

export default withTranslation()(ProfileMenu);
