import React, { useEffect, useMemo, useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import * as Yup from "yup";

import { COURSES_CATEGORIES } from "../../../common/constants/courses.constant";
import { getErrorMessage } from "../../../common/utils/formatter.util";
import { InputField } from "../../../components/common/InputField";
import { Loader } from "../../../components/common/Loader";
import { SelectField } from "../../../components/common/SelectField";
import { FileUploadStep } from "../../../components/modals/create-design/FileUploadStep";
import { useServiceContainer } from "../../../hooks/useServiceContainer";

const validationSchema = Yup.object().shape({
  title: Yup.string().required(),
  description: Yup.string().required(),
  url: Yup.string().required(),
  category: Yup.string().required(),
  image: Yup.mixed()
});

export const CoursesCreatePage = () => {
  const {
    register,
    formState: { errors },
    setValue,
    setError,
    handleSubmit,
    watch
  } = useForm({
    defaultValues: {} as any,
    resolver: yupResolver(validationSchema)
  });
  const [loader, setLoader] = useState(false);
  const { backofficeService } = useServiceContainer();
  const navigate = useNavigate();

  const categoryValue = watch("category");

  const categoryOptions = useMemo(() => {
    return COURSES_CATEGORIES;
  }, []);

  const selectedCategoryOption = useMemo(() => {
    return categoryOptions.find((type) => type.value === categoryValue) ?? null;
  }, [categoryValue, categoryOptions]);

  const onSubmitHandler = async (data: any) => {
    try {
      setLoader(true);

      await backofficeService.createCoursesItem(data);

      toast.success("Item successfully created");
      navigate("/backoffice/courses");
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setLoader(false);
    }
  };

  return (
    <Row>
      {loader && <Loader />}
      <Col xs={12}>
        <Card>
          <CardBody>
            <h3>Create course</h3>
            <form onSubmit={handleSubmit(onSubmitHandler)}>
              <InputField {...register("title")} error={errors.title?.message} label="Title" name="title" />

              <InputField
                {...register("description")}
                error={errors.description?.message}
                label="Description"
                name="description"
              />

              <InputField {...register("url")} error={errors.url?.message} label="Link" name="url" />

              <SelectField
                options={categoryOptions}
                label="Category"
                error={errors.category?.message}
                value={selectedCategoryOption}
                placeholder="Select product category"
                onChange={(data) => {
                  setValue("category", data.value);
                  setError("category", {
                    type: "manual",
                    message: ""
                  });
                }}
              />

              <FileUploadStep
                error={errors.image?.message}
                hideSubmitButton
                alwaysShowTitle
                title="Image (566x220)"
                small
                containerSize={12}
                onUpload={(file) => {
                  setValue("image", file[0]);
                  setError("image", {
                    type: "manual",
                    message: ""
                  });
                }}
              />

              <Button type="submit" color="success">
                Save
              </Button>
            </form>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};
