import React, { useEffect, useState } from "react";

import classNames from "classnames";

import { IDeliveryOption } from "../../../common/interfaces/orders.interface";
import { getExternalImageUrl } from "../../../common/utils/formatter.util";

interface Props {
  items: string[];
  onItemSelect: (item: string) => void;
}

export const MethodSelect: React.FC<Props> = ({ items, onItemSelect }) => {
  const [selectedItemId, setSelectedItemId] = useState<null | string>(null);

  const formatItem = (item: string) => {
    switch (item) {
      case "bill":
        return {
          title: "Bill",
          icon: "fas fa-receipt"
        };
      case "stripe":
        return {
          title: "Stripe",
          icon: "fab fa-cc-stripe"
        };
      default:
        return {
          title: item,
          icon: null
        };
    }
  };

  useEffect(() => {
    if (selectedItemId) {
      const selectedItem = items.find((item) => item === selectedItemId);
      if (selectedItem) {
        onItemSelect(selectedItem);
      }
    }
  }, [selectedItemId]);

  return (
    <div className="radio-select">
      {items.map((item, idx) => {
        const { title, icon } = formatItem(item);
        return (
          <div
            role="button"
            onClick={() => setSelectedItemId(item)}
            className={classNames("radio-select__item", {
              "radio-select__item--selected": selectedItemId === item
            })}
            key={idx}
          >
            <div className="radio-select__item__state" />
            <div className="radio-select__item__title">
              {icon ? <i className={classNames(icon, "me-2")} /> : ""}
              {title}
            </div>
          </div>
        );
      })}
    </div>
  );
};
