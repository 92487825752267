import React, { useEffect, useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import * as Yup from "yup";

import { IProductBackofficeAssetItem } from "../../../common/interfaces/backoffice.interface";
import { getErrorMessage } from "../../../common/utils/formatter.util";
import { getObjectDelta } from "../../../common/utils/object.util";
import { InputField } from "../../../components/common/InputField";
import { Loader } from "../../../components/common/Loader";
import { ImagePreviewWithReUpload } from "../../../components/common/uploads/ImagePreviewWithReUpload";
import { useServiceContainer } from "../../../hooks/useServiceContainer";

const validationSchema = Yup.object().shape({
  title: Yup.string().required("This field is required"),
  balance: Yup.number().integer(),
  image: Yup.mixed().required("This field is required"),
  price: Yup.number().required("This field is required"),
  // code: Yup.string().required("This field is required"),
  weight: Yup.number().required("This field is required")
});

export const ProductAssetEdit = () => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    setValue
  } = useForm({
    defaultValues: {} as any,
    resolver: yupResolver(validationSchema)
  });

  const [entityData, setEntityData] = useState<null | IProductBackofficeAssetItem>(null);

  const [loader, setLoader] = useState(true);
  const { productId, assetId } = useParams();
  const { backofficeService } = useServiceContainer();
  const navigate = useNavigate();

  const fetch = async () => {
    try {
      if (!productId || !assetId) return;
      setLoader(true);
      const data = await backofficeService.getProductAssetById(+productId, +assetId);
      setEntityData(data);
    } catch (e) {
      toast.error(getErrorMessage(e));
      navigate("/");
    } finally {
      setLoader(false);
    }
  };

  const onSubmitHandler = async (data: any) => {
    try {
      if (!entityData || !productId || !assetId) return;
      setLoader(true);
      const delta = getObjectDelta(
        {
          ...entityData
        },
        {
          title: data.title,
          balance: data.balance,
          image: data.image,
          // code: data.code,
          price: data.price,
          weight: data.weight
        }
      );

      const updatedEntity = await backofficeService.updateProductAssetById(+productId, +assetId, delta);
      setEntityData(updatedEntity);

      toast.success("Asset updated successfully");
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setLoader(false);
    }
  };

  const updateFormState = async (data: IProductBackofficeAssetItem) => {
    reset({
      title: data.title,
      balance: data.balance,
      image: data.image,
      // code: data.code,
      price: data.price,
      weight: data.weight
    });
  };

  useEffect(() => {
    if (entityData) {
      updateFormState(entityData).then(() => null);
    }
  }, [entityData]);

  useEffect(() => {
    fetch().then(() => null);
  }, [productId, assetId]);

  return (
    <Row>
      {loader && <Loader />}
      <Col xs={12}>
        <Link to={`/backoffice/products/${productId}/assets`} className="btn btn-sm btn-primary mb-3">
          <i className="bx bx-arrow-back" /> Back to list
        </Link>
      </Col>
      <Col xs={12}>
        <Card>
          <CardBody>
            {entityData && (
              <>
                <form onSubmit={handleSubmit(onSubmitHandler)}>
                  <InputField {...register("title")} error={errors.title?.message} label="Title" name="title" />
                  {/* <InputField */}
                  {/*  {...register("balance")} */}
                  {/*  error={errors.balance?.message} */}
                  {/*  label="Balance" */}
                  {/*  type="number" */}
                  {/*  step="1" */}
                  {/*  name="default_price" */}
                  {/* /> */}

                  <InputField
                    {...register("price")}
                    error={errors.price?.message}
                    label="Price"
                    name="price"
                    type="number"
                    step="0.01"
                  />

                  <InputField
                    {...register("weight")}
                    error={errors.weight?.message}
                    label="Weight"
                    name="weight"
                    type="number"
                    step="0.001"
                  />

                  <Row>
                    <Col xs={3}>
                      <ImagePreviewWithReUpload
                        title="Image"
                        originImage={entityData.image}
                        onUpload={(file) => {
                          setValue("image", file);
                        }}
                      />
                    </Col>
                  </Row>

                  <Button type="submit" color="success">
                    Save
                  </Button>
                  <Button
                    type="button"
                    color="primary"
                    className="ms-2"
                    onClick={() => {
                      updateFormState(entityData).then(() => null);
                    }}
                  >
                    Reset
                  </Button>
                </form>
              </>
            )}
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};
