import { useDispatch } from "react-redux";

import { APP_SET_INTRODUCTION_STEP_STATE, IntroductionSteps } from "../store/app/appSlice";
import { useAppSelector } from "../store/store";
import { setUserIsIntroductionPassed } from "../store/user/userSlice";

export const useIntroductionSteps = (key: keyof IntroductionSteps) => {
  const isEnabled = useAppSelector((state) => state.app.introductionSteps[key]);

  const dispatch = useDispatch();

  const setEnabled = (value: boolean) => {
    dispatch(
      APP_SET_INTRODUCTION_STEP_STATE({
        key,
        value
      })
    );
  };

  const handleComplete = () => {
    dispatch(setUserIsIntroductionPassed(true));
  };

  const handleExit = () => {
    setEnabled(false);
  };

  return {
    isEnabled,
    setEnabled,
    handleComplete,
    handleExit
  };
};
