import React from "react";

import classNames from "classnames";

interface Props {
  className?: string;
  prependAddon?: any;
  input: (props: any) => React.ReactNode;
}

export const InputGroup: React.FC<Props> = ({ className, prependAddon, input }) => {
  const [isFocused, setIsFocused] = React.useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  return (
    <div
      className={classNames("input-group", className, {
        "input-group--focus": isFocused
      })}
    >
      {prependAddon && prependAddon}
      {input({
        onFocus: handleFocus,
        onBlur: handleBlur
      })}
    </div>
  );
};
