import React, { useState } from "react";

import { toast } from "react-toastify";
import { Button, Modal } from "reactstrap";

import { useServiceContainer } from "../../../hooks/useServiceContainer";
import { Loader } from "../../common/Loader";

interface Props {
  show: boolean;
  onGenerate: () => void;
  orderIds: string[];
  toggle: () => void;
  canPrintAWB?: boolean;
}

export const OrderPrintLabelsModal: React.FC<Props> = ({ onGenerate, orderIds, show, toggle, canPrintAWB }) => {
  const [loader, setLoader] = useState(false);

  const { backofficeService } = useServiceContainer();

  const handleGenerateClick = async (type: "dhl" | "awb") => {
    try {
      if (!orderIds) return;
      setLoader(true);
      if (type === "dhl") {
        await backofficeService.generateDhlLabel(orderIds);
      } else {
        await backofficeService.generateAwbLabel(orderIds);
      }
      toggle();
      onGenerate();
    } catch (e) {
      toast.error("Failed to generate labels");
    } finally {
      setLoader(false);
    }
  };

  return (
    <Modal isOpen={show} toggle={toggle} centered size="md">
      {loader && <Loader />}
      <div className="modal-header">
        <h5 className="modal-title mt-0">Generate Labels</h5>
        <button
          type="button"
          onClick={() => {
            toggle();
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <div className="row">
          <div className="col-12">
            <div className="d-flex align-items-center justify-content-center">
              <Button color="primary" onClick={() => handleGenerateClick("dhl")}>
                Generate DHL Labels
              </Button>
              <Button
                color="primary"
                onClick={() => handleGenerateClick("awb")}
                className="ms-2"
                disabled={!canPrintAWB}
              >
                Generate AWB Labels
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <Button
          color="danger"
          onClick={() => {
            toggle();
          }}
        >
          Cancel
        </Button>
      </div>
    </Modal>
  );
};
