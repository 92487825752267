import React, { useCallback, useEffect, useMemo, useState } from "react";

import moment from "moment/moment";
import BootstrapTable, { TableChangeState } from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import paginationFactory, { PaginationProvider, PaginationListStandalone } from "react-bootstrap-table2-paginator";
// @ts-ignore
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { Button, Card, CardBody, Col, Row } from "reactstrap";

import {
  CLEAR_UNPAID_SALES_ORDER_TABLE_FILTER_VALUES,
  UNPAID_SALES_ORDER_TABLE_COLUMNS
} from "../../../common/constants/unpaid-sales-order-table.constant";
import { IPaginationMeta } from "../../../common/interfaces/api.interface";
import {
  IUnPaidSalesOrderInvoice,
  IUnPaidSalesOrderInvoiceDelivery
} from "../../../common/interfaces/invoices.interface";
import { MakeInvoicesModal } from "../../../components/backoffice/modals/MakeInvoicesModal";
import { Loader } from "../../../components/common/Loader";
import { useGenericTableSelectionControl } from "../../../hooks/genericTableSelection/useGenericTableSelectionControl";
import { useServiceContainer } from "../../../hooks/useServiceContainer";

interface Props {
  onInvoiceCreated: () => void;
}

export const UnPaidSalesOrderList: React.FC<Props> = ({ onInvoiceCreated }) => {
  const [items, setItems] = useState<IUnPaidSalesOrderInvoice[]>([]);
  const [showMakeInvoiceModal, setShowMakeInvoiceModal] = useState(false);
  const [page, setPage] = useState(1);
  const [paginationMeta, setPaginationMeta] = useState<null | IPaginationMeta>(null);
  const [loader, setLoader] = useState(false);
  const { backofficeService } = useServiceContainer();
  const [filters, setFilters] = useState<any>({});
  const [sortSettings, setSortSettings] = useState<any>({});
  const { selectRowData, selectedData, resetSelection } = useGenericTableSelectionControl(items);

  const columns = [...UNPAID_SALES_ORDER_TABLE_COLUMNS];

  const fetchList = async (selectedPage: number, _filters: Record<string, string>, _sort: Record<string, string>) => {
    setLoader(true);
    try {
      const res = await backofficeService.getUnPaidSalesOrders(selectedPage, 10, _filters, _sort);

      setItems(res.items);
      setPaginationMeta(res.meta);
    } catch (e) {
      console.error(e);
    } finally {
      setLoader(false);
    }
  };

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc"
    }
  ] as any;

  const pageOptions = {
    sizePerPage: 20,
    page,
    totalSize: paginationMeta?.total || 0,
    custom: true
  };

  const onTableChange = (event: string, data: TableChangeState<any>) => {
    switch (event) {
      case "pagination":
        setPage(data.page);
        break;
      case "filter":
        setFilters(
          Object.fromEntries(Object.entries(data.filters).map(([column, value]) => [column, value.filterVal]))
        );
        break;
      case "sort":
        setSortSettings({
          [data.sortField]: data.sortOrder
        });
        break;
      default:
        break;
    }
  };

  const handleMakeInvoiceModalOpen = () => {
    setShowMakeInvoiceModal(true);
  };

  useEffect(() => {
    fetchList(page, filters, sortSettings).then(() => null);
  }, [page, filters, sortSettings]);

  return (
    <div className="data-table">
      <Card className="border">
        {loader && <Loader />}
        <CardBody>
          <h3>Un paid sales orders</h3>
          <PaginationProvider pagination={paginationFactory(pageOptions)}>
            {({ paginationProps, paginationTableProps }: any) => (
              <ToolkitProvider keyField="id" columns={columns} data={items} search>
                {(toolkitProps: any) => {
                  return (
                    <>
                      <Row className="mb-2">
                        <Col md="12" className="mb-2">
                          Selected orders length: {selectedData.length}
                        </Col>
                        <Col md="12">
                          <div className="d-inline-flex me-3">
                            <Button
                              color="primary"
                              onClick={() => {
                                CLEAR_UNPAID_SALES_ORDER_TABLE_FILTER_VALUES();
                              }}
                            >
                              Clear filters
                            </Button>
                          </div>
                          <div className="d-inline-flex me-3">
                            <Button
                              color="primary"
                              disabled={selectedData.length === 0}
                              onClick={() => {
                                handleMakeInvoiceModalOpen();
                              }}
                            >
                              Make invoices
                            </Button>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col xl="12">
                          <div className="table-responsive">
                            <BootstrapTable
                              bootstrap4
                              remote
                              onTableChange={onTableChange}
                              bordered={false}
                              selectRow={selectRowData}
                              striped={false}
                              defaultSorted={defaultSorted}
                              filter={filterFactory()}
                              classes="table align-middle table-nowrap table-sm"
                              headerWrapperClasses="thead-light"
                              {...toolkitProps.baseProps}
                              {...paginationTableProps}
                              data={items}
                              keyField="id"
                            />
                          </div>
                        </Col>
                      </Row>

                      <Row className="align-items-md-center mt-30">
                        <Col className="inner-custom-pagination d-flex">
                          <div className="text-md-right ms-auto">
                            <PaginationListStandalone {...paginationProps} />
                          </div>
                        </Col>
                      </Row>
                    </>
                  );
                }}
              </ToolkitProvider>
            )}
          </PaginationProvider>
        </CardBody>
      </Card>

      <MakeInvoicesModal
        show={showMakeInvoiceModal}
        onSubmit={() => {
          resetSelection();
          fetchList(page, filters, sortSettings).then(() => null);
          onInvoiceCreated();
        }}
        toggle={() => setShowMakeInvoiceModal(false)}
        selectedData={selectedData}
      />
    </div>
  );
};
