export interface IUser {
  id: number;
  company_id: number;
  name: string;
  called: string;
  image: string;
  email: string;
  phone: string;
  lang: string;
  failed_logins: number;
  reg_datetime: string;
  last_visit_datetime: string;
  is_deleted: number;
  other_user_id: number;
  is_password_changed: number;
  email_signature: string;
  sms_is_active: number;
  sms_number: string;
  telegram_is_active: number;
  telegram_number: string;
  whatsapp_is_active: number;
  whatsapp_number: string;
  customer_id: number;
  random_code: string;
  email_verified_at: string | null;
  is_introduction_passed?: boolean;
  created_at: string;
  updated_at: string;
}

export interface IUserToken {
  token: string;
}

export enum UserRole {
  Admin = "admin"
}

export type IUserRole = {
  role: UserRole[];
};

export type IUserWithRole = IUser & {
  role: UserRole[];
} & IUserPaymentMethods;

export type IUserPaymentMethods = {
  payment_methods: string[];
};

export interface IToken {
  access_token: string;
}
