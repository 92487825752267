import React, { useCallback, useMemo, useState } from "react";

import { nanoid } from "nanoid";
import { Button, Col, Figure, Form, Row } from "react-bootstrap";

import TRIGGER from "../../config/trigger";
import useI18n from "../../hook/usei18n";
import useImageAsset from "../../hook/useImageAsset";
import alignStyles from "../../style/align.module.css";
import borderStyles from "../../style/border.module.css";
import colorStyles from "../../style/color.module.css";
import displayStyles from "../../style/display.module.css";
import fontStyles from "../../style/font.module.css";
import sizeStyles from "../../style/size.module.css";
import spaceStyles from "../../style/space.module.css";
import Drag from "../../util/Drag";
import { ImageItemKind } from "../../view/object/image";
import { getImageUrl } from "../../../common/utils/formatter.util";
import { ImageAssetListItem } from "../../../store/canvasImageEditor/imageAssetList";
import { getFramePos } from "../../view/frame";
import { StageData } from "../../../store/canvasImageEditor/currentStageData";
import useStage from "../../hook/useStage";
import useItem from "../../hook/useItem";
import { useListener } from "react-bus";
import { toast } from "react-toastify";

export const IMAGE_LIST_KEY = "importedImage";

interface Props {
  stageRef: ReturnType<typeof useStage>["stageRef"];
  printfileSize: [number, number]; // [width, height]
}

const ImageWidget: React.FC<Props> = ({stageRef, printfileSize}) => {
  const [searchKeyword, setSearchKeyword] = useState("");

  const { setImageAsset, imageAssetList } = useImageAsset();
  const { createItem } = useItem();
  const { getTranslation } = useI18n();

  const images = useMemo(() => {
    const sortedImageAssetList = [...imageAssetList].sort((a, b) => {
      // Handle undefined createdAt values
      if (a.createdAt === undefined && b.createdAt === undefined) {
        return 0;
      }
      if (a.createdAt === undefined) {
        return 1;
      }
      if (b.createdAt === undefined) {
        return -1;
      }

      const dateA = new Date(a.createdAt);
      const dateB = new Date(b.createdAt);

      if (dateB > dateA) {
        return 1;
      } else if (dateB < dateA) {
        return -1;
      } else {
        return 0;
      }
    });
    if (searchKeyword === "") return sortedImageAssetList;
    return sortedImageAssetList.filter((_image) => _image.name.indexOf(searchKeyword) !== -1);
  }, [searchKeyword, imageAssetList]);

  const changeKeyword = (e: React.BaseSyntheticEvent) => {
    setSearchKeyword(e.currentTarget.value as string);
  };

  const insertImage = (data: { [key: string]: any }) => {
    const imageSrc = new Image();
    let source = data.src;

    source = data.src;

    imageSrc.onload = () => {
      let width = imageSrc.width;
      let height = imageSrc.height;
      const position = getFramePos(stageRef!.current!, undefined, width, height);
      const newImage: StageData = {
        id: nanoid(),
        shouldBeSelected: true,
        attrs: {
          name: "label-target",
          "data-item-type": "image",
          x: position.x,
          y: position.y,
          width,
          height,
          src: data.src,
          zIndex: 0,
          brightness: 0,
          // _filters: ["Brighten"],
          updatedAt: Date.now()
        },
        className: "sample-image",
        children: []
      };

      // if image is 2x smaller than printfile size, then show notification
      const widthRatio = width / printfileSize[0];
      const heightRatio = height / printfileSize[1];

      if (widthRatio < 0.5 || heightRatio < 0.5) {
        toast.warn("Image is too small, please upload a larger image, to avoid pixelation.");
      }

      createItem(newImage);
    };
    imageSrc.src = source;
  };

  const uploadImage = () => {
    const file = document.createElement("input");
    file.type = "file";
    file.accept = "image/png, image/jpeg";
    file.onchange = (e) => {
      const event = e;
      if (event.target && (event.target as HTMLInputElement).files) {
        Object.values((event.target as HTMLInputElement).files!).forEach((file) => {
          const result: ImageAssetListItem[] = [
            {
              type: "image",
              id: nanoid(),
              name: "imported image",
              src: window.URL.createObjectURL(file),
              // iso string
              createdAt: new Date().toISOString(),
            }
          ];

          insertImage(result[0])

          setImageAsset(result);
        });
      }
    };
    file.click();
  };

  const onClickInputEvent = useCallback(() => {
    uploadImage()
  }, [])

  useListener('IMAGE_WIDGET:CLICK_INPUT', onClickInputEvent);

  return (
    <Col className={[sizeStyles["mx-h-30vh"]].join(" ")}>
      <Form>
        <Form.Group className="mb-3" controlId="iconKeyword">
          <Form.Label>{getTranslation("widget", "image", "search", "title")}</Form.Label>
          <Form.Control
            onChange={changeKeyword}
            type="text"
            placeholder={getTranslation("widget", "image", "search", "placeholder")}
          />
          <Form.Text className="text-muted">{getTranslation("widget", "image", "search", "desc")}</Form.Text>
        </Form.Group>
      </Form>
      <Row>
        <h6>
          {getTranslation("widget", "image", "name")}
          <Button
            color={"primary"}
            className={[
              displayStyles["inline-block"],
              spaceStyles.ml1rem,
              alignStyles["text-left"],
              "px-2 py-0"
            ].join(" ")}
            size={"sm"}
            onClick={uploadImage}
          >
            <i className="bi-plus" /> Upload
          </Button>
        </h6>
      </Row>
      <Row xs={3} className={"canvas-image-widget"}>
        {images.map((_data) => (
          <ImageThumbnail
            key={`image-thumbnail-${_data.id}`}
            data={{
              id: _data.id,
              src: _data.src ?? `find:${_data.id}`,
              name: _data.name,
              "data-item-type": _data.type
            }}
            maxPx={50}
          />
        ))}
      </Row>
    </Col>
  );
};

export default ImageWidget;

const ImageThumbnail: React.FC<{
  maxPx: number;
  data: Omit<ImageItemKind, "image">;
}> = ({ data: { id, ...data }, maxPx }) => {
  const { getImageAssetSrc } = useImageAsset();
  return (
    <Figure as={Col} className={["canvas-image-widget__item", alignStyles.absoluteCenter, alignStyles.wrapTrue].join(" ")}>
      <Drag
        dragType="copyMove"
        dragSrc={{
          trigger: TRIGGER.INSERT.IMAGE,
          "data-item-type": data["data-item-type"],
          src: data.src.startsWith("data:") || data.src.startsWith("blob:") ? data.src : getImageUrl(`/assets/mock-design-images/${data.src}`)
        }}
      >
        <Figure.Image
          alt={data.name}
          src={data.src.startsWith("data:") || data.src.startsWith("blob:") ? data.src : getImageUrl(`/assets/mock-design-images/${data.src}`) }
        />
      </Drag>
      <Figure.Caption className={[fontStyles.font075em, sizeStyles.width100, "text-center"].join(" ")}>
        {data.name}
      </Figure.Caption>
    </Figure>
  );
};
