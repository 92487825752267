import React, { useCallback, useEffect, useMemo, useState } from "react";

import debounce from "lodash.debounce";
import { useNavigate } from "react-router-dom";
import Sticky from "react-stickynode";
import { toast } from "react-toastify";
import { Button } from "reactstrap";

import { OrderSummaryData } from "../../../common/interfaces/orders.interface";
import { isDeliveryDataCompleted, isOrderDataCompleted } from "../../../common/utils/condition.util";
import { getErrorMessage } from "../../../common/utils/formatter.util";
import { useCheckout } from "../../../hooks/useCheckout";
import { useServiceContainer } from "../../../hooks/useServiceContainer";
import ordersApiService from "../../../services/api/OrdersApiService";
import { APP_ACTION_GET_ORDER_CART, APP_ADD_ORDERS_TO_CART } from "../../../store/app/appSlice";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { ORDER_RESET_WIZARD, ORDER_SET_SUMMARY_DATA } from "../../../store/wizards/orderSlice";
import { Loader } from "../../common/Loader";
import { OrderSummaryCart } from "../../common/OrderSummaryCart/OrderSummaryCart";

export const OrderCreationSummary = () => {
  const orders = useAppSelector((state) => state.orderWizard.orders);
  const { ordersService } = useServiceContainer();

  const summary = useAppSelector((state) => state.orderWizard.summaryData);

  const { proceedToCheckout } = useCheckout();

  const deliveryData = useAppSelector((state) => {
    return state.orderWizard.deliveryData;
  });

  const navigate = useNavigate();

  const [loader, setLoader] = useState(false);

  const { data: countryList } = useAppSelector((state) => {
    return state.orderWizard.countryList;
  });

  const { deliveryDataInvalidKeys } = useAppSelector((state) => {
    return state.orderWizard;
  });

  const dispatch = useAppDispatch();

  const visibleOrders = useMemo(() => {
    return orders.filter((order) => {
      return !!order.selectedProductType;
    });
  }, [orders]);

  const deliveryDataCompleted = useMemo(() => {
    return deliveryDataInvalidKeys.length === 0 && isDeliveryDataCompleted(deliveryData);
  }, [deliveryData, deliveryDataInvalidKeys]);

  const canSubmit = useMemo(() => {
    if (!deliveryDataCompleted) return false;

    if (visibleOrders.length === 0) return false;

    return visibleOrders.every((order) => {
      return order.selectedProductType && order.selectedProductAsset && order.selectedDesign;
    });
  }, [deliveryDataCompleted, visibleOrders]);

  const deliveryCountryLabel = useMemo(() => {
    if (!deliveryData.country) return "";

    const target = countryList.find((country) => country.value === deliveryData.country);
    if (!target) return deliveryData.country;
    return target.label;
  }, [deliveryData.country]);

  const orderSummaryData = useMemo(() => {
    return visibleOrders.map((order) => ({
      productId: order.selectedProductType?.id as number,
      productAssetId: order.selectedProductAsset?.id as number,
      designId: order.selectedDesign?.id as number,
      count: order.count,
      deliveryData,
      includePremiumBag: order.premiumBag,
      giftMessage: order.giftMessage
    }));
  }, [visibleOrders, deliveryData]);

  const handleAddToCart = async () => {
    try {
      setLoader(true);
      await ordersService.addOrderToCart(orderSummaryData);

      dispatch(ORDER_RESET_WIZARD());

      toast.success("Order added to cart successfully");

      navigate("/cart");
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setLoader(false);
    }
  };

  const handleProceedToCheckout = async () => {
    try {
      setLoader(true);
      // add order to cart
      const data = await ordersService.addOrderToCart(orderSummaryData);
      dispatch(APP_ADD_ORDERS_TO_CART(data));

      // then proceed to checkout with cart order id
      await proceedToCheckout(
        data.map((order) => order.salesOrderId),
        deliveryData.paymentMethod
      );

      if (deliveryData.paymentMethod === "bill") {
        dispatch(APP_ACTION_GET_ORDER_CART());
        toast.success(`Thank you! Your orders are moved to production!`);
        navigate("/orders/list");
        dispatch(ORDER_RESET_WIZARD());
      }
    } catch (e) {
      toast.error(getErrorMessage(e));
    }
  };

  const updateOrderSummary = async (summaryData: OrderSummaryData[]) => {
    try {
      setLoader(true);
      const data = await ordersApiService.calculateOrderSummary(summaryData);
      dispatch(ORDER_SET_SUMMARY_DATA(data));
    } catch (e) {
      console.error(e);
    } finally {
      setLoader(false);
    }
  };

  const debouncedUpdateOrderSummary = useCallback(debounce(updateOrderSummary, 500), []);

  useEffect(() => {
    const completedOrders = orderSummaryData.filter((order) => isOrderDataCompleted(order));
    if (completedOrders.length) {
      debouncedUpdateOrderSummary(completedOrders);
    }
  }, [orderSummaryData]);

  return (
    <Sticky top={125}>
      <div className=" h-100 wizard-summary">
        {loader && <Loader />}
        <h5 className="font-size-16 mt-0">Order Summary</h5>

        {visibleOrders.length ? (
          <OrderSummaryCart items={visibleOrders} />
        ) : (
          <div className="wizard-summary__alert">Complete the wizard to see the summary</div>
        )}
        <h6 className="pb-0 mt-3">Delivery Address</h6>

        {deliveryDataCompleted ? (
          <div className="wizard-summary__delivery-data">
            <span className="location-icon" />
            {deliveryData.addressLine1}, {deliveryData.addressLine2 && <>{deliveryData.addressLine2} </>}
            {deliveryCountryLabel}, {deliveryData.city}, {deliveryData.zipCode}
          </div>
        ) : (
          <div className="wizard-summary__alert">Please enter the delivery address to continue</div>
        )}

        <div className="wizard-summary__value-table table-responsive">
          <table className="table table-nowrap">
            <tbody>
              <tr>
                <td className="border-0">Shipping</td>
                <td className="text-end border-0">{summary?.shipping ? `€${summary.shipping.toFixed(2)}` : "-"}</td>
              </tr>
              <tr>
                <td className=" ">Total</td>
                <td className="text-end">{summary?.total ? `€${summary.total.toFixed(2)}` : "-"}</td>
              </tr>
              <tr>
                <td className="border-0">
                  <strong>Total with VAT</strong>
                </td>
                <td className="text-end border-0">
                  <h4 className="m-0">{summary?.totalWithVat ? `€${summary.totalWithVat.toFixed(2)}` : "-"}</h4>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="d-flex flex-column">
          <Button color="success" className="mb-2" disabled={!canSubmit} onClick={handleAddToCart}>
            <i className="fa fa-shopping-cart me-2" /> Add to cart
          </Button>
          <Button color="primary" disabled={!canSubmit} onClick={handleProceedToCheckout}>
            Proceed to checkout
          </Button>
        </div>

        {/* <SetDeliveryModal */}
        {/*  show={showDeliveryModal} */}
        {/*  toggle={() => { */}
        {/*    setShowDeliveryModal(false); */}
        {/*  }} */}
        {/* /> */}
      </div>
    </Sticky>
  );
};
