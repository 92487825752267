import React, { useEffect, useMemo, useState } from "react";

import { useFormContext } from "react-hook-form";
import { toast } from "react-toastify";

import { IProductSelectedListingItem } from "../../../../common/interfaces/product-wizard.interface";
import {
  IProductListingPresetItem,
  IProductListingTemplate,
  IProductSectionOption,
  IProductShippingProfileItem,
  ProductListingTemplateFields
} from "../../../../common/interfaces/products.interface";
import { replaceSpecialCharacters } from "../../../../common/utils/formatter.util";
import useDidMountEffect from "../../../../hooks/useDidMountEffect";
import { useAppDispatch } from "../../../../store/store";
import { PRODUCT_SET_SELECTED_LISTING_FIELD_VALUE } from "../../../../store/wizards/productSlice";
import { TextAreaInput } from "../../Input/TextAreaInput";
import { TextFieldInput } from "../../Input/TextFieldInput";
import { SelectField } from "../../SelectField";
import { TooltipIcon } from "../../TooltipIcon";
import { ListingFormTags } from "./ListingFormTags";
import { ListingFormTemplateSelector } from "./ListingFormTemplateSelector";

interface Props {
  selectedItem: IProductSelectedListingItem;
  presetItem: IProductListingPresetItem;
  sectionOptions: IProductSectionOption[];
  shippingProfiles: IProductShippingProfileItem[];
  hideKeywords?: boolean;
  hideHeader?: boolean;
  onFieldUpdate: (listingId: number, field: keyof IProductSelectedListingItem["fields"] | any, value: any) => void;
  onDelete?: (listingId: number) => void;
}

export const ListingForm: React.FC<Props> = ({
  hideKeywords,
  shippingProfiles,
  sectionOptions,
  selectedItem,
  presetItem,
  hideHeader,
  onFieldUpdate,
  onDelete
}) => {
  const {
    register,
    setValue,
    setError,
    watch,
    trigger,
    formState: { errors }
  } = useFormContext();

  const [tagValue, setTagValue] = useState<string>("");
  const [materialValue, setMaterialValue] = useState<string>("");
  const [keyWord1, setKeyword1] = useState();
  const [keyWord2, setKeyword2] = useState();

  const sectionOptionFormatted = useMemo(() => {
    return sectionOptions.map((e) => ({
      label: e.title,
      value: e.id.toString()
    }));
  }, [sectionOptions]);

  const shippingProfilesFormatted = useMemo(() => {
    return shippingProfiles.map((e) => ({
      label: e.title,
      value: e.shipping_profile_id.toString()
    }));
  }, [shippingProfiles]);

  // const selectedProductTitleValue = watch(`selectedListings.${selectedItem.id}.title`);
  // const selectedProductDescriptionValue = watch(`selectedListings.${selectedItem.id}.description`);
  const selectedProductSectionValue = watch(`selectedListings.${selectedItem.id}.section`);
  // @ts-ignore
  const selectedProductSection = sectionOptionFormatted.find((e) => e.value === selectedProductSectionValue);

  const selectedProductShippingProfileValue = watch(`selectedListings.${selectedItem.id}.shipping_profile`);
  const selectedProductShippingProfile = shippingProfilesFormatted.find(
    (e) => e.value === selectedProductShippingProfileValue
  );

  const currentTags = watch(`selectedListings.${selectedItem.id}.tags`);
  const currentMaterials = watch(`selectedListings.${selectedItem.id}.materials`);

  const title = watch(`selectedListings.${selectedItem.id}.title`);
  const currentKeyWord1 = watch(`selectedListings.${selectedItem.id}.keywords.keyword1`);
  const currentKeyWord2 = watch(`selectedListings.${selectedItem.id}.keywords.keyword2`);
  const description = watch(`selectedListings.${selectedItem.id}.description`);

  const currentFieldValues = useMemo(() => {
    // @ts-ignore
    // console.log("currentKeyWord1 => ",currentKeyWord1)
    // console.log("currentKeyWord2 => ",currentKeyWord2)
    return {
      title,
      description,
      tags: currentTags,
      materials: currentMaterials,
      keywords: {
        keyword1: currentKeyWord1,
        keyword2: currentKeyWord2
      }
    } as ProductListingTemplateFields;
  }, [title, description, currentTags, currentMaterials, currentKeyWord1, currentKeyWord2]);

  // @ts-ignore
  const onClearAll = (name) => {
    const val = "";
    if (name === `selectedListings.${selectedItem.id}.keywords.keyword1`) {
      // @ts-ignore
      setKeyword1(val);
    }
    if (name === `selectedListings.${selectedItem.id}.keywords.keyword2`) {
      // @ts-ignore
      setKeyword2(val);
    }
    setValue(name, val);
    trigger(name);
  };

  // @ts-ignore
  const onKeyWord1Change = (e) => {
    const { value } = e.target;
    const re = /^[\w '-]+$/;
    if (value === "" || re.test(value)) {
      setKeyword1(value);
      setValue(`selectedListings.${selectedItem.id}.keywords.keyword1`, value);
      trigger(`selectedListings.${selectedItem.id}.keywords.keyword1`);
    }
  };
  // @ts-ignore
  const onKeyWord2Change = (e) => {
    const { value } = e.target;
    const re = /^[\w '-]+$/;
    if (value === "" || re.test(value)) {
      setKeyword2(value);
      setValue(`selectedListings.${selectedItem.id}.keywords.keyword2`, value);
      trigger(`selectedListings.${selectedItem.id}.keywords.keyword2`);
    }
  };

  const handleAddTag = () => {
    if (!tagValue) return;

    const trimmedValue = tagValue.trim();
    if (!trimmedValue) return;
    const splittedValue = trimmedValue.split(",").map((e) => e.trim().replace(/[^0-9a-zA-Z{} ]+/gi, ""));
    if (!splittedValue.length) return;

    for (const tag of splittedValue) {
      const regex = new RegExp("{+[A-z0-9 ]+}", "i");
      if (regex.test(tag)) {
        if (tag !== "{keyword1}" && tag !== "{keyword2}") {
          toast.error(`Only {keyword1} and {keyword2} parameters can be used`);
          return;
        }
      }
      let dt = false;
      if (currentTags.length > 0) {
        // @ts-ignore
        currentTags.forEach((e) => {
          const regex2 = new RegExp(`^${e}$`, "i");
          if (regex2.test(tag)) {
            toast.error(`Duplicate tag`);
            dt = true;
          }
        });
        if (dt) {
          return;
        }
      }

      if (tag.length > 20) {
        toast.error(`Tag (${tag}) length must be less than 20 characters`);
        return;
      }
    }
    // @ts-ignore
    setValue(`selectedListings.${selectedItem.id}.tags`, [...(currentTags ?? []), ...splittedValue]);
    trigger(`selectedListings.${selectedItem.id}.tags`);
    setTagValue("");
  };

  const handleRemoveTag = (tag: string) => {
    setValue(
      `selectedListings.${selectedItem.id}.tags`,
      currentTags.filter((e: string) => e !== tag)
    );
    trigger(`selectedListings.${selectedItem.id}.tags`);
  };
  const handleRemoveAllTags = () => {
    setValue(`selectedListings.${selectedItem.id}.tags`, []);
    trigger(`selectedListings.${selectedItem.id}.tags`);
  };

  const handleAddMaterial = () => {
    if (!materialValue) return;

    const trimmedValue = materialValue.trim();
    if (!trimmedValue) return;
    const splittedValue = trimmedValue.split(",").map((e) => e.trim().replace(/[^0-9a-zA-Z{} ]+/gi, ""));
    if (!splittedValue.length) return;

    for (const material of splittedValue) {
      const regex = new RegExp("{+[A-z0-9 ]+}", "i");
      if (regex.test(material)) {
        if (material !== "{keyword1}" && material !== "{keyword2}") {
          toast.error(`Only {keyword1} and {keyword2} parameters can be used`);
          return;
        }
      }
      let dm = false;
      if (currentMaterials.length > 0) {
        // @ts-ignore
        currentMaterials.forEach((e) => {
          const regex2 = new RegExp(`^${e}$`, "i");
          if (regex2.test(material)) {
            toast.error(`Duplicate material`);
            dm = true;
          }
        });
        if (dm) {
          return;
        }
      }

      if (material.length > 45) {
        toast.error(`Meterial (${material}) length must be less than 45 characters`);

        return;
      }
    }

    setValue(`selectedListings.${selectedItem.id}.materials`, [...(currentMaterials ?? []), ...splittedValue]);
    trigger(`selectedListings.${selectedItem.id}.materials`);
    setMaterialValue("");
  };

  const handleRemoveMaterial = (tag: string) => {
    setValue(
      `selectedListings.${selectedItem.id}.materials`,
      currentMaterials.filter((e: string) => e !== tag)
    );
    trigger(`selectedListings.${selectedItem.id}.materials`);
  };
  const handleRemoveAllMaterials = () => {
    setValue(`selectedListings.${selectedItem.id}.materials`, []);
    trigger(`selectedListings.${selectedItem.id}.materials`);
  };

  const handleFieldUpdate = (field: keyof IProductSelectedListingItem["fields"] | any, value: any) => {
    onFieldUpdate(selectedItem.id, field, value);
  };

  const setDefaultTexts = (fields: ProductListingTemplateFields) => {
    setValue(`selectedListings.${selectedItem.id}.title`, fields.title);
    setValue(`selectedListings.${selectedItem.id}.description`, fields.description);
    setValue(`selectedListings.${selectedItem.id}.tags`, fields.tags);
    setValue(`selectedListings.${selectedItem.id}.materials`, fields.materials);
    setValue(`selectedListings.${selectedItem.id}.keywords`, {
      keyword1: fields.keywords.keyword1,
      keyword2: fields.keywords.keyword2
    });
    // setValue(`selectedListings.${selectedItem.id}.keywords.keyword2`, fields.keywords.keyword2);
  };

  const handleTemplateSelected = (template?: IProductListingTemplate) => {
    setDefaultTexts(
      template?.fields ?? {
        title: "",
        description: "",
        tags: [],
        materials: [],
        keywords: {
          keyword1: "",
          keyword2: ""
        }
      }
    );
  };

  const handleTriggerTemplateFieldsValidation = () => {
    setError(`selectedListings.${selectedItem.id}.title`, {
      type: "manual",
      message: "Please enter product title"
    });
    setError(`selectedListings.${selectedItem.id}.description`, {
      type: "manual",
      message: "TPlease enter product description"
    });
    setError(`selectedListings.${selectedItem.id}.tags`, {
      type: "manual",
      message: "Please select at least one tag"
    });
    setError(`selectedListings.${selectedItem.id}.materials`, {
      type: "manual",
      message: "Please select at least one material"
    });
  };

  useDidMountEffect(() => {
    if (currentTags) {
      handleFieldUpdate("tags", currentTags);
    }
  }, [currentTags]);

  useDidMountEffect(() => {
    if (currentMaterials) {
      handleFieldUpdate("materials", currentMaterials);
    }
  }, [currentMaterials]);

  useDidMountEffect(() => {
    handleFieldUpdate("title", title);
  }, [title]);

  useDidMountEffect(() => {
    handleFieldUpdate("description", description);
  }, [description]);

  useDidMountEffect(() => {
    handleFieldUpdate("keywords", {
      keyword1: currentKeyWord1,
      keyword2: currentKeyWord2
    });
  }, [currentKeyWord1, currentKeyWord2]);

  useDidMountEffect(() => {
    handleFieldUpdate("section", selectedProductSectionValue);
  }, [selectedProductSectionValue]);

  useDidMountEffect(() => {
    handleFieldUpdate("shipping_profile", selectedProductShippingProfileValue);
  }, [selectedProductShippingProfileValue]);

  useEffect(() => {
    setDefaultTexts(selectedItem.fields);
  }, []);

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      // @ts-ignore
      if (errors?.selectedListings?.[selectedItem.id]?.keywords?.keyword1?.message) {
        // @ts-ignore
        toast.error(errors?.selectedListings?.[selectedItem.id]?.keywords?.keyword1?.message);
      }
      // @ts-ignore
      if (errors?.selectedListings?.[selectedItem.id]?.keywords?.keyword2?.message) {
        // @ts-ignore
        toast.error(errors?.selectedListings?.[selectedItem.id]?.keywords?.keyword2?.message);
      }
      // @ts-ignore
      if (errors?.selectedListings?.[selectedItem.id]?.title?.message) {
        // @ts-ignore
        toast.error(errors?.selectedListings?.[selectedItem.id]?.title?.message);
      }
      // @ts-ignore
      if (errors?.selectedListings?.[selectedItem.id]?.description?.message) {
        // @ts-ignore
        toast.error(errors?.selectedListings?.[selectedItem.id]?.description?.message);
      }
      // @ts-ignore
      if (errors?.selectedListings?.[selectedItem!.id]?.tags?.message) {
        // @ts-ignore
        toast.error(errors?.selectedListings?.[selectedItem!.id]?.tags?.message);
      }
      // @ts-ignore
      if (errors?.selectedListings?.[selectedItem.id]?.materials?.message) {
        // @ts-ignore
        toast.error(errors?.selectedListings?.[selectedItem.id]?.materials?.message);
      }
      // @ts-ignore
      if (errors?.selectedListings?.[selectedItem.id]?.section?.message) {
        // @ts-ignore
        toast.error(errors?.selectedListings?.[selectedItem.id]?.section?.message);
      }
      // @ts-ignore
      if (errors?.selectedListings?.[selectedItem.id]?.shipping_profile?.message) {
        // @ts-ignore
        toast.error(errors?.selectedListings?.[selectedItem.id]?.shipping_profile?.message);
      }
    }
  }, [errors]);

  return (
    <>
      <div className="listing-form">
        {!hideHeader && (
          <div className="listing-form__header">
            <div className="listing-form__header__title">
              <div className="listing-form__header__title__text">{presetItem.title}</div>
              <span className="separator">|</span>
              <div className="listing-form__header__title__models">
                {presetItem.groups.map((e) => e.title).join(", ")}
              </div>
            </div>
            {onDelete ? (
              <button className="listing-form__header__delete" onClick={() => onDelete(selectedItem.id)} type="button">
                <i className="bx bx-trash-alt" />
              </button>
            ) : (
              ""
            )}
          </div>
        )}
        <div className="listing-form__body">
          <h5 className="mb-2">Product info</h5>
          {!hideKeywords && (
            <>
              <ListingFormTemplateSelector
                selectedListing={selectedItem}
                presetItem={presetItem}
                onTemplateSelected={handleTemplateSelected}
                currentFields={currentFieldValues}
                triggerTemplateFieldsValidation={handleTriggerTemplateFieldsValidation}
              />

              <TextFieldInput
                {...register(`selectedListings.${selectedItem.id}.keywords.keyword1`)}
                label="Keyword 1:"
                clearAll
                // @ts-ignore
                onClearAll={(e) => {
                  onClearAll(e);
                }}
                placeholder="Write a 1-2 keywords, for example, Black Cat (max 20 characters)"
                hint="Your keyword will be marked as {keyword1}. Use {keyword1} to insert bellow into the title, description, and tags."
                // @ts-ignore
                error={errors?.selectedListings?.[selectedItem.id]?.keywords?.keyword1?.message}
                value={keyWord1}
                onChange={onKeyWord1Change}
              />

              <TextFieldInput
                className="mt-3"
                {...register(`selectedListings.${selectedItem.id}.keywords.keyword2`)}
                label="Keyword 2:"
                clearAll
                // @ts-ignore
                onClearAll={(e) => {
                  onClearAll(e);
                }}
                placeholder="Write a 1-2 keywords, for example, Magic House (max 20 characters)"
                hint="Your keyword will be marked as {keyword2}. Use {keyword2} to insert bellow into the title, description, and tags."
                // @ts-ignore
                error={errors?.selectedListings?.[selectedItem.id]?.keywords?.keyword2?.message}
                value={keyWord2}
                onChange={onKeyWord2Change}
              />
            </>
          )}

          <TextFieldInput
            className={hideKeywords ? "" : "mt-3"}
            {...register(`selectedListings.${selectedItem.id}.title`)}
            label="Product title:"
            clearAll
            // @ts-ignore
            onClearAll={(e) => {
              onClearAll(e);
            }}
            placeholder="Write {keyword1} Product Name {keyword2} and include some information about your product and its models"
            // @ts-ignore
            error={errors?.selectedListings?.[selectedItem.id]?.title?.message}
          />

          <TextAreaInput
            className="mt-3"
            {...register(`selectedListings.${selectedItem.id}.description`)}
            label="Product description:"
            clearAll
            // @ts-ignore
            onClearAll={(e) => {
              onClearAll(e);
            }}
            placeholder="Write a description of your product, ensuring to include {keyword1} and {keyword2}. These keywords will be automatically added to the text."
            style={{ height: "180px" }}
            // @ts-ignore
            error={errors?.selectedListings?.[selectedItem.id]?.description?.message}
          />

          <ListingFormTags
            value={tagValue}
            onChange={(e) => {
              setTagValue(e.target.value);
            }}
            removeAll
            onRemoveAll={handleRemoveAllTags}
            placeholder="Add up to 13 tags,and if you wish, include your {keyword1} and {keyword2}"
            label="Tags (max 13)"
            currentTags={currentTags}
            onAddTag={handleAddTag}
            onRemoveTag={handleRemoveTag}
            // @ts-ignore
            error={errors?.selectedListings?.[selectedItem!.id]?.tags?.message}
          />

          <ListingFormTags
            value={materialValue}
            placeholder="Add up to 13 descriptions"
            onChange={(e) => {
              setMaterialValue(e.target.value);
            }}
            removeAll
            onRemoveAll={handleRemoveAllMaterials}
            label="Materials (max 13)"
            currentTags={currentMaterials}
            onAddTag={handleAddMaterial}
            onRemoveTag={handleRemoveMaterial}
            // @ts-ignore
            error={errors?.selectedListings?.[selectedItem.id]?.materials?.message}
          />

          <div className="mt-3">
            <SelectField
              options={sectionOptionFormatted}
              label={
                <>
                  Product sections:
                  <TooltipIcon
                    hoverable
                    id="product-sections-tt"
                    content="Before creating a listing you have to create a Product section in your Etsy shop."
                  />
                </>
              }
              // @ts-ignore
              error={errors?.selectedListings?.[selectedItem.id]?.section?.message}
              value={selectedProductSection}
              menuPlacement="top"
              placeholder="Choose section"
              onChange={(data) => {
                setValue(`selectedListings.${selectedItem.id}.section`, data.value);
                setError(`selectedListings.${selectedItem.id}.section`, {
                  type: "manual",
                  message: ""
                });
              }}
            />
          </div>

          <div className="mt-3">
            <SelectField
              options={shippingProfilesFormatted}
              label={
                <>
                  Shipping profile:
                  <TooltipIcon
                    hoverable
                    id="shipping-profile-tt"
                    content="Before creating a Shipping profile you have to create a Product section in your Etsy shop."
                  />
                </>
              }
              // @ts-ignore
              error={errors?.selectedListings?.[selectedItem.id]?.shipping_profile?.message}
              value={selectedProductShippingProfile}
              menuPlacement="top"
              placeholder="Choose shipping profile"
              onChange={(data) => {
                setValue(`selectedListings.${selectedItem.id}.shipping_profile`, data.value);
                setError(`selectedListings.${selectedItem.id}.shipping_profile`, {
                  type: "manual",
                  message: ""
                });
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};
