import type { IProductListingItemFields } from "./product-wizard.interface";

export enum ProductType {
  PHONE_CASE = "PHONE_CASE",
  SCREEN_PROTECTOR = "SCREEN_PROTECTOR",
  TSHIRT = "T-SHIRT",
  MACBOOK_CASE = "MACBOOK_CASE",
  IPAD_CASE = "IPAD_CASE",
  AIRPODS_CASE = "AIRPODS_CASE",
  PUZZLE = "PUZZLE",
  MAGNET = "MAGNET"
}

export interface IProductItem {
  id: number;
  title: string;
  image: string;
  image_hover: string;
  category: string;
  type: ProductType;
  description: string;
  gallery_images: string[];
  ignore_balance: boolean;
  productCount: number;
  categoryId: number;
  default_price: string;
  default_retail_price: number | null;
  status: number;
  printfile_width: number;
  printfile_height: number;
  max_designs: number;
  ord: number;
  price: {
    min: any;
    max: any;
  };
}

export interface ICategoryItem {
  id: number;
  code: string;
  title: string;
  image: string;
  deleted_at: string | null;
  created_at: string | null;
  updated_at: string | null;
}

export interface IProductMaterial {
  id: number;
  title: string;
  price: number | null;
  image: string | null;
  created_at: string | null;
  updated_at: string | null;
  deleted_at: string | null;
}

export enum ProductAssetType {
  PHONE_CASE = "PHONE_CASE"
}

export interface IProductAssetItem {
  id: number;
  title: string;
  image: string;
  type?: ProductType;
  code?: string;
  product_id: number;
  description?: string;
  price: number;
  price_with_vat: number;
  weight?: number;
  balance: number;
  reserved: number;
  is_deleted: boolean | number;
}

export interface BooleanCheckboxValue {
  value: string;
  label: string;
}

export interface IDesignItem {
  id: number;
  user_id: number;
  title: string;
  comment: string;
  productGroup: {
    id: number;
    title: string;
  };
  productId: number | null;
  type: ProductType;
  image: string;
  isDeleted: number;
}

export interface ICustomDesignItem {
  id: number;
  title: string;
  description?: string;
  image: [string, string];
}

export interface IProductList {
  all: IProductItem[];
  top: IProductItem[];
  categories: ICategoryItem[];
}

export interface IProductDetails {
  description: string;
  gallery_images: string[];
}

export interface IProductDeliveryService {
  id: number;
  material_id: number;
  delivery_service_id: number;
  created_at: string;
  updated_at: string;
  delivery_service: {
    id: number;
    title: string;
  };
}

export interface IModelBrand {
  id: string;
  name: string;
}

export interface IProductAssetGroup {
  title: string;
  id: string;
  items: IProductAssetItem[];
}

export interface IProductListingPresetItem {
  id: number;
  title: string;
  product_id?: number;
  is_default?: boolean;
  groups: IProductAssetGroup[];
  template?: IProductListingTemplate;
}

export interface IProductListingTemplate {
  id: number;
  name: string;
  preset_id?: number;
  product_id: number;
  is_default?: boolean;
  fields: ProductListingTemplateFields;
}

export interface IProductSectionOption {
  id: number;
  title: string;
}

export interface IProductShippingProfileItem {
  shipping_profile_id: number;
  title: string;
}

export type ProductListingTemplateFields = Omit<IProductListingItemFields, "section" | "shipping_profile">;

export interface IProductListingItem {
  // id: number;
  // product: IProductListingItemProduct;
  // models_count: number;
  // variants_count: number;
  // status: ProductListingItemStatus;
  // inventory_status: ProductListingItemInventoryStatus;
  // delivery_options: IProductListingItemDeliveryOptions;
  // has_alert?: boolean;
  // user: IProductListingItemUser;

  id: number;
  shop_id: number;
  product_id: number;
  product: Pick<IProductItem, "title" | "image">;
  status: boolean; // false - unpublished, true - published
  user: IProductListingItemUser;
  listings: IProductCreateSubmitPayloadListingEntity[];
  photos: (IProductCreateSubmitPayloadPhotoEntity & { mockup_file: string; sku_number: string })[];
}

export interface IProductListingItemUser {
  id: number;
  name: string;
  email: string;
}

export type IProductListingItemDeliveryOptions = "Standart";

export type IProductListingItemProduct = Pick<IProductItem, "title" | "image">;

export enum ProductListingItemStatus {
  Unpublished = 0,
  Published = 1
}

export enum ProductListingItemInventoryStatus {
  OutOfStock = 0,
  InStock = 1
}

export type IProductCreateSubmitPayloadListingEntity = {
  id: number;
  items: number[];
  status?: boolean;
  price_map: Record<string, number | null>;
  fields: Omit<IProductListingItemFields, "keywords" | "shipping_profile" | "section"> & {
    shipping_profile_id: number;
    section: number;
  };
};
export type IProductCreateSubmitPayloadPhotoEntity = {
  design_id: number;
  mockup_file_id: number;
  order: number;
};

export interface IProductCreateSubmitPayload {
  product_id: number;
  shop_id: number;
  listings: IProductCreateSubmitPayloadListingEntity[];
  photos: IProductCreateSubmitPayloadPhotoEntity[];
}

export interface IProductMockupDesignConnectorStorageItem {
  mockup_folder_id: string;
  selected_design_ids: number[];
  connections: Record<string, string>;
}
