import React, { useState } from "react";

import { toast } from "react-toastify";

import { IProductSelectedListingItem } from "../../../../common/interfaces/product-wizard.interface";
import {
  IProductAssetGroup,
  IProductItem,
  IProductListingPresetItem
} from "../../../../common/interfaces/products.interface";
import { showConfirmationModal } from "../../../../common/utils/confirm.util";
import { getErrorMessage } from "../../../../common/utils/formatter.util";
import { useServiceContainer } from "../../../../hooks/useServiceContainer";
import { useAppDispatch, useAppSelector } from "../../../../store/store";
import {
  PRODUCT_ADD_LISTING_GROUP,
  PRODUCT_DELETE_LISTING_GROUP_PRESET_BY_ID,
  PRODUCT_UPDATE_LISTING_GROUP
} from "../../../../store/wizards/productSlice";
import { UpdateListingGroupsModal } from "../../../modals/listings/UpdateListingGroupsModal";
import { Loader } from "../../Loader";
import { ListingGroupItem } from "./ListingGroupItem";

interface Props {
  groups: IProductListingPresetItem[];
  selectedListings: IProductSelectedListingItem[];
  onSelect: (item: IProductListingPresetItem, state: boolean) => void;
  allGroups: IProductAssetGroup[];
  hideSelectCheckbox?: boolean;

  onCreateNewListing?: (title: string) => Promise<IProductListingPresetItem>;
  onListingUpdate: (listingId: number, assetIds?: number[]) => Promise<void>;
  postListingUpdate: (listingId: number, newGroups: IProductAssetGroup[]) => void;
  onListingDelete: (listingId: number) => Promise<void>;
}

export const ListingGroups: React.FC<Props> = ({
  hideSelectCheckbox,
  onCreateNewListing,
  allGroups,
  onSelect,
  groups,
  selectedListings,
  onListingUpdate,
  postListingUpdate,
  onListingDelete
}) => {
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [selectedListing, setSelectedListing] = useState<IProductListingPresetItem | null>(null);
  const [loader, setLoader] = useState(false);

  const handleAddNewListingClick = async () => {
    try {
      setLoader(true);

      if (!onCreateNewListing) return;

      const title = window.prompt("Enter the title of the new listing");

      if (!title) return;

      const titleMaxLength = 100;

      if (title.length > titleMaxLength) {
        toast.error(`Title is too long, max ${titleMaxLength} characters allowed.`);
        return;
      }

      const created = await onCreateNewListing(title as string);

      setSelectedListing(created);
      setShowUpdateModal(true);
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setLoader(false);
    }
  };

  const handlePresetUpdate = async (newGroups: IProductAssetGroup[]) => {
    if (!selectedListing) return;
    if (!selectedListing.is_default) {
      try {
        await onListingUpdate(
          selectedListing.id,
          newGroups.map((group) => group.items.map((asset) => asset.id)).flat()
        );
      } catch (e) {
        console.error(e);
      }
    }

    setShowUpdateModal(false);
    postListingUpdate((selectedListing as IProductListingPresetItem).id, newGroups);
  };

  const handleListingDelete = async (listingId: number) => {
    try {
      showConfirmationModal({
        title: "Are you sure?",
        message: "Do you really want to delete?",
        onConfirm: async () => {
          await onListingDelete(listingId);
          toast.success("Listing successfully deleted");
        },
        onCancel: () => {}
      });
    } catch (e) {
      toast.error(getErrorMessage(e));
    }
  };

  return (
    <div className="listing-group">
      {loader && <Loader />}
      <p className="wizard-step__description">
        To proceed, please choose product models from the list provided, or create your own list of models. Then, fill
        in the required fields for your listing
      </p>
      <div className="listing-group__list">
        {groups.map((group, idx) => (
          // <div className="col-2" key={idx}>
          <ListingGroupItem
            hideSelectCheckbox={hideSelectCheckbox}
            key={idx}
            item={group}
            isSelected={!!selectedListings.find((e) => e.id === group.id)}
            onSelect={onSelect}
            onSelectModelsClick={() => {
              setSelectedListing(group);
              setShowUpdateModal(true);
            }}
            onDeleteClick={(item) => {
              handleListingDelete(item.id).then(() => null);
            }}
          />
          // </div>
        ))}
        {onCreateNewListing && (
          <div className="listing-group__item listing-group__item--button">
            <button className="listing-group__item__button" type="button" onClick={handleAddNewListingClick}>
              <span>+</span>
              <span>Create custom models</span>
            </button>
          </div>
        )}
      </div>

      <UpdateListingGroupsModal
        allGroups={allGroups}
        show={showUpdateModal}
        listingItem={selectedListing}
        toggle={() => {
          setShowUpdateModal(!showUpdateModal);
        }}
        onSubmit={handlePresetUpdate}
      />
    </div>
  );
};
