import { createAsyncThunk } from "@reduxjs/toolkit";

import { OrderWizardState } from "../../../common/interfaces/order-wizard.interface";
import { OrdersApiService } from "../../../services/api";

export const ORDER_ACTION_UPDATE_COUNTRY_LIST = createAsyncThunk(
  "order/updateCountryList",
  async () => {
    return OrdersApiService.getCountryList();
  },
  {
    condition: (_, { getState }) => {
      const state: any = getState();
      const orderState = state.orderWizard as OrderWizardState;

      if (orderState.countryList.loader || orderState.countryList.data.length) {
        return false;
      }

      return true;
    }
  }
);
