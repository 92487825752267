import React, { useEffect, useMemo, useState } from "react";

import _ from "lodash";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

import {
  IProductSelectedListingItem,
  PriceIncreaseType,
  PricingTableEntityItem,
  ProductListingPriceMap
} from "../../common/interfaces/product-wizard.interface";
import { IProductListingItem } from "../../common/interfaces/products.interface";
import { getErrorMessage } from "../../common/utils/formatter.util";
import { getObjectDeltaWithObjects } from "../../common/utils/object.util";
import { applyBulkOptionToProductPriceMap } from "../../common/utils/product-price-map.util";
import Breadcrumb from "../../components/common/Breadcrumb";
import { Loader } from "../../components/common/Loader";
import { ProductDetailListings } from "../../components/productDetail/ProductDetailListings";
import { ProductDetailSummary } from "../../components/productDetail/ProductDetailSummary";
import { replaceKeywords } from "../../components/wizards/product/utils/submit-body-builder.util";
import { useServiceContainer } from "../../hooks/useServiceContainer";

export const ProductDetailPage = () => {
  const { id } = useParams();

  const { productsApiService } = useServiceContainer();

  const [loader, setLoader] = useState(false);
  const [listingItem, setListingItem] = useState<IProductListingItem | null>(null);
  const [originListingItem, setOriginListingItem] = useState<IProductListingItem | null>(null);

  const [searchParams] = useSearchParams();

  const returnBackUrl = useMemo(() => {
    return searchParams.get("return_to") ?? "/products";
  }, [searchParams]);

  const fetchData = async () => {
    try {
      if (!id) return;
      setLoader(true);
      const data = await productsApiService.getProductListingById(+id);

      setListingItem({ ...data });
      setOriginListingItem(_.cloneDeep(data));
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setLoader(false);
    }
  };

  const handleFieldUpdate = (
    listingId: number,
    field: keyof IProductSelectedListingItem["fields"] | any,
    value: any
  ) => {
    // update listingItem fields by id
    setListingItem((prevState) => {
      const updatedListings = prevState!.listings.map((listing) => {
        if (listing.id === listingId) {
          return {
            ...listing,
            fields: {
              ...listing.fields,
              [field]: value
            }
          };
        }
        return listing;
      });

      return {
        ...prevState,
        listings: updatedListings
      } as IProductListingItem;
    });
  };

  const handlePriceMapUpdate = (listingId: number, item: PricingTableEntityItem, value: string | null) => {
    setListingItem((prevState) => {
      const updatedListings = prevState!.listings.map((listing) => {
        if (listing.id === listingId) {
          return {
            ...listing,
            price_map: Object.fromEntries(
              Object.entries(listing.price_map).map(([key, val]) => {
                if (key === item.asset.id.toString()) {
                  return [key, value || ""];
                }
                return [key, val];
              })
            )
          };
        }
        return listing;
      });

      return {
        ...prevState,
        listings: updatedListings
      } as IProductListingItem;
    });
  };

  const handlePriceMapBulkSet = (listingId: number, type: PriceIncreaseType, value: number) => {
    const listingPriceMap = listingItem?.listings.find((e) => e.id === listingId)?.price_map;

    const priceMap: ProductListingPriceMap[] = [
      {
        listingId,
        map: listingPriceMap ?? {}
      }
    ];

    const newPriceMap = applyBulkOptionToProductPriceMap(priceMap, type, value);

    setListingItem((prevState) => {
      const updatedListings = prevState!.listings.map((listing) => {
        if (listing.id === listingId) {
          return {
            ...listing,
            price_map: newPriceMap[0].map
          };
        }
        return listing;
      });

      return {
        ...prevState,
        listings: updatedListings
      } as IProductListingItem;
    });
  };

  const handlePriceMapReset = (listingId: number) => {
    const originalPriceMap = originListingItem?.listings.find((e) => e.id === listingId)?.price_map;
    setListingItem((prevState) => {
      const updatedListings = prevState!.listings.map((listing) => {
        if (listing.id === listingId) {
          return {
            ...listing,
            price_map: originalPriceMap
          };
        }
        return listing;
      });

      return {
        ...prevState,
        listings: updatedListings
      } as IProductListingItem;
    });
  };

  const handleListingFieldsSubmit = async (listingId: number) => {
    if (!listingItem) return;

    const target = listingItem.listings.find((e) => e.id === listingId);
    const originalTarget = originListingItem?.listings.find((e) => e.id === listingId);
    if (!target || !originalTarget) return;

    const newFields: any = target.fields;
    const oldFields = originalTarget.fields;

    if (newFields.section) {
      newFields.section = +newFields.section;
    }

    if (newFields.shipping_profile_id) {
      newFields.shipping_profile_id = +newFields.shipping_profile_id;
    }

    if (newFields.title) {
      newFields.title = replaceKeywords(newFields.title as string, newFields.keywords ?? {});
    }

    if (newFields.description) {
      newFields.description = replaceKeywords(newFields.description as string, newFields.keywords ?? {});
    }

    if (newFields.tags) {
      newFields.tags = newFields.tags.map((tag: string) => replaceKeywords(tag, newFields.keywords ?? {}));
    }

    if (newFields.materials) {
      newFields.materials = newFields.materials.map((material: string) =>
        replaceKeywords(material, newFields.keywords ?? {})
      );
    }

    const delta = getObjectDeltaWithObjects(oldFields, newFields);

    setLoader(true);

    delete delta.keywords;
    delete delta.shipping_profile;

    try {
      await productsApiService.updateProductListing(listingItem.id, listingId, {
        fields: delta
      });

      setListingItem((prevState) => {
        const updatedListings = prevState!.listings.map((listing) => {
          if (listing.id === listingId) {
            return {
              ...listing,
              fields: {
                ...listing.fields,
                ...(delta.tags ? { tags: delta.tags } : {}),
                ...(delta.materials ? { materials: delta.materials } : {})
              }
            };
          }
          return listing;
        });

        return {
          ...prevState,
          listings: updatedListings
        } as IProductListingItem;
      });

      // await fetchData();

      setLoader(false);

      toast.success("Listing successfully updated!");
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setLoader(false);
    }
  };

  const handleListingPriceMapSubmit = async (listingId: number) => {
    if (!listingItem) return;
    const target = listingItem?.listings.find((e) => e.id === listingId);
    const originalTarget = originListingItem?.listings.find((e) => e.id === listingId);
    if (!target || !originalTarget) return;

    const newPriceMap: any = target.price_map;
    const oldPriceMap = originalTarget.price_map;

    const delta = getObjectDeltaWithObjects(oldPriceMap, newPriceMap);

    setLoader(true);

    try {
      await productsApiService.updateProductListing(listingItem.id, listingId, {
        price_map: Object.fromEntries(
          Object.entries(delta).map(([key, val]) => {
            return [key, Number(val)];
          })
        )
      });

      await fetchData();

      setLoader(false);

      toast.success("Listing price map successfully updated!");
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setLoader(false);
    }
  };

  const handleListingModelsSubmit = async (listingId: number, items?: number[]) => {
    try {
      if (!items || !listingItem) return;

      setLoader(true);

      await productsApiService.updateProductListing(listingItem.id, listingId, {
        items
      });

      await fetchData();

      toast.success("Listing items updated successfully, if new items are added, please update the price map as well.");
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchData().then(() => null);
  }, []);

  return (
    <>
      {loader && <Loader />}
      {listingItem && (
        <>
          <Breadcrumb
            title={`Product listing #${listingItem.id}`}
            backButton={
              <Link to={returnBackUrl} className="btn btn-primary btn-sm me-3 mb-1">
                <i className="bx bx-arrow-back" /> Back
              </Link>
            }
            items={[]}
          />
          <div className="product-detail">
            <div className="row d-flex justify-content-center">
              <h3>Details</h3>
              <div className="col-md-12">
                <div className="card px-3">
                  <div className="row">
                    <div className="col-md-12">
                      <ProductDetailSummary listing={listingItem} />
                    </div>
                  </div>
                </div>
                <h3 className="mt-3">Listings</h3>
                <div className="col-md-12">
                  <div className="card px-3 mt-2">
                    <div className="row">
                      <div className="col-md-12">
                        <ProductDetailListings
                          onListingFieldsSubmit={handleListingFieldsSubmit}
                          onListingPriceMapSubmit={handleListingPriceMapSubmit}
                          listing={listingItem}
                          onListingFieldUpdate={handleFieldUpdate}
                          onListingPriceMapBulkSet={handlePriceMapBulkSet}
                          onListingPriceMapReset={handlePriceMapReset}
                          onListingPriceMapValueUpdate={handlePriceMapUpdate}
                          onListingModelsSubmit={handleListingModelsSubmit}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
