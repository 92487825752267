import { useState } from "react";

import { TableChangeState } from "react-bootstrap-table-next";
import { toast } from "react-toastify";

import { IPaginationMeta } from "../../common/interfaces/api.interface";
import { IDeliveryServiceItem } from "../../common/interfaces/backoffice.interface";
import { showConfirmationModal } from "../../common/utils/confirm.util";
import { getErrorMessage } from "../../common/utils/formatter.util";
import useDidMountEffect from "../useDidMountEffect";
import { useServiceContainer } from "../useServiceContainer";

export const useDeliveryServiceItemTable = (deliveryServiceId: string) => {
  const { backofficeService } = useServiceContainer();
  const [items, setItems] = useState<IDeliveryServiceItem[]>([]);
  const [loader, setLoader] = useState(false);
  const [paginationMeta, setPaginationMeta] = useState<null | IPaginationMeta>(null);
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState<any>({});
  const [sortSettings, setSortSettings] = useState<any>({});

  const fetchList = async (_page: number, _filters: Record<string, string>, _sort: Record<string, string>) => {
    if (!deliveryServiceId) return;
    setLoader(true);
    try {
      const res = await backofficeService.getDeliveryServiceItems(deliveryServiceId, _page, _filters, _sort);

      setItems(res.items);
      setPaginationMeta(res.meta);
    } catch (e) {
      console.error(e);
    } finally {
      setLoader(false);
    }
  };

  const onItemUpdate = async (updatedItem: IDeliveryServiceItem) => {
    setItems((prev) => prev.map((item) => (item.id === updatedItem.id ? updatedItem : item)));
  };

  const onItemCreate = async () => {
    await fetchList(page, filters, sortSettings);
  };

  const handleItemDelete = async (itemId: number) => {
    showConfirmationModal({
      title: "Are you sure?",
      message: "Do you really want to delete this item?",
      onConfirm: async () => {
        await processItemDelete(itemId);
      },
      onCancel: () => {}
    });
  };

  const processItemDelete = async (itemId: number) => {
    try {
      setLoader(true);
      await backofficeService.deleteDeliveryServiceItemById(+deliveryServiceId!, itemId);
      await fetchList(page, filters, sortSettings);
      toast.success("Delivery service item deleted successfully");
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setLoader(false);
    }
  };

  const onTableChange = (event: string, data: TableChangeState<any>) => {
    switch (event) {
      case "pagination":
        setPage(data.page);
        break;
      case "filter":
        setFilters(
          Object.fromEntries(Object.entries(data.filters).map(([column, value]) => [column, value.filterVal]))
        );
        break;
      case "sort":
        setSortSettings({
          [data.sortField]: data.sortOrder
        });
        break;
      default:
        break;
    }
  };

  useDidMountEffect(() => {
    fetchList(page, filters, sortSettings).then(() => null);
  }, [page, filters, sortSettings]);

  return {
    page,
    paginationMeta,
    loader,
    items,
    onTableChange,
    onItemUpdate,
    onItemCreate,
    handleItemDelete
  };
};
