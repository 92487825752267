import React from "react";

export const ExitArrowIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" id="SVGDoc" version="1.1" viewBox="19 19 16 12">
      <defs />
      <g>
        <g>
          <g>
            <g>
              <path
                d="M24,24.99998h10"
                fillOpacity="0"
                fill="#ffffff"
                strokeDashoffset="0"
                strokeLinejoin="miter"
                strokeLinecap="round"
                strokeOpacity="1"
                stroke="#bdd6e4"
                strokeMiterlimit="20"
                strokeWidth="2"
              />
            </g>
            <g>
              <g transform="rotate(45.00000000000001 25.87 23.129999999999995)">
                <path
                  d="M25.87254,20.47926v5.29636"
                  fillOpacity="0"
                  fill="#ffffff"
                  strokeDashoffset="0"
                  strokeLinejoin="miter"
                  strokeLinecap="round"
                  strokeOpacity="1"
                  stroke="#bdd6e4"
                  strokeMiterlimit="20"
                  strokeWidth="2"
                />
              </g>
              <g transform="rotate(135 25.87 26.870000000000005)">
                <path
                  d="M25.87254,24.22436v5.29636"
                  fillOpacity="0"
                  fill="#ffffff"
                  strokeDashoffset="0"
                  strokeLinejoin="miter"
                  strokeLinecap="round"
                  strokeOpacity="1"
                  stroke="#bdd6e4"
                  strokeMiterlimit="20"
                  strokeWidth="2"
                />
              </g>
            </g>
          </g>
          <g transform="rotate(90 20 25)">
            <title>Line 4</title>
            <path
              d="M15,24.99999h10"
              fillOpacity="0"
              fill="#ffffff"
              strokeDashoffset="0"
              strokeLinejoin="miter"
              strokeLinecap="round"
              strokeOpacity="1"
              stroke="#bdd6e4"
              strokeMiterlimit="20"
              strokeWidth="2"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
