import React, { useMemo, useRef, useState } from "react";

import classNames from "classnames";
import { useDrag, useDrop } from "react-dnd";
import { Col, Row } from "reactstrap";

import { IMockupFile } from "../../../../common/interfaces/mockup.inteface";
import { IDesignItem } from "../../../../common/interfaces/products.interface";
import { getImageUrl } from "../../../../common/utils/formatter.util";
import { useAppDispatch, useAppSelector } from "../../../../store/store";
import {
  PRODUCT_SWAP_MOCKUP_ENTITY_FILE,
  PRODUCT_UPDATE_DESIGN_MOCKUP_CONNECTOR_ITEM
} from "../../../../store/wizards/productSlice";
import { Loader } from "../../Loader";

interface Props {}

interface MockupOrderSetupBodyProps {
  // show: boolean;
  // toggle: () => void;
}

interface MockupFileEntityProps {
  file: IMockupFile | null;
  orderIndex: number;
  onDrop?: (item: { file: IMockupFile }, target: any) => void;
}

export const MockupFileEntity: React.FC<MockupFileEntityProps> = ({ onDrop, file, orderIndex }) => {
  const ref = useRef<any>(null);

  const isPrimary = useMemo(() => {
    return orderIndex === 1;
  }, [orderIndex]);

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: "MOCKUP_FILE",
      item: { file },
      // canDrag: !isPrimary,
      end: (item, monitor) => {
        const dropResult = monitor.getDropResult<any>();
        if (item && item.file && dropResult && onDrop) {
          onDrop(item as { file: IMockupFile }, dropResult);
        }
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
        handlerId: monitor.getHandlerId(),
        isOver: monitor
      })
    }),
    [file]
  );

  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: "MOCKUP_FILE",
    drop: () => ({ file }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  }));

  drag(drop(ref));

  const isDrop = canDrop && isOver;
  let opacity = isDragging ? 0.1 : 1;
  if (isDrop) {
    opacity = 0.6;
  }

  return (
    <div
      className={classNames("mockup-order-setup__entity", {
        "mockup-order-setup__entity--primary": isPrimary
      })}
      ref={ref}
      style={{ opacity }}
    >
      {isPrimary && (
        <div className="mockup-order-setup__entity__order">
          <span>Primary</span>
        </div>
      )}
      <div className="mockup-order-setup__entity__image">
        <img src={getImageUrl(file?.url)} alt="mockup" />
      </div>
      {!isDragging && !isPrimary && (
        <div className="mockup-order-setup__entity__hover">
          <span>Drag to reorder</span>
        </div>
      )}
      {isDrop && (
        <div className="mockup-order-setup__entity__hover active">
          <span>Drop here</span>
        </div>
      )}
      <div className="mockup-order-setup__entity__title">
        {file?.url ? file?.url.split("/").pop() : (file?.name as string)}
      </div>
    </div>
  );
};

export const MockupOrderSetupBody: React.FC<MockupOrderSetupBodyProps> = ({}) => {
  const [loader, setLoader] = useState(false);
  const selectedMockupEntity = useAppSelector((state) => state.productWizard.selectedMockupEntity);
  const dispatch = useAppDispatch();

  const sortedFiles = useMemo(() => {
    if (!selectedMockupEntity) return [];
    return [...(selectedMockupEntity?.files ?? [])].sort((a, b) => a.order! - b.order!);
  }, [selectedMockupEntity]);

  const primaryFile = useMemo(() => {
    return sortedFiles[0] ?? null;
  }, [sortedFiles]);

  const restFiles = useMemo(() => {
    return sortedFiles?.slice(1) ?? [];
  }, [sortedFiles]);

  const handleSubmit = async (method: string) => {};

  const handleFileDrop = (item: { file: IMockupFile }, target: { file?: IMockupFile; design?: IDesignItem }) => {
    if (target?.design) {
      dispatch(
        PRODUCT_UPDATE_DESIGN_MOCKUP_CONNECTOR_ITEM({
          designId: target.design.id,
          mockupId: +item.file.id
        })
      );
    }

    if (target?.file) {
      dispatch(
        PRODUCT_SWAP_MOCKUP_ENTITY_FILE({
          fileId: +item.file.id,
          targetFileId: +target.file.id
        })
      );
    }
  };

  return (
    // <Modal isOpen={show} toggle={toggle} centered size="lg">
    //   <div className="modal-header">
    //     <h5 className="modal-title mt-0">Mockup files order</h5>
    //     <button
    //       type="button"
    //       onClick={() => {
    //         toggle();
    //       }}
    //       className="close"
    //       data-dismiss="modal"
    //       aria-label="Close"
    //     >
    //       <span aria-hidden="true">&times;</span>
    //     </button>
    //   </div>
    //   <div className="modal-body pb-5">
    //     {loader && <Loader />}
    <>
      <h6>Mockup files</h6>
      <Row className="mockup-order-setup">
        <Col xs={5}>
          <MockupFileEntity file={primaryFile} key={primaryFile.id} orderIndex={1} onDrop={handleFileDrop} />
        </Col>
        <Col xs={7}>
          <Row>
            {restFiles.map((file, idx) => (
              <Col xs={4} key={file.id}>
                <MockupFileEntity file={file} orderIndex={file.order!} onDrop={handleFileDrop} />
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </>
    //   </div>
    // </Modal>
  );
};

export const MockupOrderSetup: React.FC<Props> = () => {
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      {/* <div className="d-inline-flex"> */}
      {/*  <Button */}
      {/*    color="primary" */}
      {/*    onClick={() => { */}
      {/*      setShowModal(true); */}
      {/*    }} */}
      {/*  > */}
      {/*    Setup order */}
      {/*  </Button> */}
      {/* </div> */}

      <MockupOrderSetupBody
      // show={showModal}
      // toggle={() => {
      //   setShowModal(!showModal);
      // }}
      />
    </>
  );
};
