import React, { useEffect, useState } from "react";

import BootstrapTable, { TableChangeState } from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import paginationFactory, { PaginationProvider, PaginationListStandalone } from "react-bootstrap-table2-paginator";
// @ts-ignore
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Row } from "reactstrap";

import { IPaginationMeta } from "../../../common/interfaces/api.interface";
import { IIncomingPayment } from "../../../common/interfaces/invoices.interface";
import { Loader } from "../../../components/common/Loader";
import { useIncomingPaymentTableExpandControl } from "../../../hooks/incomingPaymentsTable/useIncomingPaymentTableExpandControl";
import { useServiceContainer } from "../../../hooks/useServiceContainer";

export const IncomingPaymentsPage: React.FC = () => {
  const [items, setItems] = useState<IIncomingPayment[]>([]);
  const [page, setPage] = useState(1);
  const [paginationMeta, setPaginationMeta] = useState<null | IPaginationMeta>(null);
  const [loader, setLoader] = useState(false);
  const { backofficeService } = useServiceContainer();
  const [filters, setFilters] = useState<any>({});
  const [sortSettings, setSortSettings] = useState<any>({});

  const { expandRowData } = useIncomingPaymentTableExpandControl({
    items
  });

  const columns = [
    {
      dataField: "id",
      text: "ID",
      // filter: textFilter({ caseSensitive: true, placeholder: " ", className: "form-control-sm mt-1" }),
      // sort: true,
      style: {
        width: "90px"
      }
    },
    {
      dataField: "payment_date",
      text: "Payment date",
      // filter: textFilter({ caseSensitive: true, placeholder: " ", className: "form-control-sm mt-1" }),
      // sort: true,
      style: {
        minWidth: "90px"
      }
    },
    {
      dataField: "clientName",
      isDummyField: true,
      text: "Client name",
      // filter: textFilter({ caseSensitive: true, placeholder: " ", className: "form-control-sm mt-1" }),
      // sort: true,
      style: {
        minWidth: "90px"
      },
      formatter: (cellContent: any, row: IIncomingPayment) => {
        if (row.customer.company_title) {
          return row.customer.company_title;
        }

        return `${row.customer.name} ${row.customer.surname}`;
      }
    },
    {
      dataField: "clientRegNr",
      isDummyField: true,
      text: "Client reg nr",
      // filter: textFilter({ caseSensitive: true, placeholder: " ", className: "form-control-sm mt-1" }),
      // sort: true,
      style: {
        minWidth: "90px"
      },
      formatter: (cellContent: any, row: IIncomingPayment) => {
        return `${row.customer.reg_nr}`;
      }
    },
    {
      dataField: "clientVatNr",
      isDummyField: true,
      text: "Client vat nr",
      // filter: textFilter({ caseSensitive: true, placeholder: " ", className: "form-control-sm mt-1" }),
      // sort: true,
      style: {
        minWidth: "90px"
      },
      formatter: (cellContent: any, row: IIncomingPayment) => {
        return `${row.customer.vat_nr}`;
      }
    },
    {
      dataField: "payment_amount",
      isDummyField: true,
      text: "Payment amount",
      // filter: textFilter({ caseSensitive: true, placeholder: " ", className: "form-control-sm mt-1" }),
      // sort: true,
      style: {
        minWidth: "90px"
      },
      formatter: (cellContent: any, row: IIncomingPayment) => {
        return `${row.payment_amount}`;
      }
    },
    {
      dataField: "balance",
      isDummyField: true,
      text: "Actual balance",
      // filter: textFilter({ caseSensitive: true, placeholder: " ", className: "form-control-sm mt-1" }),
      // sort: true,
      style: {
        minWidth: "90px"
      },
      formatter: (cellContent: any, row: IIncomingPayment) => {
        return `${row.customer_balance.balance}`;
      }
    },
    {
      dataField: "actions",
      isDummyField: true,
      text: "Actions",
      style: {
        minWidth: "90px"
      },
      formatter: (cellContent: any, row: IIncomingPayment) => {
        return (
          <>
            <Link
              className="btn btn-primary btn-sm ms-2"
              color="primary"
              to={`/backoffice/invoices/incoming-payments/${row.id}/edit`}
            >
              Edit
            </Link>
          </>
        );
      }
    }
  ];

  const fetchList = async (selectedPage: number) => {
    setLoader(true);
    try {
      const res = await backofficeService.getIncomingPayments(selectedPage);

      setItems(res.items);
      setPaginationMeta(res.meta);
    } catch (e) {
      console.error(e);
    } finally {
      setLoader(false);
    }
  };

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc"
    }
  ] as any;

  const pageOptions = {
    sizePerPage: 20,
    page,
    totalSize: paginationMeta?.total || 0,
    custom: true
  };

  const onTableChange = (event: string, data: TableChangeState<any>) => {
    switch (event) {
      case "pagination":
        setPage(data.page);
        break;
      case "filter":
        setFilters(
          Object.fromEntries(Object.entries(data.filters).map(([column, value]) => [column, value.filterVal]))
        );
        break;
      case "sort":
        setSortSettings({
          [data.sortField]: data.sortOrder
        });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    fetchList(page).then(() => null);
  }, [page]);

  return (
    <div className="data-table">
      <Card className="border">
        <CardBody>
          <Link className="btn btn-primary btn-sm ms-2" color="primary" to="/backoffice/invoices/register-payment">
            Register payment
          </Link>
        </CardBody>
      </Card>

      <Card className="border">
        {loader && <Loader />}
        <CardBody>
          <h3>Incoming payments</h3>
          <PaginationProvider pagination={paginationFactory(pageOptions)}>
            {({ paginationProps, paginationTableProps }: any) => (
              <ToolkitProvider keyField="id" columns={columns} data={items} search>
                {(toolkitProps: any) => {
                  return (
                    <>
                      <Row>
                        <Col xl="12">
                          <div className="table-responsive">
                            <BootstrapTable
                              bootstrap4
                              remote
                              onTableChange={onTableChange}
                              bordered={false}
                              // selectRow={selectRowData}
                              striped={false}
                              defaultSorted={defaultSorted}
                              filter={filterFactory()}
                              classes="table align-middle table-nowrap table-sm"
                              headerWrapperClasses="thead-light"
                              {...toolkitProps.baseProps}
                              {...paginationTableProps}
                              expandRow={expandRowData}
                              data={items}
                              keyField="id"
                            />
                          </div>
                        </Col>
                      </Row>

                      <Row className="align-items-md-center mt-30">
                        <Col className="inner-custom-pagination d-flex">
                          <div className="text-md-right ms-auto">
                            <PaginationListStandalone {...paginationProps} />
                          </div>
                        </Col>
                      </Row>
                    </>
                  );
                }}
              </ToolkitProvider>
            )}
          </PaginationProvider>
        </CardBody>
      </Card>
    </div>
  );
};
