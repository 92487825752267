import React, { useEffect, useState } from "react";

import classNames from "classnames";

import { IDeliveryOption } from "../../../common/interfaces/orders.interface";
import { getExternalImageUrl } from "../../../common/utils/formatter.util";

interface Props {
  items: IDeliveryOption[];
  onItemSelect: (item: IDeliveryOption) => void;
}

export const DeliverySelect: React.FC<Props> = ({ items, onItemSelect }) => {
  const [selectedItemId, setSelectedItemId] = useState<null | number>(null);

  useEffect(() => {
    if (selectedItemId) {
      const selectedItem = items.find((item) => item.id === selectedItemId);
      if (selectedItem) {
        onItemSelect(selectedItem);
      }
    }
  }, [selectedItemId]);

  return (
    <div className="radio-select">
      {items.map((item, idx) => (
        <div
          role="button"
          onClick={() => setSelectedItemId(item.id)}
          className={classNames("radio-select__item", {
            "radio-select__item--selected": selectedItemId === item.id
          })}
          key={idx}
        >
          <div className="radio-select__item__state" />
          <div className="radio-select__item__price">{item.price} EUR</div>
          {item.image && (
            <div className="radio-select__item__image">
              <img src={getExternalImageUrl(item.image)} alt={item.name} />
            </div>
          )}
          <div className="radio-select__item__title">{item.name}</div>
        </div>
      ))}
    </div>
  );
};
