import React from "react";

import { Button, Modal } from "reactstrap";

interface Props {
  show: boolean;
  selectedItems: number;
  toggle: () => void;
}

export const ItemZeroModal: React.FC<Props> = ({ show, selectedItems, toggle }) => {


  return (
    <Modal isOpen={show} toggle={toggle} centered size="sm" className="notification-modal">
      <div className="modal-body">
        <div className="modal-body__icon">
          <i className="bx bx-error" />
        </div>
        <h4>Please select at least one Item</h4>
        <p>
            Please select at least one Item to proceed!
        </p>
      </div>
      <div className="modal-footer">
        <Button
          onClick={() => {
            toggle();
          }}
        >
          Close
        </Button>
      </div>
    </Modal>
  );
};
