import React, { useState } from "react";

import { Modal } from "reactstrap";

import CanvasEditor from "../../../canvasImageEditor/CanvasEditor";
import { IDesignItem } from "../../../common/interfaces/products.interface";
import { Loader } from "../../common/Loader";

interface Props {
  copyItemTarget?: IDesignItem | null;
  show: boolean;
  assetSrc?: string;
  printfileSize: [number, number]; // [width, height]
  designName: string;
  onSubmit: (data: File | Blob, title: string) => void;
  toggle: () => void;
}

export const CreateDesignModal: React.FC<Props> = ({
  show,
  printfileSize,
  copyItemTarget,
  toggle,
  onSubmit,
  assetSrc,
  designName
}) => {
  const [loader, setLoader] = useState(false);

  return (
    <Modal isOpen={show} toggle={toggle} centered size="xl" className="modal-fullscreen">
      {loader && <Loader />}
      <div className="modal-header">
        <h5 className="modal-title mt-0">Create New Design</h5>
        <button
          type="button"
          onClick={() => {
            toggle();
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body p-0">
        <CanvasEditor
          onCancel={() => {
            toggle();
          }}
          copyItemTarget={copyItemTarget}
          printfileSize={printfileSize}
          initialDesignName={designName}
          assetSrc={assetSrc ?? ""}
          onSubmit={(blob, title) => {
            onSubmit(blob, title);
            toggle();
          }}
        />
      </div>
    </Modal>
  );
};
