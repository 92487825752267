import React, { useEffect, useMemo, useState } from "react";

import { toast } from "react-toastify";
import { Button } from "reactstrap";

import { IProductSelectedListingItem } from "../../../../common/interfaces/product-wizard.interface";
import {
  IProductListingPresetItem,
  IProductListingTemplate,
  ProductListingTemplateFields
} from "../../../../common/interfaces/products.interface";
import { useServiceContainer } from "../../../../hooks/useServiceContainer";
import { useAppDispatch, useAppSelector } from "../../../../store/store";
import {
  PRODUCT_ADD_LISTING_TEMPLATE,
  PRODUCT_DELETE_LISTING_TEMPLATE,
  PRODUCT_SET_LISTING_TEMPLATES,
  PRODUCT_UPDATE_LISTING_TEMPLATE
} from "../../../../store/wizards/productSlice";
import { CreateListingTemplateModal } from "../../../modals/listings/CreateListingTemplateModal";
import { SelectField, SelectOption } from "../../SelectField";

interface Props {
  onTemplateSelected: (data?: IProductListingTemplate) => void;
  currentFields: ProductListingTemplateFields;
  selectedListing: IProductSelectedListingItem | null;
  presetItem: IProductListingPresetItem;
  triggerTemplateFieldsValidation: () => void;
}

export const ListingFormTemplateSelector: React.FC<Props> = ({
  selectedListing,
  onTemplateSelected,
  currentFields,
  triggerTemplateFieldsValidation,
  presetItem
}) => {
  const [selectedTemplate, setSelectedTemplate] = useState<SelectOption | null>(null);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const selectedProduct = useAppSelector((state) => {
    return state.productWizard.selectedProductType;
  });

  const { productsApiService } = useServiceContainer();

  const userTemplates = useAppSelector((state) => state.productWizard.data.listingGroupTemplates);

  const templates = useMemo(() => {
    return [...(presetItem.template ? [presetItem.template] : []), ...userTemplates];
  }, [userTemplates, presetItem]);

  const selectedTemplateEntity = useMemo(() => {
    return templates.find((e) => e.id.toString() === selectedTemplate?.value);
  }, [selectedTemplate, templates]);

  const options = useMemo(() => {
    return [
      {
        label: (
          <>
            <i className="bx bx-plus" /> Create new
          </>
        ),
        value: "create-new"
      },
      ...templates.map((template) => ({
        label: template.name,
        value: template.id.toString()
      }))
    ];
  }, [templates]);
  const dispatch = useAppDispatch();

  const fetchList = async () => {
    if (!selectedListing || !selectedProduct) return;
    try {
      const res = await productsApiService.getUserListingTemplates(selectedListing.id, selectedProduct.id);

      dispatch(PRODUCT_SET_LISTING_TEMPLATES(res));
    } catch (e) {
      toast.error(e.message);
    }
  };

  const handleDelete = async () => {
    if (!selectedTemplate) return;

    await productsApiService.deleteUserListingTemplate(+selectedTemplate.value);

    dispatch(
      PRODUCT_DELETE_LISTING_TEMPLATE({
        id: +selectedTemplate.value
      })
    );

    toast.success(`Template "${selectedTemplate.label}" deleted`);

    setSelectedTemplate(null);
  };

  const handleUpdate = async () => {
    if (!selectedTemplate) return;

    await productsApiService.updateUserListingTemplate(+selectedTemplate.value, {
      fields: currentFields
    });

    dispatch(
      PRODUCT_UPDATE_LISTING_TEMPLATE({
        id: +selectedTemplate.value,
        fields: currentFields
      })
    );

    toast.success(`Template "${selectedTemplate.label}" updated`);
  };

  const handleCreate = async (name: string) => {
    if (!selectedListing || !selectedProduct) return;
    const res = await productsApiService.createUserListingTemplate({
      name,
      fields: currentFields,
      // preset_id: selectedListing.id,
      product_id: selectedProduct.id
    });
    dispatch(PRODUCT_ADD_LISTING_TEMPLATE(res));

    setSelectedTemplate({
      label: name,
      value: res.id.toString()
    });

    toast.success(`Template "${name}" created`);
  };

  const handleCreateClick = () => {
    if (
      currentFields.title &&
      currentFields.description &&
      currentFields.tags?.length &&
      currentFields.materials?.length
    ) {
      setShowCreateModal(true);
    } else {
      triggerTemplateFieldsValidation();
      toast.error("Please fill required fields");
    }
  };

  useEffect(() => {
    const template = templates.find((e) => e.id.toString() === selectedTemplate?.value);
    onTemplateSelected(template);
  }, [selectedTemplate]);

  useEffect(() => {
    if (selectedListing && selectedProduct) {
      fetchList().then(() => null);
    }
  }, []);

  useEffect(() => {
    if (presetItem.template) {
      setSelectedTemplate({
        label: presetItem.template.name,
        value: presetItem.template.id.toString()
      });
    }
  }, []);

  return (
    <div className="listing-form__template-selector">
      <SelectField
        options={options}
        label="Template"
        value={selectedTemplate}
        placeholder="Select template"
        onChange={(data) => {
          if (data.value === "create-new") {
            // setSelectedTemplate(null);
            handleCreateClick();
            // setShowCreateModal(true);
          } else {
            setSelectedTemplate(data);
          }
        }}
      />

      <div className="listing-form__template-selector__actions">
        {selectedTemplateEntity && !selectedTemplateEntity.is_default ? (
          <>
            <Button size="sm" color="danger" onClick={handleDelete}>
              Delete
            </Button>
            <Button size="sm" color="primary" onClick={handleUpdate}>
              Update
            </Button>
          </>
        ) : (
          ""
        )}
      </div>

      <CreateListingTemplateModal
        show={showCreateModal}
        onSubmit={handleCreate}
        toggle={() => setShowCreateModal(!showCreateModal)}
      />
    </div>
  );
};
