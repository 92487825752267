import React, { useEffect, useMemo, useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import * as Yup from "yup";

import { COURSES_CATEGORIES } from "../../../common/constants/courses.constant";
import { ICoursesItem } from "../../../common/interfaces/courses.interface";
import { getErrorMessage } from "../../../common/utils/formatter.util";
import { getObjectDelta } from "../../../common/utils/object.util";
import { InputField } from "../../../components/common/InputField";
import { Loader } from "../../../components/common/Loader";
import { SelectField } from "../../../components/common/SelectField";
import { ImagePreviewWithReUpload } from "../../../components/common/uploads/ImagePreviewWithReUpload";
import { FileUploadStep } from "../../../components/modals/create-design/FileUploadStep";
import { useServiceContainer } from "../../../hooks/useServiceContainer";

const validationSchema = Yup.object().shape({
  title: Yup.string().required(),
  description: Yup.string().required(),
  url: Yup.string().required(),
  image: Yup.mixed().nullable(),
  category: Yup.string().required()
});

export const CoursesEditPage = () => {
  const {
    register,
    formState: { errors },
    setValue,
    setError,
    watch,
    handleSubmit,
    reset
  } = useForm({
    defaultValues: {} as any,
    resolver: yupResolver(validationSchema)
  });
  const [entityData, setEntityData] = useState<null | ICoursesItem>(null);
  const [loader, setLoader] = useState(false);
  const { backofficeService } = useServiceContainer();
  const { id } = useParams();
  const navigate = useNavigate();

  const categoryValue = watch("category");

  const categoryOptions = useMemo(() => {
    return COURSES_CATEGORIES;
  }, []);

  const selectedCategoryOption = useMemo(() => {
    return categoryOptions.find((type) => type.value === categoryValue) ?? null;
  }, [categoryValue, categoryOptions]);

  const fetch = async () => {
    try {
      if (!id) return;
      setLoader(true);
      const data = await backofficeService.getCoursesItemById(+id);
      setEntityData(data);
    } catch (e) {
      toast.error(getErrorMessage(e));
      navigate("/");
    } finally {
      setLoader(false);
    }
  };

  const onSubmitHandler = async (data: any) => {
    try {
      if (!entityData || !id) return;
      setLoader(true);

      const delta = getObjectDelta(entityData, {
        title: data.title,
        image: data.image,
        description: data.description,
        url: data.url,
        category: data.category
      }) as any;

      const updatedItem = await backofficeService.updateCoursesItem(+id, delta);

      setEntityData(updatedItem);
      toast.success("News item successfully updated");
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setLoader(false);
    }
  };

  const updateFormState = async (data: ICoursesItem) => {
    reset({
      title: data.title,
      image: data.image,
      description: data.description,
      url: data.url,
      category: data.category
    });
  };

  useEffect(() => {
    if (entityData) {
      updateFormState(entityData).then(() => null);
    }
  }, [entityData]);

  useEffect(() => {
    fetch().then(() => null);
  }, [id]);

  return (
    <Row>
      {loader && <Loader />}
      <Col xs={12}>
        <Card>
          <CardBody>
            <h3>Edit course item</h3>
            {entityData && (
              <form onSubmit={handleSubmit(onSubmitHandler)}>
                <InputField {...register("title")} error={errors.title?.message} label="Title" name="title" />

                <InputField
                  {...register("description")}
                  error={errors.description?.message}
                  label="Description"
                  name="description"
                />

                <InputField {...register("url")} error={errors.url?.message} label="Link" name="url" />

                <SelectField
                  options={categoryOptions}
                  label="Category"
                  error={errors.category?.message}
                  value={selectedCategoryOption}
                  placeholder="Select product category"
                  onChange={(data) => {
                    setValue("category", data.value);
                    setError("category", {
                      type: "manual",
                      message: ""
                    });
                  }}
                />

                {entityData.image ? (
                  <ImagePreviewWithReUpload
                    title="Image (566x220)"
                    error={errors.image?.message}
                    originImage={entityData.image}
                    onUpload={(file) => {
                      setValue("image", file);
                    }}
                  />
                ) : (
                  <FileUploadStep
                    error={errors.image?.message}
                    hideSubmitButton
                    alwaysShowTitle
                    title="Image (566x220)"
                    small
                    containerSize={12}
                    onUpload={(file) => {
                      setValue("image", file[0]);
                      setError("image", {
                        type: "manual",
                        message: ""
                      });
                    }}
                  />
                )}

                <Button type="submit" color="success">
                  Save
                </Button>
              </form>
            )}
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};
