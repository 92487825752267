import React, { useEffect, useMemo, useState } from "react";

import { toast } from "react-toastify";
import { Button, Modal } from "reactstrap";

import { IPossibleDeliveryService } from "../../../common/interfaces/order-wizard.interface";
import { ICartOrder } from "../../../common/interfaces/orders.interface";
import { getErrorMessage, getImageUrl } from "../../../common/utils/formatter.util";
import { useServiceContainer } from "../../../hooks/useServiceContainer";
import { Loader } from "../../common/Loader";
import { SelectField } from "../../common/SelectField";

interface Props {
  orders: ICartOrder[];
  show: boolean;
  toggle: () => void;
  onSubmit: () => void;
}

interface CountryGroupOrdersProps {
  ordersPossibleDeliveryServicesData: Record<
    string,
    {
      country: string;
      services: IPossibleDeliveryService[];
    }
  >;
  country: string;
  orders: ICartOrder[];
  orderDeliveryServices: Record<string, number>;
  onOrderDeliveryServiceChange: (orderId: string, deliveryServiceId: number) => void;
}

const CountryGroupOrders: React.FC<CountryGroupOrdersProps> = ({
  country,
  orderDeliveryServices,
  onOrderDeliveryServiceChange,
  orders,
  ordersPossibleDeliveryServicesData
}) => {
  const [selectedBulkOption, setSelectedBulkOption] = useState<{
    label: string;
    value: string;
  } | null>(null);

  const countryOptions = useMemo(() => {
    for (const [key, value] of Object.entries(ordersPossibleDeliveryServicesData)) {
      if (value.country === country) {
        return value.services;
      }
    }

    return [];
  }, [ordersPossibleDeliveryServicesData]);

  const countrySelectOptions = useMemo(() => {
    return countryOptions.map((option) => ({
      label: option.name,
      value: option.id.toString()
    }));
  }, [countryOptions]);

  const handleBulkServiceChange = (option: { label: string; value: string }) => {
    setSelectedBulkOption(option);
  };

  useEffect(() => {
    if (!selectedBulkOption) return;
    for (const order of orders) {
      onOrderDeliveryServiceChange(order.products[0].sales_order_product_id.toString(), +selectedBulkOption.value);
    }
  }, [selectedBulkOption]);

  return (
    <div className="setup-pending-orders__country-group">
      <div className="setup-pending-orders__heading">
        <h5>{country}</h5>
        <SelectField
          options={countrySelectOptions}
          placeholder="Bulk delivery service selector"
          required
          value={selectedBulkOption}
          onChange={handleBulkServiceChange}
        />
      </div>
      <table className="setup-pending-orders__table">
        <thead>
          <tr>
            <th>Order ID</th>
            <th>Product</th>
            <th>Design</th>
            <th>Client name</th>
            <th className="setup-pending-orders__table__price">
              Product <br /> Price
            </th>
            <th className="setup-pending-orders__table__price">
              Delivery <br /> Price
            </th>
            <th className="setup-pending-orders__table__price">
              Total <br /> Price
            </th>
            <th className="setup-pending-orders__table__select">Delivery service</th>
          </tr>
        </thead>
        <tbody>
          {orders.map((order) => {
            const options =
              ordersPossibleDeliveryServicesData[order.products[0]?.sales_order_product_id]?.services ?? [];
            const selectedDeliveryService = orderDeliveryServices[order.products[0].sales_order_product_id.toString()]
              ? options.find((o) => o.id === orderDeliveryServices[order.products[0].sales_order_product_id.toString()])
              : null;

            return (
              <tr key={order.salesOrderId} className="setup-pending-orders__table__row">
                <td>{order.products[0]!.sales_order_product_id}</td>
                <td>{order.products[0]!.product.name}</td>
                <td>
                  <img src={getImageUrl(order.products[0]!.design.print_file)} alt="Design" />
                </td>
                <td>{order.deliveryData.fullName}</td>
                <td className="setup-pending-orders__table__price">€{order.amountWithVat}</td>
                <td className="setup-pending-orders__table__price">
                  {selectedDeliveryService ? `€${selectedDeliveryService.price}` : "N/A"}
                </td>
                <td className="setup-pending-orders__table__price">
                  €{(order.amountWithVat + (selectedDeliveryService?.price ?? 0)).toFixed(2)}
                </td>
                <td className="setup-pending-orders__table__select">
                  <div className="d-flex align-items-center">
                    <SelectField
                      options={countrySelectOptions}
                      placeholder="Delivery service"
                      required
                      value={
                        selectedDeliveryService
                          ? countrySelectOptions.find((o) => o.value === selectedDeliveryService.id.toString())
                          : null
                      }
                      onChange={(e) => {
                        setSelectedBulkOption(null);
                        onOrderDeliveryServiceChange(order.products[0].sales_order_product_id.toString(), +e.value);
                      }}
                    />
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export const SetupPendingOrdersModal: React.FC<Props> = ({ onSubmit, show, toggle, orders }) => {
  const [loader, setLoader] = useState(false);
  const { ordersService, backofficeService } = useServiceContainer();

  // orderId: deliveryServiceId
  const [orderDeliveryServices, setOrderDeliveryServices] = useState<Record<string, number>>({});

  const [ordersPossibleDeliveryServicesData, setOrdersPossibleDeliveryServicesData] = useState<
    Record<
      string,
      {
        country: string;
        services: IPossibleDeliveryService[];
      }
    >
  >({});

  const ordersGroupedByCountry = useMemo(() => {
    return orders.reduce((acc, order) => {
      if (!acc[order.deliveryData.country]) {
        acc[order.deliveryData.country] = [];
      }
      acc[order.deliveryData.country].push(order);
      return acc;
    }, {} as { [key: string]: ICartOrder[] });
  }, [orders]);

  const fetchPossibleDeliveryServices = async () => {
    try {
      setLoader(true);
      const allSalesOrderIds = orders.map((o) => o.products.map((p) => p.sales_order_product_id)).flat();
      const existingSalesOrderIds = Object.keys(ordersPossibleDeliveryServicesData);

      const salesOrderIds = allSalesOrderIds.filter((id) => !existingSalesOrderIds.includes(id));

      const res = await ordersService.getMultiOrderPossibleDeliveryServices(salesOrderIds);

      setOrdersPossibleDeliveryServicesData((prev) => ({
        ...prev,
        ...Object.fromEntries(
          res.map((r) => [
            r.order,
            {
              country:
                orders.find((o) => o.products.some((p) => p.sales_order_product_id === r.order))?.deliveryData
                  .country || "",
              services: r.deliveryOptions
            }
          ])
        )
      }));
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setLoader(false);
    }
  };

  const handleOrderDeliveryServiceChange = (orderId: string, deliveryServiceId: number) => {
    setOrderDeliveryServices((prev) => ({
      ...prev,
      [orderId]: deliveryServiceId
    }));
  };

  const handleSubmit = async () => {
    try {
      setLoader(true);
      if (Object.keys(orderDeliveryServices).length === 0) return;

      for (const [orderId, deliveryServiceId] of Object.entries(orderDeliveryServices)) {
        // eslint-disable-next-line no-await-in-loop
        await backofficeService.updateOrderDeliveryService(orderId, +deliveryServiceId);
      }

      // const res = await ordersService.setBulkDeliveryService(orderDeliveryServices);

      toast.success("Orders updated successfully");
      toggle();
      onSubmit();
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (show && orders.length) {
      fetchPossibleDeliveryServices().then(() => null);
    }
  }, [show, orders]);

  return (
    <Modal isOpen={show} toggle={toggle} centered size="xl">
      <div className="modal-header">
        <h5 className="modal-title mt-0">Setup orders</h5>
        <button
          type="button"
          onClick={() => {
            toggle();
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="setup-pending-orders modal-body pb-5">
        {loader && <Loader />}
        {Object.entries(ordersGroupedByCountry).map(([country, countryOrders]) => (
          <CountryGroupOrders
            ordersPossibleDeliveryServicesData={ordersPossibleDeliveryServicesData}
            orderDeliveryServices={orderDeliveryServices}
            onOrderDeliveryServiceChange={handleOrderDeliveryServiceChange}
            key={country}
            country={country}
            orders={countryOrders}
          />
        ))}

        <div className="mt-4 d-flex justify-content-center">
          <Button
            type="button"
            color="primary"
            onClick={() => {
              // todo: check if all orders have delivery service selected
              handleSubmit();
              // console.log(orderDeliveryServices);
            }}
          >
            Submit
          </Button>
        </div>
      </div>
    </Modal>
  );
};
