import React, { useEffect, useState } from "react";

import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { numberFilter, selectFilter, textFilter } from "react-bootstrap-table2-filter";
// @ts-ignore
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { Button, Card, CardBody, Col, Row } from "reactstrap";

import { ORDER_STATUSES } from "../../../common/interfaces/orders.interface";
import { ProductType } from "../../../common/interfaces/products.interface";
import {
  IWarehouseItem,
  IWarehouseItemAsset,
  IWarehouseItemMaterial
} from "../../../common/interfaces/warehouse.interface";
import { formatProductType } from "../../../common/utils/formatter.util";
import { UpdateWarehouseItemBalanceModal } from "../../../components/backoffice/modals/UpdateWarehouseItemBalanceModal";
import { Loader } from "../../../components/common/Loader";
import { useServiceContainer } from "../../../hooks/useServiceContainer";

export const WarehouseListPage = () => {
  const [items, setItems] = useState<IWarehouseItem[]>([]);
  const [selectedItem, setSelectedItem] = useState<IWarehouseItem | null>(null);
  const [warehouseAction, setWarehouseAction] = useState<"add" | "remove">("add");
  const [loader, setLoader] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const { backofficeService } = useServiceContainer();

  const columns = [
    {
      dataField: "id",
      text: "Id"
    },
    {
      dataField: "material",
      text: "Product",
      filter: textFilter({
        caseSensitive: false,
        delay: 200,
        placeholder: " ",
        className: "form-control-sm mt-1"
      }),
      filterValue: (cellContent: IWarehouseItemMaterial, row: IWarehouseItem) => {
        return `[${formatProductType(row.product_type.title as ProductType)}] ${cellContent.title}`;
      },
      formatter: (cellContent: IWarehouseItemMaterial, row: IWarehouseItem) => {
        return `[${formatProductType(row.product_type.title as ProductType)}] ${cellContent.title}`;
      }
    },
    {
      dataField: "asset",
      text: "Product asset",
      filter: textFilter({
        caseSensitive: false,
        delay: 200,
        placeholder: " ",
        className: "form-control-sm mt-1"
      }),
      filterValue: (cellContent: IWarehouseItemAsset) => cellContent.title,
      formatter: (cellContent: IWarehouseItemAsset) => {
        return `${cellContent.title}`;
      }
    },
    {
      dataField: "balance",
      text: "Balance",
      filter: numberFilter({
        delay: 200,
        className: "d-flex mt-1",
        numberClassName: "form-control-sm ",
        comparatorClassName: "form-control-sm "
      }),
      filterValue: (cellContent: number) => cellContent,
      formatter: (cellContent: number, row: IWarehouseItem) => {
        return (
          <>
            <Button
              color="success"
              size="sm"
              className="me-2"
              onClick={() => {
                handleWarehouseAction(row, "add");
              }}
            >
              ADD
            </Button>
            <span>{cellContent}</span>
            <Button
              color="danger"
              size="sm"
              className="ms-2"
              onClick={() => {
                handleWarehouseAction(row, "remove");
              }}
            >
              REMOVE
            </Button>
          </>
        );
      }
    }
  ];

  const handleWarehouseAction = (row: IWarehouseItem, action: "add" | "remove") => {
    setSelectedItem(row);
    setWarehouseAction(action);
    setShowUpdateModal(true);
  };

  const fetchList = async () => {
    setLoader(true);
    try {
      const res = await backofficeService.getWarehouse();
      setItems(res);
    } catch (e) {
      console.error(e);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchList().then(() => null);
  }, []);

  return (
    <div className="data-table">
      <Card className="border">
        {loader && <Loader />}
        <CardBody>
          <ToolkitProvider keyField="id" columns={columns} data={items} search>
            {(toolkitProps: any) => {
              return (
                <>
                  <Row>
                    <Col xl="12">
                      <div className="table-responsive">
                        <BootstrapTable
                          bootstrap4
                          bordered={false}
                          striped={false}
                          // defaultSorted={defaultSorted}
                          filter={filterFactory()}
                          classes="table align-middle table-nowrap"
                          headerWrapperClasses="thead-light"
                          {...toolkitProps.baseProps}
                          data={items}
                          keyField="id"
                        />
                      </div>
                    </Col>
                  </Row>
                </>
              );
            }}
          </ToolkitProvider>
        </CardBody>
      </Card>
      <UpdateWarehouseItemBalanceModal
        show={showUpdateModal}
        warehouseItem={selectedItem}
        action={warehouseAction}
        onUpdated={() => {
          fetchList().then(() => null);
        }}
        toggle={() => {
          setShowUpdateModal(!showUpdateModal);
        }}
      />
    </div>
  );
};
