import moment from "moment";

import { OrderStatus } from "../interfaces/orders.interface";
import { ProductType } from "../interfaces/products.interface";

export const addPostfixToFileName = (fileName: string, postfix: string): string => {
  const parts = fileName.split(".");
  const extension = parts.pop();
  return `${parts.join(".")}${postfix}.${extension}`;
};

export const getImageUrl = (url: any): string => {
  let src = Array.isArray(url) ? url[0] : (url as string);
  if (!src) return "/assets/image-placeholder.png";

  const isExternal = src.startsWith("/storage") || src.startsWith("storage/");
  const isExternalOld = src.startsWith("upload/");
  if (isExternal || isExternalOld)
    src = getExternalImageUrl(isExternalOld ? `storage/${src}` : src, process.env.REACT_APP_API_BASE_ENDPOINT);

  // if (isHover && (src.includes(".") || src.includes("blob"))) {
  //   return src.replace(".png", "_hover.png").replace(".jpg", "_hover.jpg");
  // }

  return src;
};

export const getExternalImageUrl = (url: string, base = process.env.REACT_APP_API_BASE_ENDPOINT): string => {
  let formattedUrl = url;
  if (formattedUrl.startsWith("/")) formattedUrl = formattedUrl.substring(1);
  return `${base}/${formattedUrl}`;
};

export const formatAssetType = (type: ProductType): string => {
  switch (type) {
    case ProductType.PHONE_CASE:
      return "Phone";
    case ProductType.TSHIRT:
      return "T-Shirt";
    case ProductType.MACBOOK_CASE:
      return "MacBook case";
    case ProductType.AIRPODS_CASE:
      return "AirPods case";
    case ProductType.IPAD_CASE:
      return "iPad case";
    case ProductType.SCREEN_PROTECTOR:
      return "Screen protector";
    case ProductType.PUZZLE:
      return "Puzzle";
    case ProductType.MAGNET:
      return "Magnet";
    default:
      return "";
  }
};

export const formatProductType = (type: ProductType): string => {
  switch (type) {
    case ProductType.PHONE_CASE:
      return "Phone case";
    case ProductType.SCREEN_PROTECTOR:
      return "Screen protector";
    case ProductType.IPAD_CASE:
      return "iPad case";
    case ProductType.TSHIRT:
      return "T-Shirt";
    case ProductType.MACBOOK_CASE:
      return "MacBook case";
    case ProductType.AIRPODS_CASE:
      return "AirPods case";
    case ProductType.PUZZLE:
      return "Puzzle";
    case ProductType.MAGNET:
      return "Magnet";
    default:
      return "";
  }
};

export const getErrorMessage = (err: any): string => {
  if (err.response && err.response.data) {
    if (err.response.data.message.includes("Validation")) {
      const errors: Record<string, string[]> = err.response.data.data;
      return errors[Object.keys(errors)[0]][0];
    }
    return err.response.data.message;
  }
  return err.message;
};

export const formatOrderStatus = (status: OrderStatus): string => {
  switch (status) {
    case OrderStatus.Closed:
      return "Closed";
    case OrderStatus.Created:
      return "Created";
    case OrderStatus.Deleted:
      return "Deleted";
    case OrderStatus.MissingInformation:
      return "Missing information";
    case OrderStatus.Open:
      return "Open";
    case OrderStatus.Processing:
      return "Processing";
    case OrderStatus.ReadyToPrint:
      return "Ready to Print";
    case OrderStatus.ReadyToSend:
      return "Ready to Send";
    case OrderStatus.Restored:
      return "Restored";
    default:
      return "";
  }
};

export const getOrderStatusColor = (status: OrderStatus): string => {
  switch (status) {
    case OrderStatus.Closed:
      return "danger";
    case OrderStatus.Created:
      return "primary";
    case OrderStatus.Deleted:
      return "danger";
    case OrderStatus.MissingInformation:
      return "warning";
    case OrderStatus.Open:
      return "primary";
    case OrderStatus.Processing:
      return "warning";
    case OrderStatus.ReadyToPrint:
      return "info";
    case OrderStatus.ReadyToSend:
      return "info";
    case OrderStatus.Restored:
      return "warning";
    default:
      return "";
  }
};

export const timeSince = (isoDate: string) => {
  return moment(isoDate).fromNow();
};

export const replaceSpecialCharacters = (str: string, replacer = ""): string => {
  return str.replace(/[^a-zA-Z0-9 -]/g, "");
};

export const replaceWhiteSpacesToUnderscores = (str: string): string => {
  // also replace slashes with underscores

  return str.replace(/\//g, "_").replace(/\s/g, "_");
  // return str.replace(/\s/g, "_").replace()
};

export const getAttachmentName = (attachment: string): string => {
  const parts = attachment.split(".");
  const extension = parts.pop();
  return `attachment.${extension}`;
};
