import React, { useEffect, useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import * as Yup from "yup";

import { IMessage } from "../../../common/interfaces/messages.interface";
import { getErrorMessage } from "../../../common/utils/formatter.util";
import { getObjectDelta } from "../../../common/utils/object.util";
import { RichTextEditor } from "../../../components/common/Input/RichTextEditor";
import { InputField } from "../../../components/common/InputField";
import { Loader } from "../../../components/common/Loader";
import { ImagePreviewWithReUpload } from "../../../components/common/uploads/ImagePreviewWithReUpload";
import { FileUploadStep } from "../../../components/modals/create-design/FileUploadStep";
import { useServiceContainer } from "../../../hooks/useServiceContainer";

const validationSchema = Yup.object().shape({
  title: Yup.string().required(),
  description: Yup.string().required(),
  text: Yup.string().required(),
  image: Yup.mixed().nullable()
});

export const MessagesEditPage = () => {
  const {
    register,
    formState: { errors },
    setValue,
    setError,
    watch,
    handleSubmit,
    reset
  } = useForm({
    defaultValues: {} as any,
    resolver: yupResolver(validationSchema)
  });
  const [entityData, setEntityData] = useState<null | IMessage>(null);
  const [loader, setLoader] = useState(false);
  const { backofficeService } = useServiceContainer();
  const { id } = useParams();
  const navigate = useNavigate();
  const description = watch("description");
  const text = watch("text");
  const image = watch("image");
  const fetch = async () => {
    try {
      if (!id) return;
      setLoader(true);
      const data = await backofficeService.getNewsById(+id);
      setEntityData(data);
    } catch (e) {
      toast.error(getErrorMessage(e));
      navigate("/");
    } finally {
      setLoader(false);
    }
  };

  const onSubmitHandler = async (data: any) => {
    try {
      if (!entityData || !id) return;
      setLoader(true);

      const delta = getObjectDelta(entityData, {
        title: data.title,
        image: data.image,
        description: data.description,
        text: data.text
      }) as any;

      console.log(delta);

      const updatedItem = await backofficeService.updateNewsItem(+id, delta);

      setEntityData(updatedItem);
      toast.success("News item successfully updated");
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setLoader(false);
    }
  };

  const updateFormState = async (data: IMessage) => {
    reset({
      title: data.title,
      image: data.image,
      description: data.description,
      text: data.text
    });
  };

  useEffect(() => {
    if (entityData) {
      updateFormState(entityData).then(() => null);
    }
  }, [entityData]);

  useEffect(() => {
    fetch().then(() => null);
  }, [id]);

  return (
    <Row>
      {loader && <Loader />}
      <Col xs={12}>
        <Card>
          <CardBody>
            <h3>Edit news article</h3>
            {entityData && (
              <form onSubmit={handleSubmit(onSubmitHandler)}>
                <InputField {...register("title")} error={errors.title?.message} label="Title" name="title" />

                <RichTextEditor
                  value={description}
                  error={errors.description?.message}
                  name="description"
                  editorStyle={{
                    minHeight: "150px",
                    resize: "vertical"
                  }}
                  onChange={(val) => {
                    setValue("description", val);
                    setError("description", {
                      type: "manual",
                      message: ""
                    });
                  }}
                  label="Description"
                />

                <RichTextEditor
                  value={text}
                  editorStyle={{
                    minHeight: "150px",
                    resize: "vertical"
                  }}
                  error={errors.text?.message}
                  name="text"
                  onChange={(val) => {
                    setValue("text", val);
                    setError("text", {
                      type: "manual",
                      message: ""
                    });
                  }}
                  label="Text"
                />

                {image ? (
                  <ImagePreviewWithReUpload
                    title="Image (460x400)"
                    error={errors.image?.message}
                    originImage={image}
                    onUpload={(file) => {
                      setValue("image", file);
                    }}
                    deletable
                    onDelete={() => {
                      setValue("image", null);
                      setError("image", {
                        type: "manual",
                        message: ""
                      });
                    }}
                  />
                ) : (
                  <FileUploadStep
                    error={errors.image?.message}
                    hideSubmitButton
                    alwaysShowTitle
                    title="Image (460x400)"
                    small
                    containerSize={12}
                    onUpload={(file) => {
                      setValue("image", file[0]);
                      setError("image", {
                        type: "manual",
                        message: ""
                      });
                    }}
                  />
                )}

                <Button type="submit" color="success">
                  Save
                </Button>
              </form>
            )}
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};
