import React from "react";

import { TextFieldInput } from "../../Input/TextFieldInput";

interface Props {
  value: string;
  label: string;
  currentTags: string[];
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onAddTag: () => void;
  removeAll?: boolean;
  onRemoveAll?: () => void;
  onRemoveTag: (tag: string) => void;
  error?: any;
  placeholder?: any;
}

export const ListingFormTags: React.FC<Props> = ({
  error,
  value,
  onChange,
  currentTags,
  label,
  onAddTag,
  onRemoveTag,
  placeholder,
    removeAll,
    onRemoveAll,
}) => {
  return (
    <div className="listing-form__form-group">
      <div className="listing-form__form-group__input">
        <TextFieldInput
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          label={label}
          clearAll={removeAll}
          onClearAll={onRemoveAll}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              onAddTag();
            }
          }}
          // @ts-ignore
          error={error}
        />
        <button className="btn btn-primary btn-sm" onClick={onAddTag} type="button">
          Add
        </button>
      </div>
      <div className="listing-form__form-group__bottom">
        {(currentTags ?? []).map((tag: string, idx: number) => (
          <div className="listing-form__tag" key={idx}>
            {tag}
            <button className="listing-form__tag__remove" type="button" onClick={() => onRemoveTag(tag)}>
              <i className="bx bx-x" />
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};
