import React, { useEffect, useMemo, useState } from "react";

import BootstrapTable, { TableChangeState } from "react-bootstrap-table-next";
import filterFactory from "react-bootstrap-table2-filter";
import paginationFactory, { PaginationProvider, PaginationListStandalone } from "react-bootstrap-table2-paginator";
// @ts-ignore
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { Link, useSearchParams } from "react-router-dom";
import { Button, Card, CardBody, Col, Row } from "reactstrap";

import { ORDER_HISTORY_COLUMNS } from "../../common/constants/order-history.constant";
import { IPaginationMeta } from "../../common/interfaces/api.interface";
import { IOrderItem, IOrderTableItem, IOrderTableItemChildren } from "../../common/interfaces/orders.interface";
import { assembleFiltersAndSort, parseQueryFilters, parseQuerySort } from "../../common/utils/filter.util";
import { DatePickerField } from "../../components/common/DatePickerField";
import { Loader } from "../../components/common/Loader";
import { TooltipIcon } from "../../components/common/TooltipIcon";
import { useOrderManagementTableExpandControl } from "../../hooks/orderManagementTable/useOrderManagementTableExpandControl";
import { useOrderManagementTableFilters } from "../../hooks/orderManagementTable/useOrderManagementTableFilters";
import useDidMountEffect from "../../hooks/useDidMountEffect";
import { useServiceContainer } from "../../hooks/useServiceContainer";

export const OrderHistoryPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [items, setItems] = useState<IOrderTableItem[]>([]);
  // const [page, setPage] = useState(1);
  // const [filters, setFilters] = useState<any>({});
  // const [sortSettings, setSortSettings] = useState<any>(parseQuerySort(Object.fromEntries(searchParams)));
  const [paginationMeta, setPaginationMeta] = useState<null | IPaginationMeta>(null);
  const [loader, setLoader] = useState(false);
  const { ordersService } = useServiceContainer();

  const {
    filters,
    filterDates,
    sortSettings,
    perPage,
    page,
    pageOptions,
    defaultSorted,
    setFilterDates,
    handleTableChange: filtersHandleTableChange,
    handleClearFilters
  } = useOrderManagementTableFilters(searchParams, paginationMeta);

  const { expandRowData } = useOrderManagementTableExpandControl({
    items,
    isPublic: true
  });

  const columns = useMemo(() => {
    return [
      ...ORDER_HISTORY_COLUMNS(parseQueryFilters(Object.fromEntries(searchParams))),
      {
        dataField: "children",
        text: "Actions",
        formatter: (cellContent: IOrderTableItemChildren[]) => {
          if (cellContent.length !== 1) return "";

          const item = cellContent[0];

          return (
            <Link className="btn btn-primary btn-sm ms-2" color="primary" to={`/orders/${encodeURIComponent(item.id)}`}>
              View
            </Link>
          );
        },
        sort: false
      }
    ];
  }, []);

  const fetchList = async (
    selectedPage: number,
    _dates: Date[],
    _filters: Record<string, string>,
    _sort: Record<string, string>
  ) => {
    setLoader(true);
    try {
      const res = await ordersService.getOrderHistory(selectedPage, 10, _dates, _filters, _sort, false);

      setItems(res.items);
      setPaginationMeta(res.meta);
    } catch (e) {
      console.error(e);
    } finally {
      setLoader(false);
    }
  };

  const onTableChange = (event: string, data: TableChangeState<any>) => {
    filtersHandleTableChange(event, data);
  };

  const handleExcelExport = async (_dates: Date[], _filters: Record<string, string>, _sort: Record<string, string>) => {
    setLoader(true);
    try {
      await ordersService.getOrderHistory(1, 10, _dates, _filters, _sort, false, true);
    } catch (e) {
      console.error(e);
    } finally {
      setLoader(false);
    }
  };

  useDidMountEffect(() => {
    fetchList(page, filterDates, filters, sortSettings).then(() => null);
  }, [page, filters, sortSettings, filterDates]);

  useEffect(() => {
    setSearchParams(assembleFiltersAndSort(filters, sortSettings));
  }, [filters, sortSettings]);

  return (
    <div className="data-table">
      <Card className="border">
        {loader && <Loader />}
        <CardBody>
          <PaginationProvider pagination={paginationFactory(pageOptions)}>
            {({ paginationProps, paginationTableProps }: any) => (
              <ToolkitProvider keyField="id" columns={columns} data={items} search>
                {(toolkitProps: any) => (
                  <>
                    <Row className="mb-4">
                      <Col xl={3}>
                        <DatePickerField
                          className="mb-0"
                          label="Filter by date"
                          value={filterDates}
                          options={{
                            mode: "range"
                          }}
                          onChange={(dates: Date[]) => {
                            if (dates.length !== 2) return;

                            setFilterDates(dates);
                          }}
                        />
                      </Col>
                      <Col xl={3} className="d-flex align-items-end">
                        <Button color="primary" onClick={handleClearFilters}>
                          Clear filters
                        </Button>
                        <Button
                          color="primary"
                          className="ms-2"
                          // disabled={!!selectedData.length}
                          onClick={() => {
                            handleExcelExport(filterDates, filters, sortSettings).then(() => null);
                          }}
                        >
                          Export to excel
                        </Button>
                      </Col>
                    </Row>

                    <Row>
                      <Col xl="12">
                        <div className="table-responsive">
                          <BootstrapTable
                            bootstrap4
                            remote
                            onTableChange={onTableChange}
                            bordered={false}
                            striped={false}
                            defaultSorted={defaultSorted}
                            filter={filterFactory()}
                            classes="table align-middle table-nowrap table-sm"
                            headerWrapperClasses="thead-light"
                            {...toolkitProps.baseProps}
                            {...paginationTableProps}
                            expandRow={expandRowData}
                            keyField="id"
                          />
                        </div>
                      </Col>
                    </Row>

                    <Row className="align-items-md-center mt-30">
                      <Col className="inner-custom-pagination d-flex">
                        <div className="text-md-right ms-auto">
                          <PaginationListStandalone {...paginationProps} />
                        </div>
                      </Col>
                    </Row>
                  </>
                )}
              </ToolkitProvider>
            )}
          </PaginationProvider>
        </CardBody>
      </Card>
    </div>
  );
};
