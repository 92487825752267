import React, { useEffect, useMemo, useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import * as Yup from "yup";

import { PRODUCT_TYPES } from "../../../common/constants/product-types.constant";
import { ICategoryItem, IProductItem, ProductType } from "../../../common/interfaces/products.interface";
import { getErrorMessage } from "../../../common/utils/formatter.util";
import { getObjectDelta } from "../../../common/utils/object.util";
import { CheckboxFieldInput } from "../../../components/common/Input/CheckboxFieldInput";
import { RichTextEditor } from "../../../components/common/Input/RichTextEditor";
import { InputField } from "../../../components/common/InputField";
import { Loader } from "../../../components/common/Loader";
import { SelectField } from "../../../components/common/SelectField";
import { ImagePreviewWithReUpload } from "../../../components/common/uploads/ImagePreviewWithReUpload";
import { UploadedImageList } from "../../../components/common/uploads/UploadedImageList";
import { useServiceContainer } from "../../../hooks/useServiceContainer";

const validationSchema = Yup.object().shape({
  title: Yup.string().required("This field is required"),
  default_retail_price: Yup.string(),
  image: Yup.mixed().required("This field is required"),
  image_hover: Yup.mixed().required("This field is required"),
  ignore_balance: Yup.boolean(),
  description: Yup.string().required("This field is required"),
  type: Yup.string().required("This field is required"),
  printfile_width: Yup.string().required("This field is required"),
  max_designs: Yup.string().required("This field is required"),
  printfile_height: Yup.string().required("This field is required"),
  categoryId: Yup.string().required("This field is required"),
  files: Yup.array().of(Yup.mixed()).min(1, "Please upload at least one file")
});

export const ProductEditPage = () => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    watch,
    setValue,
    setError
  } = useForm({
    defaultValues: {} as any,
    resolver: yupResolver(validationSchema)
  });

  const [entityData, setEntityData] = useState<null | IProductItem>(null);
  const [galleryImages, setGalleryImages] = useState<string[]>([]);

  const [loader, setLoader] = useState(true);
  const { id } = useParams();
  const { backofficeService } = useServiceContainer();
  const navigate = useNavigate();

  const [categories, setCategories] = useState<ICategoryItem[]>([]);

  const formTypeValue = watch("type");
  const formCategoryValue = watch("categoryId");
  const description = watch("description");

  const productTypes = useMemo(() => {
    return PRODUCT_TYPES;
  }, []);

  const categoriesOptions = useMemo(() => {
    return categories.map((category) => ({
      value: category.id.toString(),
      label: category.title
    }));
  }, [categories]);

  const selectedType = useMemo(() => {
    return productTypes.find((type) => type.value === formTypeValue) ?? null;
  }, [formTypeValue, productTypes]);

  const selectedCategory = useMemo(() => {
    return categoriesOptions.find((type) => +type.value === formCategoryValue) ?? null;
  }, [formCategoryValue, categoriesOptions]);

  const fetchCategories = async () => {
    try {
      const res = await backofficeService.getProductsCategories();
      setCategories(res);
    } catch (e) {
      toast.error(getErrorMessage(e));
    }
  };

  const processDeleteGalleryImage = async (key: string) => {
    try {
      setLoader(true);
      if (!id) return;
      await backofficeService.deleteProductGalleryImageById(+id, key);

      setGalleryImages(galleryImages.filter((image) => image !== key));
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setLoader(false);
    }
  };

  const processAddGalleryImages = async (images: File[]) => {
    try {
      setLoader(true);
      if (!id) return;
      const data = await backofficeService.addGalleryImagesToProduct(+id, images);

      setGalleryImages(data.galleryImages);
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setLoader(false);
    }
  };

  const fetch = async () => {
    try {
      if (!id) return;
      setLoader(true);
      const data = await backofficeService.getProductById(+id);
      setEntityData(data);
    } catch (e) {
      toast.error(getErrorMessage(e));
      navigate("/");
    } finally {
      setLoader(false);
    }
  };

  const onSubmitHandler = async (data: any) => {
    try {
      if (!entityData || !id) return;
      setLoader(true);
      const delta = getObjectDelta(
        {
          ...entityData
          // default_retail_price: data.default_retail_price ?? ""
        },
        {
          title: data.title,
          ignore_balance: data.ignore_balance ? 1 : 0,
          default_retail_price: data.default_retail_price,
          image: data.image,
          image_hover: data.image_hover,
          description: data.description,
          type: data.type,
          categoryId: +data.categoryId,
          printfile_width: data.printfile_width,
          max_designs: data.max_designs,
          printfile_height: data.printfile_height
        }
      );

      const updatedEntity = await backofficeService.updateProductById(+id, delta);

      setEntityData(updatedEntity);

      toast.success("Product updated successfully");
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setLoader(false);
    }
  };

  const updateFormState = async (data: IProductItem) => {
    reset({
      title: data.title,
      default_price: data.default_price?.replace(",", ".") ?? "",
      default_retail_price: data.default_retail_price ?? "",
      ignore_balance: data.ignore_balance,
      image: data.image,
      image_hover: data.image_hover,
      description: data.description,
      printfile_width: data.printfile_width,
      max_designs: data.max_designs,
      printfile_height: data.printfile_height,
      type: data.type,
      categoryId: data.categoryId,
      files: data.gallery_images
    });
  };

  useEffect(() => {
    if (entityData) {
      setGalleryImages(entityData.gallery_images);
      updateFormState(entityData).then(() => null);
    }
  }, [entityData]);

  useEffect(() => {
    fetch().then(() => null);
    fetchCategories().then(() => null);
  }, [id]);

  console.log(errors);

  return (
    <Row>
      {loader && <Loader />}
      <Col xs={12}>
        <Card>
          <CardBody>
            {entityData && (
              <>
                <h3>
                  Product #{entityData.id}{" "}
                  <Link className="btn btn-primary" to={`/backoffice/products/${entityData.id}/assets`}>
                    Manage product assets
                  </Link>
                  <Link className="btn btn-primary ms-1" to={`/backoffice/products/${entityData.id}/delivery-services`}>
                    Manage product delivery services
                  </Link>
                </h3>
                <form onSubmit={handleSubmit(onSubmitHandler)}>
                  <InputField {...register("title")} error={errors.title?.message} label="Title" name="title" />

                  <CheckboxFieldInput
                    {...register("ignore_balance")}
                    error={errors.ignore_balance?.message}
                    label="Ignore asset balance"
                    name="ignore_balance"
                  />

                  <RichTextEditor
                    value={description}
                    error={errors.description?.message}
                    name="description"
                    onChange={(val) => {
                      setValue("description", val);
                      setError("description", {
                        type: "manual",
                        message: ""
                      });
                    }}
                    label="Description"
                  />
                  <InputField
                    {...register("default_retail_price")}
                    error={errors.default_retail_price?.message}
                    label="Default retail price (for listings)"
                    type="number"
                    step="0.01"
                    name="default_retail_price"
                  />

                  <SelectField
                    options={productTypes}
                    label="Product type"
                    error={errors.type?.message}
                    value={selectedType}
                    placeholder="Search.."
                    onChange={(data) => {
                      setValue("type", data.value);
                      setError("type", {
                        type: "manual",
                        message: ""
                      });
                    }}
                  />

                  <InputField
                    {...register("printfile_width")}
                    error={errors.printfile_width?.message}
                    label="Print file width"
                    name="printfile_width"
                    type="number"
                    step="1"
                    placeholder="Enter print file width (in pixels)"
                  />

                  <InputField
                    {...register("printfile_height")}
                    error={errors.printfile_height?.message}
                    label="Print file height"
                    name="printfile_height"
                    type="number"
                    step="1"
                    placeholder="Enter print file height (in pixels)"
                  />

                  <InputField
                    {...register("max_designs")}
                    error={errors.max_designs?.message}
                    label="Max designs count"
                    name="max_designs"
                    type="number"
                    step="1"
                    placeholder="Enter max designs count (for mockups)"
                  />

                  <SelectField
                    options={categoriesOptions}
                    label="Product category"
                    error={errors.categoryId?.message}
                    value={selectedCategory}
                    placeholder="Search.."
                    onChange={(data) => {
                      setValue("categoryId", +data.value);
                      setError("categoryId", {
                        type: "manual",
                        message: ""
                      });
                    }}
                  />

                  <Row>
                    <Col xs={3}>
                      <ImagePreviewWithReUpload
                        title="Image"
                        error={errors.image?.message}
                        originImage={entityData.image}
                        onUpload={(file) => {
                          setValue("image", file);
                        }}
                      />
                    </Col>

                    <Col xs={3}>
                      <ImagePreviewWithReUpload
                        title="Hover image"
                        error={errors.image_hover?.message}
                        originImage={entityData.image_hover}
                        onUpload={(file) => {
                          setValue("image_hover", file);
                        }}
                      />
                    </Col>

                    <Col xs={12}>
                      <UploadedImageList
                        title="Gallery images"
                        error={errors.files?.message}
                        images={galleryImages}
                        onUpload={(files) => {
                          processAddGalleryImages(files).then(() => null);
                          setValue("files", files);
                          setError("files", {
                            type: "manual",
                            message: ""
                          });
                        }}
                        onDelete={(key) => {
                          processDeleteGalleryImage(key).then(() => null);
                          // setValue("files", files);
                          setError("files", {
                            type: "manual",
                            message: ""
                          });
                        }}
                      />
                    </Col>
                  </Row>

                  <Button type="submit" color="success">
                    Save
                  </Button>
                  <Button
                    type="button"
                    color="primary"
                    className="ms-2"
                    onClick={() => {
                      updateFormState(entityData).then(() => null);
                    }}
                  >
                    Reset
                  </Button>
                </form>
              </>
            )}
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};
