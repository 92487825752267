import React, { useCallback, useEffect, useMemo, useState } from "react";

import debounce from "lodash.debounce";
import { TableChangeState } from "react-bootstrap-table-next";
import { toast } from "react-toastify";

import { IPaginationMeta } from "../../common/interfaces/api.interface";
import { IProductItem, IProductListingItem } from "../../common/interfaces/products.interface";
import { showConfirmationModal } from "../../common/utils/confirm.util";
import { getErrorMessage } from "../../common/utils/formatter.util";
import { ListingStatusControlModal } from "../../components/modals/listing-status-control/ListingStatusControlModal";
import useDidMountEffect from "../useDidMountEffect";
import { useServiceContainer } from "../useServiceContainer";

export const useListingManagementTable = (search?: string, selectedProduct?: IProductItem | null) => {
  const [page, setPage] = useState(1);
  const [items, setItems] = useState<IProductListingItem[]>([]);
  const [paginationMeta, setPaginationMeta] = useState<IPaginationMeta | null>(null);
  const [loader, setLoader] = useState(false);
  const [selectedItem, setSelectedItem] = useState<IProductListingItem | null>(null);
  const [showListingStatusControlModal, setShowListingStatusControlModal] = useState(false);
  const { productsApiService } = useServiceContainer();
  const [filters, setFilters] = useState<any>({});
  const [sortSettings, setSortSettings] = useState<any>({});

  const fetchList = async (selectedPage: number, _filters: Record<string, string>, _sort: Record<string, string>) => {
    setLoader(true);
    try {
      const res = await productsApiService.getProductListings(selectedPage, _filters, true);
      setItems(res.items);
      setPaginationMeta(res.meta);

      // setItems(res);
      // setPaginationMeta(res.meta);
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setLoader(false);
    }
  };

  const handleListingDelete = async (listing: IProductListingItem) => {
    const oneOfListingIsActive = listing.listings.some((l) => l.status === true);

    if (oneOfListingIsActive) {
      toast.error(
        "To delete a listing, please ensure it is set to DRAFT (deactivated) and that there are no active orders associated with it."
      );
      return;
    }

    showConfirmationModal({
      title: "Are you sure?",
      message:
        "To delete a listing, please ensure it is set to DRAFT (deactivated) and that there are no active orders associated with it.",
      onConfirm: async () => {
        try {
          setLoader(true);
          await productsApiService.deleteProductListingById(listing.id);
          await fetchList(page, filters, sortSettings);
        } catch (e) {
          toast.error(getErrorMessage(e));
        } finally {
          setLoader(false);
        }
      },
      onCancel: () => {}
    });
  };

  const openListingStatusControlModal = (listingItem: IProductListingItem) => {
    setSelectedItem(listingItem);
    setShowListingStatusControlModal(true);
  };

  const onTableChange = (event: string, data: TableChangeState<any>) => {
    switch (event) {
      case "pagination":
        setPage(data.page);
        break;
      case "filter":
        setFilters(
          Object.fromEntries(Object.entries(data.filters).map(([column, value]) => [column, value.filterVal]))
        );
        break;
      case "sort":
        setSortSettings({
          [data.sortField]: data.sortOrder
        });
        break;
      default:
        break;
    }
  };

  const setSearchFilter = useCallback((searchValue?: string) => {
    setFilters({
      ...filters,
      search: searchValue
    });
  }, []);

  const debouncedSetSearchFilter = useCallback(debounce(setSearchFilter, 500), []);
  const debouncedFetchList = useCallback(debounce(fetchList, 100), [filters, sortSettings, page]);

  useEffect(() => {
    debouncedSetSearchFilter(search);
  }, [search]);

  useDidMountEffect(() => {
    setFilters({
      ...filters,
      product_id: selectedProduct?.id.toString() ?? null
    });
  }, [selectedProduct]);

  useDidMountEffect(() => {
    fetchList(page, filters, sortSettings);
  }, [filters, sortSettings, page]);

  const pageOptions = {
    sizePerPage: 10,
    page,
    totalSize: paginationMeta?.total || 0,
    custom: true
  };

  const ListingStatusControlModalComponent = useMemo(() => {
    return (
      <ListingStatusControlModal
        show={showListingStatusControlModal}
        item={selectedItem}
        toggle={() => setShowListingStatusControlModal(!showListingStatusControlModal)}
        onSubmit={() => fetchList(page, filters, sortSettings)}
      />
    );
  }, [showListingStatusControlModal, selectedItem, page, filters, sortSettings]);

  return {
    items,
    loader,
    onTableChange,
    fetchList: () => fetchList(page, filters, sortSettings),
    page,
    pageOptions,
    ListingStatusControlModalComponent,
    openListingStatusControlModal,
    handleListingDelete
  };
};
