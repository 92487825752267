import { DownloadCategory } from "../interfaces/downloads.interface";

export const DOWNLOADS_CATEGORIES = [
  {
    label: "Mockups",
    value: DownloadCategory.Mockups
  },
  {
    label: "Pictures",
    value: DownloadCategory.Pictures
  },
  {
    label: "Artificial intelligence",
    value: DownloadCategory.AI
  }
];
