import React, { useRef, useState } from "react";

import classNames from "classnames";
import { Button } from "reactstrap";

import { ExtendedFile } from "../../../common/interfaces/vendor.interface";
import { extendFile } from "../../../common/utils/file.util";
import { getImageUrl } from "../../../common/utils/formatter.util";

interface Props {
  originImage: string;
  title?: string;
  error?: any;
  onUpload: (file: ExtendedFile) => void;
  onDelete?: () => void;
  deletable?: boolean;
}

export const ImagePreviewWithReUpload: React.FC<Props> = ({
  onDelete,
  deletable,
  error,
  title,
  originImage,
  onUpload
}) => {
  const [preview, setPreview] = useState<string>(originImage);
  const fileInputRef = useRef<HTMLInputElement>(null);
  return (
    <div
      className={classNames("image-preview-with-reupload", {
        "image-preview-with-reupload--error": !!error
      })}
    >
      {title && (
        <div className="image-preview-with-reupload__title">
          {title} {error && <span className="error-message">{error}</span>}
        </div>
      )}
      <div className="image-preview-with-reupload__inner">
        <div className="image-preview-with-reupload__image">
          <img src={getImageUrl(preview)} alt="Preview" />
        </div>

        <div className="image-preview-with-reupload__upload">
          <input
            style={{ display: "none" }}
            ref={fileInputRef}
            type="file"
            accept="image/*"
            onChange={(e) => {
              if (e.target.files?.length === 0) return;
              const file = e.target.files?.[0] as File;

              extendFile(file);
              setPreview((file as ExtendedFile).preview);
              onUpload(file as ExtendedFile);
            }}
          />
          <Button
            color="primary"
            type="button"
            onClick={() => {
              fileInputRef.current?.click();
            }}
          >
            Upload new
          </Button>
          {deletable && (
            <Button
              className="ms-2"
              color="danger"
              type="button"
              onClick={() => {
                if (onDelete) {
                  onDelete();
                }
              }}
            >
              Delete
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
