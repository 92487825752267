import { AuthApiService } from "../../services/api";
import { TokenService } from "../../services/TokenService";
import { store } from "../../store/store";
import { setUser } from "../../store/user/userSlice";
import { IUserWithRole } from "../interfaces/user.interface";

const initializeUser = async () => {
  if (TokenService.getAccessToken()) {
    try {
      const user: IUserWithRole | null = await AuthApiService.getUser().catch(() => null);
      if (user) {
        store.dispatch(setUser(user));
        // await TokenService.init();
      }
    } catch (e) {
      TokenService.removeAccessToken();
    }
  }
};

export default initializeUser;
