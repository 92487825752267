import React, { useEffect, useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Button, Modal } from "reactstrap";
import { object, string } from "yup";

import { getErrorMessage } from "../../../common/utils/formatter.util";
import { InputField } from "../../common/InputField";
import { Loader } from "../../common/Loader";

const validationSchema = object().shape({
  name: string().required("This field is required")
});

interface Props {
  show: boolean;
  toggle: () => void;
  onSubmit: (name: string) => Promise<void>;
}

export const CreateListingTemplateModal: React.FC<Props> = ({ show, toggle, onSubmit }) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset
  } = useForm({
    defaultValues: {
      name: ""
    },
    resolver: yupResolver(validationSchema)
  });

  const [loader, setLoader] = useState(false);

  useEffect(() => {
    reset();
  }, []);

  const onSubmitHandler = async (data: { name: string }) => {
    try {
      setLoader(true);
      await onSubmit(data.name);
      toggle();
      reset();
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setLoader(false);
    }
  };

  return (
    <Modal isOpen={show} toggle={toggle} centered size="md">
      {loader && <Loader />}
      <div className="modal-header">
        <h5 className="modal-title mt-0">Create new listing template</h5>
        <button
          type="button"
          onClick={() => {
            toggle();
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form>
        <div className="modal-body">
          <div className="row">
            <div className="col-12">
              <InputField
                {...register("name")}
                error={errors.name?.message}
                label="Template name"
                name="name"
                required
                placeholder="Enter template name"
              />
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <Button
            color="danger"
            onClick={() => {
              toggle();
            }}
          >
            Cancel
          </Button>
          <Button color="primary" type="button" onClick={handleSubmit((d) => onSubmitHandler(d))}>
            Save
          </Button>
        </div>
      </form>
    </Modal>
  );
};
