import React, { useCallback, useEffect, useRef, useState } from "react";

import classNames from "classnames";
import debounce from "lodash.debounce";

import { TooltipIcon } from "../../common/TooltipIcon";

interface Props {
  children: any;
  error?: any;
  className?: string;
  withoutMaxHeight?: boolean;
  containerGap?: number;
  disableDynamicContainerHeight?: boolean;
}

export const WizardScrollableContainer: React.FC<Props> = ({
  containerGap,
  withoutMaxHeight,
  className,
  children,
  disableDynamicContainerHeight,
  error
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const [maxHeight, setMaxHeight] = useState(369);

  const calculateMaxHeight = () => {
    if (disableDynamicContainerHeight) return;
    setMaxHeight(1);

    setTimeout(() => {
      const wrapperElement = ref.current?.closest(".wizard-step-scrollable-container-wrapper-ref") as HTMLElement;
      if (ref.current && wrapperElement) {
        const refBounding = ref.current.getBoundingClientRect();
        const wrapperBounding = wrapperElement.getBoundingClientRect();
        const topOffsetInWrapper = refBounding.top - wrapperBounding.top;
        const height = wrapperElement.clientHeight - topOffsetInWrapper - (containerGap ?? 30);
        if (height < 200) {
          setMaxHeight(200);
          return;
        }
        setMaxHeight(wrapperElement.clientHeight - topOffsetInWrapper - (containerGap ?? 30));
        return;
      }

      setMaxHeight(369);
    }, 50);
  };

  const handleResize = () => {
    calculateMaxHeight();
  };

  const debouncedHandleResize = useCallback(debounce(handleResize, 100), [ref]);

  useEffect(() => {
    calculateMaxHeight();
  }, [ref]);

  useEffect(() => {
    window.addEventListener("resize", debouncedHandleResize);

    return () => {
      window.removeEventListener("resize", debouncedHandleResize);
    };
  }, []);

  return (
    <div
      ref={ref}
      className={classNames("wizard-step__scrollable-container", className, {
        "wizard-step__scrollable-container--invalid": !!error,
        "wizard-step__scrollable-container--wmx": withoutMaxHeight
      })}
    >
      <div
        className={classNames("wizard-step__scrollable-container__inner")}
        style={{
          maxHeight: withoutMaxHeight ? "none" : `${maxHeight || 369}px`
        }}
      >
        {children}
      </div>
      {error && (
        <div className="wizard-step__scrollable-container__tooltip">
          <TooltipIcon id="scrollable-container-tt" error content={error} placement="left" />
        </div>
      )}
    </div>
  );
};
