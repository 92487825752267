import React, { useEffect, useMemo } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Button, CardBody, Modal } from "reactstrap";
import { number, object, string } from "yup";

import { IDeliveryServiceItem } from "../../../common/interfaces/backoffice.interface";
import { useDeliveryServiceItemModal } from "../../../hooks/deliveryService/useDeliveryServiceItemModal";
import { InputField } from "../../common/InputField";
import { Loader } from "../../common/Loader";
import { SelectField } from "../../common/SelectField";

const validationSchema = object().shape({
  country_code: string().required("This field is required"),
  weight_from: number().required("This field is required"),
  weight_to: number().required("This field is required"),
  delivery_time: string().required("This field is required"),
  price: number().required("This field is required"),
  is_tracking: number().required("This field is required")
});

interface Props {
  show: boolean;
  item: IDeliveryServiceItem | null;
  serviceId: number;
  onUpdated: (updatedItem: IDeliveryServiceItem) => void;
  onCreated: (updatedItem: IDeliveryServiceItem) => void;
  toggle: () => void;
}

export const UpdateDeliveryServiceItemModal: React.FC<Props> = ({
  serviceId,
  onUpdated,
  onCreated,
  item,
  show,
  toggle
}) => {
  const {
    formState: { errors },
    handleSubmit,
    register,
    reset,
    watch,
    setValue,
    setError
  } = useForm({
    defaultValues: {
      country_code: "",
      weight_from: 0,
      weight_to: 0,
      delivery_time: "",
      price: 0,
      is_tracking: 0
    },
    resolver: yupResolver(validationSchema)
  });

  const { loader, onSubmitHandler } = useDeliveryServiceItemModal(serviceId, item, onUpdated, onCreated, () => {
    toggle();
    reset();
  });

  const isTracking = watch("is_tracking");

  const trackingOptions = useMemo(() => {
    return [
      {
        label: "Yes",
        value: "1"
      },
      {
        label: "No",
        value: "0"
      }
    ];
  }, []);

  const isTrackingSelectedOption = useMemo(() => {
    return trackingOptions.find((option) => +option.value === isTracking) ?? null;
  }, [isTracking, trackingOptions]);

  useEffect(() => {
    if (!item) {
      reset({
        country_code: "",
        weight_from: 0,
        weight_to: 0,
        delivery_time: "",
        price: 0,
        is_tracking: 0
      });
    } else {
      reset({
        country_code: item.country_code,
        weight_from: item.weight_from,
        weight_to: item.weight_to,
        delivery_time: item.delivery_time,
        price: item.price,
        is_tracking: item.is_tracking
      });
    }
  }, [item]);

  return (
    <Modal isOpen={show} toggle={toggle} centered size="md">
      {loader && <Loader />}
      <div className="modal-header">
        {item ? (
          <h5 className="modal-title mt-0">Update delivery service #{item.id}</h5>
        ) : (
          <h5 className="modal-title mt-0">Create new delivery item</h5>
        )}
        <button
          type="button"
          onClick={() => {
            toggle();
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form onSubmit={handleSubmit(onSubmitHandler)}>
        <>
          <div className="modal-body">
            <div className="row">
              <div className="col-12">
                <div className="card border">
                  <CardBody>
                    <InputField
                      readOnly
                      label="Service id"
                      name="service_id"
                      type="text"
                      value={serviceId.toString()}
                    />
                    <InputField
                      {...register("country_code")}
                      error={errors.country_code?.message}
                      label="Enter country_code"
                      name="country_code"
                      type="text"
                      step="any"
                      placeholder="Enter country code"
                    />
                    <InputField
                      {...register("weight_from")}
                      error={errors.weight_from?.message}
                      label="Enter weight_from"
                      name="weight_from"
                      type="number"
                      step="any"
                      placeholder="Enter weight from"
                    />
                    <InputField
                      {...register("weight_to")}
                      error={errors.weight_to?.message}
                      label="Enter weight_to"
                      name="weight_to"
                      type="number"
                      step="any"
                      placeholder="Enter weight to"
                    />
                    <InputField
                      {...register("delivery_time")}
                      error={errors.delivery_time?.message}
                      label="Enter delivery_time"
                      name="delivery_time"
                      type="text"
                      placeholder="Enter delivery time"
                    />
                    <InputField
                      {...register("price")}
                      error={errors.price?.message}
                      label="Enter price"
                      name="price"
                      step="any"
                      type="number"
                      placeholder="Enter price"
                    />
                    <SelectField
                      options={trackingOptions}
                      label="Is tracking"
                      error={errors.is_tracking?.message}
                      value={isTrackingSelectedOption}
                      placeholder="Search.."
                      onChange={(data) => {
                        setValue("is_tracking", +data.value);
                        setError("is_tracking", {
                          type: "manual",
                          message: ""
                        });
                      }}
                    />
                  </CardBody>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <Button
              color="danger"
              onClick={() => {
                toggle();
              }}
            >
              Cancel
            </Button>
            <Button color="primary" type="submit">
              Save
            </Button>
          </div>
        </>
      </form>
    </Modal>
  );
};
