import { createAsyncThunk } from "@reduxjs/toolkit";

import { OrderSummaryData } from "../../../common/interfaces/orders.interface";
import { CommonApiService, OrdersApiService } from "../../../services/api";

export const APP_ACTION_ADD_ORDER_TO_CART = createAsyncThunk("app/addOrderToCart", async (data: OrderSummaryData[]) => {
  return OrdersApiService.addOrderToCart(data);
});

export const APP_ACTION_GET_ORDER_CART = createAsyncThunk("app/getOrderCart", async () => {
  return OrdersApiService.getCartOrders();
});

export const APP_ACTION_GET_NOTIFICATIONS = createAsyncThunk("app/getNotifications", async () => {
  return CommonApiService.getNotifications();
});
