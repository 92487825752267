import React, { useEffect, useMemo, useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import * as Yup from "yup";

import { ICategoryItem } from "../../../common/interfaces/products.interface";
import { getErrorMessage } from "../../../common/utils/formatter.util";
import { getObjectDelta } from "../../../common/utils/object.util";
import { InputField } from "../../../components/common/InputField";
import { Loader } from "../../../components/common/Loader";
import { useServiceContainer } from "../../../hooks/useServiceContainer";

const validationSchema = Yup.object().shape({
  code: Yup.string(),
  title: Yup.string()
});

export const CategoryEditPage = () => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset
  } = useForm({
    defaultValues: {} as any,
    resolver: yupResolver(validationSchema)
  });

  const [entityData, setEntityData] = useState<null | ICategoryItem>(null);

  const [loader, setLoader] = useState(true);
  const { id } = useParams();
  const { backofficeService } = useServiceContainer();
  const navigate = useNavigate();

  const fetch = async () => {
    try {
      if (!id) return;
      setLoader(true);
      const data = await backofficeService.getCategoryById(+id);
      setEntityData(data);
    } catch (e) {
      toast.error(getErrorMessage(e));
      navigate("/");
    } finally {
      setLoader(false);
    }
  };

  const onSubmitHandler = async (data: any) => {
    try {
      if (!entityData || !id) return;
      setLoader(true);
      const delta = getObjectDelta(entityData, {
        code: data.code,
        title: data.title
      });

      const updatedEntity = await backofficeService.updateCategoryById(+id, delta);

      setEntityData(updatedEntity);

      toast.success("Category updated successfully");
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setLoader(false);
    }
  };

  const updateFormState = async (data: ICategoryItem) => {
    reset({
      code: data.code,
      title: data.title
    });
  };

  useEffect(() => {
    if (entityData) {
      updateFormState(entityData).then(() => null);
    }
  }, [entityData]);

  useEffect(() => {
    fetch().then(() => null);
  }, [id]);

  return (
    <Row>
      {loader && <Loader />}
      <Col xs={12}>
        <Card>
          <CardBody>
            {entityData && (
              <>
                <h3>Category #{entityData.id}</h3>
                <form onSubmit={handleSubmit(onSubmitHandler)}>
                  <InputField {...register("code")} error={errors.code?.message} label="Code" name="code" />
                  <InputField {...register("title")} error={errors.title?.message} label="Title" name="title" />

                  <Button type="submit" color="success">
                    Save
                  </Button>
                  <Button
                    type="button"
                    color="primary"
                    className="ms-2"
                    onClick={() => {
                      updateFormState(entityData).then(() => null);
                    }}
                  >
                    Reset
                  </Button>
                </form>
              </>
            )}
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};
