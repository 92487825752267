import React from "react";

import { Col, Container, Row } from "react-bootstrap";

import alignStyles from "../style/align.module.css";
import colorStyles from "../style/color.module.css";
import overflowStyles from "../style/overflow.module.css";
import positionStyles from "../style/position.module.css";
import sizeStyles from "../style/size.module.css";
import spaceStyles from "../style/space.module.css";

type LayoutProps = {
  header?: React.ReactNode;
  navBar?: React.ReactNode;
  settingBar?: React.ReactNode;
  footer?: React.ReactNode;
  children: React.ReactNode;
};

const Layout: React.FC<LayoutProps> = ({ header, navBar, settingBar, children, footer }) => (
  <div className="canvas-editor">
    <Container fluid className={[sizeStyles.height100].join(" ")}>
      <Row xs={12} className={[sizeStyles.height100, positionStyles.relative, positionStyles.zIndex1].join(" ")}>
        <Col
          xs="auto"
          className={[
            "canvas-navbar",
            colorStyles.darkTheme,
            sizeStyles.height100,
            positionStyles.zIndex3
          ].join(" ")}
        >
          {navBar}
        </Col>
        <Col className={["h-100", overflowStyles.hide, positionStyles.relative, colorStyles.greyTheme].join(" ")}>
          {children}
        </Col>
        <Col
          xs={3}
          className={[colorStyles.darkTheme, sizeStyles.height100, positionStyles.relative, positionStyles.zIndex1].join(
            " "
          )}
        >
          {settingBar}
        </Col>
      </Row>
    </Container>
  </div>
);

export default Layout;
