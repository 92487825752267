import React from "react";

import classNames from "classnames";

import { IProductListingPresetItem } from "../../../../common/interfaces/products.interface";
import { CheckboxFieldInput } from "../../Input/CheckboxFieldInput";
import { TooltipIcon } from "../../TooltipIcon";

interface Props {
  item: IProductListingPresetItem;
  isSelected: boolean;
  onSelect: (item: IProductListingPresetItem, state: boolean) => void;
  onSelectModelsClick: (item: IProductListingPresetItem) => void;
  onDeleteClick?: (item: IProductListingPresetItem) => void;
  hideSelectCheckbox?: boolean;
}

export const ListingGroupItem: React.FC<Props> = ({
  onDeleteClick,
  onSelectModelsClick,
  hideSelectCheckbox,
  isSelected,
  item,
  onSelect
}) => {
  return (
    <div className="listing-group__item">
      <div className="listing-group__item__inner">
        <div
          className={classNames("listing-group__item__checkbox", {
            "listing-group__item__checkbox--hide": hideSelectCheckbox
          })}
        >
          <CheckboxFieldInput
            checked={isSelected}
            hideCheckbox={hideSelectCheckbox}
            green
            onChange={(e) => {
              onSelect(item, e.target.checked);
            }}
            label={
              <div className="listing-group__item__label">
                <div className="listing-group__item__label__title">{item.title}</div>
                <div className="listing-group__item__label__models">{item.groups.map((e) => e.title).join(", ")}</div>
              </div>
            }
          />
          <div className="listing-group__item__action">
            <button
              type="button"
              onClick={() => {
                onSelectModelsClick(item);
              }}
            >
              Select models{" "}
              <TooltipIcon
                hoverable
                id={`listing-tt-${item.id}`}
                content="Select or deselect models that you want to be included in the list."
              />
            </button>
          </div>
        </div>
        {!item.is_default && onDeleteClick ? (
          <button className="listing-group__item__delete" onClick={() => onDeleteClick(item)} type="button">
            <i className="bx bx-trash-alt" />
          </button>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
