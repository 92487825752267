import { useState } from "react";

import { toast } from "react-toastify";

import { IDeliveryServiceItem } from "../../common/interfaces/backoffice.interface";
import { getErrorMessage } from "../../common/utils/formatter.util";
import { getObjectDelta } from "../../common/utils/object.util";
import { useServiceContainer } from "../useServiceContainer";

export const useDeliveryServiceItemModal = (
  serviceId: number,
  item: IDeliveryServiceItem | null,
  onUpdated: (updatedItem: IDeliveryServiceItem) => void,
  onCreated: (updatedItem: IDeliveryServiceItem) => void,
  onSubmit: () => void
) => {
  const [loader, setLoader] = useState(false);
  const { backofficeService } = useServiceContainer();

  const handleItemUpdate = async (_item: IDeliveryServiceItem, data: any) => {
    const delta = getObjectDelta(_item, {
      country_code: data.country_code,
      weight_from: data.weight_from,
      weight_to: data.weight_to,
      delivery_time: data.delivery_time,
      price: data.price,
      is_tracking: data.is_tracking
    });
    return backofficeService.updateDeliveryServiceItemById(_item.service_id, _item.id, delta);
  };

  const handleItemCreate = async (data: any) => {
    return backofficeService.createDeliveryServiceItem(serviceId, {
      country_code: data.country_code,
      weight_from: data.weight_from,
      weight_to: data.weight_to,
      delivery_time: data.delivery_time,
      price: data.price,
      is_tracking: data.is_tracking
    });
  };

  const onSubmitHandler = async (data: any) => {
    try {
      setLoader(true);

      if (item) {
        const updatedItem = await handleItemUpdate(item, data);
        onUpdated(updatedItem);
        toast.success("Delivery item updated successfully");
      } else {
        const createdItem = await handleItemCreate(data);
        onCreated(createdItem);
        toast.success("Delivery item created successfully");
      }

      // toggle();
      // reset();
      onSubmit();
    } catch (e) {
      console.error(e);
      toast.error(getErrorMessage(e));
    } finally {
      setLoader(false);
    }
  };

  return {
    onSubmitHandler,
    loader
  };
};
