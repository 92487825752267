import React, { useEffect, useMemo, useState } from "react";

import classNames from "classnames";
import { useFormContext } from "react-hook-form";
import Sticky from "react-stickynode";
import { Alert, Collapse } from "reactstrap";

import { OrderDeliveryData, OrderInstance } from "../../../../common/interfaces/order-wizard.interface";
import { IDeliveryOption } from "../../../../common/interfaces/orders.interface";
import useAuth from "../../../../hooks/useAuth";
import { useServiceContainer } from "../../../../hooks/useServiceContainer";
import { useAppDispatch, useAppSelector } from "../../../../store/store";
import {
  ORDER_ACTION_UPDATE_COUNTRY_LIST,
  ORDER_ADD_INVALID_DELIVERY_KEY,
  ORDER_GET_INSTANCE,
  ORDER_INIT_NEW_ORDER,
  ORDER_REMOVE_INVALID_DELIVERY_KEY,
  ORDER_SET_DELIVERY_DATA,
  ORDER_SET_DELIVERY_DATA_VALUE
} from "../../../../store/wizards/orderSlice";
import { CollapseHeading } from "../../../common/Collapse/CollapseHeading";
import { DeliverySelect } from "../../../common/DeliverySelect/DeliverySelect";
import { InputField } from "../../../common/InputField";
import { Loader } from "../../../common/Loader";
import { MethodSelect } from "../../../common/MethodSelect/MethodSelect";
import { OrderSummaryCart } from "../../../common/OrderSummaryCart/OrderSummaryCart";
import { SelectField, SelectOption } from "../../../common/SelectField";
import { TooltipIcon } from "../../../common/TooltipIcon";
import { InsertAddress } from "../../../modals/set-delivery/InsertAddress";

export const ProductDeliveryStep = () => {
  const dispatch = useAppDispatch();
  const { user } = useAuth();
  const { ordersService } = useServiceContainer();
  const orders = useAppSelector((state) => state.orderWizard.orders);
  const summary = useAppSelector((state) => state.orderWizard.summaryData);
  const { data: countryList, loader } = useAppSelector((state) => {
    return state.orderWizard.countryList;
  });

  const selectedProduct = useAppSelector((state) => {
    const order = ORDER_GET_INSTANCE(state.orderWizard) as OrderInstance;
    return order.selectedProductType;
  });

  const deliveryData = useAppSelector((state) => {
    return state.orderWizard.deliveryData;
  });

  const visibleOrders = useMemo(() => {
    return orders.filter((order) => {
      return !!order.selectedProductType;
    });
  }, [orders]);

  const [deliveryOptions, setDeliveryOptions] = useState<IDeliveryOption[]>([]);
  const [isInsertAddressOpen, setIsInsertAddressOpen] = useState(false);

  const {
    formState: { errors },
    reset,
    setValue,
    trigger
  } = useFormContext();

  const handleFieldChange = async (fieldName: keyof OrderDeliveryData, value: any) => {
    dispatch(
      ORDER_SET_DELIVERY_DATA_VALUE({
        key: fieldName,
        value
      })
    );

    setValue(fieldName, value);

    const isValid = await trigger(fieldName as any);

    if (!isValid) {
      dispatch(ORDER_ADD_INVALID_DELIVERY_KEY(fieldName));
    } else {
      dispatch(ORDER_REMOVE_INVALID_DELIVERY_KEY(fieldName));
    }
  };

  const fetchDeliveryOptions = async (_country: string, _weight: number) => {
    if (!selectedProduct) return;
    const res = await ordersService.getDeliveryOptions(selectedProduct.id, _country, _weight);

    setDeliveryOptions(res);
  };

  useEffect(() => {
    if (deliveryData.country && summary?.totalWeight) {
      fetchDeliveryOptions(deliveryData.country, summary.totalWeight).then(() => null);
    }
  }, [deliveryData.country, summary]);

  useEffect(() => {
    dispatch(ORDER_ACTION_UPDATE_COUNTRY_LIST());
  }, []);

  return (
    <>
      {loader && <Loader />}

      <h4 className="mt-3 mb-3">Cart</h4>
      <OrderSummaryCart items={visibleOrders} extended />

      <button
        className="btn btn-link mt-4 mb-3 p-0 fw-bold"
        onClick={() => {
          dispatch(ORDER_INIT_NEW_ORDER());
        }}
      >
        + Add another product
      </button>

      <hr />

      <CollapseHeading onClick={(v) => setIsInsertAddressOpen(v)}>
        <h5 className="mt-3 mb-3">
          Insert address from E-SHOP{" "}
          <TooltipIcon
            id="insert-address-tt"
            content="Paste delivery address from Etsy, Shopify, Ebay to auto-fill address fields"
          />
        </h5>
      </CollapseHeading>

      <Collapse isOpen={isInsertAddressOpen}>
        <InsertAddress
          onInsert={(data) => {
            const countryCode =
              countryList.find((c) => c.label === data.country || data.country.includes(c.label))?.value ?? "";
            const newData = {
              ...(deliveryData as OrderDeliveryData),
              fullName: data.name,
              addressLine1: data.addressLine1,
              addressLine2: data.addressLine2,
              city: data.city,
              zipCode: data.zipCode,
              country: countryCode
            };
            handleFieldChange("fullName", newData.fullName);
            handleFieldChange("addressLine1", newData.addressLine1);
            handleFieldChange("addressLine2", newData.addressLine2);
            handleFieldChange("city", newData.city);
            handleFieldChange("zipCode", newData.zipCode);
            handleFieldChange("country", newData.country);
          }}
        />
      </Collapse>

      <hr />

      <div className="row mt-4">
        <div className="col-6">
          <h5 className="mb-3">Contact information</h5>

          <InputField
            onChange={(e) => {
              handleFieldChange("fullName", e.target.value);
            }}
            value={deliveryData.fullName}
            error={errors.fullName?.message}
            label="Full name"
            name="fullName"
            required
            placeholder="Enter your name"
          />

          <InputField
            onChange={(e) => {
              handleFieldChange("phone", e.target.value);
            }}
            value={deliveryData.phone}
            error={errors.phone?.message}
            label="Phone"
            name="phone"
            placeholder="Enter your phone"
          />

          <h5 className="mt-5 mb-3">Delivery Address</h5>
          <InputField
            onChange={(e) => {
              handleFieldChange("addressLine1", e.target.value);
            }}
            required
            value={deliveryData.addressLine1}
            error={errors.addressLine1?.message}
            label="Address line 1"
            name="addressLine1"
            placeholder="Enter address"
          />

          <InputField
            onChange={(e) => {
              handleFieldChange("addressLine2", e.target.value);
            }}
            value={deliveryData.addressLine2}
            error={errors.addressLine2?.message}
            label="Address line 2"
            name="addressLine2"
            placeholder="Enter address"
          />

          <InputField
            onChange={(e) => {
              handleFieldChange("city", e.target.value);
            }}
            required
            value={deliveryData.city}
            error={errors.city?.message}
            label="City"
            name="city"
            placeholder="Enter city"
          />

          <InputField
            onChange={(e) => {
              handleFieldChange("zipCode", e.target.value);
            }}
            required
            value={deliveryData.zipCode}
            error={errors.zipCode?.message}
            label="Zip code"
            name="zipCode"
            placeholder="Enter zip code"
          />

          <InputField
            onChange={(e) => {
              handleFieldChange("state", e.target.value);
            }}
            value={deliveryData.state}
            error={errors.state?.message}
            label="State"
            name="state"
            placeholder="Enter state"
          />

          <SelectField
            options={countryList}
            label="Country"
            placeholder="Select country"
            required
            menuPlacement="top"
            error={errors.country?.message}
            value={countryList.find((item) => item.value === deliveryData.country)}
            onChange={(data) => {
              handleFieldChange("country", data.value);
            }}
          />
        </div>
        <div className="col-6">
          <Sticky top={125}>
            <h5 className="mb-3">Delivery</h5>

            {!deliveryOptions.length ? (
              <Alert>Please select country to see delivery options</Alert>
            ) : (
              <DeliverySelect
                items={deliveryOptions}
                onItemSelect={(item) => {
                  handleFieldChange("deliveryOption", item.id);

                  if (item.name.includes("Local pick up")) {
                    handleFieldChange("addressLine1", "Brīvības 338-101A");
                    handleFieldChange("city", "Rīga");
                    handleFieldChange("zipCode", "LV-1006");
                  }
                }}
              />
            )}

            <h5 className="mb-3">Payment method</h5>

            <MethodSelect
              items={user!.payment_methods}
              onItemSelect={(item) => {
                handleFieldChange("paymentMethod", item);
              }}
            />
          </Sticky>
        </div>
      </div>
    </>
  );
};
