import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IUserWithRole } from "../../common/interfaces/user.interface";
import type { RootState } from "../store";

interface UserState {
  value: IUserWithRole | null;
}

const initialState: UserState = {
  value: null
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state: UserState, action: PayloadAction<IUserWithRole | null>) => {
      state.value = action.payload;
    },
    setUserIsIntroductionPassed: (state: UserState, action: PayloadAction<boolean>) => {
      if (state.value) {
        state.value.is_introduction_passed = action.payload;
      }
    }
  }
});

export const { setUser, setUserIsIntroductionPassed } = userSlice.actions;

export const selectUser = (state: RootState) => state.user.value;

export default userSlice.reducer;
