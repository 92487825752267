import React, { useMemo, useState } from "react";

import BootstrapTable from "react-bootstrap-table-next";
import filterFactory from "react-bootstrap-table2-filter";
// @ts-ignore
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { Link, useParams } from "react-router-dom";
import { Button, Card, CardBody, Col, Row } from "reactstrap";

import { IDeliveryService } from "../../../common/interfaces/backoffice.interface";
import { Loader } from "../../../components/common/Loader";
import { useDeliveryServiceTable } from "../../../hooks/deliveryService/useDeliveryServiceTable";
import { useProductDeliveryOptions } from "../../../hooks/useProductDeliveryOptions";

export const ProductDeliveryServiceManagement = () => {
  const { id } = useParams();
  const { items: allServices, loader: allServicesLoader, onTableChange } = useDeliveryServiceTable();
  const {
    items: productServices,
    loader: productServiceLoader,
    handleAddServiceItem,
    handleRemoveServiceItem
  } = useProductDeliveryOptions(+id!);

  const formattedServices = useMemo(() => {
    return allServices.map((e) => {
      const targetItem = productServices.find((item) => item.delivery_service_id === e.id);

      return {
        ...e,
        id: targetItem?.id ?? e.id,
        is_enabled: !!targetItem
      };
    });
  }, [allServices, productServices]);

  const columns = [
    {
      dataField: "id",
      text: "Id",
      sort: false,
      style: {
        minWidth: "90px"
      }
    },
    {
      dataField: "title",
      text: "Title",
      style: {
        maxWidth: "200px"
      },
      classes: "text-truncate",
      sort: false
    },
    {
      dataField: "is_api",
      text: "Is API",
      sort: false
    },
    {
      dataField: "delivery_count",
      text: "Delivery count",
      sort: false
    },
    {
      text: "Actions",
      dataField: "is_enabled",
      formatter: (cellContent: any, row: IDeliveryService) => {
        // const isEnabled = productServices.find((item) => item.delivery_service_id === row.id);
        // console.log("IS ENABLED", isEnabled);
        // console.log("PRODUCT SERVICES", productServices);
        return (
          <>
            <Button
              size="sm"
              color={cellContent ? "danger" : "success"}
              onClick={() => {
                if (cellContent) {
                  handleRemoveServiceItem(row.id).then(() => null);
                } else {
                  handleAddServiceItem(row.id).then(() => null);
                }
              }}
            >
              {cellContent ? "Disable" : "Enable"}
            </Button>

            <Link
              className="btn btn-primary btn-sm ms-1"
              color="primary"
              target="_blank"
              to={`/backoffice/delivery/${row.id}/edit`}
            >
              Edit
            </Link>
          </>
        );
      }
    }
  ];

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc"
    }
  ] as any;

  return (
    <div className="data-table">
      <h3>
        <Link to={`/backoffice/products/${id}/edit`} className="btn btn-primary btn-sm me-3 mb-1">
          <i className="bx bx-arrow-back" /> Back
        </Link>
        Product #{id} delivery services management
      </h3>
      <Card className="border">
        {(allServicesLoader || productServiceLoader) && <Loader />}
        <CardBody>
          <ToolkitProvider keyField="id" columns={columns} data={formattedServices} search>
            {(toolkitProps: any) => {
              return (
                <>
                  <Row>
                    <Col xl="12">
                      <div className="table-responsive">
                        <BootstrapTable
                          bootstrap4
                          remote
                          onTableChange={onTableChange}
                          bordered={false}
                          striped={false}
                          defaultSorted={defaultSorted}
                          filter={filterFactory()}
                          classes="table align-middle table-nowrap table-sm"
                          headerWrapperClasses="thead-light"
                          {...toolkitProps.baseProps}
                          data={formattedServices}
                          keyField="id"
                        />
                      </div>
                    </Col>
                  </Row>
                </>
              );
            }}
          </ToolkitProvider>
        </CardBody>
      </Card>
    </div>
  );
};
