import { isEmpty } from "lodash";
import * as Yup from "yup";

import { replaceKeywords } from "../../components/wizards/product/utils/submit-body-builder.util";

export const SELECTED_LISTING_VALIDATION_SCHEMA = (options: {
  includeKeywords?: boolean;
  includeSectionAndShippingProfile?: boolean;
}) => {
  return {
    title: Yup.string()
      .required("Please enter product title")
      .test({
        name: "max",
        exclusive: false,
        params: {},
        message: "Max 140 characters including keyword 1 and 2",
        test(value) {
          const checkValue = replaceKeywords(value, this.parent.keywords);
          return checkValue.length <= 140;
        }
      }),
    // .max(140, "Max 140 characters including keyword 1 and 2"),
    description: Yup.string().required("Please enter product description"),
    ...(options.includeSectionAndShippingProfile && {
      section: Yup.string().required("Please select section"),
      shipping_profile: Yup.string().required("Please select shipping profile")
    }),
    ...(options.includeKeywords && {
      keywords: Yup.object().shape({
        keyword1: Yup.string().max(20, "Max 20 characters").required("Please enter keyword1"),
        keyword2: Yup.string().max(20, "Max 20 characters").required("Please enter keyword2")
      })
    }),
    tags: Yup.array()
      .of(Yup.string().max(20, "Max 20 characters"))
      .min(1, "Please select at least one tag")
      .max(13, "Max 13 tags")
      .typeError("Please select at least one tag"),
    materials: Yup.array()
      .of(Yup.string().max(45, "Max 45 characters"))
      .min(1, "Please select at least one material")
      .max(13, "Max 13 materials")
      .typeError("Please select at least one material")
  };
};

export const LISTING_PRICE_MAP_VALIDATION_SCHEMA = Yup.lazy((value) => {
  if (!isEmpty(value)) {
    // value is {listingId: {assetId: price}}

    const newEntries = Object.keys(value).reduce(
      (acc, val) => ({
        ...acc,
        [val]: Yup.object().shape(
          Object.keys(value[val]).reduce(
            (acc2, val2) => ({
              ...acc2,
              [val2]: Yup.number().min(0.18, "Minimal price is 0.18").required("Please enter price")
            }),
            {}
          )
        )
      }),
      {}
    );

    return Yup.object().shape(newEntries);
  }
  return Yup.mixed().required();
});
