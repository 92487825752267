import React, { useEffect } from "react";

import { useFormContext } from "react-hook-form";

import { OrderInstance } from "../../../../common/interfaces/order-wizard.interface";
import { IProductItem } from "../../../../common/interfaces/products.interface";
import { useAppDispatch, useAppSelector } from "../../../../store/store";
import { ORDER_GET_INSTANCE, ORDER_SET_SELECTED_PRODUCT_TYPE } from "../../../../store/wizards/orderSlice";
import { PRODUCT_TYPES_ACTION_UPDATE_LIST } from "../../../../store/wizards/ThunkActions/productTypesSliceThunkActions";
import { ProductTypesList } from "../../../common/ProductTypes/ProductTypesList";

export const ProductSelectionStep = () => {
  const dispatch = useAppDispatch();
  const selectedProduct = useAppSelector((state) => {
    const order = ORDER_GET_INSTANCE(state.orderWizard) as OrderInstance;
    return order.selectedProductType;
  });

  const {
    register,
    setValue,
    setError,
    formState: { errors }
  } = useFormContext();

  useEffect(() => {
    register("productType");
  }, [register]);

  useEffect(() => {
    if (!selectedProduct) return;
    setValue("productType", selectedProduct.id);
  }, [selectedProduct]);

  useEffect(() => {
    dispatch(PRODUCT_TYPES_ACTION_UPDATE_LIST());
  }, []);

  return (
    <>
      <ProductTypesList
        hidePrice={false}
        onSelect={(item) => {
          setError("productType", {
            type: "manual",
            message: ""
          });
          dispatch(ORDER_SET_SELECTED_PRODUCT_TYPE(item as IProductItem));
        }}
        selectedProduct={selectedProduct}
        error={errors.productType?.message}
      />
    </>
  );
};
