import React from "react";

import { useDispatch } from "react-redux";
import { Button } from "reactstrap";

import { BACKOFFICE_SET_ORDER_SET_TRACKING_NUMBER_MODAL_STATE } from "../../../store/backoffice/backofficeSlice";

interface Props {
  orderId: string;
}

export const OrderTrackingNumberSetter: React.FC<Props> = ({ orderId }) => {
  const dispatch = useDispatch();

  const handleButtonClick = () => {
    dispatch(
      BACKOFFICE_SET_ORDER_SET_TRACKING_NUMBER_MODAL_STATE({
        orderId,
        show: true
      })
    );
  };

  return (
    <Button size="sm" onClick={handleButtonClick} className="px-3">
      <i className="bx bx-error" /> Set
    </Button>
  );
};
