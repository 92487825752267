import React, { useCallback, useEffect, useMemo, useState } from "react";

import { FormProvider } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";

import {
  AUTO_MOCKUP_WIZARD_STEPS_DATA,
  AutoMockupWizardStep
} from "../../../../common/interfaces/mockup-wizard.interface";
import { IMockupEntity, IMockupFile } from "../../../../common/interfaces/mockup.inteface";
import { IProductItem } from "../../../../common/interfaces/products.interface";
import { dataURLtoFile } from "../../../../common/utils/file.util";
import { getErrorMessage } from "../../../../common/utils/formatter.util";
import { useCustomForm } from "../../../../hooks/useCustomForm";
import { useServiceContainer } from "../../../../hooks/useServiceContainer";
import { useAppDispatch, useAppSelector } from "../../../../store/store";
import { DESIGNS_SET } from "../../../../store/wizards/productDesignsSlice";
import { PRODUCT_TYPES_SET_SELECTED } from "../../../../store/wizards/productTypesSlice";
import { Loader } from "../../../common/Loader";
import { WizardStepControls } from "../../../wizards/common/WizardStepControls";
import { WizardStepNavigation } from "../../../wizards/common/WizardStepNavigation";
import { ProductTypeSelect } from "../common/ProductTypeSelect";
import { MockupDesignSelect } from "./DesignSelect";
import { GeneratedMockups } from "./GeneratedMockups";
import { AutoMockupGeneratorSettings } from "./Settings";

// const DESIGN_COUNT_LIMITATIONS = [1, 3, 5];

interface Props {
  onSubmit: (mockupEntity: IMockupEntity) => void;
  onReset: () => void;
  predefinedData: {
    productId?: string;
    designIds?: string[];
    store?: string;
  };
}

const STEPS_MAP = {
  [AutoMockupWizardStep.PRODUCT_TYPE_SELECT]: {
    next: AutoMockupWizardStep.DESIGN_SELECT,
    previous: null
  },
  [AutoMockupWizardStep.DESIGN_SELECT]: {
    next: AutoMockupWizardStep.SETTINGS,
    previous: AutoMockupWizardStep.PRODUCT_TYPE_SELECT
  },
  [AutoMockupWizardStep.SETTINGS]: {
    next: null,
    previous: AutoMockupWizardStep.DESIGN_SELECT
  }
};

export const AutoMockupWizard: React.FC<Props> = ({ onSubmit, onReset, predefinedData }) => {
  const [step, setStep] = useState<AutoMockupWizardStep>(AutoMockupWizardStep.PRODUCT_TYPE_SELECT);
  const [loader, setLoader] = useState(false);
  const { mockupService, ordersService } = useServiceContainer();
  const dispatch = useAppDispatch();

  const [generatedMockups, setGeneratedMockups] = useState<IMockupEntity | null>(null);

  const stateValues = useAppSelector((state) => ({
    selectedProductType: state.productTypes.selectedProduct,
    selectedDesigns: state.productDesigns.selectedDesigns
  }));

  const externalStore = useAppSelector((state) => {
    if (predefinedData.store === "product") {
      return state.productWizard;
    }

    return null;
  });

  const wizardFirstStep = useMemo(() => {
    if (predefinedData.designIds && predefinedData.productId) {
      return AutoMockupWizardStep.SETTINGS;
    }
    if (predefinedData.productId) {
      return AutoMockupWizardStep.DESIGN_SELECT;
    }

    return AutoMockupWizardStep.PRODUCT_TYPE_SELECT;
  }, [predefinedData]);

  const isPredefinedMockupSettings = useMemo(() => {
    return !!predefinedData.designIds && !!predefinedData.productId;
  }, [predefinedData]);

  const methods = useCustomForm({
    schema: Yup.object().shape({})
  });

  const { handleSubmit, updateSchema, getValues, watch } = methods;

  const previewFiles = watch("previewFiles");

  const formCanBeSubmitted = useMemo(() => {
    return !!previewFiles?.length;
  }, [previewFiles]);

  const handleReset = () => {
    setGeneratedMockups(null);
    methods.reset();
    onReset();
    setStep(AutoMockupWizardStep.PRODUCT_TYPE_SELECT);
  };

  const stepContent = useMemo(() => {
    switch (step) {
      case AutoMockupWizardStep.PRODUCT_TYPE_SELECT:
        return <ProductTypeSelect />;
      case AutoMockupWizardStep.DESIGN_SELECT:
        return <MockupDesignSelect />;
      case AutoMockupWizardStep.SETTINGS:
        return <AutoMockupGeneratorSettings />;
      case AutoMockupWizardStep.GENERATED_MOCKUPS:
        return (
          <GeneratedMockups
            onGenerateNewMockup={() => {
              handleReset();
            }}
            data={generatedMockups as IMockupEntity}
          />
        );
      default:
        return <>not implemented</>;
    }
  }, [step, onSubmit, generatedMockups, predefinedData]);

  const handleStepChange = useCallback((newStep: AutoMockupWizardStep) => {
    setStep(newStep);
  }, []);

  const onSubmitHandler = () => {
    const current = (STEPS_MAP as any)[step];
    if (!current) return;
    if (current.next) {
      setStep(current.next);
    } else {
      const formValues = getValues();
      saveMockups(formValues).then(() => null);
    }
  };

  const saveMockups = async (values: any) => {
    setLoader(true);
    try {
      const res = await mockupService.uploadMockups({
        product_id: values.productType,
        sku_number: values.skuNumber,
        files: values.previewFiles.map((e: IMockupFile) => dataURLtoFile([e.image!, e.name!]))
      });

      toast.success("Mockups saved successfully");
      onSubmit(res);
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    switch (step) {
      case AutoMockupWizardStep.PRODUCT_TYPE_SELECT:
        updateSchema(
          Yup.object().shape({
            productType: Yup.string().required("Please select product")
          })
        );
        break;
      case AutoMockupWizardStep.DESIGN_SELECT:
        updateSchema(
          Yup.object().shape({
            selectedDesigns: Yup.array()
              .min(1, "Please select at least one design")
              .max(5, "Please select no more than 5 designs")
              .required("Please select at least one design")
          })
        );
        break;
      case AutoMockupWizardStep.SETTINGS:
        updateSchema(
          Yup.object().shape({
            skuNumber: Yup.string()
              .matches(/^[\w_'-]+$/, "Only letters and numbers are allowed")
              .required("This field is required"),
            assetModel: Yup.string().required("This field is required"),
            assetColor: Yup.string().required("This field is required"),
            mockupBackgroundColor: Yup.string().required("This field is required"),
            optionsShowNumbers: Yup.boolean(),
            previewFiles: Yup.array().of(Yup.mixed())
          })
        );
        break;
      default:
    }
  }, [step]);

  const wizardPassedSteps = useMemo(() => {
    const steps = [];

    if (!stateValues) return [];

    if (stateValues.selectedProductType && step !== AutoMockupWizardStep.PRODUCT_TYPE_SELECT) {
      steps.push(AutoMockupWizardStep.PRODUCT_TYPE_SELECT);
    }

    if (stateValues.selectedDesigns.length && step !== AutoMockupWizardStep.DESIGN_SELECT) {
      steps.push(AutoMockupWizardStep.DESIGN_SELECT);
    }

    return steps;
  }, [stateValues]);

  const initPredefinedData = async () => {
    if (!externalStore) return;

    let product: IProductItem | null = null;

    if (predefinedData.productId) {
      try {
        product = externalStore.selectedProductType ?? (await ordersService.getProductById(+predefinedData.productId!));
        dispatch(PRODUCT_TYPES_SET_SELECTED(product));
        methods.setValue("productType", product.id);
      } catch (e) {
        toast.error(getErrorMessage(e));
        return;
      }
    }

    if (predefinedData.designIds) {
      try {
        const designsFromExternalStore = externalStore.selectedDesigns.filter((e) =>
          predefinedData!.designIds!.includes(e.id.toString())
        );
        const designs = designsFromExternalStore.length
          ? designsFromExternalStore
          : await ordersService
              .getDesignList(
                product!.type,
                product!.id,
                undefined,
                undefined,
                predefinedData!.designIds!.map((e) => +e)
              )
              .then((res) => res.items);

        dispatch(DESIGNS_SET(designs));
        methods.setValue(
          "selectedDesigns",
          predefinedData!.designIds!.map((e) => +e)
        );
      } catch (e) {
        toast.error(getErrorMessage(e));
      }
    }
  };

  useEffect(() => {
    initPredefinedData().then(() => null);

    if (predefinedData.designIds && predefinedData.productId) {
      setStep(AutoMockupWizardStep.SETTINGS);
    } else if (predefinedData.productId) {
      setStep(AutoMockupWizardStep.DESIGN_SELECT);
    }
  }, [predefinedData]);

  return (
    <div className="row h-100">
      {loader && <Loader />}
      <div className="col-8 mx-auto">
        <div className="row flex-column h-100">
          {step !== AutoMockupWizardStep.GENERATED_MOCKUPS && !isPredefinedMockupSettings && (
            <div className="col-auto mx-auto">
              <WizardStepNavigation
                passedSteps={wizardPassedSteps}
                stepsData={AUTO_MOCKUP_WIZARD_STEPS_DATA({
                  disableDesignStep: !!predefinedData.designIds,
                  disableProductStep: !!predefinedData.productId
                })}
                activeStep={step}
                onStepChange={(e) => setStep(e)}
              />
            </div>
          )}
          <div className="col-auto flex-grow-1">
            <FormProvider {...methods}>
              <form
                onSubmit={handleSubmit(onSubmitHandler)}
                className="wizard-step-scrollable-container-wrapper-ref"
                style={{
                  height: "calc(100% - 70px)"
                }}
              >
                <div className="row">
                  <div className="col-12 mt-3">{stepContent}</div>
                  <div className="col-12">
                    {step !== AutoMockupWizardStep.GENERATED_MOCKUPS && (
                      <WizardStepControls
                        smallTopOffset
                        lastStepIsSubmit
                        canBeSubmitted={formCanBeSubmitted}
                        firstStep={wizardFirstStep}
                        stepsMap={STEPS_MAP}
                        activeStep={step}
                        onMove={handleStepChange}
                      />
                    )}
                  </div>
                </div>
              </form>
            </FormProvider>
          </div>
        </div>
      </div>
    </div>
  );
};
