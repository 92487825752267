import React, { useEffect, useMemo, useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Button } from "reactstrap";
import { number, object, string } from "yup";

import { IDeliveryService } from "../../../common/interfaces/backoffice.interface";
import { getErrorMessage } from "../../../common/utils/formatter.util";
import { getObjectDelta } from "../../../common/utils/object.util";
import { useServiceContainer } from "../../../hooks/useServiceContainer";
import { TextAreaInput } from "../../common/Input/TextAreaInput";
import { InputField } from "../../common/InputField";
import { Loader } from "../../common/Loader";
import { SelectField } from "../../common/SelectField";

const validationSchema = object().shape({
  title: string().required("This field is required"),
  is_api: number().required("This field is required"),
  info: string()
});

interface Props {
  itemId: number;
}

export const EditDeliveryServiceForm: React.FC<Props> = ({ itemId }) => {
  const {
    formState: { errors },
    handleSubmit,
    register,
    reset,
    watch,
    setValue,
    setError
  } = useForm({
    defaultValues: {
      title: "",
      info: "",
      is_api: 0
    },
    resolver: yupResolver(validationSchema)
  });

  const [loader, setLoader] = useState(false);
  const [item, setItem] = useState<IDeliveryService | null>(null);

  const { backofficeService } = useServiceContainer();

  const isApi = watch("is_api");

  const isApiOptions = useMemo(() => {
    return [
      {
        label: "Yes",
        value: "1"
      },
      {
        label: "No",
        value: "0"
      }
    ];
  }, []);

  const isApiSelectedOption = useMemo(() => {
    return isApiOptions.find((option) => +option.value === isApi) ?? null;
  }, [isApi, isApiOptions]);

  const onSubmitHandler = async (data: any) => {
    try {
      if (!item) return;
      setLoader(true);
      const delta = getObjectDelta(item, {
        title: data.title,
        is_api: data.is_api,
        info: data.info
      });
      const updatedItem = await backofficeService.updateDeliveryServiceById(item.id, delta);

      toast.success("Delivery service updated successfully");

      setItem(updatedItem);
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setLoader(false);
    }
  };

  const fetchItem = async () => {
    try {
      const res = await backofficeService.getDeliveryServiceById(itemId);

      setItem(res);
    } catch (e) {
      toast.error(getErrorMessage(e));
    }
  };

  useEffect(() => {
    if (!item) {
      reset();
      return;
    }
    reset({
      is_api: item.is_api,
      title: item.title,
      info: item.info ?? ""
    });
  }, [item]);

  useEffect(() => {
    fetchItem().then(() => null);
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)}>
      {loader && <Loader />}
      {item && (
        <>
          <InputField readOnly label="Service id" name="id" type="text" value={item.id.toString()} />
          <InputField
            {...register("title")}
            error={errors.title?.message}
            label="Enter title"
            name="title"
            type="text"
            placeholder="Enter title"
          />
          <TextAreaInput
            {...register("info")}
            error={errors.info?.message}
            label="Enter delivery info"
            name="info"
            placeholder="Enter delivery info"
          />
          <SelectField
            options={isApiOptions}
            label="Is api"
            error={errors.is_api?.message}
            value={isApiSelectedOption}
            placeholder="Search.."
            onChange={(data) => {
              setValue("is_api", +data.value);
              setError("is_api", {
                type: "manual",
                message: ""
              });
            }}
          />
          <Button color="primary" type="submit">
            Save
          </Button>
        </>
      )}
    </form>
  );
};
