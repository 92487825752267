import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface OrderSetTrackingNumberModal {
  show: boolean;
  orderId?: string;
}

interface BackofficeState {
  orderSetTrackingNumberModal: OrderSetTrackingNumberModal;
}

export const backofficeInitialState: BackofficeState = {
  orderSetTrackingNumberModal: {
    show: false
  }
};

export const backofficeSlice = createSlice({
  name: "backoffice",
  initialState: backofficeInitialState,
  reducers: {
    BACKOFFICE_SET_ORDER_SET_TRACKING_NUMBER_MODAL_STATE: (
      state: BackofficeState,
      action: PayloadAction<OrderSetTrackingNumberModal>
    ) => {
      state.orderSetTrackingNumberModal.orderId = action.payload.orderId;
      state.orderSetTrackingNumberModal.show = action.payload.show;
    }
  }
});

export const { BACKOFFICE_SET_ORDER_SET_TRACKING_NUMBER_MODAL_STATE } = backofficeSlice.actions;

export default backofficeSlice.reducer;
