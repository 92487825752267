import React from "react";

import classNames from "classnames";
import { saveAs } from "file-saver";
import { Link } from "react-router-dom";
import { Button, Card, Col, Row } from "reactstrap";

import { ExtendedFile } from "../../../common/interfaces/vendor.interface";

interface Props {
  file: ExtendedFile;
  deletable?: boolean;
  onDelete?: (file: ExtendedFile) => void;
  className?: string;
  downloadable?: boolean;
}

export const UploadedFileItem: React.FC<Props> = ({ downloadable, file, deletable, onDelete, className }) => {
  return (
    <Card
      className={classNames(
        "mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete",
        className
      )}
    >
      <div className="p-2">
        <Row className="align-items-center">
          <Col className="col-auto">
            <img
              data-dz-thumbnail=""
              height="80"
              className="avatar-sm rounded bg-light"
              alt={file.name}
              src={file.preview}
            />
          </Col>
          <Col>
            <div className="d-flex align-items-center">
              <div>
                <Link to="#" className="text-muted font-weight-bold">
                  {file.name}
                </Link>
                <p className="mb-0">
                  <strong>{file.formattedSize}</strong>
                </p>
              </div>
              {deletable && (
                <div className="ms-auto">
                  <Button
                    color="danger"
                    onClick={() => {
                      if (onDelete) {
                        onDelete(file);
                      }
                    }}
                  >
                    <i className="bx bx-trash-alt" />
                  </Button>
                </div>
              )}
              {downloadable && (
                <div className="ms-auto">
                  <Button
                    color="info"
                    onClick={() => {
                      saveAs(file as File);
                    }}
                  >
                    <i className="bx bx-download" />
                  </Button>
                </div>
              )}
            </div>
          </Col>
        </Row>
      </div>
    </Card>
  );
};
