import React, { useEffect, useMemo, useState } from "react";

import { toast } from "react-toastify";
import { Button, Modal } from "reactstrap";

import { IProductListingItem } from "../../../common/interfaces/products.interface";
import { getErrorMessage } from "../../../common/utils/formatter.util";
import { useServiceContainer } from "../../../hooks/useServiceContainer";
import { Loader } from "../../common/Loader";
import { SelectField } from "../../common/SelectField";

interface Props {
  show: boolean;
  item: IProductListingItem | null;
  toggle: () => void;
  onSubmit: () => void;
}

export const ListingStatusControlModal: React.FC<Props> = ({ onSubmit, show, toggle, item }) => {
  const { productsApiService } = useServiceContainer();
  const [loader, setLoader] = useState(false);

  const [listingStatuses, setListingStatuses] = useState<Record<string, boolean>>({});

  const selectOptions = useMemo(() => {
    return [
      {
        value: "true",
        label: "Active"
      },
      {
        value: "false",
        label: "Draft"
      }
    ];
  }, []);

  const populateListingStatuses = () => {
    if (!item) return;

    const map = Object.fromEntries(item.listings.map((l) => [l.id, l.status as boolean]));

    setListingStatuses(map);
  };

  const handleSubmit = async () => {
    try {
      setLoader(true);
      await productsApiService.setListingStatuses(item!.id as number, listingStatuses);
      toast.success("Listing statuses updated successfully");
      toggle();
      onSubmit();
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (item) {
      populateListingStatuses();
    }
  }, [item]);

  if (!item) {
    return <></>;
  }

  return (
    <Modal isOpen={show} toggle={toggle} centered size="md">
      {loader && <Loader />}
      <div className="modal-header">
        <h5 className="modal-title mt-0">Listing statuses</h5>
        <button
          type="button"
          onClick={() => {
            toggle();
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body pb-5">
        {Object.entries(listingStatuses).map(([id, status]) => {
          const listingItem = item.listings.find((l) => l.id === +id);
          return (
            <SelectField
              options={selectOptions}
              label={`Listing "${listingItem?.fields.title}" status`}
              value={selectOptions.find((o) => (o.value === "true") === status)}
              placeholder="Select status"
              onChange={(data) => {
                setListingStatuses((prev) => ({
                  ...prev,
                  [id]: data.value === "true"
                }));
              }}
            />
          );
        })}

        <div className="mt-4 d-flex justify-content-center">
          <Button type="button" color="primary" onClick={() => handleSubmit()}>
            Save
          </Button>
        </div>
      </div>
    </Modal>
  );
};
